import { useContext, useState } from "react";
import SupportingDocUpload from "./SupportingDocUpload";
import VisitorParamsContext from "../../../../Contexts/VisitorParams";
import { useFollowupUserQuestions } from "../../../../Hooks/useFollowupUserQuestions";


const SupportingDocs = ({
  setSupportDocPopup,
  setSupportDocSucess,
  setAgreementPopup,
  removeFromPending
}) => {
  const { visitorParameters } = useContext(VisitorParamsContext);
  const { saveFollowupUserQuestions } = useFollowupUserQuestions();
  const [docUpload, setDocUpload] = useState(false);

  const query_string = localStorage.getItem("querystring");
  const formdata = JSON.parse(localStorage.getItem("formData"));
  const split_name = localStorage.getItem("split_name");

  const labelclicked = async (event) => {
    let selectedAns = parseInt(event?.target?.getAttribute("data-ans-id"));
    let selectedQuest = parseInt(event?.target?.getAttribute("data-quest-id"));

    const question_data = {
      question_id: selectedQuest,
      option_id: selectedAns,
      answer_text: null,
      input_answer: "",
    };

    const allFollowupData = JSON.parse(localStorage.getItem("allFollowupData"));
    const response = await saveFollowupUserQuestions(
        question_data,
        allFollowupData,
        "followup_question_store"
    );

    let uploadDoc = ["125", "120", "126", "127"];
    if (uploadDoc.includes(event?.target?.value)) {
      setDocUpload(true);
    }
    if ("124" == event?.target?.value) {
      setAgreementPopup(true);
    }
    if ("123" == event?.target?.value) {
      setSupportDocPopup(false);
      setSupportDocSucess(true);
      removeFromPending('supportDoc');
    }
  };

  const closePopup=()=>{
    setSupportDocPopup(false);

  }

  return (
    <div
      className="modal fade show"
      id="quesBackdrop"
      data-bs-backdrop="static"
      data-bs-keyboard="false"
      tabIndex={-1}
      aria-labelledby="quesBackdropLabel"
      aria-modal="true"
      role="dialog"
      style={{ display: "block" }}
    >
      <div className="modal-dialog modal-dialog-centered modal-lg">
        <div className="modal-content">
          <div className="modal-header border border-0">
            <button
              type="button"
              className="btn-close"
              data-bs-dismiss="modal"
              aria-label="Close"
              onClick={closePopup}
            />
          </div>
          <div className="modal-body">
            <ul className="p-0 m-0 questionistmod mb-3">
              <li>
                <div
                  className="questitem q16"
                  id="slide16"
                  style={docUpload ? { display: "none" } : {}}
                >
                  <h1 className="que-title p-3 text-style">
                    What supporting documentation are you able to provide to
                    bolster your complaint with Vanquis?
                  </h1>
                  <div className="question-content mt-0 scale-down-ver-center px-3 py-lg-3 py-md-3 py-sm-2 py-2 pb-3">
                    <div className="row">
                      <div className="col-lg-12 col-md-12 col-sm-12">
                        <div className=" mb-2">
                          <button
                            className="btn-comm2--low-zindex  gb16b  btn-comm2 py-3 w-100  nextBtn"
                            type="button"
                            onClick={labelclicked}
                            value="124"
                            name="question_42"
                            id="agreeone"
                            data-quest-id={42}
                            data-ans-id={124}
                            data-ans-label="Your Credit Agreement Number"
                            data-click-id='supportDoc_creditAgNumber'
                          >
                            Your Credit Agreement Number
                          </button>
                        </div>
                      </div>
                      <div className="col-lg-12 col-md-12 col-sm-12">
                        <div className=" mb-2">
                          <button
                            className=" btn-comm2--low-zindex gb16b btn-comm2 py-lg-3 py-md-3 py-sm-2 py-2 w-100 nextBtn"
                            onClick={labelclicked}
                            type="button"
                            value="125"
                            name="question_42"
                            id="agreetwo"
                            data-quest-id={42}
                            data-ans-id={125}
                            data-ans-label="Any Documents Relating to Your Credit Agreement"
                            data-click-id='supportDoc_documentsRelated'
                          >
                            Any Documents Relating to Your Credit Agreement
                          </button>
                        </div>
                      </div>
                      <div className="col-lg-12 col-md-12 col-sm-12">
                        <div className=" mb-2">
                          <button
                            className=" btn-comm2--low-zindex gb16b btn-comm2 py-lg-3 py-md-3 py-sm-2 py-2 w-100 nextBtn"
                            // onClick={() => setDocUpload(true)}
                            onClick={labelclicked}
                            type="button"
                            value="120"
                            name="question_42"
                            id="agreethree"
                            data-quest-id={42}
                            data-ans-id={120}
                            data-ans-label="A Statement of Account"
                            data-click-id='supportDoc_statementOfAccount'
                          >
                            A Statement of Account
                          </button>
                        </div>
                      </div>
                      <div className="col-lg-12 col-md-12 col-sm-12">
                        <div className=" mb-2">
                          <button
                            className=" btn-comm2--low-zindex gb16b btn-comm2 py-lg-3 py-md-3 py-sm-2 py-2 w-100 nextBtn"
                            onClick={labelclicked}
                            type="button"
                            value="126"
                            name="question_42"
                            id="agreefour"
                            data-quest-id={42}
                            data-ans-id={126}
                            data-ans-label="BANK STATEMENTS (3 months before and after the start of your credit(s)"
                            data-click-id='supportDoc_bankStatements'
                          >
                            Bank Statements (One Month Before and After the
                            Start of Your Credit(s)
                          </button>
                        </div>
                      </div>
                      <div className="col-lg-12 col-md-12 col-sm-12">
                        <div className=" mb-2">
                          <button
                            className=" btn-comm2--low-zindex gb16b btn-comm2 py-lg-3 py-md-3 py-sm-2 py-2 w-100 nextBtn"
                            onClick={labelclicked}
                            type="button"
                            value="127"
                            name="question_42"
                            id="agreefive"
                            data-quest-id={42}
                            data-ans-id={127}
                            data-ans-label="Arrears Letters"
                            data-click-id='supportDoc_arrearsLetters'
                          >
                            Arrears Letters
                          </button>
                        </div>
                      </div>
                      <div className="col-lg-12 col-md-12 col-sm-12">
                        <div className=" mb-2">
                          <button
                            className=" btn-comm2--low-zindex gb16b btn-comm2 py-lg-3 py-md-3 py-sm-2 py-2 w-100 nextBtn"
                            onClick={labelclicked}
                            type="button"
                            value="123"
                            name="question_42"
                            id="agreesix"
                            data-quest-id={42}
                            data-ans-id={123}
                            data-ans-label="None of the Above"
                            data-click-id='supportDoc_noneOfTheAbove'
                          >
                            None of the Above
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </li>
              <li>
                <SupportingDocUpload
                  docUpload={docUpload}
                  setSupportDocPopup={setSupportDocPopup}
                  setSupportDocSucess={setSupportDocSucess}
                  removeFromPending={removeFromPending}
                />
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SupportingDocs;
