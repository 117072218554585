import { Accordion } from "react-bootstrap";
import UploadSection from "./UploadSection";

export default function IdPopUpload({close,setIdUploadSucess,removeFromPending}) {
  return (
    <div
      className="modal fade show"
      id="iduploadBackdrop"
      data-bs-backdrop="static"
      data-bs-keyboard="false"
      tabIndex={-1}
      aria-labelledby="iduploadBackdropLabel"
      style={{ display: "block" }}
      aria-modal="true"
      role="dialog"
    >
      <div className="modal-dialog modal-dialog-centered modal-lg">
        <div className="modal-content">
          <div className="modal-header border border-0">
            <button
              type="button"
              className="btn-close"
              data-bs-dismiss="modal"
              aria-label="Close"
              onClick={close}
            />
          </div>
          <div className="modal-body">
            <div className="row" id="quetWraper">
              <div className="col-xl-12 col-lg-12 col-12 intro">
                <h2>Verify Your identity</h2>
                <h3>You must provide 1 proof(s) of address to proceed.</h3>
                <p>
                  Tip - Please provide a photo of one of the following:{" "}
                  <strong>National ID Card, Driver's License, Passport</strong>
                </p>
              </div>
              <div className="uploading-part col-12">
                <Accordion>
                  <Accordion.Item eventKey="0">
                    <Accordion.Header>
                        National ID Card
                    </Accordion.Header>
                    <Accordion.Body>
                      <UploadSection uploadName='nationalIDCard_doc' captureName='nationalIDCard_doc_cam' setIdUploadSucess={setIdUploadSucess} removeFromPending={removeFromPending} close={close}/>
                    </Accordion.Body>
                  </Accordion.Item>
                  <Accordion.Item eventKey="1">
                    <Accordion.Header>
                    Driver's License
                    </Accordion.Header>
                    <Accordion.Body>
                     <UploadSection uploadName='driversLicense_doc' captureName='driversLicense_doc_cam' setIdUploadSucess={setIdUploadSucess} removeFromPending={removeFromPending} close={close} />
                    </Accordion.Body>
                  </Accordion.Item>
                  <Accordion.Item eventKey="2">
                    <Accordion.Header>
                    Passport
                    </Accordion.Header>
                    <Accordion.Body>
                      <UploadSection uploadName='passport_doc' captureName='passport_doc_cam' setIdUploadSucess={setIdUploadSucess} removeFromPending={removeFromPending} close={close} />
                    </Accordion.Body>
                  </Accordion.Item>
                </Accordion>
              </div>
              <div className="doc-op pt-3">
                <ul>
                  <li> Document should be Clear &amp; Legible</li>
                  <li> Entire Document should be Captured</li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
