import React, { useState } from "react";
import SelectBox from "../../../UI/SelectBox";
import InputButton from "../../../UI/InputButton";
import { Modal } from "react-bootstrap";
import { ClosedYearsV3 } from "../../../../Constants/Constants";
import RadioButtonQuest from "../../../UI/RadioButtonQuest";

const CreditCardOpenYear = ({
  className,
  saveQuestAnswer,
  validationMsg,
  clearErrors,
  validation,
  trigger,
  getValues,
  backClick,
  removeFromPending,
  addPending,
  setCardOpenYr,
  qnNo
}) => {
  const [claimModal, setshowClaimModal] = useState(false);
  const handleCloseclaimModal = () => setshowClaimModal(false);

  const selectedYear = async (e) => {
    if (e.target.name == "openYearCredit") {
      saveQuestAnswer(88, null, e.target.value);
      setCardOpenYr(e.target.value);
      removeFromPending('credCardOpenYear');
    } else {
      let errorFlag = 0;
      let yearresult = await trigger("creditopenyears");

      if (!yearresult) {
        errorFlag = 1;
        return false;
      } else {
        const valuesreg = getValues();
        saveQuestAnswer(88, null, valuesreg['creditopenyears']);
        setCardOpenYr(valuesreg['creditopenyears']);
        removeFromPending('credCardOpenYear');
      }
    }
  };

  return (
    <>
      <div className={`container ${className}`}>
        <div className="questitem qc-2 mt-0  scale-down-ver-center mt-5">
          <h1 className="que-title p-3">
            What year did you open your Vanquis credit card?
          </h1>
          <div className="row mb-3 px-3 py-lg-3 py-md-3 py-sm-2 py-2">
            <div className="mb-2">
              <ul className="p-0 m-0 option-grid">
                {["2024", "2023", "2022", "2021", "2020", "2019", "2018"].map(
                  (year, index) => (
                    <li key={year}>
                      <div className="optionbtn qb5 nextBtn">
                        <RadioButtonQuest
                          type="button"
                          //className="btn-comm2 py-lg-3 py-md-3 py-sm-2 py-2 w-100 nextBtn"
                          id={`optionsCardYr${index + 1}`}
                          name="openYearCredit"
                          value={year}
                          labelName={year}
                          onClick={selectedYear}
                          dataQuestId={88}
                          dataClickId='credCardOpenYear_option_next'
                        >
                          {year}
                        </RadioButtonQuest>
                      </div>
                    </li>
                  )
                )}
              </ul>
            </div>
            <div className="row">
              <div className="col-lg-8 mb-2">
                <SelectBox
                  className="form-select onSelect"
                  OptionValue={ClosedYearsV3}
                  name="creditopenyears"
                  id="creditopenyears"
                  onChange={() => clearErrors("creditopenyears")}
                  clearErrors={clearErrors}
                  myRef={validation({ required: "Please select year" })}
                  validationMsg={
                    validationMsg.creditopenyears &&
                    validationMsg.creditopenyears.message
                  }
                ></SelectBox>
              </div>
              <div className="col-lg-4 mb-2">
                <button
                  type="button"
                  className=" qb5 btn-comm2 py-lg-3 py-md-3 py-sm-2 py-2 w-100 nextBtn"
                  id="nextscroll"
                  name="creditopenyear"
                  onClick={selectedYear}
                  data-quest-id={88}
                  data-click-id='credCardOpenYear_next'
                >
                  Next &#8594;
                </button>
              </div>
            </div>
            {/* <p className="text-center bck-marwid">
              <button
                name="banks"
                className="btn btn-secondary backbtn1a"
                id=""
                value=""
                type="button"
                onClick={()=>addPending('documentClaim')}
                data-click-id='credCardOpenYear_back'
              >
                Go Back
              </button>
            </p> */}
          </div>
        </div>
        <div
          id="rangequestions"
          class="qrange d-flex align-items-sm-center text-center"
        >
          Question <br class="d-none d-md-block" />{qnNo}
        </div>
      </div>
    </>
  );
};
export default CreditCardOpenYear;
