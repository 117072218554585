import React, {
  useRef,
  useState,
  useContext,
  useReducer,
  useEffect,
} from "react";
import {
  BrowserView,
  MobileView,
  MobileOnlyView,
  TabletView,
  deviceType,
  isIPad13,
  ConsoleView,
} from "react-device-detect";
import "../../../../assets/SignatureVAQ/css/signatureVaq.scss";
import VisitorParamsContext from "../../../../Contexts/VisitorParams";
import SignatureCanvas from "react-signature-canvas";
import CheckBox2 from "../../../UI/CheckBox2";
import { useHistory } from "react-router-dom";
import { useFollowupUserSignature } from "../../../../Hooks/useFollowupUserSignature";
import Footer from "../../../Includes/Layouts/Common/Footer";
import LOA from '../../../../assets/pdf/LOA_Vanquis_till_30_10_2024.pdf';
import TOB from '../../../../assets/pdf/Business_Terms_TMS.pdf';
import CFD from '../../../../assets/pdf/FOS_Vanquis.pdf';
import VQ from '../../../../assets/pdf/Questionnaire_Vanquis.pdf';
import DSAR from '../../../../assets/pdf/MoneyBarn_DSAR.pdf';
import { useUpdateMilestone } from "../../../../Hooks/useUpdateMilestone";


const initialState = {
  isChecked: false,
  isAgreed: false,
  isCanvasEmpty: false,
  isValid: false,
};
const signatureReducer = (state, action) => {
  switch (action.type) {
    case "signPadChange": {
      return {
        ...state,
        isCanvasEmpty: action.payload.value,
      };
    }
    case "privacyAgreed": {
      return {
        ...state,
        isAgreed: action.payload.value,
      };
    }
    case "validSignature": {
      return {
        ...state,
        isValid: action.payload.value,
      };
    }
    default:
      return state;
  }
};
const SignatureFormVAQ = ({ allFollowupData, showQuestion, productId, uuid }) => {
  const [state, dispatch] = useReducer(signatureReducer, initialState);
  const { saveFollowupUserSignature, isLoading } = useFollowupUserSignature();
  const [isChecked, setIsChecked] = useState(false);
  const history = useHistory();
  const { visitorParameters } = useContext(VisitorParamsContext);
  const signPadRef = useRef();
  const formParameters = JSON.parse(localStorage.getItem("formData"));
  const message_type = "signature_store";
  const [signatureWrapWidth, setSignatureWrapWidth] = useState(0);
  const [signatureWrapHeight, setSignatureWrapHeight] = useState(0);
  const signatureWrapRef = useRef(null);
  const {updateMilestone} = useUpdateMilestone();
  const flp_source = allFollowupData.atp_sub6;

  const handleClick = async () => {
    try {
      const signPad = signPadRef.current;
      if (signPad.isEmpty()) {
        return dispatch({ type: "signPadChange", payload: { value: true } });
      }
      if (!isChecked) {
        return dispatch({ type: "privacyAgreed", payload: { value: true } });
      }
      const rpdata = signPad.toData();
      var signLength = 0;
      for (var i = 0; i < rpdata.length; i++) {
        signLength += Object.keys(rpdata[i]).length;
      }
      if (signLength > 13) {
        const response = await saveFollowupUserSignature(
          signPad.getCanvas().toDataURL("image/png"),
          allFollowupData,
          'followup_user_signature'
        )
        if (response.data.status === "Success") {
          updateMilestone(uuid,"signature",flp_source);
          showQuestion(productId);
        }
      } else {
        return dispatch({ type: "validSignature", payload: { value: true } });
      }
    } catch (e) {
      console.warn(e);
    }
  };
  useEffect(() => {
    if (isChecked) {
      dispatch({ type: "privacyAgreed", payload: { value: false } });
    }
  }, [isChecked]);

  useEffect(() => {
    function handleResize() {
      setSignatureWrapWidth(signatureWrapRef.current.clientWidth);
      setSignatureWrapHeight(signatureWrapRef.current.clientHeight);
    }
    setSignatureWrapWidth(signatureWrapRef.current.clientWidth);
    setSignatureWrapHeight(signatureWrapRef.current.clientHeight);

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  const clearSign = () => {
    if (signPadRef.current) signPadRef.current.clear();
  };
  return (
    <>
      <div className="SignatureVANQUIS">
        <header className="d-none d-lg-block">
          <div className="container">
            <div className="row">
              <div className="logo-part col-lg-12 col-md-12 col-12  text-center">
                <img src="/assets/Signature/img/logo.png" alt="" />
              </div>
              <div
                className="col-lg-6 offset-lg-2 col-md-8 col-12 text-center d-none d-md-block "
              ></div>
            </div>
          </div>
        </header>
        <section className="signature-section">
          <div className="container">
            <div className="row ">
              <div className="offset-lg-2 col-lg-8 col-12 sign-question">
                <h2>
                PLEASE SIGN IN THE BOX BELOW TO START YOUR CLAIM
                </h2>
                <div className="offset-lg-2 col-lg-8 col-12">
                  <ul>
                    <li>Sign using your finger, mouse or stylus</li>
                    <li>Keep it fully contained within the box</li>
                    <li>Ensure it is a true likeness of your signature</li>
                  </ul>
                </div>
                <div className="sign-div">
                  <form>
                    <div className="form-group">
                      <div className="signature_canvaseWrap">
                        <div>
                          <div className="signature-pad anim_bt d-lg-block web-canvas" ref={signatureWrapRef}>
                            <SignatureCanvas
                              canvasProps={{
                                id: "myID",
                                width: signatureWrapWidth,
                                height: signatureWrapHeight,
                              }}
                              ref={signPadRef}
                              onEnd={() => {
                                if (!signPadRef.current.isEmpty()) {
                                  dispatch({
                                    type: "signPadChange",
                                    payload: { value: false },
                                  });
                                  dispatch({
                                    type: "validSignature",
                                    payload: { value: false },
                                  });
                                  document
                                    .getElementById("acceptterms")
                                    .classList.add("anim_ylw");
                                }
                              }}
                            />
                          </div>

                          {state.isAgreed && (
                            <div className="sign_msg col-12 text-center">
                              Please tick to show your authorisation for us to
                              proceed
                            </div>
                          )}
                          {state.isCanvasEmpty && (
                            <div className="sign_msg col-12 text-center sign_msg" id="signatures_required">
                              Signature is required!
                            </div>
                          )}
                          {state.isValid && (
                            <div className="sign_msg col-12 text-center sign_msg" id="signatures_valid">
                              Draw valid signature!
                            </div>
                          )}
                        </div>
                      </div>
                    </div>
                    <div className="form-check my-3">
                      <CheckBox2
                        dataId="acceptterms"
                        anchorText=""
                        anchorStyle=""
                        href=""
                        onChange={(e) => setIsChecked(!isChecked)}
                        className="form-check-input input_1"
                        labelClassName="form-check-label sign-custom-control-label"
                        value={isChecked}
                        CheckBoxText="
                        By clicking submit you agree to us appending your electronic signature to the claim 
                        documents and confirming that you accept the terms of this contract for TMS Legal to 
                        represent you in your unaffordable/irresponsible lending claim, and that you agree to 
                        pay us our commission starting at 36% including VAT, and going as low as 18% 
                        including VAT for larger claims, as laid out in the TMS Legal Terms of Business 
                        Agreement (‘TOBA’) if the claim is successful"
                      />

                     <p className="my-2">
                        Preview your unsigned documents before signing above:
                     </p>

                     <ul className="list-1a">
                        <li> <a href={`${LOA}`} target="_blank"> <img className="link_img_1a" src="/assets/SignatureVAQ/img/link_1a.png" alt=""/> Letter of Authority </a></li>
                        <li> <a href={`${TOB}`} target="_blank"> <img className="link_img_1a" src="/assets/SignatureVAQ/img/link_1a.png" alt=""/> Terms of Business Agreement</a></li>
                        <li> <a href={`${VQ}`} target="_blank"> <img className="link_img_1a" src="/assets/SignatureVAQ/img/link_1a.png" alt=""/> Questionnaire</a></li>
                        <li> <a href={`${CFD}`} target="_blank"> <img className="link_img_1a" src="/assets/SignatureVAQ/img/link_1a.png" alt=""/> Complaint Form & Declaration</a></li>
                        <li> <a href={`${DSAR}`} target="_blank"> <img className="link_img_1a" src="/assets/SignatureVAQ/img/link_1a.png" alt=""/> DSAR Letter of Authority</a></li>
                        
                     </ul>
                    </div>
                    <input
                      type="button"
                      id=""
                      name=""
                      className="btn-clear"
                      value="Clear"
                      onClick={clearSign}
                    />
                    <input
                      className="btn btn-submit"
                      type="button"
                      onClick={handleClick}
                      value="Submit"
                    />
                  </form>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
      <Footer />
    </>
  );
};
export default SignatureFormVAQ;
