import "../../assets/VAQ_UB_5/scss/custom.scss";
import "../../assets/VAQ_UB_5/scss/id_uplaod.scss";
import loaderSvg from "../../assets/VAQ_UB_5/img/loader-review2.svg";
import logo from "../../assets/VAQ_UB_5/img/logo-white.png";
import uplaodGif from "../../assets/VAQ_UB_5/img/upload.gif";
import uplaodPng from "../../assets/VAQ_UB_5/img/upload.png";
import successTick from "../../assets/VAQ_UB_5/img/tick.png";

import Footer from "../Includes/Layouts/VAQ_UB_5/Footer";
import IdPopUpload from "../Includes/Layouts/VAQ_UB_5/IdPopUpload";
import { useContext, useEffect, useState } from "react";
import SupportingDocs from "../Includes/Layouts/VAQ_UB_5/SupportingDocs";
import { useHistory } from "react-router-dom";
import VisitorParamsContext from "../../Contexts/VisitorParams";
import AgreementNoPopup from "../Includes/Layouts/VAQ_UB_5/AgreementNoPopup";
import { Api } from "../../api/Api";
import { queryString,urlParams } from "../../Utility/QueryString";


export default function VAQ_ID_UPLOAD_V5() {
  const { visitorParameters } = useContext(VisitorParamsContext);
  const history = useHistory();
  const [idPopUp, setIdPopUp] = useState(false);
  const [supportDocPopup, setSupportDocPopup] = useState(false);
  const [loader, setLoader] = useState(true);
  const [supportDocSuccess, setSupportDocSucess] = useState(false);
  const [idUploadSuccess, setIdUploadSucess] = useState(false);
  const [agreementPopup, setAgreementPopup] = useState(false);

  const t2a_Validation = localStorage.getItem("t2aValidation");

  const uuid=visitorParameters?.visitor_parameters?.uuid || urlParams.get("uuid") || localStorage.getItem("uuid") || localStorage.getItem("queryString")
  
  useEffect(() => {
    window.scrollTo(0, 0);
    const loaderTimer = setTimeout(() => {
      setLoader(false);
    }, 3000);
    generatePdf();
    return () => clearTimeout(loaderTimer);
  }, []);
  
  const generatePdf = async () => {
    try {

      const responsepdf = Api.get(`/api/v1/vaq-quest-generate?uuid=${uuid}`, {});

    } catch (err) {
      console.error("Error generating PDF:", err);
    }
  };

  const cakePostApi = async () => {
    try {
      const responsecakePostApi = Api.get(`/api/v1/vaq-cake-post?uuid=${uuid}`, {});
    } catch (err) {
      console.error("Error CakePosting:", err);
    }
  };

  const closeIdPopUp = () => {
    setIdPopUp(false);
  };


  useEffect(() => {
    if (supportDocSuccess && idUploadSuccess && "0" === t2a_Validation) {
      cakePostApi();
      history.push(
        `/Vanquisthankyou?uuid=${uuid}`
      );
    }
  }, [supportDocSuccess, idUploadSuccess]);

  useEffect(() => {
    if ("1" === t2a_Validation && supportDocSuccess) {
      cakePostApi();
      history.push(
        `/Vanquisthankyou?uuid=${uuid}`
      );
    }
  }, [supportDocSuccess, t2a_Validation]);



  return (
    <>
      <div className="VAQ_ID_5">
        <div className="VAQ_UB_5 form-font">
          <section className="banner qs-section id-upload">
            <div className="container mt-5 mb-5">
              <div className="logo">
                <img src={logo} alt="TMS Legal Logo" />
              </div>
              {loader ? (
                <div className="vh-100 d-flex loader-wrap text-center text-light">
                  <div className="m-auto">
                    <img height={80} src={loaderSvg} alt="" />
                    <h6 className="mt-3">Analysing your answers</h6>
                  </div>
                </div>
              ) : (
                <div id="upload-section">
                  <div className="container">
                    <h2 className="thank-you text-white">Thank you</h2>
                    <h3 className="onboarding-message text-white">
                      {"1" === t2a_Validation
                        ? "Thank you for submitting your claim to TMS Legal. We have completed your digital ID check and it was successful. We will now email you copies of all signed documents and we will call you shortly to verify your details and answer any questions."
                        : "Your claim is now registered with us, and we will commence the onboarding process."}
                    </h3>
                    <div className="identity-icon">
                      <div className="uploadicons">
                        <img height={80} src={uplaodGif} alt="" />
                      </div>
                    </div>
                    <div className="instruction">
                      We need the following information to expedite your claim
                      process. <br />
                      Please upload the required documents.
                    </div>
                    <div className="dropdown-buttons">
                      {"0" === t2a_Validation && (
                        <label
                          className="dropdown-item"
                          onClick={() => setIdPopUp(true)}
                        >
                          Upload Your ID
                          <img
                            src={idUploadSuccess ? successTick : uplaodPng}
                            className="upload-icon"
                            alt="Upload Icon"
                          />
                          <input
                            type="button"
                            name="document1"
                            className="d-none"
                            data-bs-toggle="modal"
                            data-bs-target="#iduploadBackdrop"
                          />
                        </label>
                      )}
                      <label
                        className="dropdown-item"
                        onClick={() => setSupportDocPopup(true)}
                      >
                        Upload Supporting Documents
                        <img
                          src={supportDocSuccess ? successTick : uplaodPng}
                          className="upload-icon"
                          alt="Upload Icon"
                        />
                        <input
                          type="button"
                          name="document2"
                          className="d-none"
                          data-bs-toggle="modal"
                          data-bs-target="#quesBackdrop"
                        />
                      </label>
                    </div>
                    <div className="footer-note">
                      Document should be Clear &amp; Legible | Entire Document
                      should be Captured
                    </div>
                  </div>
                </div>
              )}
            </div>
          </section>
          <Footer />
          {idPopUp && (
            <IdPopUpload
              close={closeIdPopUp}
              setIdUploadSucess={setIdUploadSucess}
            />
          )}
          {supportDocPopup && (
            <SupportingDocs
              setSupportDocPopup={setSupportDocPopup}
              setSupportDocSucess={setSupportDocSucess}
              setAgreementPopup={setAgreementPopup}
            />
          )}
          {agreementPopup && (
            <AgreementNoPopup
              setAgreementPopup={setAgreementPopup}
              setSupportDocPopup={setSupportDocPopup}
              setSupportDocSucess={setSupportDocSucess}
            />
          )}
        </div>
      </div>
    </>
  );
}
