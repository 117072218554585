import React from "react";
const OverLimitCharges = ({
    onSelectAnswer,
    backClick
}) => {
    const optionClicked = async (e) => {
        onSelectAnswer(e);
    };

    return (
        <>
            <div className="question-content mt-0 scale-down-ver-center px-3 py-lg-3 py-md-3 py-sm-2 py-2 ">
                <div className="row">
                    <div className="col-lg-3 col-md-6 col-sm-12">
                        <div className=" mb-2">
                            <button
                                type="button"
                                className="btn-comm2 py-lg-3 py-md-3 py-sm-2 py-2 w-100 nextBtn"
                                onClick={optionClicked}
                                value="261"
                                name="overlimit"
                                id="nextscrolla"
                                data-quest-id={97}
                                data-ans-id={261}
                                data-ans-label={"Always"}
                            >Always
                            </button>
                        </div>
                    </div>

                    <div className="col-lg-3 col-md-6 col-sm-12">
                        <div className=" mb-2">
                            <button
                                type="button"
                                className="btn-comm2 py-lg-3 py-md-3 py-sm-2 py-2 w-100 nextBtn"
                                onClick={optionClicked}
                                value="262"
                                name="overlimit"
                                id="nextscrolla"
                                data-quest-id={97}
                                data-ans-id={262}
                                data-ans-label={"Sometimes"}
                            >Sometimes
                            </button>
                        </div>
                    </div>
                    <div className="col-lg-3 col-md-6 col-sm-12">
                        <div className=" mb-2">
                            <button
                                type="button"
                                className="btn-comm2 py-lg-3 py-md-3 py-sm-2 py-2 w-100 nextBtn"
                                onClick={optionClicked}
                                value="263"
                                name="overlimit"
                                id="nextscrolla"
                                data-quest-id={97}
                                data-ans-id={263}
                                data-ans-label={"Rarely"}
                            >Rarely
                            </button>
                        </div>
                    </div>

                    <div className="col-lg-3 col-md-6 col-sm-12">
                        <div className=" mb-2">
                            <button
                                type="button"
                                className="btn-comm2 py-lg-3 py-md-3 py-sm-2 py-2 w-100 nextBtn"
                                onClick={optionClicked}
                                value="264"
                                name="overlimit"
                                id="nextscrolla"
                                data-quest-id={97}
                                data-ans-id={264}
                                data-ans-label={"Never"}
                            >Never
                            </button>
                        </div>
                    </div>
                </div>
                <p className="text-center bck-marwid">
                    <button
                        name="oftenexceedCredit"
                        className="btn btn-secondary backbtn1a"
                        id=""
                        value=""
                        type="button"
                        onClick={backClick}

                    >Go Back</button>
                </p>
            </div>
        </>
    )
}
export default OverLimitCharges; 