import React, { useRef, useState } from "react";
import CheckBox from "../../../UI/CheckBox";
const ConfirmSituation = ({
  clsName,
  reff,
  onSelectAnswer,
  validation,
  validationMsg,
  getValues,
  trigger,
  setError,
  SelectedOptions,
  optionArr,
  backClick,
  showError76,
  setshowError76
}) => {
  // const [showError, setshowError] = useState("hide");
  const [btndisabled, setBtndisabled] = useState(false);
  const checkboxRefs = useRef({});

  const labelclicked = async (e) => {
    let date_index = e.target.id;
    let selectedCount = SelectedOptions.length;
    if (selectedCount == 0) {
      setBtndisabled(false);
      setshowError76("show");
    } else {
      setBtndisabled(true);

      onSelectAnswer(e);
    }
  };

  const onSelectOption = (e) => {
    if (e.target.checked === true) {
      setshowError76("hide");
      SelectedOptions.push({ id: +e.target.value });
      checkBoxDisable(e.target.value);
    } else {
      let deselect = e.target.value;
      const decre = SelectedOptions.map((OptionArray, index) => {
        if (deselect == OptionArray.id) {
          SelectedOptions.splice(index, 1);
          if (SelectedOptions.length == 0) {
            checkBoxEnable();
          }
        }
      });
    }
  };

  const handleChange = (e) => {
    // const re = /^[0-9\b]+$/;
    const re = /^[1-9]\d*$/;
    if (e.target.value !== "" && !re.test(e.target.value)) {
      e.target.value = e.target.value.replace(/\D/g, "") * 1;
    }
    e.target.value = e.target.value
      .toString()
      .replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  };
  let checkArray = optionArr.answers;

  const questionId = optionArr.questionId ? optionArr.questionId : 0;

  const lenderCheckBox = checkArray.map((checArr, index) => {
    let heightcls = checArr.value == '104' ? 'heightnewcls' : '';
    return (
      <li className={`adjustbalewidfull ${heightcls}`} key={checArr.value} >
        <div className="optionbtn op_btn" ref={(e) => checkboxRefs.current[checArr.value] = e}>
          <CheckBox
            dataId={`confirmSituation${checArr.value}`}
            name="chkBank[]"
            onChange={onSelectOption}
            className=""
            CheckBoxText={'<span></span>'+checArr.label}
            value={checArr.value}
            labelClassName="tick-check-box"
          />
        </div>
      </li>
    );
  });

  const checkBoxDisable = (checkBoxId) => {
    if (checkBoxId != 225) {
      checkArray.map((checArr, index) => {
        checkboxRefs.current[checArr.value].childNodes[0].disabled = false;
        checkboxRefs.current[checArr.value].childNodes[1].classList.remove("disabledcheckbox");
      })
      checkboxRefs.current[225].childNodes[0].disabled = true;
      checkboxRefs.current[225].childNodes[1].classList.add("disabledcheckbox");
    } else if (checkBoxId == 225) {
      checkArray.map((checArr, index) => {
        checkboxRefs.current[checArr.value].childNodes[0].disabled = true;
        checkboxRefs.current[checArr.value].childNodes[1].classList.add("disabledcheckbox");
      })
      checkboxRefs.current[225].childNodes[0].disabled = false;
      checkboxRefs.current[225].childNodes[1].classList.remove("disabledcheckbox");
    }
  }

  const checkBoxEnable = () => {
    checkArray.map((checArr, index) => {
      checkboxRefs.current[checArr.value].childNodes[0].disabled = false;
      checkboxRefs.current[checArr.value].childNodes[1].classList.remove("disabledcheckbox");
    })
  }

  return (
    <>
      <div className="question-content mt-0  scale-down-ver-center px-3 py-lg-3 py-md-3 py-sm-2 py-2 " >
        <div className="row">
          <div className="col-lg-12 mb-2">
            <ul className="p-0 m-0 option-grid">
              {lenderCheckBox}
            </ul>
          </div>
          <div className="col-lg-12 mb-2 mt-2">
            <span className={`error_msg ${showError76}`} id="email_err">
              Please select at least one
            </span>
            <button
              className="qb3 btn-comm2 py-lg-3 py-md-3 py-sm-2 py-2 w-100 nextBtn d-sm-block d-none anim_ylw"
              name="btn_qest_76"
              type="button"
              id="q76"
              data-quest-id={76}
              //disabled={btndisabled}
              onClick={labelclicked}
            >
              Next &#8594; </button>
          </div>
        </div>
        <p className="text-center bck-marwid">
          <button
            name="btn_qest_36"
            className="btn btn-secondary backbtn1a"
            id=""
            value=""
            type="button"
            onClick={backClick}

          >Go Back</button>
        </p>
      </div>
    </>
  )
}
export default ConfirmSituation;