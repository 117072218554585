import React, { useState } from "react";
import RadioButtonQuest from "../../../UI/RadioButtonQuest";
const ExceedingCreditLimit = ({
    clsName,
    onSelectAnswer,
    reff,
    splitForm,
    getValues,
    backClick
}) => {
    const [questResult, setQuestResult] = useState(false);
    const [checkedOne, setCheckedOne] = useState("unchecked");
    const [checkedTwo, setCheckedTwo] = useState("unchecked");
    const [checkedThree, setCheckedThree] = useState("unchecked");
    const [checkedFour, setcheckedFour] = useState("unchecked");
    const [checkedFive, setcheckedFive] = useState("unchecked");
    const optionClicked = async (e) => {
        if (e.target.value === "141") {
            setCheckedOne("checked");
        } else {
            setCheckedOne("unchecked");
        }

        if (e.target.value === "142") {
            setCheckedTwo("checked");
        } else {
            setCheckedTwo("unchecked");
        }

        if (e.target.value === "143") {
            setCheckedThree("checked");
        } else {
            setCheckedThree("unchecked");
        }

        if (e.target.value === "144") {
            setcheckedFour("checked");
        } else {
            setcheckedFour("unchecked");
        }
        if (e.target.value === "145") {
            setcheckedFive("checked");
        } else {
            setcheckedFive("unchecked");
        }
        setQuestResult(false);
    };
    const selectedClick = async (e) => {
        onSelectAnswer(e);
        // e.preventDefault();
        // let errorFlag = 0;
        // let optionselect = splitForm.current["exceedCreditLimit"].value;
        // if (optionselect == '') {
        //     errorFlag = 1;
        //     setQuestResult(true);
        //     return false;
        // } else {
        //     onSelectAnswer(e);
        // }
    }
    return (
        <>
            <div className="question-content mt-0 scale-down-ver-center px-3 py-lg-3 py-md-3 py-sm-2 py-2 pb-3 ">
                <div className="row">
                    <div className="col-lg-12 mb-2">
                        <ul className="p-0 m-0 option-grid">
                            <li className="adjustbalewid">
                                <div className="optionbtn">
                                    <RadioButtonQuest
                                        name="excdCreditLimit"
                                        id="optionztwo"
                                        labelClassName={`${checkedOne}`}
                                        value="141"
                                        labelName="Always"
                                        onClick={selectedClick}
                                        dataQuestId={55}
                                        dataAnsId={146}
                                        dataAnsLabel="Always"
                                    />
                                </div>
                            </li>
                            <li className="adjustbalewid">
                                <div className="optionbtn">
                                    <RadioButtonQuest
                                        name="excdCreditLimit"
                                        id="optionztwo2"
                                        labelClassName={`${checkedTwo}`}
                                        value="142"
                                        labelName="Sometimes"
                                        onClick={selectedClick}
                                        dataQuestId={55}
                                        dataAnsId={142}
                                        dataAnsLabel="Sometimes"
                                    />
                                </div>
                            </li>
                            <li className="adjustbalewid">
                                <div className="optionbtn">
                                    <RadioButtonQuest
                                        name="excdCreditLimit"
                                        id="optionztwo3"
                                        labelClassName={`${checkedThree}`}
                                        value="143"
                                        labelName="Twice"
                                        onClick={selectedClick}
                                        dataQuestId={55}
                                        dataAnsId={143}
                                        dataAnsLabel="Twice"
                                    />
                                </div>
                            </li>
                            <li className="adjustbalewid">
                                <div className="optionbtn">
                                    <RadioButtonQuest
                                        name="excdCreditLimit"
                                        id="optionztwo4"
                                        labelClassName={`${checkedFour}`}
                                        value="144"
                                        labelName="Once"
                                        onClick={selectedClick}
                                        dataQuestId={55}
                                        dataAnsId={144}
                                        dataAnsLabel="Once"
                                    />
                                </div>
                            </li>
                            <li className="adjustbalewid">
                                <div className="optionbtn">
                                    <RadioButtonQuest
                                        name="excdCreditLimit"
                                        id="optionztwo5"
                                        labelClassName={`${checkedFive}`}
                                        value="145"
                                        labelName="Never"
                                        onClick={selectedClick}
                                        dataQuestId={55}
                                        dataAnsId={145}
                                        dataAnsLabel="Never"
                                    />
                                </div>
                            </li>
                        </ul>
                        {/* {questResult && (
                            <span className="error_msg">Please select an option</span>
                        )} */}
                    </div>
                    {/* <div className="col-lg-12 mb-2 mt-2">
                        <button
                            className="qb15 btn-comm2 py-lg-3 py-md-3 py-sm-2 py-2 w-100 nextBtn"
                            name="excdCreditLimit"
                            type="button"
                            onClick={selectedClick}
                            data-quest-id={55}
                        >
                           Next &#8594;
                        </button>
                    </div> */}
                </div>
                <p className="text-center bck-marwid">
                    <button
                      name="CredLimitInc"
                      className="btn btn-secondary backbtn1a"
                      id=""
                      value=""
                      type="button"
                      onClick={backClick}
              
                    >Go Back</button>
                    </p>
            </div>
        </>
    )
}
export default ExceedingCreditLimit; 