import React, { useState } from "react";
import Email from "../../../UI/Email";
import GtmDataLayer from "../../../../Utility/GtmDataLayer";
import { useUpdateMilestone } from "../../../../Hooks/useUpdateMilestone";
const Emailconfirmation = ({
  className,
  reff,
  saveQuestAnswer,
  validation,
  validationMsg,
  trigger,
  getValues,
  userEmail,
  addPending,
  removeFromPending,
  qnNo,
  uuid,
  allFollowupData
}) => {

  const [showRegEmail, setShowRegEmail] = useState("hide");
  const { updateUserMilestoneStatsQuest } = useUpdateMilestone();

  const optionSelected = async (e) => {
    e.preventDefault();
    GtmDataLayer({
      question: "registered email address",
    });
    saveQuestAnswer(61, e.target.value);
    if (e.target.value == "163") {
      setShowRegEmail("show");
    }else{
      removeFromPending("emailConfirm");
      updateUserMilestoneStatsQuest(uuid, allFollowupData.atp_sub6);
    }
  };
  const handleClick = async (e) => {
    e.preventDefault();
    let errorFlag = 0;
    const values = getValues();
    let question48Result = await trigger("question_48");
    if (!question48Result) {
      errorFlag = 1;
      return false;
    }
    if (errorFlag === 0) {
      let data = getValues();
      saveQuestAnswer(48, null, data.question_48);
      removeFromPending("emailConfirm");
      updateUserMilestoneStatsQuest(uuid, allFollowupData.atp_sub6);
    }
  };
  return (
    <>
      <div className={`container ${className}`}>
        <div
          className="questitem mt-5 scale-down-ver-center px-3 py-lg-3 py-md-3 py-sm-2 py-2 pb-3"
          ref={reff}
        >
          <div class={showRegEmail == "show" ? "hide" : ""} >
          <h1 className="que-title p-3 regemail">
            Is this the email address {userEmail} that you registered with
            Vanquis
          </h1>
          <div className={`d-flex gap-2`}>
            <div className="d-flex w-50 py-2">
              <button
                type="button"
                className=" btn-comm2 py-3 w-100  nextBtn"
                onClick={optionSelected}
                value="162"
                name="question_61"
                id="nextscroll"
                data-quest-id={61}
                data-ans-id={162}
                data-click-id='emailConfirm_yes'
              >
                Yes
              </button>
            </div>
            <div className="d-flex w-50 py-2">
              <button
                type="button"
                className=" btn-comm2 py-lg-3 py-md-3 py-sm-3 py-3 w-100 newemailbut"
                onClick={optionSelected}
                value="163"
                name="question_61"
                id="nextscroll"
                data-quest-id={61}
                data-ans-id={163}
                data-click-id='emailConfirm_no'
              >
                No{" "}
              </button>
            </div>
          </div>
          <p className="text-center bck-marwid mt-2">
            <button
              name="dependents"
              className="btn btn-secondary backbtn1a"
              id=""
              value=""
              type="button"
              onClick={() => addPending("dependents")}
              data-click-id='emailConfirm_back'
            >
              Go Back
            </button>
          </p>
          </div>

          <div className={`row  ${showRegEmail == "show" ? "" : "hide"}`}>
          <h1 className="p-3 newemail que-title">
                          Please provide the e-mail address you registered with
                          Vanquis
                        </h1>
            <div className="col-lg-8 mb-2 mb-lg-0">
              <Email
                name="question_48"
                className="form-control p-3 h-55"
                id="question_48"
                placeholder="Email Address"
                // onBlur={emailValidCheck}
                validation={validation({
                  required: "Please Enter Valid Email Address",
                  pattern: {
                    value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
                    message: "Invalid Email Address",
                  },
                })}
              ></Email>
              {validationMsg.question_48 && (
                <span className="error_msg " id="email_err">
                  {validationMsg.question_48.message}
                </span>
              )}
            </div>
            <div className="col-lg-4">
              <button
                type="button"
                className="qb15 btn-comm2 py-lg-3 py-md-3 py-sm-2 py-2 w-100 nextBtn"
                id="nextscroll"
                name="btn_qest_48"
                onClick={handleClick}
                data-quest-id={48}
                data-click-id='emailAddress_next'
              >
                Next &#8594;{" "}
              </button>
            </div>
            <p className="text-center bck-marwid mt-2">
            <button
              name="email-confirm"
              className="btn btn-secondary backbtn1a"
              id=""
              value=""
              type="button"
              onClick={()=>setShowRegEmail('hide')}
              data-click-id='emailAddress_back'
            >
              Go Back
            </button>
          </p>
          </div>
        </div>
        <div class="tipbox tip2 show">
          This is required to cross confirm with Vanquis database and for
          effective communication.
          <div class="arrow  arrow-top"></div>
        </div>
        <div
          id="rangequestions"
          class="qrange d-flex align-items-sm-center text-center"
        >
          Question <br class="d-none d-md-block" /> {qnNo}
        </div>
      </div>
    </>
  );
};
export default Emailconfirmation;
