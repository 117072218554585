import '../../../assets/OD_UFL_4/scss/main.scss'
import AdtopiaLoadLP from "../../../Utility/AdtopiaLoadLP.js";
import FbViewPixel from "../../../Utility/FbViewPixel.js";
import FormOD_UFL_4 from "../../Forms/FormOD_UFL_4.js";
import AccordSection from '../../Includes/Layouts/OD_UFL_4/AccordSection.js';
import Footer from "../../Includes/Layouts/OD_UFL_4/Footer.js";



const OD_UFL_4 = () => {
  return (
    <>
      <AdtopiaLoadLP pageType="LP" splitName="OD_UFL_4" />
      <FbViewPixel />
      <div className="OD_UFL_4">
        <FormOD_UFL_4 />
        <AccordSection />
        <Footer />
      </div>
    </>
  )
}


export default OD_UFL_4;