import React, { useState } from 'react';
import {HighestCreditLimitOption}from '../../../../Constants/Constants'
import SelectBox from '../../../UI/SelectBox';

const HighestCreditLimit = ({
    trigger,
    validation,
    getValues,
    saveInputAns,
    backClick,
}) => {
    const [error, setError] = useState(false);

    const handleClick = async (e) => {
        let highestBalance = await trigger("creditLimitHigh");
        let errorFlag = 0;
        if (!highestBalance) {
          errorFlag = 1;
        }
        if (errorFlag === 0) {
          setError("");
          saveInputAns(e);
        } else {
          setError("Please enter credit limit");
          return false;
        }
      };

    return (
        <div className="question-content mt-0 scale-down-ver-center px-3 pb-lg-3 pb-md-3 pb-sm-2 pb-2">
            <div className="row">
                <div className="col-lg-8 mb-3">
                    <SelectBox
                        type=""
                        className="form-control"
                        name="creditLimitHigh"
                        OptionValue={HighestCreditLimitOption}
                        onChange={() => setError("")}
                        myRef={validation({
                            required: true,
                        })}
                    />
                    {error && (
                        <span className="error_msg">{error}</span>
                    )}
                </div>
                <div className="col-lg-4 mb-2">
                    <button
                        type="button"
                        name="highestcreditLimit"
                        className=" btn-comm2 py-lg-3 py-md-3 py-sm-2 py-2 w-100 nextBtn"
                        onClick={handleClick}
                        data-quest-id={94}
                    >
                        Next  &#8594;
                    </button>
                </div>
            </div>
            <p className="text-center bck-marwid">
                <button
                    name="bankActedIrresponsibly"
                    className="mt-1 btn btn-secondary backbtn1a"
                    id=""
                    value=""
                    type="button"
                    onClick={backClick}
                >
                    Go Back
                </button>
            </p>
        </div>
    )
}

export default HighestCreditLimit;
