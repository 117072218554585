import React from "react";
import { Amount } from "../../../../Constants/Constants";
import SelectBox from "../../../UI/SelectBox";
const OutBalAmount = ({
 className,
  validation,
  validationMsg,
  trigger,
  saveQuestAnswer,
  removeFromPending,
  addPending,
  getValues,
  qnNo
}) => {
  const handleClick = async (e) => {
    let highestBalance = await trigger("outBalAmount");
    let errorFlag = 0;
    if (!highestBalance) {
      errorFlag = 1;
    }
    if (errorFlag === 0) {
      const data = getValues();
      let amount = data.outBalAmount;
      saveQuestAnswer(90, null, amount);
      removeFromPending("debtBalValue");
    } else {
      return false;
    }
  };
  return (
    <>
      <div className={`container ${className}`}>
        <div className="questitem mt-5 mt-0 scale-down-ver-center px-3 py-lg-3 py-md-3 py-sm-2 py-2 pb-3">
          <h1 className="que-title p-3 ">
            Please select from the list below approximately how much is your
            outstanding balance with Vanquis.
          </h1>
          <div className="row">
            <div className="col-lg-8 mb-2">
              <SelectBox
                className="form-select onSelect"
                OptionValue={Amount}
                name="outBalAmount"
                id="outBalAmount"
                myRef={validation({
                  required: "Please select amount",
                })}
                validationMsg={
                  validationMsg.outBalAmount &&
                  validationMsg.outBalAmount.message
                }
              />
            </div>
            <div className="col-lg-4 mb-2 ">
              <button
                type="button"
                className="qb10 btn-comm2 py-lg-3 py-md-3 py-sm-2 py-2 w-100 nextBtn "
                id="nextscroll"
                name="outstanding_amount"
                onClick={handleClick}
                data-quest-id={90}
                data-click-id='debtBalValue_next'
              >
                Next &#8594;{" "}
              </button>
            </div>
          </div>
          <p className="text-center bck-marwid">
            <button
              name="OutstandBalance"
              className="btn btn-secondary backbtn1a"
              id=""
              value=""
              type="button"
              onClick={() => addPending("debtBalance")}
              data-click-id='debtBalValue_back'
            >
              Go Back
            </button>
          </p>
        </div>
        <div
          id="rangequestions"
          class="qrange d-flex align-items-sm-center text-center"
        >
          Question <br class="d-none d-md-block" />{qnNo}
        </div>
      </div>
    </>
  );
};
export default OutBalAmount;
