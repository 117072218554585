import idUpload from "../../../../assets/FollowupS4/img/idUpload.jpg";
import browse from "../../../../assets/FollowupS4/img/browse.png";
import camera from "../../../../assets/FollowupS4/img/camera.png";
import ErrorMessage from "./ErrorMessage";
import { useContext, useState } from "react";
import { useIdUpload } from "../../../../Hooks/useIdUpload";
import VisitorParamsContext from "../../../../Contexts/VisitorParams";
import { useUpdateMilestone } from "../../../../Hooks/useUpdateMilestone";
import { queryString,urlParams } from "../../../../Utility/QueryString";


const UploadSection = ({ uploadName, captureName,setIdUploadSucess,close,removeFromPending }) => {

  const { visitorParameters } = useContext(VisitorParamsContext);
  const { updateMilestone } = useUpdateMilestone();
  const { saveFLPUserIdUpload, isLoading } = useIdUpload();
  const [isInValidfFile, setIsInValidfFile] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [userfile, setUserfile] = useState({ preview: "", title: "" });
  const [uploadedFile, setUploadedFile] = useState("");
  const [selectedFileType, setSelectedFileType] = useState("");
  const [base64Image, setBase64Image] = useState();

  const allFollowupData = JSON.parse(localStorage.getItem("allFollowupData"));
  const formParameters = JSON.parse(localStorage.getItem("formData"));
  let local_flp_source = localStorage.getItem("flp_source");
  let source = local_flp_source ? local_flp_source : "live";

  const uuid=visitorParameters?.visitor_parameters?.uuid || urlParams.get("uuid") || localStorage.getItem("uuid") || localStorage.getItem("queryString")


  const changeFilesHandler = (event) => {
    
    let imageFile = event.target.files[0];
    if (imageFile) {
      let extension = event.target.files[0].type;
      let imgsize = event.target.files[0].size;
      let allowedExtensions = ["image/jpeg", "image/png"];
      if (allowedExtensions.indexOf(extension) === -1) {
        setIsInValidfFile(true);
        setErrorMessage(
          "The uploaded ID type should be an image in jpeg,png or jpg"
        );
      } else if (imgsize > 1024 * 1024 * 20) {
        setIsInValidfFile(true);
        setErrorMessage("Please choose a file less than 20mb");
      } else {
        setSelectedFileType(event.target.name);
        setIsInValidfFile(false);
      
      }

      let idType = ["nationalIDCard_doc", "driversLicense_doc", "passport_doc"];
     let camDoc=['nationalIDCard_doc_cam','driversLicense_doc_cam','passport_doc_cam'];
      if (idType.includes(event?.target?.name)) {
        setUploadedFile(event?.target?.name);
      }else if(camDoc.includes(event?.target?.name)){
        setUploadedFile(event?.target?.name);
      }else {
        setUploadedFile("");
      }

      setUserfile((prev) => ({
        ...prev,
        preview: URL.createObjectURL(imageFile),
        title: imageFile.name,
      }));
      
      if (imageFile) {
        let reader = new FileReader();
        reader.onload = _handleReaderLoaded.bind(this);
        reader.readAsDataURL(imageFile);
      }
    } else {
      setIsInValidfFile(true);
      setUserfile((prev) => ({ ...prev, preview: "", title: "" }));
    }
  };

  const _handleReaderLoaded = (readerEvt) => {
    let binaryString = readerEvt.target.result;
    setBase64Image({
      base64TextString: binaryString,
    });
  };

  const retakeClick = () => {
    setUploadedFile("");
  };

  const scrollToTop = (ref) => {
    setTimeout(() => {
       if (ref.current) {
         ref.current.scrollIntoView({
           behavior: 'smooth',
           block: 'start',
         });
       }
     }, 100);
   };

  const cake_post = 0; 
 
   const savedocument = async () => {
     try {
       if (
        allFollowupData !== null ||
        allFollowupData !== undefined ||
        allFollowupData.atp_sub6 !== null
       ) {
         let uploadData = "";
         uploadData = {
           imageFile: base64Image.base64TextString,
           captureType: selectedFileType.substring(
             selectedFileType.indexOf("doc")
           ),
           documentType: selectedFileType.substr(
             0,
             selectedFileType.indexOf("_")
           ),
         };
         const response = saveFLPUserIdUpload(
          uploadData,
          allFollowupData,
          queryString
        );

        
        updateMilestone(uuid, "id_upload", source);
        removeFromPending('idUpload');
        
        window.scrollTo(0, 0);
        setIdUploadSucess(true);
        close();
       }
     } catch (e) {
       console.warn("error");
       console.warn(e);
     }
   };

  return (
    <div className="col-lg-12  col-12 upload-area">
      {"" === uploadedFile && !isInValidfFile ? (
        <img src={idUpload} alt="" style={{ width: "100%"}}/>
      ) : (
        <img
          src={userfile.preview}
          alt={userfile.title}
          style={{ width: "100%", padding: "0px 69px" }}
        />
      )}
      <div className="col-lg-12 col-12">
        {"" === uploadedFile && (
          <div>
            <span className="button-tab browsebut">
              <img src={browse} alt="" />
              Browse from Your Device
              <input
                type="file"
                accept="image/*"
                id="idTypeFile"
                name={uploadName}
                onChange={changeFilesHandler}
                onClick={(e) => (e.target.value = null)}
                data-click-id='idUpload_browseFromDevice'
              />
            </span>
            <div className="button-tab">
              <input
                type="file"
                accept="image/*"
                capture="camera"
                name={captureName}
                onChange={changeFilesHandler}
                onClick={(e) => (e.target.value = null)}
                data-click-id='idUpload_takePictureOfYourID'
              />
              <img src={camera} alt="" />
              Take picture of Your Id
            </div>
          </div>
        )}
        {"" !== uploadedFile && (
          <div>
            {isInValidfFile && <ErrorMessage message={errorMessage} />}
            <button className="button-tab" data-click-id='idUpload_retake' onClick={retakeClick}>
              <img src={camera} alt="" />
              Retake Photo
            </button>
            {isLoading && <p>Please Wait...</p>}
            {!isInValidfFile && (
              <button
                type="button"
                className="id-success button-tab"
                value="Submit"
                onClick={savedocument}
                data-click-id='idUpload_next'
              >
                SUBMIT
              </button>
            )}
          </div>
        )}
      </div>
    </div>
  );
};

export default UploadSection;
