import React, { useEffect, useState } from 'react';
import Logo from "../../assets/img/logo_1.png";
import {
    ReliantBorrowing,
    Overindebtness,
    DebtEssentialBills,
    CreditFileInformation,
    ImpactedNegatively,
    HighUtilisation,
    StruggledRepayments,
    ClientFinancialArrears,
    CardNotManaged,
    UnableRepayments
} from "../../Constants/Constants";
import '../../assets/Preview_Vaq_V5/scss/merit_Vaq_V5_custom.scss';

const PreviewContentComponent = ({
    category,
    InputValues,
    setShowModal,
}) => {
    const [matchedItems, setMatchedItems] = useState([]);

    const categories = {
        1: ReliantBorrowing,
        2: Overindebtness,
        3: DebtEssentialBills,
        4: CreditFileInformation,
        5: ImpactedNegatively,
        6: HighUtilisation,
        7: StruggledRepayments,
        8: ClientFinancialArrears,
        9: CardNotManaged,
        10: UnableRepayments
    };

    const categoryNames = {
        1: "Reliant on further borrowing",
        2: "Overindebtness",
        3: "Caused further debt with essential bills",
        4: "Adverse credit file information",
        5: "Negative impact on circumstances",
        6: "High utilisation of existing credit ",
        7: "Struggled to make repayments",
        8: "Financial Arrears",
        9: "Credit card account was not managed well",
        10: "Unable to maintain sustainsable repayments"
    };

    useEffect(() => {

        if(category > 0){
        if (category in categories) {

            const selectedCategory = categories[category];

            const valuesArray = InputValues.filter(value => !isNaN(value));
            const filteredItems = valuesArray.map(value => ({
                id: value,
                description: selectedCategory[value] || ''
            })).filter(item => item.description);

            setMatchedItems(filteredItems);
        } else {
            console.error(`Category ${category} is not supported.`);
        }
    }
    }, [category, InputValues]);
    return (
        <>
            <div
                // className="modal fade"
                className={`modal fade blackover ${category ? "show" : "hide"}`}
                id="staticBackdrop2"
                data-bs-backdrop="static"
                data-bs-keyboard="false"
                tabIndex={-1}
                aria-labelledby="staticBackdrop2Label"
                aria-hidden="true"
            >
                <div className="modal-dialog modal-dialog-centered modal-lg">
                    <div className="modal-content">
                        <div className="modal-body">
                            <div className="text-end">
                                <div className="flex-container">
                                    <button
                                        className="item-1"
                                        data-bs-dismiss="modal"
                                        aria-label="Close"
                                        onClick={() => setShowModal(0)}
                                    >
                                        <span className="inner">
                                            <span className="label">Close</span>
                                        </span>
                                    </button>
                                </div>
                            </div>
                            <table className="adition_page3">
                                <tbody style={{ display: "table" }}>
                                    <tr>
                                        <td style={{ width: "100%" }}>
                                            <center>
                                                <img src={Logo} style={{ height: "60px" }} alt="Logo" />
                                            </center>
                                            <h2 className="questh2 text-center m-2">{categoryNames[category]}</h2>
                                            <br />
                                            {matchedItems.length > 0 && (
                                                <div>
                                                    <ol>
                                                        {matchedItems.map(item => (
                                                            <li key={item.id}>{item.description}</li>
                                                        ))}
                                                    </ol>
                                                </div>
                                            )}
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default PreviewContentComponent