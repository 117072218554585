import React, { useState } from "react";
import OtpInputBox from "../../../UI/OtpInputBox";
import Accordion from "react-bootstrap/Accordion";
import GtmDataLayer from "../../../../Utility/GtmDataLayer";

const BankAccNo = ({
  saveInputAns,
  backClick,
  SkippedLeads,
  setClickedSlide,
  setAccNum,
  lenderName,
  accNumPopUp,
  setAccNumPopUp,
  setSortCodePopUp,
  closeModal,
}) => {
  const [accNo, setAccNo] = useState("");
  const [error, setError] = useState(false);

  const handlebtnChange = (accNo) => {
    setAccNo(accNo);
    setAccNum(accNo);
  };
  const skipNext = () => {
    GtmDataLayer({
      question: "account number",
    });
    let array = JSON.parse(localStorage.getItem("questionData"));
    array = array.filter((item) => item.question_id !== 87);
    localStorage.setItem("questionData", JSON.stringify(array));
    SkippedLeads("skipAccountNumber", false);
    // setClickedSlide({ slide: "bank_acc_no" });
    setAccNumPopUp("hide");
    setSortCodePopUp("show");
  };

  const handleNext = async (e) => {
    if (accNo.length != 8) {
      setError(true);
      return;
    } else if (parseInt(accNo) === 0) {
      setError(true);
      return;
    } else {
      GtmDataLayer({
        question: "account number",
      });
      setError(false);
      setAccNumPopUp("hide");
      setSortCodePopUp("show");
      saveInputAns(e);
    }
  };

  return (
    <>
      <div
        className={`modal fade ${accNumPopUp}`}
        style={{ backgroundColor: "#000000e3" }}
        id="CredCardAgreement"
        data-bs-backdrop="static"
        data-bs-keyboard="false"
        tabIndex={-1}
        aria-labelledby="staticBackdropLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-dialog-centered modal-lg">
          <div className="modal-content">
          <div className="modal-header border border-0 popmodalbg">
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
                onClick={closeModal}
              />
            </div>
            <div className="modal-body">
              {"others" !== lenderName && "" !== lenderName && (
                <h3 className="que-title p-3 text-center">
                  <b>Bank: {lenderName}</b>
                </h3>
              )}

              <div className="question-content mt-0 scale-down-ver-center px-3 pb-lg-3 pb-md-3 pb-sm-2 pb-2">
                <div>
                  <h1 className="que-title p-0 pb-3 text-center" style={{fontSize : '16px'}}>
                   For your bank to locate your account please provide your account number
                  </h1>
                  <div className="mb-3 " >
                    <Accordion>
                      <Accordion.Item className="custom-accordion" eventKey="0">
                        <Accordion.Header>
                          <div className="p-1 text-primary text-decoration-underline" style={{fontSize : '14px'}}>
                            Why are we asking for this information?
                          </div>
                        </Accordion.Header>
                        <Accordion.Body>
                          When submitting a DSAR, it is essential to provide the
                          bank with sufficient details to identify the correct
                          records associated with your account. The bank account
                          number and sort code are crucial identifiers that
                          allow the bank to locate all pertinent information
                          quickly and accurately. This ensures that your DSAR is
                          processed efficiently and that all relevant data is
                          included in the response
                          <br />
                          <br />
                          Please rest assured that this information will be used
                          solely for the purpose of submitting your DSAR to{" "}
                          {lenderName} and will be handled with the utmost
                          confidentiality in accordance with applicable data
                          protection laws
                        </Accordion.Body>
                      </Accordion.Item>
                    </Accordion>
                  </div>
                  <div className="col-lg-12 m-auto mb-4">
                    <div className="otp-inputV2">
                      <OtpInputBox
                        length={8}
                        onChange={handlebtnChange}
                        className={"otp-input-fieldV2"}
                      />
                    </div>
                    {error && (
                      <span className="error_msg mt-2">
                        Please enter a valid Bank Account Number
                      </span>
                    )}
                  </div>
                  {/* <div className="col-lg-6 m-auto"> */}
                  <div className="m-auto">
                    <button
                      type="button"
                      className="btn-comm2 py-lg-3 py-md-3 py-sm-2  py-2 w-100 nextBtn mt-2  "
                      id="nextscroll"
                      name="bank_acc_no"
                      onClick={handleNext}
                      data-quest-id={87}
                    >
                      Next &#8594;
                    </button>
                  </div>
                  <div className="m-auto mb-2 mt-2">
                    <button
                      name="sortcode"
                      className="btn btn-green-skip py-lg-3 py-md-3 py-sm-2 py-2 w-100 "
                      id=""
                      value=""
                      type="button"
                      onClick={skipNext}
                    >
                      Skip
                    </button>
                  </div>
                  <div className="warning-text">
                    {
                      "(if you prefer not to provide it, you can skip this step)"
                    }
                  </div>
                  <div className="col-12 text-center mt-1">
                    <img
                      src={
                        "../../../../assets/Quest_VAQ_UB_4_2/img/secure_iconV2.webp"
                      }
                      style={{ width: "81px" }}
                    />
                  </div>
                </div>

                {/* <div className="row">
                  <div className="mb-2 mt-3 text-center">
                    <a className="backbtn skip-btn" name="bank_acc_no" onClick={skipNext}>{"Skip"}</a>
                </div>
          </div> */}

                {/* <p className="text-center bck-marwid">
                                        <button
                                            name="banks"
                                            className="btn btn-secondary backbtn1a"
                                            id=""
                                            value=""
                                            type="button"
                                            onClick={backClick}
                                        >
                                            Go Back
                                        </button>
                                    </p> */}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default BankAccNo;
