import React, { useEffect, useState,useContext } from 'react';
import '../../../../assets/FollowupS4/scss/modules/merit_flp_S4.scss';
import '../../../../assets/FollowupS4/scss/modules/preview_flp_S4.scss';
import { useClaimReg } from '../../../../Hooks/useClaimReg';
import {
    VanquisProducts,
    ReliantBorrowing,
    Overindebtness,
    DebtEssentialBills,
    CreditFileInformation,
    ImpactedNegatively,
    HighUtilisation,
    StruggledRepayments,
    ClientFinancialArrears,
    CardNotManaged,
    UnableRepayments,
} from "../../../../Constants/Constants";
import { usePreviewData } from '../../../../Hooks/usePreviewData';
import { useHistory } from "react-router-dom";
import PreviewContentComponent from './PreviewContentComponent';
import { useFollowupUserQuestions } from "../../../../Hooks/useFollowupUserQuestions";
import { useQuestionnaire } from "../../../../Hooks/useQuestionnaire";
import VisitorParamsContext from "../../../../Contexts/VisitorParams";


const MeritPage_FLP_S4 = ({uuid,allFollowupData,removeFromPending}) => {
    const [meritPopUpLoader, setMeritPopUpLoader] = useState('show');
    const { clickPreviewVisit, clickMeritPageVisit } = useClaimReg();
    const { savePreviewData, getMeritPagePreviewData } = usePreviewData();
    const [ConstantMeritList, setConstantMeritList] = useState({
        ReliantBorrowing: false,
        Overindebtness: false,
        DebtEssentialBills: false,
        CreditFileInformation: false,
        ImpactedNegatively: false,
        HighUtilisation: false,
        StruggledRepayments: false,
        ClientFinancialArrears: false,
        CardNotManaged: false,
        UnableRepayments: false,
    });
    const [combinedMeritPageData, setCombinedMeritPageData] = useState([]);
    const history = useHistory();
    const { saveFollowupUserQuestions } = useFollowupUserQuestions();
    const { SaveQuestionnaire } = useQuestionnaire();
    const { visitorParameters } = useContext(VisitorParamsContext);

    let split_name = localStorage.getItem("split_name") || "";

    const [showModal, setShowModal] = useState(0);

    const closeModal = () => {
        // setShowModal(prevState => ({ ...prevState, [modalType]: false }));
        console.log("inside close modal");
        setShowModal(0);
    };

    useEffect(() => {
        setTimeout(() => {
            setMeritPopUpLoader('hide');
        }, 3000);
            getMeritPageResult();
    }, []);

    const getMeritPageResult = async () => {

        let MeritPageResponse = await getMeritPagePreviewData(uuid);
        var combinedMeritList = MeritPageResponse.data.result.MeritPreviewData;

        setCombinedMeritPageData(combinedMeritList);

        const finalMeritPageList = combinedMeritList.forEach(id => {
            if (ReliantBorrowing[id.toString()]) ConstantMeritList.ReliantBorrowing = true;
            if (Overindebtness[id.toString()]) ConstantMeritList.Overindebtness = true;
            if (DebtEssentialBills[id.toString()]) ConstantMeritList.DebtEssentialBills = true;
            if (CreditFileInformation[id.toString()]) ConstantMeritList.CreditFileInformation = true;
            if (ImpactedNegatively[id.toString()]) ConstantMeritList.ImpactedNegatively = true;
            if (HighUtilisation[id.toString()]) ConstantMeritList.HighUtilisation = true;
            if (StruggledRepayments[id.toString()]) ConstantMeritList.StruggledRepayments = true;
            if (ClientFinancialArrears[id.toString()]) ConstantMeritList.ClientFinancialArrears = true;
            if (CardNotManaged[id.toString()]) ConstantMeritList.CardNotManaged = true;
            if (UnableRepayments[id.toString()]) ConstantMeritList.UnableRepayments = true;
        });

        return combinedMeritList;
    }
    const onSelectAnswerV2 = async (e) => {
        // GtmDataLayer({
        //     question: "faced difficulties",
        // });

        let question_data = {
            question_id: 29,
            option_id: 99,
            answer_text: null,
            input_answer: "",
        };

            storeFollowupQuestionnire(question_data);
            var existingEntries = JSON.parse(localStorage.getItem("questionData"));
            if (existingEntries == null) existingEntries = [];
            localStorage.setItem(
                "currentquestionData",
                JSON.stringify(question_data)
            );
            existingEntries.push(question_data);
            localStorage.setItem("questionData", JSON.stringify(existingEntries));
    }

    const storeFollowupQuestionnire = async (question_data) => {
        const response = await saveFollowupUserQuestions(
            question_data,
            allFollowupData,
            "followup_question_store"
        );
    };

    const handleMeritPopUp = async () => {
        // GtmDataLayer({
        //   question: "proceed claim",
        // });
        const claimReg = await clickMeritPageVisit(
            uuid,
            'followup-s4',
            "merit_page_click",
            allFollowupData.atp_sub6
        );
        onSelectAnswerV2();
        window.scrollTo(0, 0);
        removeFromPending('meritPage');
    };

    return (
        <div className="merit_flp_S4 preview_flp_S4" >
            <section className="banner qs-section">
                <div className="container">
                    <div>
                        <div className="brand">
                            <div className="logo pt-3 text-center">
                                <a>
                                    {" "}
                                    <img src="assets/VAQ_UB_5/prviewPage/img/logo-white.png" height={60} alt="" />
                                </a>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="container my-4">
                    {/* Loader */}
                    <div className={`loader-wrap text-center text-light ${meritPopUpLoader == "show" ? "show" : "hide"}`}>
                        <div className='analyzeLoader'>
                            <img height={80} src="assets/VAQ_UB_5/prviewPage/img/loader-review2.svg" alt="" />
                            <h6 className="mt-3">Analysing your answers</h6>
                        </div>
                    </div>
                </div>

                <div className={`container mb-5 gn_slide"  ${meritPopUpLoader == "hide" ? "show" : "hide"}`}>
                    <div className="row">
                        <div className="col-lg-8 offset-lg-2 col-12 goodnews_popup p-0">
                            <div className="goodnewshow p-0">

                            <div className="background_div">
                            <div class="pt-4 mb-3">
                                    <h4 className="fs-6">
                                    It sounds like you've been finding it tough to keep up with your Vanquis repayments while meeting your other commitments. <br/>
                                    <br/>To instruct TMS Legal to pursue your claim, click the button below.
                                    </h4>
                                    </div>
                                    <div className="mb-2">
                                        <button
                                            id="proceedBtn"
                                            className="btnwidth btn-comm2 p-2    py-3 px-4 pulse"
                                            onClick={handleMeritPopUp}
                                            data-click-id='meritPage_next'
                                        >
                                            <span id="spanbtn" data-click-id='meritPage_next'>Yes, I confirm</span>

                                        </button>
                                    </div>

                                    <div class="mt-2 mb-3">
                                    <p className="">
                                           <i> I understand the merits of my claim and that I am able to self represent.</i>
                                        </p>
                                    </div>
                                    </div>
                                    <div className="text-center title mt-2 mb-2">
                                        <strong className="textgood text-success">GOOD NEWS!</strong>
                                    </div>
                                    <h4 className="text-center fs-6 p-2">We believe your case has merit because you told us:</h4>
                         



                                <div className="pb-3 text-center px-lg-4 px-md-4 px-sm-0 p-2">
                
                                    <div className="d-flex justify-content-center">
                                        <ul className=" m-0 mt-2 text-start row justify-content-center listfi">
                                            {ConstantMeritList.ReliantBorrowing && (
                                                <li className="align-items-center">
                                                    <a onClick={() => setShowModal(1)}
                                                     data-click-id='meritPage_reliantBorrowing'
                                                    >
                                                        
                                                        Reliant on further borrowing
                                                    </a>                                                </li>
                                            )}
                                            {ConstantMeritList.Overindebtness && (
                                                <li className="align-items-center">
                                                    <a onClick={() => setShowModal(2)}
                                                     data-click-id='meritPage_overindebtedness'
                                                    >
                                                        Overindebtedness
                                                    </a>                                                </li>
                                            )}
                                            {ConstantMeritList.DebtEssentialBills && (
                                                <li className="align-items-center">
                                                    <a onClick={() => setShowModal(3)}  data-click-id='meritPage_debtEssentialBills'>
                                                        Caused further debt with essential bills
                                                    </a>                                                </li>
                                            )}
                                            {ConstantMeritList.CreditFileInformation && (
                                                <li className="align-items-center">
                                                    <a onClick={() => setShowModal(4)} data-click-id='meritPage_creditFileInformation'>
                                                        Adverse credit file information
                                                    </a>                                                </li>
                                            )}
                                            {ConstantMeritList.ImpactedNegatively && (
                                                <li className="align-items-center">
                                                    <a onClick={() => setShowModal(5)} data-click-id='meritPage_impactedNegatively'>
                                                    Negative impact on circumstances
                                                    </a>                                                </li>
                                            )}
                                            {ConstantMeritList.HighUtilisation && (
                                                <li className="align-items-center">
                                                    <a onClick={() => setShowModal(6)}
                                                    data-click-id='meritPage_highUtilisation'>
                                                        High utilisation of existing credit
                                                    </a>                                                </li>
                                            )}
                                            {ConstantMeritList.StruggledRepayments && (
                                                <li className="align-items-center">
                                                    <a onClick={() => setShowModal(7)}
                                                    data-click-id='meritPage_struggledRepayments'>
                                                        Struggling to make repayments
                                                    </a>                                                </li>
                                            )}
                                            {ConstantMeritList.ClientFinancialArrears && (
                                                <li className="align-items-center">
                                                    <a onClick={() => setShowModal(8)}
                                                     data-click-id='meritPage_financialArrears'>
                                                    Financial Arrears
                                                    </a>                                                </li>
                                            )}
                                            {ConstantMeritList.CardNotManaged && (
                                                <li className="align-items-center">
                                                    <a onClick={() => setShowModal(9)} data-click-id='meritPage_creditNotManaged'>
                                                        Credit account was not managed well
                                                    </a>                                                </li>
                                            )}
                                            {ConstantMeritList.UnableRepayments && (
                                                <li className="align-items-center">
                                                    <a onClick={() => setShowModal(10)} data-click-id='meritPage_unableRepayments'>
                                                        Unable to maintain sustainable repayments
                                                    </a>                                                </li>
                                            )}
                                        </ul>
                                    </div>
                                    {/* <div className="mt-4 mb-3">
                                        <h5 className="text-arrow fs-6">
                                            I understand the merits of my claim and that I am able to
                                            self-represent. To instruct TMS Legal to pursue your claim,
                                            click the button below.
                                        </h5>
                                    </div> */}
                                    {/* <div className="mb-3">
                                        <button
                                            id="proceedBtn"
                                            className=" btn-comm2 p-2    py-3 px-4 pulse"
                                            onClick={handleMeritPopUp}
                                        >
                                            <span id="spanbtn">Yes, I confirm</span>

                                        </button>
                                    </div> */}
                                    <p className="fst-italic">
                                        <br/>
                                        Next, you just need to review and sign our online letter of
                                        authority, your questionnaire, our terms, DSAR request for your
                                        bank statements, FOS documentation, and we will then initiate our
                                        background checking and onboarding process.
                                    </p>
                                    <p className="fst-italic">
                                        We will complete our KYC (know your customer) checks, process your
                                        claim, and send it to the lender.
                                    </p>
                                    <p className="fst-italic">
                                        Should your claim be rejected, and we see merit in sending it to
                                        the Financial Ombudsman, we will inform you of this decision at
                                        that time.
                                    </p>
                                    <p className="fst-italic">
                                        Good to know: You do not need to use a no win no fee solicitor to
                                        make a complaint to your lender. If your complaint is not
                                        successful, you can refer it to the Financial Ombudsman service
                                        for free.
                                    </p>
                                    <p className="fst-italic">
                                        Please note the lender may reduce the credit limit, suspend the
                                        card, or remove/close the product. You acknowledge that by making
                                        this unaffordable/irresponsible lending complaint, it may mean
                                        that you won't be able to borrow from the lender in the future.
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

            </section>
            <PreviewContentComponent
                category={showModal}
                InputValues={combinedMeritPageData}
                setShowModal={setShowModal}
            />
        </div>
    )
}

export default MeritPage_FLP_S4