import React from "react";

const Button = ({ type, className, id, onClick, buttonText, disabled, value,QuestID,AnsID,style ,dataClickId}) => {
  return (
    <button
      dangerouslySetInnerHTML={{ __html: buttonText }}
      type={type}
      className={className}
      id={id}
      onClick={onClick}
      disabled={disabled}
      value={value}
      data-quest-id={QuestID}
      data-ans-id={AnsID}
      style={style}
      data-click-id={dataClickId}
    >
    </button>
  );
};
export default Button;

