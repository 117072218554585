import React, { useState } from "react";
import RadioButtonQuest from "../../../UI/RadioButtonQuest";
const EmploymentStatus = ({
  className,
  saveQuestAnswer,
  addPending,
  removeFromPending,
  qnNo
}) => {
  const [questResult, setQuestResult] = useState(false);
  const [checkedOne, setCheckedOne] = useState("unchecked");
  const [checkedTwo, setCheckedTwo] = useState("unchecked");
  const [checkedThree, setCheckedThree] = useState("unchecked");
  const [checkedFour, setCheckedFour] = useState("unchecked");
  const [checkedFive, setCheckedFive] = useState("unchecked");
  const [checkedSix, setCheckedSix] = useState("unchecked");
  const [checkedSeven, setCheckedSeven] = useState("unchecked");
  const [checkedEight, setCheckedEight] = useState("unchecked");
  const [checkedNine, setCheckedNine] = useState("unchecked");
  const [checkedTen, setCheckedTen] = useState("unchecked");
  const [checkedEleven, setCheckedEleven] = useState("unchecked");
  // const [checkedTwelve, setCheckedTwelve] = useState("unchecked");

  const optionSelected = async (e) => {
    if (e.target.value == "3") {
      setCheckedOne("checked");
    } else {
      setCheckedOne("unchecked");
    }
    if (e.target.value == "199") {
      setCheckedTwo("checked");
    } else {
      setCheckedTwo("unchecked");
    }
    if (e.target.value == "4") {
      setCheckedThree("checked");
    } else {
      setCheckedThree("unchecked");
    }
    if (e.target.value == "5") {
      setCheckedFour("checked");
    } else {
      setCheckedFour("unchecked");
    }
    if (e.target.value == "6") {
      setCheckedFive("checked");
    } else {
      setCheckedFive("unchecked");
    }
    if (e.target.value == "200") {
      setCheckedSix("checked");
    } else {
      setCheckedSix("unchecked");
    }
    if (e.target.value == "201") {
      setCheckedSeven("checked");
    } else {
      setCheckedSeven("unchecked");
    }
    if (e.target.value == "202") {
      setCheckedEight("checked");
    } else {
      setCheckedEight("unchecked");
    }
    if (e.target.value == "203") {
      setCheckedNine("checked");
    } else {
      setCheckedNine("unchecked");
    }
    if (e.target.value == "204") {
      setCheckedTen("checked");
    } else {
      setCheckedTen("unchecked");
    }
    if (e.target.value == "205") {
      setCheckedEleven("checked");
    } else {
      setCheckedEleven("unchecked");
    }
    setQuestResult(false);
  };


  const handleClick = async (e) => {
    saveQuestAnswer(2,e.target.value);
    removeFromPending('empStatus');
  };
  return (
    <>
      <div className={`container ${className}`}>
        <div className="questitem mt-5 scale-down-ver-center px-3 py-lg-3 py-md-3 py-sm-2 py-2 pb-3  ">
          <h1 className="que-title p-3  ">
            At the <b>time you took out the account with Vanquis</b>, what was
            your employment status?{" "}
          </h1>
          <div className="row mb-3">
            <div className="mb-2">
              <ul className="p-0 m-0 option-grid">
                <li className="adjustbalewidth">
                  <div className="optionbtn">
                    <RadioButtonQuest
                      name="employmentsts"
                      id="Employed"
                      labelClassName={`${checkedOne}`}
                      value="3"
                      labelName="Employed"
                      onClick={handleClick}
                      dataQuestId={2}
                      dataAnsId={3}
                      dataAnsLabel="Employed"
                      dataClickId='empStatus_employed'
                    />
                  </div>
                </li>
                <li className="adjustbalewidth">
                  <div className="optionbtn">
                    <RadioButtonQuest
                      name="employmentsts"
                      id="Unemployed"
                      labelClassName={`${checkedTwo}`}
                      value="199"
                      labelName="Unemployed"
                      onClick={handleClick}
                      dataQuestId={2}
                      dataAnsId={199}
                      dataAnsLabel="Unemployed"
                      dataClickId='empStatus_unemployed'
                    />
                  </div>
                </li>
                <li className="adjustbalewidth">
                  <div className="optionbtn">
                    <RadioButtonQuest
                      name="employmentsts"
                      id="Selfemployed"
                      labelClassName={`${checkedThree}`}
                      value="4"
                      labelName="Selfemployed"
                      onClick={handleClick}
                      dataQuestId={2}
                      dataAnsId={4}
                      dataAnsLabel="Selfemployed"
                      dataClickId='empStatus_selfemployed'
                    />
                  </div>
                </li>
                <li className="adjustbalewidth">
                  <div className="optionbtn">
                    <RadioButtonQuest
                      name="employmentsts"
                      id="Student"
                      labelClassName={`${checkedFour}`}
                      value="5"
                      labelName="Student"
                      onClick={handleClick}
                      dataQuestId={2}
                      dataAnsId={5}
                      dataAnsLabel="Student"
                      dataClickId='empStatus_student'
                    />
                  </div>
                </li>
                <li className="adjustbalewidth">
                  <div className="optionbtn">
                    <RadioButtonQuest
                      name="employmentsts"
                      id="Retired"
                      labelClassName={`${checkedFive}`}
                      value="6"
                      labelName="Retired"
                      onClick={handleClick}
                      dataQuestId={2}
                      dataAnsId={6}
                      dataAnsLabel="Retired"
                      dataClickId='empStatus_retired'
                    />
                  </div>
                </li>
                <li className="adjustbalewidth">
                  <div className="optionbtn">
                    <RadioButtonQuest
                      name="employmentsts"
                      id="Benefitsincome"
                      labelClassName={`${checkedSix}`}
                      value="200"
                      labelName="Benefits main source of income"
                      onClick={handleClick}
                      dataQuestId={2}
                      dataAnsId={200}
                      dataAnsLabel="Benefits main source of income"
                      dataClickId='empStatus_benefitsMainSourceOfIncome'
                    />
                  </div>
                </li>
                <li className="adjustbalewidth">
                  <div className="optionbtn">
                    <RadioButtonQuest
                      name="employmentsts"
                      id="Parttime"
                      labelClassName={`${checkedSeven}`}
                      value="201"
                      labelName="Part-time job"
                      onClick={handleClick}
                      dataQuestId={2}
                      dataAnsId={201}
                      dataAnsLabel="Part-time job"
                      dataClickId='empStatus_partTimeJob'
                    />
                  </div>
                </li>
                <li className="adjustbalewidth">
                  <div className="optionbtn">
                    <RadioButtonQuest
                      name="employmentsts"
                      id="Temporaryworker"
                      labelClassName={`${checkedEight}`}
                      value="202"
                      labelName="Temporary worker"
                      onClick={handleClick}
                      dataQuestId={2}
                      dataAnsId={202}
                      dataAnsLabel="Temporary worker"
                      dataClickId='empStatus_temporaryWorker'
                    />
                  </div>
                </li>
                <li className="adjustbalewidth">
                  <div className="optionbtn">
                    <RadioButtonQuest
                      name="employmentsts"
                      id="LongSick"
                      labelClassName={`${checkedNine}`}
                      value="203"
                      labelName="Long Term Sick"
                      onClick={handleClick}
                      dataQuestId={2}
                      dataAnsId={203}
                      dataAnsLabel="Long Term Sick"
                      dataClickId='empStatus_longTermSick'
                    />
                  </div>
                </li>
                <li className="adjustbalewidth">
                  <div className="optionbtn">
                    <RadioButtonQuest
                      name="employmentsts"
                      id="Maternity"
                      labelClassName={`${checkedTen}`}
                      value="204"
                      labelName="Maternity"
                      onClick={handleClick}
                      dataQuestId={2}
                      dataAnsId={204}
                      dataAnsLabel="Maternity"
                      dataClickId='empStatus_maternity'
                    />
                  </div>
                </li>
                <li className="adjustbalewidth">
                  <div className="optionbtn">
                    <RadioButtonQuest
                      name="employmentsts"
                      id="contract"
                      labelClassName={`${checkedEleven}`}
                      value="205"
                      labelName="Rolling/Zero hours contract"
                      onClick={handleClick}
                      dataQuestId={2}
                      dataAnsId={205}
                      dataAnsLabel="Rolling/Zero hours contract"
                      dataClickId='empStatus_rollingZeroHoursContract'
                    />
                  </div>
                </li>
                {/* <li>
                <div class="optionbtn">
                  <RadioButtonQuest
                    name="employment"
                    id="Notsure"
                    labelClassName={`${checkedTwelve}`}
                    value="195"
                    labelName="Not sure"
                    onClick={optionSelected}
                    dataQuestId={2}
                    dataAnsId={195}
                  />
                </div>
              </li> */}
              </ul>
              {/* {questResult && (
              <span className="error_msg">Please select an option</span>
            )} */}
            </div>
          </div>
          <div className="row">
            {/* <div className="col-12 mb-2">
            <button
              type="button"
              className="qb12 btn-comm2 py-lg-3 py-md-3 py-sm-2 py-2 w-100 nextBtn "
              id="nextscroll"
              name="employmentsts"
              onClick={handleClick}
              data-quest-id={2}
            >
             Next &#8594; */}
            {/* <i className="bi bi-arrow-right"></i> */}
            {/* </button>
          </div> */}
            <p className="text-center bck-marwid">
              <button
                name={"soldOnDebtBack"}
                className="btn btn-secondary backbtn1a"
                id=""
                value=""
                type="button"
                onClick={()=>addPending("soldOnDebt")}
                data-click-id='empStatus_back'
              >
                Go Back
              </button>
            </p>
          </div>
        </div>
        <div class="tipbox tip2 show">
        We require details regarding your employment status at the time you obtained the credit card, as it aids in assessing your financial situation..
          <div class="arrow  arrow-top"></div>
        </div>
        <div
          id="rangequestions"
          class="qrange d-flex align-items-sm-center text-center"
        >
          Question <br class="d-none d-md-block" />{qnNo}
        </div>
      </div>
    </>
  );
};
export default EmploymentStatus;
