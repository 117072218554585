import React, { useState } from "react";
import RadioButtonQuest from "../../../UI/RadioButtonQuest";
const StillActive = ({
  clsName,
  onSelectAnswer,
  backClick,
  splitForm
}) => {
  const [questResult, setQuestResult] = useState(false);
  const [checkedOne, setCheckedOne] = useState("unchecked");
  const [checkedTwo, setCheckedTwo] = useState("unchecked");
  const [checkedThree, setCheckedThree] = useState("unchecked");
  const optionSelected = async (e) => {
    if (e.target.value == "95") {
      setCheckedOne("checked");
    } else {
      setCheckedOne("unchecked");
    }
    if (e.target.value == "96") {
      setCheckedTwo("checked");
    } else {
      setCheckedTwo("unchecked");
    }
    if (e.target.value == "195") {
      setCheckedThree("checked");
    } else {
      setCheckedThree("unchecked");
    }
    setQuestResult(false);

  }
  const handleClick = async (e) => {
    e.preventDefault();
    let errorFlag = 0;
    let optionselect = splitForm.current["stillactive"].value;
    if (optionselect == '') {
      errorFlag = 1;
      setQuestResult(true);
      return false;
    } else {
      onSelectAnswer(e);
    }
  }
  return (
    <>
      <div className={`question-content mt-0  scale-down-ver-center px-3   py-lg-3 py-md-3 py-sm-2 py-2  ${clsName}`}>
        <div className="row">
          <div className="col-lg-12 mb-2">
            <ul className="p-0 m-0 option-grid">
              <li>
                <div className="optionbtn">
                  <RadioButtonQuest
                    name="stillactive"
                    id="numplate1"
                    labelClassName={`${checkedOne}`}
                    value="95"
                    labelName="Yes"
                    onClick={optionSelected}
                    dataQuestId={26}
                    dataAnsId={95}
                  />
                </div>
              </li>

              <li>
                <div className="optionbtn">
                  <RadioButtonQuest
                    name="stillactive"
                    id="numplate2"
                    labelClassName={`${checkedTwo}`}
                    value="96"
                    labelName="No"
                    onClick={optionSelected}
                    dataQuestId={26}
                    dataAnsId={96}
                  />
                </div>
              </li>
              <li>
                <div className="optionbtn">
                  <RadioButtonQuest
                    name="stillactive"
                    id="numplate3"
                    labelClassName={`${checkedThree}`}
                    value="195"
                    labelName="Not sure"
                    onClick={optionSelected}
                    dataQuestId={26}
                    dataAnsId={195}
                  />
                </div>
              </li>

            </ul>
            {questResult && (
              <span className="error_msg">Please select an option</span>
            )}

          </div>
          <div className="col-lg-12 mb-2 mt-2">
            <button
              type="button"
              className="  btn-comm2 py-3 w-100 nextBtn "
              id="nextscroll"
              name="stillActBtn"
              onClick={handleClick}
              data-quest-id={26}
            >
              Next &#8594;
              {/* <i className="bi bi-arrow-right"></i> */}
            </button>
          </div>
          <div className="row">

            <div className="  text-center mt-3 " >

              <div className="btn btn-secondary backbtn" name="btn_qest_44" onClick={backClick}  > <i className="bi bi-arrow-left"></i> Back</div>
            </div>

          </div>
        </div>
      </div>
    </>
  )
}
export default StillActive;