import "../../../../assets/MeritPage/scss/MeritPage.scss";
import React, { useRef, useState, useContext, useReducer, useEffect, } from "react";
import ModalListModal from "./MeritDataListOverDraft";
import Complaints from '../Common/Complaints';
import TC from '../../../../assets/pdf/TOBA_New.pdf';

const MeritPopupOD_UFL_4 = ({
    meritPagePopUp,
    popUpModalBody,
    meritPopUpLoader,
    meritPopUpContent,
    ConstantMeritList,
    combinedMeritPageData,
    handleMeritPopUp,
}) => {

    const cfirstName = localStorage.getItem("UcFname") ? localStorage.getItem("UcFname") : '';
    const [showModal, setShowModal] = useState(0);
    const [showComplaint, setShowComplaint] = useState(false);
    const modalClick = (clickValue) => {
        switch (clickValue) {

          case "showComplaint": {
            setShowComplaint(true);
            break;
          }
          case "exitComplaint": {
            setShowComplaint(false);
            break;
          }
        }
      };
    

    return (
        <div className="MeritPage">
            <div className={`modal blackover fade ${meritPagePopUp ? "show" : "hide"}`} id="sample" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true">
                <div className="modal-dialog modal-dialog-centered modal-lg">
                    <div className={`modal-content  goodnews_popup ${popUpModalBody == "show" ? "show" : "hide"}`}>
                        <div className="modal-body">
                            <div className={`text-center max-400 m-auto thakyoumsg ${meritPopUpLoader == "show" ? "show" : "hide"}`}>
                                <div className="pb-lg-2 mt-5 ">
                                    <img height="50" src="assets/MeritPage/img/logo.png" alt="" />
                                </div>
                                <div className="my-4 mt-5">
                                    <img height="50" src="assets/MeritPage/img/loader-review2.svg" alt="" />
                                </div>
                                <div className="">
                                    <h4 className="h4-thakyou">Thank you <strong>{cfirstName}</strong> for completing our assessment, which we will output into a
                                        questionnaire format.</h4>
                                    <h5 className="fst-italic fs-6"> Please note – you are able to self represent your complaint.</h5>
                                </div>
                            </div>

                            <div className={`goodnewsrow ${meritPopUpContent == "show" ? "show" : "hide"}`}>
                                {/* <div className="text-end">
                                <button
                                    type="button"
                                    className="btn-close close22"
                                    data-bs-dismiss="modal"
                                    aria-label="Close"
                                    onClick={handleMeritPopUpClose}
                                >
                                </button>
                                </div> */}
                                <div className="pb-3 text-center px-lg-4 px-md-4  px-sm-0">
                                    <div className="title mb-2"> <strong className="textgood text-success">GOOD NEWS,</strong> </div>
                                    <h4> From your responses, we understand that you've had difficulties making repayments to Overdraft on time while meeting other commitments and believe your case has merit because you told us:</h4>
                                    <div className="d-flex justify-content-center  ">
                                        <ul className="p-0 m-0 mt-2  text-start row  justify-content-center listfi ">
                                          
                                            {ConstantMeritList.ReliantBorrowingOverDraft && (
                                                <li className="align-items-center">
                                                    <a onClick={() => setShowModal(1)}>
                                                        Reliant on further borrowing
                                                    </a>
                                                </li>
                                            )}
                                            {ConstantMeritList.OverindebtnessOverDraft && (
                                                <li className="align-items-center">
                                                    <a onClick={() => setShowModal(2)}>
                                                    Overindebtedness
                                                    </a>
                                                </li>
                                            )}
                                            {ConstantMeritList.DebtEssentialBillsOverDraft && (
                                                <li className="align-items-center">
                                                    <a onClick={() => setShowModal(3)}>
                                                        Caused further debt with essential bills
                                                    </a>
                                                </li>
                                            )}
                                            {ConstantMeritList.CreditFileInformationOverDraft && (
                                                <li className="align-items-center">
                                                    <a onClick={() => setShowModal(4)}>
                                                        Adverse credit file information
                                                    </a>
                                                </li>
                                            )}
                                            {ConstantMeritList.ImpactedNegativelyOverDraft && (
                                                <li className="align-items-center">
                                                    <a onClick={() => setShowModal(5)}>
                                                        Negative impact on circumstances
                                                    </a>
                                                </li>
                                            )}
                                            {ConstantMeritList.HighUtilisationOverDraft && (
                                                <li className="align-items-center">
                                                    <a onClick={() => setShowModal(6)}>
                                                        High utilisation of existing credit
                                                    </a>
                                                </li>
                                            )}
                                            {ConstantMeritList.StruggledRepaymentsOverDraft && (
                                                <li className="align-items-center">
                                                    <a onClick={() => setShowModal(7)}>
                                                        Struggling to make repayments
                                                    </a>
                                                </li>
                                            )}
                                            {ConstantMeritList.ClientFinancialArrearsOverDraft && (
                                                <li className="align-items-center">
                                                    <a onClick={() => setShowModal(8)}>
                                                        Financial arrears
                                                    </a>
                                                </li>
                                            )}
                                            {ConstantMeritList.AccNotManagedOverDraft && (
                                                <li className="align-items-center">
                                                    <a onClick={() => setShowModal(9)}>
                                                    Account was not managed well
                                                    </a>
                                                </li>
                                            )}
                                            {ConstantMeritList.UnableRepaymentsOverDraft && (
                                                <li className="align-items-center">
                                                    <a onClick={() => setShowModal(10)}>
                                                        Unable to maintain sustainable repayments
                                                    </a>
                                                </li>
                                            )}

                                        </ul>
                                    </div>
                                 
                                    <p className="mt-4 p-align" >1. Please note that you have the right to pursue a complaint directly with your lender and thereafter directly to the Financial Ombudsman Service for free and without using the firm or any solicitor or claims handler.</p>
                                    <p className="p-align">2. We operate on a ‘No Win No Fee’ basis. Our fees range from 18% to 36% including VAT, which is dependent on the amount of redress awarded. Please <a className='text-primary' href={TC} target="_blank">click here</a> for further details.</p>
                                    <p className="p-align">3. The lender may reduce the credit limit/ suspend the card or remove/ close the product (credit card/ overdraft cases) or may repossess the car (car loan cases) you acknowledge that by making the unaffordable/ irresponsible lending complaint that you may not be able to borrow from the lender in future.</p>
                                    <p className="p-align">4. If you decide to sign up with the firm the next steps are that the firm will assess the paperwork, initiate a background check, carry out the onboarding process, then if this all proceeds successfully the firm will send its advice on the merits and, if merits are reasonable or more than reasonable, the firm will process your claim and send it to the lender. Should the claim to the lender be rejected and the firm see merit in it, or should the lender not respond in time and the firm see merit in doing so, the firm will send it to the Financial Ombudsman Service and will inform you of that decision at that time.</p>

                                    <div className="mt-4 mb-3">
                                        <h5 className="text-arrow"><strong> I understand the merits of my claim and have read and understood the above guidance. To instruct TMS Legal to pursue your claim, Click the button below. </strong></h5>
                                    </div>

                                    <div className="mb-3">
                                        <button
                                            id="proceedBtn"
                                            className=" btn-comm2 p-2    py-3 px-4 pulse"
                                            onClick={handleMeritPopUp}
                                        >
                                            <span>Proceed with my claim</span>
                                        </button>
                                    </div>
                                    {/* <p className="fst-italic">  Next you just need to review and sign our online letter of authority, your questionnaire, our terms, DSAR request for your bank statements, FOS documentation and we will then initiate our background checking and onboarding process.
                                    </p>
                                    <p className="fst-italic">
                                        We will complete our KYC (know your customer), checks, process your claim and send it to the lender.
                                    </p>
                                    <p className="fst-italic"> Should your claim be rejected, and we see merit in sending it to the Financial Ombudsman, we will inform you of this decision at that time.
                                    </p>
                                    <p className="fst-italic">Good to know: You do not need to use a no win no fee solicitor to make a complaint to your lender. If your complaint is not successful you can refer it to the Financial Ombudsman service for free.
                                    </p>

                                    <p className="fst-italic">Please note the lender may reduce the credit limit/suspend the card or remove/close the product. You acknowledge that by making this unaffordable/irresponsible lending complaint, it may mean that you won't be able to borrow from the lender in future.</p> */}
                                    <p className="fst-italic p-align">
                                    There are however many benefits of choosing us to handle your claim. We will manage all communication with your lender, working closely with them to ensure that you receive a fair level of compensation. If your lender encounters any difficulties in locating your details, we will assist them to enable your claim to proceed. After you register with us, you can simply sit back and relax while we handle everything, keeping you updated via email throughout the process.</p>
                                </div>
                            </div>

                            <ModalListModal
                              category={showModal}
                              InputValues={combinedMeritPageData}
                              setShowModal={setShowModal}
                             />

                            <Complaints
                              showComplaint={showComplaint}
                              modalClick={modalClick}
                            />

                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default MeritPopupOD_UFL_4;
