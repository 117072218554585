import React, { useState } from "react";
import RadioButtonQuest from "../../../UI/RadioButtonQuest";
const CreditLimitIncreased = ({
    clsName,
    onSelectAnswer,
    validationMsg,
    clearErrors,
    validation,
    trigger,
    getValues,
    splitForm,
    backClick
}) => {
    const [questResult, setQuestResult] = useState(false);
    const [checkedOne, setCheckedOne] = useState("unchecked");
    const [checkedTwo, setCheckedTwo] = useState("unchecked");
    const [checkedThree, setCheckedThree] = useState("unchecked");
    const [checkedFour, setcheckedFour] = useState("unchecked");
    const optionClicked = async (e) => {
        if (e.target.value === "137") {
            setCheckedOne("checked");
        } else {
            setCheckedOne("unchecked");
        }

        if (e.target.value === "138") {
            setCheckedTwo("checked");
        } else {
            setCheckedTwo("unchecked");
        }

        if (e.target.value === "139") {
            setCheckedThree("checked");
        } else {
            setCheckedThree("unchecked");
        }

        if (e.target.value === "140") {
            setcheckedFour("checked");
        } else {
            setcheckedFour("unchecked");
        }
        setQuestResult(false);
    };
    const selectedClick = async (e) => {
        onSelectAnswer(e);
        // e.preventDefault();
        // let errorFlag = 0;
        // let optionselect = splitForm.current["incresedCreditLimit"].value;
        // if (optionselect == '') {
        //     errorFlag = 1;
        //     setQuestResult(true);
        //     return false;
        // } else {
        //     onSelectAnswer(e);
        // }
    }
    return (
        <>
            <div className="question-content mt-0 scale-down-ver-center px-3 py-lg-3 py-md-3 py-sm-2 py-2 pb-3" >
                <div className="row">
                    <div className="col-lg-12 mb-2">
                        <ul className="p-0 m-0 option-grid">
                            <li>
                                <div className="optionbtn">
                                    <RadioButtonQuest
                                        name="CredLimitInc"
                                        id="optionzone"
                                        labelClassName={`${checkedOne}`}
                                        value="137"
                                        labelName="1"
                                        onClick={selectedClick}
                                        dataQuestId={54}
                                        dataAnsId={137}
                                        dataAnsLabel="1"
                                    />
                                </div>
                            </li>
                            <li>
                                <div className="optionbtn">
                                    <RadioButtonQuest
                                        name="CredLimitInc"
                                        id="optionzone2"
                                        labelClassName={`${checkedTwo}`}
                                        value="138"
                                        labelName="2"
                                        onClick={selectedClick}
                                        dataQuestId={54}
                                        dataAnsId={138}
                                        dataAnsLabel="2"
                                    />
                                </div>
                            </li>
                            <li>
                                <div className="optionbtn">
                                    <RadioButtonQuest
                                        name="CredLimitInc"
                                        id="optionzone3"
                                        labelClassName={`${checkedThree}`}
                                        value="139"
                                        labelName="3"
                                        onClick={selectedClick}
                                        dataQuestId={54}
                                        dataAnsId={139}
                                        dataAnsLabel="3"
                                    />

                                </div>
                            </li>
                            <li>
                                <div className="optionbtn">
                                    <RadioButtonQuest
                                        name="CredLimitInc"
                                        id="optionzone4"
                                        labelClassName={`${checkedFour}`}
                                        value="140"
                                        labelName="4&nbsp;or more"
                                        onClick={selectedClick}
                                        dataQuestId={54}
                                        dataAnsId={140}
                                        dataAnsLabel="4 or more"
                                    />

                                </div>

                            </li>
                        </ul>
                        {/* {questResult && (
                            <span className="error_msg">Please select an option</span>
                        )} */}
                    </div>
                    </div>
                    {/* <div className="row mt-2">
                    <div className="col-lg-12 mb-2">
                        <button
                            type="button"
                            className="qb14 btn-comm2 py-lg-3 py-md-3 py-sm-2 py-2 w-100 nextBtn"
                            id="nextscroll"
                            name="CredLimitInc"
                            onClick={selectedClick}
                            data-quest-id={54}
                        >
                            Next &#8594; 
                        </button>

                    </div>
                    </div> */}
                <p className="text-center bck-marwid">
                    <button
                      name="high_balance"
                      className="btn btn-secondary backbtn1a"
                      id=""
                      value=""
                      type="button"
                      onClick={backClick}
              
                    >Go Back</button>
                    </p>
            </div>
        </>
    )
}
export default CreditLimitIncreased;