import React,{useState} from "react";
const StillActive = ({
    clsName,
    onSelectAnswer,
    backClick,
    setStillAct
}) => {
    const [checkedQuestionOne, setCheckedQuestionOne] = useState("unchecked");
    const [checkedQuestionTwo, setCheckedQuestionTwo] = useState("unchecked");
    
    const optionSelected = async(e) => {
        if (e.target.value === "95") {
            setCheckedQuestionOne("checked");
            setCheckedQuestionTwo("unchecked");
            setStillAct("95");
            onSelectAnswer(e);
          }
          if (e.target.value === "96") {
            setCheckedQuestionOne("unchecked");
            setCheckedQuestionTwo("checked");
            setStillAct("96");
            onSelectAnswer(e);
          }
    }
    return (
        <>
        <div className={`question-content qc-3 mt-0 scale-down-ver-center px-3 py-lg-3 py-md-3 py-sm-2 py-2`}>
            
                      <div className="row">
                        <div className="col-6">
                          <div className=" mb-2"> 
                            <button
                                type="button"
                                className={`"qb6 btn-comm2 py-lg-3 py-md-3 py-sm-2 py-2 w-100 nextBtn" ${checkedQuestionOne}`}
                                onClick={optionSelected}
                                value="95"
                                name="stillactive"
                                id="nextscroll"
                                data-quest-id={26}
                                data-ans-id={95}
                                data-ans-label={null}
                            >Yes
                            </button>
                            </div>
                        </div>
                        <div className="col-6">
                          <div className=" mb-2"> 
                            <button
                                type="button"
                                className={`qb6 btn-comm2 py-lg-3 py-md-3 py-sm-2 py-2 w-100 nextBtn ${checkedQuestionTwo}`}
                                onClick={optionSelected}
                                value="96"
                                name="stillactive"
                                id="nextscroll"
                                data-quest-id={26}
                                data-ans-id={96}
                                data-ans-label={null}
                            >No
                            </button>
                            </div>
                        </div>
                      </div>
                      <p className="text-center bck-marwid">
                    <button
                      name="creditopenyear"
                      className="btn btn-secondary backbtn1a"
                      id=""
                      value=""
                      type="button"
                      onClick={backClick}
              
                    >Go Back</button>
                    </p>
                    </div>
        </>
    )
}
export default StillActive;