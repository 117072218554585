import React, { useState } from "react";
import RadioButtonQuest from "../../../UI/RadioButtonQuest";
const Dependents = ({
  clsName,
  onSelectAnswer,
  validationMsg,
  clearErrors,
  validation,
  trigger,
  getValues,
  backClick,
  splitForm,
  outBalace,
  previous,
  quest77Previous
}) => {
  const [questResult, setQuestResult] = useState(false);
  const [checkedOne, setCheckedOne] = useState("unchecked");
  const [checkedTwo, setCheckedTwo] = useState("unchecked");
  const [checkedThree, setCheckedThree] = useState("unchecked");
  const [checkedFour, setCheckedFour] = useState("unchecked");
  const [checkedFive, setCheckedFive] = useState("unchecked");
  const [checkedSix, setCheckedSix] = useState("unchecked");

  const optionSelected = async (e) => {
    if (e.target.value == "7") {
      setCheckedOne("checked");
    } else {
      setCheckedOne("unchecked");
    }
    if (e.target.value == "8") {
      setCheckedTwo("checked");
    } else {
      setCheckedTwo("unchecked");
    }
    if (e.target.value == "9") {
      setCheckedThree("checked");
    } else {
      setCheckedThree("unchecked");
    }
    if (e.target.value == "279") {
      setCheckedFour("checked");
    } else {
      setCheckedFour("unchecked");
    }
    if (e.target.value == "280") {
      setCheckedFive("checked");
    } else {
      setCheckedFive("unchecked");
    }
    if (e.target.value == "281") {
      setCheckedSix("checked");
    } else {
      setCheckedSix("unchecked");
    }

    setQuestResult(false);

  }

  let toggle;
  if(quest77Previous == "226"){
    toggle = "cardMonthlyIncome";
  }else if(quest77Previous == "227"){
    toggle = "didReceiveIncome"
  }

  const handleClick = async (e) => {
    onSelectAnswer(e);
    // let errorFlag = 0;
    // let optionselect = splitForm.current["dependents"].value;
    // if (optionselect == '') {
    //   errorFlag = 1;
    //   setQuestResult(true);
    //   return false;
    // } else {
    //   onSelectAnswer(e);
    // }
  };
  return (
    <>
      <div className="question-content mt-0 scale-down-ver-center px-3 py-lg-3 py-md-3 py-sm-2 py-2 pb-3 " >
        <div className="row">
          <div className="col-lg-12 mb-2">
            <ul class="p-0 m-0 option-grid">
              <li>
                <div class="optionbtn">
                  <RadioButtonQuest
                    name="dependents"
                    id="dependents0"
                    labelClassName={`${checkedOne}`}
                    value="7"
                    labelName="0"
                    onClick={handleClick}
                    dataQuestId={3}
                    dataAnsId={7}
                  />
                </div>
              </li>
              <li>
                <div class="optionbtn">
                  <RadioButtonQuest
                    name="dependents"
                    id="dependents1"
                    labelClassName={`${checkedTwo}`}
                    value="8"
                    labelName="1"
                    onClick={handleClick}
                    dataQuestId={3}
                    dataAnsId={8}
                  />
                </div>
              </li>
              <li>
                <div class="optionbtn">
                  <RadioButtonQuest
                    name="dependents"
                    id="dependents2"
                    labelClassName={`${checkedThree}`}
                    value="9"
                    labelName="2"
                    onClick={handleClick}
                    dataQuestId={3}
                    dataAnsId={9}
                  />
                </div>
              </li>
              <li>
                <div class="optionbtn">
                  <RadioButtonQuest
                    name="dependents"
                    id="dependents3"
                    labelClassName={`${checkedFour}`}
                    value="279"
                    labelName="3"
                    onClick={handleClick}
                    dataQuestId={3}
                    dataAnsId={279}
                  />
                </div>
              </li>
              <li>
                <div class="optionbtn">
                  <RadioButtonQuest
                    name="dependents"
                    id="dependents4"
                    labelClassName={`${checkedFive}`}
                    value="280"
                    labelName="4"
                    onClick={handleClick}
                    dataQuestId={3}
                    dataAnsId={280}
                  />
                </div>
              </li>
              <li>
                <div class="optionbtn">
                  <RadioButtonQuest
                    name="dependents"
                    id="dependents4plus"
                    labelClassName={`${checkedSix}`}
                    value="281"
                    labelName="4+"
                    onClick={handleClick}
                    dataQuestId={3}
                    dataAnsId={281}
                  />
                </div>
              </li>
            </ul>
            {/* {questResult && (
              <span className="error_msg">Please select an option</span>
            )} */}
          </div>
           {/* <div className="col-lg-12 mb-2 mt-2">

            <button
              type="button"
              className="  qb19 btn-comm2 py-lg-3 py-md-3 py-sm-2 py-2 w-100 nextBtn  "
              id="nextscroll"
              name="dependents"
              onClick={handleClick}
              data-quest-id={3}
            >
             Next &#8594;  */}
             {/* <i className="bi bi-arrow-right"></i> */}
             {/* </button> 
          </div> */}
          <p className="text-center bck-marwid">
          <button
            name={toggle}
            className="btn btn-secondary backbtn1a"
            id=""
            value=""
            type="button"
            onClick={backClick}
          >
            Go Back
          </button>
        </p>
        </div>
      </div>
    </>
  )
}
export default Dependents; 
