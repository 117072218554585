import React from "react";
import "../../../assets/MB_UFGN_4_2/scss/modules/MB_UFGN_4_2.scss";
import AdtopiaLoadLP from "../../../Utility/AdtopiaLoadLP";
import Header from "../../Includes/Layouts/MB_UFGN_4_2/Header";
import FormMB_UFGN_4_2 from "../../Forms/FormMB_UFGN_4_2";
import Section1 from "../../Includes/Layouts/MB_UFGN_4_2/Section1";
import AccordSection from "../../Includes/Layouts/MB_UFGN_4_2/AccordSection";
import Footer from "../../Includes/Layouts/MB_UFGN_4_2/Footer";
import FbViewPixel from "../../../Utility/FbViewPixel";
const MB_UFGN_4_2 = () => {
  return (
    <>
      <AdtopiaLoadLP pageType="LP" splitName="MB_UFGN_4_2" />
      <FbViewPixel />
      <div className="MB_UFGN_4_2">
        <Header />
        <FormMB_UFGN_4_2 />
        <Section1 />
        <AccordSection />
        <Footer />
      </div>
    </>
  );
};
export default MB_UFGN_4_2;