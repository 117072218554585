import React, { useRef, useState, useContext, useReducer, useEffect, } from "react";
import { questinnare2LS, questinnare2, questinnare2Very, questinnare38, questinnare39, employmentVAQ3, answerArrayVery, questionnaire50, questinnaire76Vanquis, questinnaire36Vanquis, wereUalreadyPaying } from "../../Constants/Questions";
import { usePreviewData } from "../../Hooks/usePreviewData";
import '../../assets/Preview_Vaq_V5/scss/PreviewQuestVaqV5.scss';
import {
  VanquisProducts,
  ReliantBorrowing,
  Overindebtness,
  DebtEssentialBills,
  CreditFileInformation,
  ImpactedNegatively,
  HighUtilisation,
  StruggledRepayments,
  ClientFinancialArrears,
  CardNotManaged,
  UnableRepayments,
} from "../../Constants/Constants";
import { useHistory } from "react-router-dom";
import VisitorParamsContext from "../../Contexts/VisitorParams";
import GetVisitorsParams from "../../Utility/GetVisitorsParams";
import { useUpdateMilestone } from "../../Hooks/useUpdateMilestone";
import GtmDataLayer from "../../Utility/GtmDataLayer";
import { useClaimReg } from "../../Hooks/useClaimReg";




const PreviewQuestVaq_V5 = () => {

  const [showNextBtn, SetNextBtn] = useState(true);
  const [showSubmitBtn, SetShowSubmitBtn] = useState(false);
  const [productClaim, setProductClaim] = useState("");
  const [confirmation, setConfirmation] = useState();
  const [productType, setProductType] = useState('');
  const [credcardOpenYr, setCredcardOpenYr] = useState();
  const [financeAgreement, setFinanceAgreement] = useState();
  const [recentCredit, setRecentCredit] = useState();
  const [soldOnDebt, setSoldOnDebt] = useState();
  const [whatYearThisHappen, setWhatYearThisHappen] = useState();
  const [employmentVal, setEmploymentVal] = useState();
  const [repayment, SetRepayment] = useState();
  const [hightBalCred, setHightBalCred] = useState();
  const [accClosed_year, setAccClosedyear] = useState();
  const [creditCardlimitInc, setCreditCardlimitInc] = useState();
  const [exceedCredLimit, setExceedCredLimit] = useState();
  const [minPaymentMonDue, setMinPaymentMonDue] = useState();
  const [outstandingBalance, setOutstandingBalance] = useState();
  const [approximateOutstandingBalance, setApproximateOutstandingBalance] = useState();
  const [didReceiveIncome, setDidReceiveIncome] = useState();
  const [cardMonthlyIncome, setCardMonthlyIncome] = useState();
  const [dependent, SetDependent] = useState();
  const [question79, setQuestion79] = useState([]);
  const [slide80, setSlide80] = useState([]);
  const [userMortages, setUserMortage] = useState('');
  const [userRentss, setUserRent] = useState('');
  const [userTotalUtilityBillss, setUserTotalUtilityBills] = useState('');
  const [userMonthlyLoanRepaymentss, setUserMonthlyLoanRepayments] = useState('');
  const [userCreditCardRepaymentss, setUserCreditCardRepayments] = useState('');
  const [userMonthlyCarFinancess, setUserMonthlyCarFinance] = useState('');
  const [userMonthlyStoreCardPaymentss, setUserMonthlyStoreCardPayments] = useState('');
  const [userOtherMonthlyPaymentss, setUserOtherMonthlyPayments] = useState('');
  const [RegisEmail, SetRegisEmail] = useState();
  const [otherChoose, setOtherChoose] = useState([]);
  const [question76, setQuestion76] = useState([]);
  const [userName, setUserName] = useState();
  const [userSign, setUserSign] = useState();
  const [currentDate, setCurrentDate] = useState();
  const [productClaimTypeId, setProductClaimTypeId] = useState("");
  const [t2aStatus, SetT2AStatus] = useState();
  const { visitorParameters } = useContext(VisitorParamsContext);
  const { updateMilestone } = useUpdateMilestone();
  const { clickPreviewVisit, clickMeritPageVisit } = useClaimReg();
  const history = useHistory();
  const [signReview, setSignReview] = useState(0);


  const divRefQuest = useRef(null);
  let local_flp_source = localStorage.getItem("flp_source");
  let source = local_flp_source ? local_flp_source : "live";
  const query = new URLSearchParams(window.location.search);
  const query_uuid = query.get("uuid");
  const local_uuid = localStorage.getItem("uuid");
  let customUuid = query_uuid ? query_uuid : local_uuid;

  let split_name = localStorage.getItem("split_name") || "";

  const { savePreviewData, getMeritPagePreviewData } = usePreviewData();
  const [btndisabled, setBtndisabled] = useState(false);

  const pushPageChange = async () => {
    setBtndisabled(true);
    try {
      const previewresponse = await clickPreviewVisit(
        query_uuid, split_name, "second_preview_submit", source
      );
      if (previewresponse.data.status == "Success") {
        GtmDataLayer({
          'question': 'update questionnaire'
        });
        updateMilestone(visitorParameters.visitor_parameters.uuid, "preview_submit", source);


        // const allFollowupData = JSON.parse(localStorage.getItem('allFollowupData'));

        // if (allFollowupData) {
        //   window.scrollTo(0, 0);
        //   history.push("/Vanquisthankyou?uuid=" + query_uuid);
        // } else {
        //   if (t2aStatus == '1') {
        //     window.scrollTo(0, 0);
        //     history.push("/Vanquisthankyou?uuid=" + query_uuid);
        //   } else {
        //     window.scrollTo(0, 0);
        //     history.push(`/VAQ_UB_4_ID?uuid=${query_uuid}&split_name=${split_name}`);
        //   }
        // }
        window.scrollTo(0, 0);
        history.push("/VAQ_UB_5_ID?uuid=" + query_uuid);
      }
    } catch (error) {
      console.error("Error:", error);
    }
  };

  const scrollToElement = () => {
    setSignReview(prev => prev + 1);
    SetNextBtn(false);
    SetShowSubmitBtn(true);
    divRefQuest.current.scrollIntoView({ behavior: 'smooth', block: 'start' })

  };

  const MonthlyIncomeList = () => {
    let letter = ["a", "b", "c", "d", "e", "f", "g", "h"];
    if (question79.length > 0) {
      return question79.map((ps, index) => {
        return (
          <p key={index}>
            {letter[index]}) {ps}
          </p>
        );
      });
    }
  };

  const OtherChooseStrCombined = () => {
    let letter = [
      "a", "b", "c", "d", "e", "f", "g", "h", "i", "j", "k", "l", "m", "n", "o", "p", "q", "r",
      "s", "t"];
    // let combinedArray = otherChoose.concat(question76);
    let combinedArray = [...otherChoose, ...question76];


    if (combinedArray.length > 0) {
      return combinedArray.map((ps, index) => {
        let letterIndex = index < letter.length ? index : index % letter.length;
        return (
          <p key={index}>
            {letter[letterIndex]}) {ps}
          </p>
        );
      });
    } else {
      setQuestion76(false);
      setOtherChoose(false);
      return null;
    }
  };

  function Capitalize(string) {
    return string.charAt(0).toUpperCase() + string.slice(1).toLowerCase();
  }

  useEffect(
    () => {
      window.scrollTo(0, 0);
      const ProductId = localStorage.getItem("ProductType");
      if (ProductId == "167") {
        setProductType("loan");
      } else {
        setProductType("card");
      }

      let userOtherChoose = [];
      let userSituation76 = [];
      let quest79List = [];
      const formdata = JSON.parse(localStorage.getItem("formData"));

      if ((formdata === undefined || formdata === null || formdata == "") || (source != 'live')) {
        const getResult = async () => {
          let resp = await savePreviewData(customUuid);
          var result = resp.data.result;
          return result;
        };
        getResult().then((res) => {
          console.log("inside form undefined After Api call");


          const userVehicleReg = res.questionData.vehicleRegistration
            ? res.questionData.vehicleRegistration.toUpperCase()
            : "";
          const userKeeperDate = res.questionData.keeperDate
            ? res.questionData.keeperDate
            : "";
          const userIsVehicle = res.questionData.isVehicle
            ? res.questionData.isVehicle
            : "";

          const userMonthlyIncome = res.questionData.monthlyIncome
            ? res.questionData.monthlyIncome
            : "";
          const userRentExpense = res.questionData.rentExpense
            ? res.questionData.rentExpense
            : "";
          const userCarFinanceExpense = res.questionData.carFinanceExpense
            ? res.questionData.carFinanceExpense
            : "";
          const userOtherMonthlyCredit = res.questionData.otherMonthlyCredit
            ? res.questionData.otherMonthlyCredit
            : "";

          const isSele = res.questionData.otherChoose
            ? res.questionData.otherChoose
            : "";

          const still_active = res.questionData.stillactiveStat
            ? res.questionData.stillactiveStat
            : "";


          let dependent_status = res.questionData.dependent
            ? res.questionData.dependent
            : "";

          const repayment_status = res.questionData.verify
            ? res.questionData.verify
            : "";


          const emp_statu = res.questionData.emp_status
            ? res.questionData.emp_status
            : "";

          const supp_docu = res.questionData.supp_document
            ? res.questionData.supp_document
            : "";

          const regis_email = res.questionData.reg_email
            ? res.questionData.reg_email
            : "";
          const product_claim = res.questionData.product_claimed_vanquis
            ? res.questionData.product_claimed_vanquis
            : "";
          let chooseObj = JSON.parse(isSele);

          chooseObj.forEach((ele) => {
            const found = questinnaire36Vanquis.answers.find(
              (obj) => obj.value == ele.id
            );
            if (found.value != '155') {
              userOtherChoose.push(found.label);
            }
          });

          const isSele76 = res.questionData.otherChoose76
            ? res.questionData.otherChoose76
            : "";

          let chooseObj76 = JSON.parse(isSele76);

          chooseObj76.forEach((ele) => {
            const foundopt = questinnaire76Vanquis.answers.find(
              (obj) => obj.value == ele.id
            );
            if (foundopt.value != '225') {
              userSituation76.push(foundopt.label);
            }
          });

          const hightestCredBal = res.questionData.hightBalCred
            ? res.questionData.hightBalCred
            : "";

          const accClosedyear = res.questionData.accCloseYear
            ? res.questionData.accCloseYear
            : "";

          const creditCardlimitInc = res.questionData.creditCardlimitInc
            ? res.questionData.creditCardlimitInc
            : "";

          const exceedCredLimit = res.questionData.exceedCredLimit
            ? res.questionData.exceedCredLimit
            : "";

          const minPaymentMonDue = res.questionData.minPaymentMonDue
            ? res.questionData.minPaymentMonDue
            : "";



          let what_year_happen = res.questionData.what_year_happen
            ? res.questionData.what_year_happen
            : "";



          let sold_debt_opt = res.questionData.sold_on_debt
            ? res.questionData.sold_on_debt
            : "";
          let sold_debt_on;

          if (sold_debt_opt === "YES") {
            sold_debt_on = 206;
          } else {
            sold_debt_on = 207;
          }

          const creditlimit_inc_year = res.questionData.creditlimit_inc_year
            ? res.questionData.creditlimit_inc_year
            : "";


          const isSele79 = res.questionData.quest_79
            ? res.questionData.quest_79
            : "";

          if (isSele79) {
            let chooseObj79 = JSON.parse(isSele79);

            if (chooseObj79?.length > 0) {
              chooseObj79.forEach((ele) => {
                const foundopts = wereUalreadyPaying?.answers.find(
                  (obj) => obj.value == ele.id
                );
                if (foundopts.value) {
                  quest79List.push(foundopts?.label);
                }
              });
            }
          }

          let mnthlyIncomeval = res.questionData.mnthlyIncome
            ? res.questionData.mnthlyIncome
            : "";

          let incomeYorN = res.questionData.incYesorNo
            ? res.questionData.incYesorNo
            : "";

          if (incomeYorN === 226) {
            incomeYorN = "YES";
          }
          else {
            incomeYorN = "NO";
          }

          let OutStandBlncVal = res.questionData.OutStandBlnc
            ? res.questionData.OutStandBlnc
            : "";

          if (OutStandBlncVal == 245) {
            OutStandBlncVal = "YES";
          } else if (OutStandBlncVal == 246){
            OutStandBlncVal = "NO";
          } else {
            OutStandBlncVal = "";
          }

          let ApproxOutStandBlncVal = res.questionData.ApproxOutStandBlnc
            ? res.questionData.ApproxOutStandBlnc
            : "";
            
            const product_Vaqid = res.questionData.product_claimed_vanquis_questid
            ? res.questionData.product_claimed_vanquis_questid
            : "";
            setProductClaimTypeId(product_Vaqid);



          let slide80find = res.questionData ? res.questionData.newiande : "";


          let userMortage = '';
          let userRent = '';
          let userTotalUtilityBills = '';
          let userCreditCardRepayments = '';
          let userMonthlyLoanRepayments = '';
          let userMonthlyCarFinance = '';
          let userMonthlyStoreCardPayments = '';
          let userOtherMonthlyPayments = '';

          if (slide80find) {

            slide80find = JSON.parse(slide80find);

            let userMortage_find = slide80find.find((arr) => arr[0] === '228');
            if (userMortage_find) {
              userMortage = userMortage_find[1];
            }

            let userRent_find = slide80find.find((arr) => arr[0] === '229');
            if (userRent_find) {
              userRent = userRent_find[1];
            }

            let userTotalUtilityBills_find = slide80find.find((arr) => arr[0] === '230');
            if (userTotalUtilityBills_find) {
              userTotalUtilityBills = userTotalUtilityBills_find[1];
            }

            let userMonthlyLoanRepayments_find = slide80find.find((arr) => arr[0] === '231');
            if (userMonthlyLoanRepayments_find) {
              userMonthlyLoanRepayments = userMonthlyLoanRepayments_find[1];
            }

            let userCreditCardRepayments_find = slide80find.find((arr) => arr[0] === '232');
            if (userCreditCardRepayments_find) {
              userCreditCardRepayments = userCreditCardRepayments_find[1];
            }

            let userMonthlyCarFinance_find = slide80find.find((arr) => arr[0] === '233');
            if (userMonthlyCarFinance_find) {
              userMonthlyCarFinance = userMonthlyCarFinance_find[1];
            }

            let userMonthlyStoreCardPayments_find = slide80find.find((arr) => arr[0] === '234');
            if (userMonthlyStoreCardPayments_find) {
              userMonthlyStoreCardPayments = userMonthlyStoreCardPayments_find[1];
            }

            let userOtherMonthlyPayments_find = slide80find.find((arr) => arr[0] === '235');
            if (userOtherMonthlyPayments_find) {
              userOtherMonthlyPayments = userOtherMonthlyPayments_find[1];
            }
          }


          const creditCardOpenYr = res.questionData.creditCardOpenYr
            ? res.questionData.creditCardOpenYr
            : "";

          setOutstandingBalance(OutStandBlncVal);
          setApproximateOutstandingBalance(ApproxOutStandBlncVal);
          setCredcardOpenYr(creditCardOpenYr);
          setRecentCredit(creditlimit_inc_year);
          setWhatYearThisHappen(what_year_happen);
          setSoldOnDebt(sold_debt_on);
          setQuestion76(userSituation76);
          setMinPaymentMonDue(minPaymentMonDue);
          setExceedCredLimit(exceedCredLimit);
          setCreditCardlimitInc(creditCardlimitInc);
          setHightBalCred(hightestCredBal);
          setAccClosedyear(accClosedyear);
          setProductClaim(product_claim);
          setEmploymentVal(emp_statu);
          setUserName(res.client_name);
          SetT2AStatus(res.t2a_status);
          setUserSign(res.signature);
          let timeNow = new Date().toLocaleString();
          timeNow = timeNow.split(" ")[0].replace(/,/g, "");
          setCurrentDate(timeNow);
          setOtherChoose(userOtherChoose);
          setFinanceAgreement(still_active);
          SetDependent(dependent_status);
          SetRepayment(repayment_status);
          setCardMonthlyIncome(mnthlyIncomeval);
          setQuestion79(quest79List);
          setDidReceiveIncome(incomeYorN);
          setUserMortage(userMortage);
          setUserRent(userRent);
          setUserTotalUtilityBills(userTotalUtilityBills);
          setUserCreditCardRepayments(userCreditCardRepayments);
          setUserMonthlyLoanRepayments(userMonthlyLoanRepayments);
          setUserMonthlyCarFinance(userMonthlyCarFinance);
          setUserMonthlyStoreCardPayments(userMonthlyStoreCardPayments);
          setUserOtherMonthlyPayments(userOtherMonthlyPayments);
          setSlide80(slide80find);
          const email_provided = res.questionData.email_provided
            ? res.questionData.email_provided
            : "";
          if (email_provided && email_provided == '162') {
            SetRegisEmail(res.email);
          } else if (email_provided && email_provided == '163') {
            SetRegisEmail(regis_email);
          }

        });
      }
      else {
        let product_claim_id = formdata.productclaim;

        setProductClaimTypeId(product_claim_id);

        const product_claim_n = product_claim_id ? VanquisProducts[product_claim_id] : '';

        const userName = formdata
          ? Capitalize(formdata.txtFName) + " " + Capitalize(formdata.txtLName)
          : "";
        // const joineeName = (formdata) ? Capitalize(formdata.jointFName) + ' ' + Capitalize(formdata.jointLName) : '';
        const userdob = formdata
          ? formdata.DobDay + "/" + formdata.DobMonth + "/" + formdata.DobYear
          : "";
        const joineedob = formdata.JointDobDay
          ? formdata.JointDobDay +
          "/" +
          formdata.JointDobMonth +
          "/" +
          formdata.JointDobYear
          : "";
        const userSign = formdata ? formdata.user_sign : "";
        const ph = formdata ? formdata.txtPhone : "";
        const post = formdata ? formdata.txtPostCode.toUpperCase() : "";
        const mail = formdata ? formdata.txtEmail : "";


        let today = new Date(userdob);
        let dob_date = String(today.getDate()).padStart(2, "0");
        let dob_month = String(today.getMonth() + 1).padStart(2, "0");
        let dob_year = String(today.getFullYear()).padStart(4, "0");


        if (formdata) {

          if (formdata.previous_postcode != undefined) {
            let preAddress = "";
            if (
              formdata.previous_address_city !== "" ||
              formdata.previous_address_city !== null
            ) {
              if (formdata.previous_address_line1 !== null) {
                preAddress += formdata.previous_address_line1;
              }
              if (formdata.previous_address_line2 !== "") {
                preAddress += ", " + formdata.previous_address_line2;
              }
              if (formdata.previous_address_town !== "") {
                preAddress += ", " + formdata.previous_address_town;
              }
              if (formdata.previous_address_county !== "") {
                preAddress += ", " + formdata.previous_address_county;
              }
              if (formdata.previous_address_country !== "") {
                preAddress += ", " + formdata.previous_address_country;
              }
              if (formdata.previous_postcode !== "") {
                preAddress += ", " + formdata.previous_postcode.toUpperCase();
              }
            }
          }
        }



        const questionData = JSON.parse(localStorage.getItem("questionData"));

        const t2a_Validation = localStorage.getItem("t2aValidation");

        SetT2AStatus(t2a_Validation);

        if (formdata) {

          let question_49 = null;
          if (formdata.question_49 == 131) {
            question_49 = 'AGREE';
          } else if (formdata.question_49 == 132) {
            question_49 = 'DISAGREE';
          }
          setConfirmation(question_49);


        }
        if (questionData) {

          let is_stillactive = questionData ? questionData.find((obj) => obj.question_id == '26') : "";

          let financestatus = questionData ? is_stillactive?.option_id : "";

          let repayment_status;

          let repayment_opt = questionData ? questionData.find((obj) => obj.question_id == '30') : "";
          let repayment_opt_value = questionData ? repayment_opt?.option_id : "";


          if (repayment_opt_value === 101) {
            repayment_status = "YES";
          } else if (repayment_opt_value === 102) {
            repayment_status = "No";
          } else {
            repayment_status = "";
          }


          let question_02 = questionData ? questionData.find((obj) => obj.question_id == '2') : "";
          let employee_status = questionData ? question_02?.option_id : "";

          let answer_02 = employmentVAQ3?.answers.find(str => str.value == employee_status);
          setEmploymentVal(answer_02?.label);

          let accCloseyear = questionData ? questionData.find((obj) => obj.question_id == '52') : "";
          const accClosedyear = questionData ? accCloseyear?.input_answer : "";

          let highbalance = questionData ? questionData.find((obj) => obj.question_id == '53') : "";
          const hightestCredBal = questionData ? highbalance?.input_answer : "";

          let creditCardLimit = questionData ? questionData.find((obj) => obj.question_id == '54') : "";

          let didReceiveIncome_opt = questionData ? questionData.find((obj) => obj.question_id == '77') : "";
          let didReceiveIncome_value = questionData ? didReceiveIncome_opt?.option_id : "";

          let Card_Limit_opt = questionData ? creditCardLimit?.option_id : "";
          let creditCardLimitC;

          if (Card_Limit_opt == 137) {
            creditCardLimitC = 1;
          } else if (Card_Limit_opt == 138) {
            creditCardLimitC = "2";
          } else if (Card_Limit_opt == 139) {
            creditCardLimitC = 3;
          } else if (Card_Limit_opt == 140) {
            creditCardLimitC = "4 or more";
          } else {
            creditCardLimitC = "";
          }

          if (didReceiveIncome_value === 226) {
            didReceiveIncome_value = "YES";
          }
          else {
            didReceiveIncome_value = "NO";
          }


          let exclimit = questionData ? questionData.find((obj) => obj.question_id == '55') : "";

          let exced_Limit_opt = questionData ? exclimit?.option_id : "";

          let exccredlimit;

          if (exced_Limit_opt == 141) {
            exccredlimit = "Always";
          } else if (exced_Limit_opt == 142) {
            exccredlimit = "Sometimes";
          } else if (exced_Limit_opt == 143) {
            exccredlimit = "Twice";
          } else if (exced_Limit_opt == 144) {
            exccredlimit = "Once";
          } else if (exced_Limit_opt == 145) {
            exccredlimit = "Never";
          } else {
            exccredlimit = "";
          }

          let monthlyacstmt = questionData ? questionData.find((obj) => obj.question_id == '56') : "";

          let Monthly_statemetnt_opt = questionData ? monthlyacstmt?.option_id : "";

          let montlyaccst;

          if (Monthly_statemetnt_opt == 146) {
            montlyaccst = "Always";
          } else if (Monthly_statemetnt_opt == 147) {
            montlyaccst = "Sometimes";
          } else if (Monthly_statemetnt_opt == 148) {
            montlyaccst = "Twice";
          } else if (Monthly_statemetnt_opt == 149) {
            montlyaccst = "Once";
          } else if (Monthly_statemetnt_opt == 150) {
            montlyaccst = "Never";
          } else {
            montlyaccst = "";
          }

          let slide4find = questionData ? questionData.find((obj) => obj.slide_4) : [];
          const userMonthlyIncome = slide4find ? slide4find.slide_4[0][1] : "";
          const userRentExpense = slide4find ? slide4find.slide_4[1][1] : "";
          const userCarFinanceExpense = slide4find ? slide4find.slide_4[2][1] : "";

          let userOtherMonthlyCredit = '';
          let userOtherMonthlyExpense = '';
          let userTotalLoanCreditExp = '';
          let userTotalMonthlyExp = '';

          let montly_loan_credit = slide4find?.slide_4 ? slide4find.slide_4.find((obj) => obj[0] == '57') : "";

          if (typeof montly_loan_credit !== 'undefined') {
            userOtherMonthlyCredit = montly_loan_credit[1] == '151' ? 'Yes' : 'No';
          }

          let other_monthly_expense = slide4find?.slide_4 ? slide4find.slide_4.find((obj) => obj[0] == '58') : "";
          if (typeof other_monthly_expense !== 'undefined') {
            userOtherMonthlyExpense = other_monthly_expense[1] == '153' ? 'Yes' : 'No';
          }

          let total_loan_credit_exp = slide4find?.slide_4 ? slide4find.slide_4.find((obj) => obj[0] == '59') : "";
          if (typeof total_loan_credit_exp !== 'undefined') {
            userTotalLoanCreditExp = total_loan_credit_exp[1] ? total_loan_credit_exp[1] : '';
          }


          let total_monthly_exp = slide4find?.slide_4 ? slide4find.slide_4.find((obj) => obj[0] == '60') : "";
          if (typeof total_monthly_exp !== 'undefined') {
            userTotalMonthlyExp = total_monthly_exp[1] ? total_monthly_exp[1] : '';
          }

          let slide80find = questionData ? questionData.find((obj) => obj.slide_80) : null;


          let userMortage = '';
          let userRent = '';
          let userTotalUtilityBills = '';
          let userCreditCardRepayments = '';
          let userMonthlyLoanRepayments = '';
          let userMonthlyCarFinance = '';
          let userMonthlyStoreCardPayments = '';
          let userOtherMonthlyPayments = '';
          let parsedSlideQuest80 = '';

          if (slide80find && Array.isArray(slide80find.slide_80)) {

            let parsedSlide80 = JSON.parse(slide80find.slide_80[1]);
            let parsedSlideQuest80_find = JSON.parse(slide80find.slide_80[0]);

            if (parsedSlideQuest80_find) {
              parsedSlideQuest80 = parsedSlideQuest80_find || '';
            }

            let userMortage_find = parsedSlide80.find((arr) => arr[0] === '228');
            if (userMortage_find) {
              userMortage = userMortage_find[1];
            }

            let userRent_find = parsedSlide80.find((arr) => arr[0] === '229');
            if (userRent_find) {
              userRent = userRent_find[1];
            }

            let userTotalUtilityBills_find = parsedSlide80.find((arr) => arr[0] === '230');
            if (userTotalUtilityBills_find) {
              userTotalUtilityBills = userTotalUtilityBills_find[1];
            }

            let userMonthlyLoanRepayments_find = parsedSlide80.find((arr) => arr[0] === '231');
            if (userMonthlyLoanRepayments_find) {
              userMonthlyLoanRepayments = userMonthlyLoanRepayments_find[1];
            }

            let userCreditCardRepayments_find = parsedSlide80.find((arr) => arr[0] === '232');
            if (userCreditCardRepayments_find) {
              userCreditCardRepayments = userCreditCardRepayments_find[1];
            }

            let userMonthlyCarFinance_find = parsedSlide80.find((arr) => arr[0] === '233');
            if (userMonthlyCarFinance_find) {
              userMonthlyCarFinance = userMonthlyCarFinance_find[1];
            }

            let userMonthlyStoreCardPayments_find = parsedSlide80.find((arr) => arr[0] === '234');
            if (userMonthlyStoreCardPayments_find) {
              userMonthlyStoreCardPayments = userMonthlyStoreCardPayments_find[1];
            }

            let userOtherMonthlyPayments_find = parsedSlide80.find((arr) => arr[0] === '235');
            if (userOtherMonthlyPayments_find) {
              userOtherMonthlyPayments = userOtherMonthlyPayments_find[1];
            }
          }


          let appliedListFind = questionData ? questionData.find((obj) => obj.question_id == '36') : "";

          const appliedLists = appliedListFind ? appliedListFind?.input_answer : [];

          if (appliedLists?.length > 0) {
            appliedLists.forEach((ele) => {
              const found = questinnaire36Vanquis?.answers.find(
                (obj) => obj.value == ele.id
              );
              if (found.value != '155') {
                userOtherChoose.push(found?.label);
              }
            });
          }

          let userSituation_find = questionData ? questionData.find((obj) => obj.question_id == '76') : "";

          const ques76list = userSituation_find ? userSituation_find?.input_answer : [];

          if (ques76list?.length > 0) {
            ques76list.forEach((ele) => {
              const foundopt = questinnaire76Vanquis?.answers.find(
                (obj) => obj.value == ele.id
              );
              if (foundopt.value != '225') {
                userSituation76.push(foundopt?.label);
              }
            });
          }

          let monthlyIncome_find = questionData ? questionData.find((obj) => obj.question_id == '79') : "";

          const ques79list = monthlyIncome_find ? monthlyIncome_find?.input_answer : [];

          if (ques79list?.length > 0) {
            ques79list.forEach((ele) => {
              const foundopts = wereUalreadyPaying?.answers.find(
                (obj) => obj.value == ele.id
              );
              if (foundopts.value) {
                quest79List.push(foundopts?.label);
              }
            });
          }


          let reg_email_f = questionData ? questionData.find((obj) => obj.question_id == '48') : "";

          let providedEmil = questionData ? questionData.find((obj) => obj.question_id == '61') : "";
          let providedEmilOption = questionData ? providedEmil?.option_id : "";
          let reg_email = '';
          if (providedEmilOption && providedEmilOption == '162') {
            reg_email = mail;
          } else if (providedEmilOption && providedEmilOption == '163') {
            reg_email = questionData ? reg_email_f?.input_answer : "";
          }

          let credit_limit_year = questionData ? questionData.find((obj) => obj.question_id == '70') : "";
          let recent_credit_increase = questionData ? credit_limit_year?.input_answer : "";

          let dependent_status_opt = questionData ? questionData.find((obj) => obj.question_id == '3') : "";
          let dependent_status = questionData ? dependent_status_opt?.input_answer : "";

          let dependent_status_Options = questionData ? questionData.find((obj) => obj.question_id == '3') : "";
          let dependent_status_Option_result = questionData ? dependent_status_Options?.option_id : "";

          let dependentsOptionValue;

          if (dependent_status_Option_result == 7) {
            dependentsOptionValue = "0";
          } else if (dependent_status_Option_result == 8) {
            dependentsOptionValue = "1";
          } else if (dependent_status_Option_result == 9) {
            dependentsOptionValue = "2";
          } else if (dependent_status_Option_result == 279) {
            dependentsOptionValue = "3";
          } else if (dependent_status_Option_result == 280) {
            dependentsOptionValue = "4";
          } else if (dependent_status_Option_result == 281) {
            dependentsOptionValue = "4+";
          } else {
            dependentsOptionValue = "";
          }

          let card_monthly_income_opt = questionData ? questionData.find((obj) => obj.question_id == '78') : "";
          let card_monthly_income = questionData ? card_monthly_income_opt?.input_answer : "";

          let sold_on_opt = questionData ? questionData.find((obj) => obj.question_id == '71') : "";
          let VaqSoldOnDebt = questionData ? sold_on_opt?.option_id : "";

          let whichyrHappen = questionData ? questionData.find((obj) => obj.question_id == '72') : "";

          let WhatYrThisHappen = questionData ? whichyrHappen?.input_answer : "";

          let question_75_opt = questionData ? questionData.find((obj) => obj.question_id == '75') : "";
          let ques_75 = questionData ? question_75_opt?.option_id : "";

          const outStandBalance = questionData ? questionData.find((obj) => obj.question_id == '89') : "";
          let outStandBalanceResult = questionData ? outStandBalance?.option_id : "";

          const ApproximatelyOutStandBalance = questionData ? questionData.find((obj) => obj.question_id == '90') : "";
          let ApproximateOutStandBalanceResult = questionData ? ApproximatelyOutStandBalance?.input_answer : "";

          if (outStandBalanceResult == 245) {
            outStandBalanceResult = "YES";
          } else if (outStandBalanceResult == 246) {
            outStandBalanceResult = "NO";
          } else {
            outStandBalanceResult = "";

          }

          const lender_option = questionData ? questionData.find((obj) => obj.question_id == '64') : "";
          let selectLender = questionData ? lender_option?.input_answer : "";

          const oldcardval = questionData ? questionData.find((obj) => obj.question_id == '81') : "";
          let oldcardvalue = questionData ? oldcardval?.option_id : "";

          if (oldcardvalue == 236) {
            oldcardvalue = "YES";
          } else {
            oldcardvalue = "NO";
          }

          const latepayval = questionData ? questionData.find((obj) => obj.question_id == '82') : "";
          let latepayment = questionData ? latepayval?.option_id : "";

          if (latepayment == 238) {
            latepayment = "YES";
          } else {
            latepayment = "NO";
          }
          const problemval = questionData ? questionData.find((obj) => obj.question_id == '84') : "";
          let problem = questionData ? problemval?.input_answer : "";

          const awareVal = questionData ? questionData.find((obj) => obj.question_id == '85') : "";
          let awareValue = questionData ? awareVal?.option_id : "";
          let awareInpValue = questionData ? awareVal?.input_answer : "";
          if (awareValue == "242") {
            awareValue = "When TMS Legal contacted me";
          } else if (awareValue == "243") {
            awareValue = "I saw their ad online";
          } else {
            awareValue = awareInpValue;
          }
          const sortcode = questionData ? questionData.find((obj) => obj.question_id == '86') : "";
          let sortcodevalue = questionData ? sortcode?.input_answer : "";
          const accnum = questionData ? questionData.find((obj) => obj.question_id == '87') : "";
          let accnumvalue = questionData ? accnum?.input_answer : "";

          let credCardOpenyear = questionData ? questionData.find((obj) => obj.question_id == '88') : "";
          const creditOpenYr = questionData ? credCardOpenyear?.input_answer : "";

          let further36 = questionData ? questionData.find((obj) => obj.question_id == '36') : "";
          const further36Lists = further36 ? further36?.input_answer : [];

          let further76 = questionData ? questionData.find((obj) => obj.question_id == '76') : "";
          const further76Lists = further76 ? further76?.input_answer : [];

          const quest82 = questionData ? questionData.find((obj) => obj.question_id == '82') : "";
          let quest82value = questionData ? quest82?.option_id : "";

          const quest29 = questionData ? questionData.find((obj) => obj.question_id == '29') : "";
          let quest29value = questionData ? quest29?.option_id : "";

          const quest9 = questionData ? questionData.find((obj) => obj.question_id == '9') : "";
          let quest9value = questionData ? quest9?.option_id : "";

          const quest71 = questionData ? questionData.find((obj) => obj.question_id == '71') : "";
          let quest71value = questionData ? quest71?.option_id : "";

          const quest55 = questionData ? questionData.find((obj) => obj.question_id == '55') : "";
          let quest55value = questionData ? Number(quest55?.option_id) : "";

          const combinedMeritList = [
            quest29value,
            quest9value,
            ...further36Lists.map(item => item.id),
            ...further76Lists.map(item => item.id),
            quest82value,
            quest71value,
            quest55value
          ].filter(item => item !== null && item !== undefined);

          // setCombinedMeritPageData(combinedMeritList);

          // const finalMeritPageList = combinedMeritList.forEach(id => {
          //   if (ReliantBorrowing[id.toString()]) ConstantMeritList.ReliantBorrowing = true;
          //   if (Overindebtness[id.toString()]) ConstantMeritList.Overindebtness = true;
          //   if (DebtEssentialBills[id.toString()]) ConstantMeritList.DebtEssentialBills = true;
          //   if (CreditFileInformation[id.toString()]) ConstantMeritList.CreditFileInformation = true;
          //   if (ImpactedNegatively[id.toString()]) ConstantMeritList.ImpactedNegatively = true;
          //   if (HighUtilisation[id.toString()]) ConstantMeritList.HighUtilisation = true;
          //   if (StruggledRepayments[id.toString()]) ConstantMeritList.StruggledRepayments = true;
          //   if (ClientFinancialArrears[id.toString()]) ConstantMeritList.ClientFinancialArrears = true;
          //   if (CardNotManaged[id.toString()]) ConstantMeritList.CardNotManaged = true;
          //   if (UnableRepayments[id.toString()]) ConstantMeritList.UnableRepayments = true;
          // });


          setCreditCardlimitInc(creditCardLimitC);
          setHightBalCred(hightestCredBal);
          setExceedCredLimit(exccredlimit);
          setMinPaymentMonDue(montlyaccst);
          setDidReceiveIncome(didReceiveIncome_value);
          setCardMonthlyIncome(card_monthly_income);
          setAccClosedyear(accClosedyear);

          setUserMortage(userMortage);
          setUserRent(userRent);
          setUserTotalUtilityBills(userTotalUtilityBills);
          setUserCreditCardRepayments(userCreditCardRepayments);
          setUserMonthlyLoanRepayments(userMonthlyLoanRepayments);
          setUserMonthlyCarFinance(userMonthlyCarFinance);
          setUserMonthlyStoreCardPayments(userMonthlyStoreCardPayments);
          setUserOtherMonthlyPayments(userOtherMonthlyPayments);
          setSlide80(parsedSlideQuest80);

          setOtherChoose(userOtherChoose);
          SetRegisEmail(reg_email);
          setFinanceAgreement(financestatus);
          SetRepayment(repayment_status);
          setRecentCredit(recent_credit_increase);
          SetDependent(dependent_status || dependentsOptionValue);
          setSoldOnDebt(VaqSoldOnDebt);
          setWhatYearThisHappen(WhatYrThisHappen);
          setQuestion76(userSituation76);
          setQuestion79(quest79List);
          setCredcardOpenYr(creditOpenYr);
          setOutstandingBalance(outStandBalanceResult);
          setApproximateOutstandingBalance(ApproximateOutStandBalanceResult);
        }

        const partnerSign = formdata ? formdata.partner_sign : "";


        setProductClaim(product_claim_n);
        setUserName(userName);

        setUserSign(userSign);
        let timeNow = new Date().toLocaleString();
        timeNow = timeNow.split(" ")[0].replace(/,/g, "");

        setCurrentDate(timeNow);

      }
    },
    []
  );
  return (

    <>
      <GetVisitorsParams />
      <div className="PreviewQuestVaqV5" >
        <div id="root">
          <div className="PreviewPageV2">
            <div className="container-top textwrap mb-5">
              <h2 className="preview_headline">
                Here is the UPDATED preview of the Questionnaire document with your
                answers.
              </h2>
              <p className="green_subtext">
                Please Click the "NEXT" button to navigate, and finally click "SUBMIT"
                when done.
              </p>
            </div>

            {/* <section className="container-1 my-5 declare-sec">
        <table>
          <tbody>
            <tr>
              <td>
                <table className="logo-tab">
                  <tbody>
                    <tr>
                      <td style={{ textAlign: "center" }}>
                        <img
                          src="dist/img/logo.png"
                          style={{ width: 125, opacity: "0.8" }}
                        />
                      </td>
                    </tr>
                  </tbody>
                </table>
                <div style={{ clear: "both" }} />
                <h4
                  style={{
                    fontSize: 24,
                    margin: "10px 0px",
                    textAlign: "center",
                    color: "rgb(38, 128, 213)",
                    fontWeight: 400
                  }}
                >
                  Vanquis Questionnaire
                </h4>
                <p>
                  <b>
                    Which product would you like to register a claim against?
                  </b>
                </p>
                <p>Vanquis Classic Card</p>
                <br />
                <p>
                  <b>What year did you open your Vanquis credit card?</b>
                </p>
                <p>2011</p>
                <br />
                <p>
                  <b>Is your account with Vanquis still active?</b>
                </p>
                <p>YES</p>
                <br />
                <p>
                  <b>
                    What year was the last credit limit increase on your
                    account, if ever?
                  </b>
                </p>
                <p>2009</p>
                <br />
                <p>
                  <b>
                    Has Vanquis sold on your debt, if any, to a company such as
                    Lowell Portfolio / Arrow Global?
                  </b>
                </p>
                <p>YES</p>
                <br />
                <p>
                  <b>What year did this happen?</b>
                </p>
                <p>2011</p>
                <br />
                <p>
                  <b>
                    At the time you took out the account with Vanquis what was
                    your employment status?
                  </b>
                </p>
                <p>Temporary worker</p>
                <br />
                <p>
                  <b>
                    Have you experienced any hardships or struggle in making the
                    repayments to Vanquis on time while still meeting all your
                    other commitments?
                  </b>
                </p>
                <p>YES</p>
                <br />
                <p>
                  <b>
                    Have you, (or a company on your behalf), ever filed a claim
                    with Vanquis?
                  </b>
                </p>
                <p>NO</p>
                <br />
                <p>
                  <b>
                    Approximately what was the highest limit you ever had with
                    Vanquis credit account?
                  </b>
                </p>
                <p>£8000</p>
                <br />
                <p>
                  <b>
                    Approximately how many times was your credit limit increased
                    on this account?
                  </b>
                </p>
                <p>3</p>
                <br />
                <p>
                  <b>
                    How often would you have a balance which was close to, or
                    exceeding your credit limit?
                  </b>
                </p>
                <p>Twice</p>
                <br />
                <p>
                  <b>
                    How often did you only make the minimum payment due on the
                    monthly account statement (rather than paying off a larger
                    amount)?
                  </b>
                </p>
                <p>Once</p>
                <br />
                <p>
                  <b>Do you have any debt/outstanding balance with Vanquis?</b>
                </p>
                <p>YES</p>
                <br />
                <p>
                  <b>
                    Please select from the list below approximately how much is
                    your outstanding balance with Vanquis.
                  </b>
                </p>
                <p>£9000</p>
                <br />
                <p>
                  <b>
                    In the year when you took the card – did you receive any
                    income?
                  </b>
                </p>
                <p>YES</p>
                <br />
                <p>
                  <b>
                    When you took out your card what was your monthly income?
                  </b>
                </p>
                <p>£500 - £1000</p>
                <br />
                <p>
                  <b>How many dependents did you have at that time?</b>
                </p>
                <p>0</p>
                <br />
                <p>
                  <b>
                    When you took out the card,{" "}
                    <b>were you already paying monthly</b> for one or more of
                    the following:
                  </b>
                </p>
                <p>a) Utility Bills</p>
                <p>b) Loans</p>
                <br />
                <p>
                  <b>
                    {" "}
                    Please confirm the following MONTHLY / ANNUAL income and
                    expenditure details
                  </b>
                </p>
                <p> My monthly TOTAL utility bills were: £67</p>
                <p> My monthly loan repayments were:£1,888</p>
                <br />
                <p>
                  <b>
                    Please provide the e-mail address you registered with
                    Vanquis
                  </b>
                </p>
                <p>james8hdgdsg@922.com</p>
                <br />
                <div>
                  <p>
                    <b>Further details to be considered:</b>
                  </p>
                  <p>
                    a) My income changed between taking out and repaying the
                    credit but the lender didn’t check
                  </p>
                  <p>
                    b) I was in arrears with my household bills when I took the
                    credit
                  </p>
                  <p>
                    c) My income while holding the credit was not always
                    reliable.
                  </p>
                </div>
                <br />
                <br />
              </td>
            </tr>
          </tbody>
        </table>
      </section>
      <section className="container-1 my-5 table-sec">
        <table style={{ width: "100%" }}>
          <tbody>
            <tr>
              <td>
                <div className="d-flex flex-column flex-lg-row justify-content-evenly">
                  <div
                    style={{
                      borderRadius: 35,
                      border: "1px solid rgb(6, 81, 151)",
                      padding: "18px 15px 2px",
                      width: 312,
                      marginBottom: 12
                    }}
                  >
                    <h3
                      style={{
                        textAlign: "center",
                        fontSize: 18,
                        fontWeight: 900
                      }}
                    >
                      Customer 1
                    </h3>
                    <p style={{ fontSize: 13, paddingTop: 3 }}>
                      Full Name : James Test
                    </p>
                    <div
                      className="anim_ylw_preview"
                      style={{
                        border: "1px solid black",
                        padding: "15px 5px 10px"
                      }}
                    >
                      <table style={{ fontSize: 14, height: 50 }}>
                        <tbody>
                          <tr>
                            <td>Signature:</td>
                            <td>
                              <img alt="" src="" style={{ width: "85%" }} />
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                    <p style={{ fontSize: 12 }}>Dated: 8/21/2024</p>
                  </div>
                  <div
                    style={{
                      borderRadius: 35,
                      border: "1px solid rgb(6, 81, 151)",
                      padding: "18px 15px 2px",
                      width: 312,
                      marginBottom: 12
                    }}
                  >
                    <h3
                      style={{
                        textAlign: "center",
                        fontSize: 18,
                        fontWeight: 900
                      }}
                    >
                      Customer 2
                    </h3>
                    <p style={{ fontSize: 13, paddingTop: 3 }}>
                      Full Name: ____________________
                    </p>
                    <div
                      style={{
                        border: "1px solid black",
                        padding: "15px 5px 10px"
                      }}
                    >
                      <table style={{ fontSize: 14, height: 50 }}>
                        <tbody>
                          <tr>
                            <td>Signature:</td>
                            <td />
                          </tr>
                        </tbody>
                      </table>
                    </div>
                    <p style={{ fontSize: 12 }}>Dated:</p>
                  </div>
                </div>
              </td>
            </tr>
          </tbody>
        </table>
      </section> */}

            {/* page-5 */}
            <section className="container-1 mb-5 mt-1 declare-sec">
              <table>
                <tbody><tr>
                  <td>
                    <table className="logo-tab">
                      <tbody>
                        <tr>
                          <td style={{ textAlign: 'center' }}>
                            <img
                              src="assets/VAQ_UB_5/prviewPage/img/logo.png"
                              style={{ width: 125, opacity: "0.8" }}
                            />
                          </td>
                        </tr>
                      </tbody>
                    </table>
                    {/* Header */}
                    <div style={{ clear: 'both' }} />
                    <h4 style={{ fontSize: 24, margin: '10px 0', textAlign: 'center', color: '#2680d5', fontWeight: 400 }}>Vanquis Questionnaire</h4>
                    {/* <p>
                      <b>
                        Which product would you like to register a claim
                        against?
                      </b>
                    </p> */}
                    {/* {productClaimTypeId && productClaimTypeId == "168" ? (
                      <p>Vanquis</p>
                    ) : ( */}
                      {/* <p>{productClaim}</p> */}
                    {/* )} */}
                    {/* <br /> */}

                    {productClaimTypeId != 168 ? (                      
                      <>
                          <p>
                          <b>Which product would you like to register a claim against?</b>
                          </p>
                          <p>{productClaim}</p>
                          <br />
                      </>
                    ) : null}

                    {/* <p>
                      <b>What year did you take out the product with Vanquis?</b>
                    </p>
                    <p>{financeYear}</p>
                    <br /> */}

                    {confirmation != null && (
                      <>
                        <p>
                          <b>
                            I only recently understood I had cause to complain
                            when TMS Legal represented my interests regarding
                            irresponsible lending. I considered my financial
                            issues to be my problem and didn't link it to any
                            irresponsible lending by the lender.
                          </b>
                        </p>
                        <p>{confirmation}</p>
                        <br />
                      </>
                    )}

                    <p>
                      <b>{productType == "loan" ? 'What year did you open your Vanquis loan?' : 'What year did you open your Vanquis credit card?'}</b>
                    </p>
                    <p>{credcardOpenYr}</p>
                    <br />


                    <p>
                      <b>Is your account with Vanquis still active?</b>
                    </p>
                    <p>{financeAgreement == "95" ? "YES" : "NO"}</p>
                    <br />

                    {/* {financeAgreement == "95" &&
                      <>
                        <p>
                          <b>Can you remember the last time Vanquis increased your credit limit?</b>
                        </p>
                        <p>{creditLimit == "197" ? 'YES' : 'NO'}</p>
                        <br />
                      </>
                    } */}

                    {financeAgreement == "95" ? (
                      <>
                        <p>
                          <b>What year was the last credit limit increase on your account, if ever?</b>
                        </p>
                        <p>{recentCredit}</p>
                        <br />
                      </>
                    ) : financeAgreement == "96" ? (
                      <>
                        <p>
                          <b>What year did you close the account?</b>
                        </p>
                        <p>{accClosed_year}</p>
                        <br />
                      </>
                    ) : ""}

                    <p>
                      <b>
                        Has Vanquis sold on your debt, if any, to a company such
                        as Lowell Portfolio / Arrow Global?
                      </b>
                    </p>
                    <p>{soldOnDebt == "206" ? "YES" : "NO"}</p>
                    <br />

                    {soldOnDebt == "206" && (
                      <>
                        <p>
                          <b>What year did this happen?</b>
                        </p>
                        <p>{whatYearThisHappen}</p>
                        <br />
                      </>
                    )}

                    <p>
                      <b>
                        At the time you took out the account with Vanquis what
                        was your employment status?
                      </b>
                    </p>
                    <p>{employmentVal}</p>
                    <br />

                    <p>
                      <b>Are you currently subject to Bankruptcy or Trust deed?</b>
                    </p>
                    <p>NO</p>
                    <br />


                    <p>
                      <b>
                        Have you, (or a company on your behalf), ever filed a
                        claim with Vanquis?
                      </b>
                    </p>
                    <p>{repayment == "101" ? "YES" : "NO"}</p>
                    <br />
                    {/* <p>
                  <b>Are you currently subject to Bankruptcy or Trust deed?</b>
                </p>
                <p>{bankruptcy == "21" ? 'YES' : 'NO'}</p>
                <br /> */}

                    <p>
                      <b>
                        Approximately what was the highest limit you ever had
                        with Vanquis credit account?
                      </b>
                    </p>
                    <p>{hightBalCred}</p>
                    <br />

                    <p>
                      <b>
                        Approximately how many times was your credit limit
                        increased on this account?
                      </b>
                    </p>
                    <p>{creditCardlimitInc}</p>
                    <br />

                    <p>
                      <b>
                        How often would you have a balance which was close to,
                        or exceeding your credit limit?
                      </b>
                    </p>
                    <p>{exceedCredLimit}</p>
                    <br />

                    <p>
                      <b>
                        How often did you only make the minimum payment due on
                        the monthly account statement (rather than paying off a
                        larger amount)?
                      </b>
                    </p>
                    <p>{minPaymentMonDue}</p>
                    <br />

                    {outstandingBalance && outstandingBalance != "" && outstandingBalance == "YES" &&(
                      <>
                        <p>
                          <b>
                            Do you have any debt/outstanding balance with Vanquis?
                          </b>
                        </p>
                        <p>{outstandingBalance}</p>
                        <br />
                      </>
                    )}

                    {approximateOutstandingBalance && approximateOutstandingBalance != "" && outstandingBalance == "YES" && (
                      <>
                        <p>
                          <b>
                            Please select from the list below approximately how much is your outstanding balance with Vanquis.
                          </b>
                        </p>
                        <p>{approximateOutstandingBalance}</p>
                        <br />
                      </>
                    )}

                    <>
                      <p>
                        <b>{`In the year when you took the ${productType} – did you receive any income?`}</b>
                      </p>
                      <p>{didReceiveIncome}</p>
                      <br />

                      {(didReceiveIncome == "YES" && cardMonthlyIncome != "") && (
                        <>
                          <p>
                            <b>{`When you took out your ${productType} what was your monthly income?`}</b>
                          </p>
                          <p>{cardMonthlyIncome}</p>
                          <br />
                        </>
                      )}

                      {(dependent && dependent != "") && (
                        <>
                          <p>
                            <b>How many dependents did you have at that time?</b>
                          </p>
                          <p>{dependent}</p>
                          <br />
                        </>
                      )}


                      {question79 && question79.length > 0 && (
                        <>
                          <p>
                            <b>{`When you took out the ${productType},`} <b>{`were you already paying monthly`}</b> {`for one or more of the following:`}</b>
                          </p>
                          <MonthlyIncomeList />
                        </>
                      )}

                      {slide80 && (
                        <>
                          <p>
                            <b> Please confirm the following MONTHLY / ANNUAL income and expenditure details</b>
                          </p>

                          {(userMortages || userRentss) && (
                            <p>
                              My monthly Mortgage / Rent was:
                              {userMortages || userRentss}
                            </p>
                          )}
                          {userTotalUtilityBillss && userTotalUtilityBillss != "" && (
                            <>
                              <p> My monthly TOTAL utility bills were: {userTotalUtilityBillss}</p>
                            </>
                          )}
                          {userMonthlyLoanRepaymentss && userMonthlyLoanRepaymentss != "" && (
                            <>
                              <p> My monthly loan repayments were:{userMonthlyLoanRepaymentss}</p>
                            </>
                          )}
                          {userCreditCardRepaymentss && userCreditCardRepaymentss != "" && (
                            <>
                              <p> My monthly credit card repayments were: {userCreditCardRepaymentss}</p>
                            </>
                          )}
                          {userMonthlyCarFinancess && userMonthlyCarFinancess != "" && (
                            <>
                              <p> My monthly car finance payments were: {userMonthlyCarFinancess}</p>
                            </>
                          )}
                          {userMonthlyStoreCardPaymentss && userMonthlyStoreCardPaymentss != "" && (
                            <>
                              <p> My monthly store card payments were: {userMonthlyStoreCardPaymentss}</p>
                            </>
                          )}
                          {userOtherMonthlyPaymentss && userOtherMonthlyPaymentss != "" && (
                            <>
                              <p> My other monthly payment commitments were: {userOtherMonthlyPaymentss}</p>
                            </>
                          )}
                        </>
                      )}
                    </>
                    {/* )} */}

                    <p>
                      <b>
                        Please provide the e-mail address you registered with
                        Vanquis
                      </b>
                    </p>
                    <p>{RegisEmail}</p>
                    <br />



                    {(otherChoose.length > 0 || question76.length > 0) && (
                      <div>
                        <p>

                          <b>{questinnaire36Vanquis.question}</b>
                        </p>
                        <OtherChooseStrCombined />
                      </div>
                    )}
                    <br />
                    {/* {(oldCard && oldCard != "") && (
                                    <>
                                        <p>
                                            <b> {`Did you take the ${productType} over 6 years ago?`}</b>
                                        </p>
                                        <p>{oldCard}</p>
                                        <br />
                                    </>
                                )} */}
                    {/* {(oldCard == "YES" && latepayment != '') && (
                                    <>
                                        {(aware && aware != "") && (
                                            <>
                                                <p>
                                                    <b>
                                                        When did you become aware you could make a claim against Vanquis?
                                                    </b>
                                                </p>
                                                <p>
                                                    {aware.split('\n').map((line, index) => (
                                                        <>
                                                            <span key={index}>{line}</span><br />
                                                        </>
                                                    ))}
                                                </p>
                                            </>
                                        )}
                                        <br />
                                        {(latepayment && latepayment != "") && (
                                            <>
                                                <p>
                                                    <b>
                                                        Did you receive any late payment letters / any other notifications from them or was your account passed to a debt company?                       </b>
                                                </p>
                                                <p>{latepayment}</p>
                                                <br />
                                            </>
                                        )}
                                        {latepayment == "YES" && (
                                            <>
                                                <p>
                                                    <b>
                                                        When you received the communications from the lender, did you feel that any difficulties you had repaying / financially were
                                                    </b>
                                                </p>
                                                <p>I thought that it was my fault / responsibility.</p>
                                                <br />
                                                {financialProblem && (
                                                    <>
                                                        <p>
                                                            <b>
                                                                Please describe what really caused you to have problems in repaying / financially?
                                                            </b>
                                                        </p>
                                                        <p>
                                                            {financialProblem.split('\n').map((line, index) => (
                                                                <>
                                                                    <span key={index}>{line}</span><br />
                                                                </>
                                                            ))}
                                                        </p>
                                                        <br />
                                                    </>
                                                )}
                                            </>
                                        )}
                                    </>
                                )} */}

                    <br />
                  </td>
                </tr>
                </tbody></table>
            </section>
            {/* page-6 */}
            <section className="container-1 my-5 table-sec" ref={divRefQuest}>
              <table style={{ width: '100%' }}>
                <tbody>
                  <tr>
                    <td>
                      <div className="d-flex flex-column flex-lg-row justify-content-evenly">
                        <div style={{ borderRadius: 35, border: '1px solid #065197', padding: '18px 15px 2px 15px', width: 312, marginBottom: 12 }}>
                          <h3 style={{ textAlign: 'center', fontSize: 18, fontWeight: 900 }}>Customer 1</h3>
                          <p style={{ fontSize: 13, paddingTop: 3 }}>
                            Full Name : {userName}
                          </p>
                          <div className="anim_ylw_preview" style={{ border: '1px solid black', padding: '15px 5px 10px 5px' }}>
                            <table style={{ fontSize: 14, height: 50 }}>
                              <tbody>
                                <tr>
                                  <td>
                                    Signature:
                                  </td>
                                  <td>
                                    <img style={{ width: '85%' }} src={userSign} alt="" />
                                  </td>
                                </tr>
                              </tbody>
                            </table>
                          </div>

                          <p style={{ fontSize: 12 }}>
                            Dated: {currentDate}
                          </p>
                        </div>
                        <div style={{ borderRadius: 35, border: '1px solid #065197', padding: '18px 15px 2px 15px', width: 312, marginBottom: 12 }}>
                          <h3 style={{ textAlign: 'center', fontSize: 18, fontWeight: 900 }}>Customer 2</h3>
                          <p style={{ fontSize: 13, paddingTop: 3 }}>
                            Full Name: ____________________
                          </p>
                          <div style={{ border: '1px solid black', padding: '15px 5px 10px 5px' }}>
                            <table style={{ fontSize: 14, height: 50 }}>
                              <tbody>
                                <tr>
                                  <td>
                                    Signature:
                                  </td>
                                  <td>
                                  </td>
                                </tr>
                              </tbody>
                            </table>
                          </div>
                          <p style={{ fontSize: 12 }}>
                            Dated:
                          </p>
                        </div>
                      </div>
                    </td>
                  </tr>
                </tbody>
              </table>
            </section>


            {/* <footer className="next-btn  bg-dark p-2">
        <a className="anim_ylw_preview btn btn-success px-3 " id="next-btn2">
          Next
        </a>
      </footer> */}
            <footer className="next-btn  bg-dark p-2">
              {showSubmitBtn && (
                <button
                  className="anim_ylw_preview btn btn-success px-3"
                  type="button"
                  id="nextscroll"
                  disabled={btndisabled}
                  onClick={pushPageChange}
                >
                  Submit</button>
              )}
              {showNextBtn && (
                <a
                  onClick={scrollToElement}
                  className="anim_ylw_preview btn btn-success px-3 "
                >
                  Next
                </a>
              )}

              <span className="text-white ms-3 ms-md-4 ms-lg-5">Signature Reviewed : {signReview}/1</span>


            </footer>
          </div>
        </div>
        {/* The Modal Merit page Loader */}
        {/* <div
      className="modal blackover fade hide"
      id="sample1"
      data-bs-backdrop="static"
      data-bs-keyboard="false"
      tabIndex={-1}
      aria-labelledby="staticBackdropLabel"
      aria-hidden="true"
    >
      <div className="modal-dialog modal-dialog-centered modal-lg">
        <div className="modal-content goodnews_popup">
          <div className="modal-body">
            <div className="text-end">
              <button
                type="button"
                className="btn-close close22"
                data-bs-dismiss="modal"
                aria-label="Close"
              />
            </div>
            <div className="text-center max-400 m-auto thakyoumsg">
              <div className="pb-lg-2">
                <img height={50} src="dist/img/logo.png" alt="" />
              </div>
              <div className="my-4 mt-5">
                <img height={50} src="dist/img/loader-review2.svg" alt="" />
              </div>
              <div className="">
                <h4 className="h4-thakyou">
                  Thank you <strong>Sdsdfg</strong> for completing our assessment,
                  which we will output into a questionnaire format.
                </h4>
                <h5 className="fst-italic fs-6">
                  Please note – you are able to self-represent your complaint.
                </h5>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div> */}
      </div>
    </>






  );
};

export default PreviewQuestVaq_V5;