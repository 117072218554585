import { useEffect, useState } from "react";
import { obLender } from "../../../../Hooks/useOblender";
import Select from "react-select";
import ErrorMessage from "./ErrorMessage";
import Autocomplete from "./AutoComplete";
import  { OverDraftBankList } from "../../../../Constants/Constants"
export default function BankSelect({
  setSelectedBank,
  bankErrMsg,
  selectedBank,
  validation,
  getValues,
  trigger,
  validationMsg,
  setError,
  clearErrors,
  setBankErrMsg
}) {

  const { getLenderlist } = obLender();

  const [lenderList, setLenderList] = useState([]);

  let BankList = [];

  if (lenderList.length > 0) {
    BankList = BankList.concat(lenderList.filter(function (val) {
      return !val.display_name.endsWith("Card") &&
        !val.display_name.endsWith("Business") &&
        !val.display_name.endsWith("card") &&
        !val.display_name.endsWith("Cards") &&
        !val.display_name.endsWith("Others");
    }).map(function (val) {
      // return {
      //   value: val.provider,
      //   label: val.display_name
      // };
      return val.display_name
    }));
  }


  const getLender = async () => {
    try {
      const response = await getLenderlist();
      const dataLength = response.data.providers.length;

      if (dataLength > 0) {
        setLenderList(response.data.providers);
      }
    } catch (error) {
      console.error("Error fetching lender list:", error);
    }
  };

  useEffect(() => {
    getLender();

  }, []);

  function handleSelect(opt) {

    const formattedBank = {value: opt.value, label: opt.label}

    setSelectedBank([formattedBank]);
    setBankErrMsg("");
  }

  const customStyles = {
    control: base => ({
      ...base,
      //height: 50,
      minHeight: 50
    })
  };
  return (
    <>
      <div className="mt-3 mb-3">
        <h5 className="title mb-3"> Which bank did you have your overdraft with?</h5>
        <div className="bank-select">

          {/* <div className="col-lg-12 mb-2 z-2 autocomplete-wrapper"> */}
            {/* <Autocomplete
              validation={validation}
              clearErrors={clearErrors}
              suggestionList={BankList}
              setSelectBank={setSelectedBank}
            /> */}
              <Select
              options={OverDraftBankList}
              placeholder="Select Bank"
              aria-label="Username"
              name="bankName"
              id="bankName"
              value={selectedBank}
              onChange={handleSelect}
              isSearchable={true}
              styles={customStyles}
              />
          {/* </div> */}
        </div>
        {/* {validationMsg.lenders && (
          <span id="bank_err" className="error_msg">
            {validationMsg.lenders.message}
          </span>
        )} */}
      <span id="bank_err" className="error_msg">{bankErrMsg}</span>

      
      </div>

    </>
  );
}
