import React, { useState } from "react";
const StillActive = ({ className, saveQuestAnswer, addPending, setStillAct,removeFromPending,qnNo }) => {
  const [checkedQuestionOne, setCheckedQuestionOne] = useState("unchecked");
  const [checkedQuestionTwo, setCheckedQuestionTwo] = useState("unchecked");

  const optionSelected = async (e) => {
    saveQuestAnswer(26,e.target.value);
    if (e.target.value === "95") {
      setCheckedQuestionOne("checked");
      setCheckedQuestionTwo("unchecked");
      setStillAct("95");
      removeFromPending("stillActive");
      removeFromPending("acntCloseYear");
      addPending("credLimitIncrYear");
    }
    if (e.target.value === "96") {
      setCheckedQuestionOne("unchecked");
      setCheckedQuestionTwo("checked");
      setStillAct("96");
      removeFromPending("stillActive");
      removeFromPending("credLimitIncrYear");
      addPending("acntCloseYear");
    }
  };
  return (
    <>
      <div className={`container ${className}`}>
        <div
          className={`questitem mt-5 qc-3 mt-0 scale-down-ver-center px-3 py-lg-3 py-md-3 py-sm-2 py-2`}
        >
           <h1 className="que-title p-3">
           Is your <b>account with Vanquis</b> still active?
           </h1>
          <div className="row">
            <div className="col-6">
              <div className=" mb-2">
                <button
                  type="button"
                  className={`"qb6 btn-comm2 py-lg-3 py-md-3 py-sm-2 py-2 w-100 nextBtn" ${checkedQuestionOne}`}
                  onClick={optionSelected}
                  value="95"
                  name="stillactive"
                  id="nextscroll"
                  data-quest-id={26}
                  data-ans-id={95}
                  data-ans-label={null}
                  data-click-id='stillActive_yes'
                >
                  Yes
                </button>
              </div>
            </div>
            <div className="col-6">
              <div className=" mb-2">
                <button
                  type="button"
                  className={`qb6 btn-comm2 py-lg-3 py-md-3 py-sm-2 py-2 w-100 nextBtn ${checkedQuestionTwo}`}
                  onClick={(optionSelected)}
                  value="96"
                  name="stillactive"
                  id="nextscroll"
                  data-quest-id={26}
                  data-ans-id={96}
                  data-ans-label={null}
                  data-click-id='stillActive_no'
                >
                  No
                </button>
              </div>
            </div>
          </div>
          <p className="text-center bck-marwid">
            <button
              name="creditopenyear"
              className="btn btn-secondary backbtn1a"
              id=""
              value=""
              type="button"
              onClick={()=>addPending('credCardOpenYear')}
              data-click-id='stillActive_back'
            >
              Go Back
            </button>
          </p>
        </div>
        <div class="tipbox tip2 show">
        This is to determine the status of your Vanquis account.<div class="arrow  arrow-top"></div>
        </div>
        <div
          id="rangequestions"
          class="qrange d-flex align-items-sm-center text-center"
        >
          Question <br class="d-none d-md-block" />{qnNo}
        </div>
      </div>
    </>
  );
};
export default StillActive;
