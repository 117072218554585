import { useContext, useEffect, useRef, useState } from "react";
import { useFormContext } from "react-hook-form";
import { SalutationVAQUB4 } from "../../../../Constants/Questions";
import SelectBox from "../../../UI/SelectBox";
import Claim from "./Claim";
import ContactDetails from "./ContactDetails";
import DateOfBirth from "./DateOfBirth";
import PostCode from "./PostCode";
import Sign from "./Sign";
import Input from "./Input";
import useValidDOB from "../../../../Hooks/useValidDOB";
import { blacklist } from "../../../../Hooks/blacklist";
import { AppConversionPixel } from "../../../../Constants/EnvConstants";
import VisitorParamsContext from "../../../../Contexts/VisitorParams";
import { useQueryString } from "../../../../Hooks/useQueryString";
import { useHistory } from "react-router-dom";
import { useUpdateMilestone } from "../../../../Hooks/useUpdateMilestone";
import { useSignature } from "../../../../Hooks/useSignature";
import { useDataIngestion } from "../../../../Hooks/useDataIngestion";

import moneypic1 from "../../../../assets/VAQ_UB_5/img/moneypic.1.png";
import moneypic2 from "../../../../assets/VAQ_UB_5/img/moneypic.2.png";
import clockpic from "../../../../assets/VAQ_UB_5/img/clockpic.png";
import { useEmailValidation } from "../../../../Hooks/useEmailValidation";
import { usePhoneValidation } from "../../../../Hooks/usePhoneValidation";

export default function PersonDetails({ divShow }) {
  const history = useHistory();
  const { trigger, clearErrors, register, getValues, errors, setError } =
    useFormContext();
  const { validateDateOfBirth } = useValidDOB();
  const { getBlacklist } = blacklist();
  const { queryString } = useQueryString();
  const { updateMilestone } = useUpdateMilestone();
  const { saveSignature } = useSignature();
  const { formDataIngestion } = useDataIngestion();
  const { visitorParameters } = useContext(VisitorParamsContext);
  const splitForm = useRef(null);
  const [notDobValid, setNotDobValid] = useState(false);
  const [blacklisted, setBlacklisted] = useState([]);
  const [signatureDataURL, setSignatureDataURL] = useState("");
  const { emailValidation } = useEmailValidation();
  const { phoneValidation } = usePhoneValidation();


  const form = splitForm.current;
  const values = getValues();
  const formData = values;
  const field = "pid";

  useEffect(() => {
    let responsesss = [];
    (async () => {
      const response = await getBlacklist();
      let dataLength = response?.data?.response?.length;
      responsesss = response?.data?.response;
      if (dataLength > 0) {
        setBlacklisted(response?.data?.response);
      }
    })();
  }, []);

  const basicDetailsValidation = async (e) => {
    let errorFlag = 0;
    let lstSalutationResult = await trigger("lstSalutation");

    let salutation = formData?.lstSalutation;
    let txtFNameResult;
    let txtLNameResult;
    let lstDobDayResult;
    let lstDobMonthResult;
    let lstDobYearResult;
    let lstPostResult;
    let address1Result;
    let txtPhoneResult;
    let txtEmailResult;
    let productClaimResult;
    let dobday = document.getElementById("DobDay").value;
    let dobmonth = document.getElementById("DobMonth").value;
    let dobyear = document.getElementById("DobYear").value;
    var phone = document.getElementById("txtPhone").value;
    var email = document.getElementById("txtEmail").value;

    if (lstSalutationResult) {
      txtFNameResult = await trigger("txtFName");
    } else {
      document.getElementById("lstSalutation").focus();
      return false;
    }
    if (txtFNameResult) {
      txtLNameResult = await trigger("txtLName");
    } else {
      document.getElementById("txtFName").focus();
      return false;
    }
    if (txtLNameResult) {
      lstPostResult = await trigger("txtPostCode");
    } else {
      document.getElementById("txtLName").focus();
      return false;
    }
    if (lstPostResult) {
      address1Result = await trigger("address1");
    } else {
      document.getElementById("txtPostCode").focus();
      return false;
    }
    if (address1Result) {
      lstDobDayResult = await trigger("DobDay");
    } else {
      document.getElementById("address1").focus();
      return false;
    }
    if (lstDobDayResult) {
      lstDobMonthResult = await trigger("DobMonth");
    } else {
      document.getElementById("DobDay").focus();
      return false;
    }
    if (lstDobMonthResult) {
      lstDobYearResult = await trigger("DobYear");
    } else {
      document.getElementById("DobMonth").focus();
      return false;
    }
    if (lstDobYearResult) {
      const getPhoneValidation = await phoneValidation(phone);
      if (getPhoneValidation.data.status_code === 0) {
        setError("txtPhone", {
          type: "manual",
          message: "Please Enter Valid Working Phone Number",
        });
        document.getElementById("txtPhone").focus();
        return false;
      } else if (getPhoneValidation.data.status_code === 2) {
        setError("txtPhone", {
          type: "manual",
          message: "Phone Number Already Exist",
        });
        document.getElementById("txtPhone").focus();
        return false;
      } else if (getPhoneValidation.data.status_code === 3) {
        setError("txtPhone", {
          type: "manual",
          message: "Please Enter Valid Phone Number",
        });
        document.getElementById("txtPhone").focus();
        return false;
      } else {
        txtPhoneResult = true;
      }
    } else {
      document.getElementById("DobYear").focus();
      return false;
    }
    if (txtPhoneResult) {
      txtEmailResult = await trigger("txtEmail");
      if(txtEmailResult){
        const getEmailValidation = await emailValidation(email);
        if (getEmailValidation.data.status_code === 1) {
          txtEmailResult = true;
        } else {
          setError("txtEmail", {
            type: "manual",
            message: getEmailValidation.data.message,
          });
          document.getElementById("txtEmail").focus();
          return false;
        }
      }else{
        document.getElementById("txtEmail").focus();
        return false;
      }
    } else {
      document.getElementById("txtPhone").focus();
      return false;
    }
    if (txtEmailResult) {
      productClaimResult = await trigger("productclaim");
    } else {
      document.getElementById("txtEmail").focus();
      return false;
    }
    if (productClaimResult) {
      const dobIsValid = validateDateOfBirth(dobday, dobmonth, dobyear);

      if (!dobIsValid) {
        document.getElementById("DobDay").focus();
        setNotDobValid(true);
        return false;
      } else {
        setNotDobValid(false);
      }
    } else {
      document.getElementById("card1").focus();
      return false;
    }
    if (
      !lstSalutationResult ||
      !txtFNameResult ||
      !txtLNameResult ||
      !lstDobDayResult ||
      !lstDobMonthResult ||
      !lstDobYearResult ||
      !lstPostResult ||
      !txtPhoneResult ||
      !txtEmailResult ||
      !productClaimResult
    ) {
      errorFlag = 1;
    }
    if (errorFlag === 0) {
      // if (
      //   salutation == "Mrs" ||
      //   salutation == "Ms" ||
      //   salutation == "Miss" ||
      //   salutation == "Dame" ||
      //   salutation == "Lady"
      // ) {
      //   setTitleQuest(true);
      // } else {
      //   setTitleQuest(false);
      // }
      return true;
    } else {
      return false;
    }
  };

  
  useEffect(()=>{
    if(signatureDataURL!==''){
      formSubmit();
    }
  },[signatureDataURL])

  const formSubmit = async () => {
    
    if (formData.address1 !== "") {
      const txtUdprn = form["txtUdprn"].value;
      const txtDeliveryPointSuffix = form["txtDeliveryPointSuffix"].value;
      const txtPz_mailsort = form["txtPz_mailsort"].value;
      const txtStreet = form["txtStreet"].value;
      const txtAddress3 = form["txtAddress3"].value;
      const txtDistrict = form["txtDistrict"].value;

      formData.txtDistrict = txtDistrict;
      formData.txtAddress3 = txtAddress3;
      formData.txtUdprn = txtUdprn;
      formData.txtDeliveryPointSuffix = txtDeliveryPointSuffix;
      formData.txtPz_mailsort = txtPz_mailsort;
      formData.txtStreet = txtStreet;
    }

   

    formData.user_sign = signatureDataURL;
    formData.question_62 = form["productclaim"].value;
    localStorage.setItem("ProductType", formData.question_62);
    formData.page_name = "VAQ_UB_5";
    formData.pCode_manual = form["pCode_EntryType"].value;
    if (
      visitorParameters.data !== null ||
      visitorParameters.data !== undefined
    ) {
      // let dobMonth = formData.DobMonth ? MonthNameArray[formData.DobMonth] : '';
      localStorage.setItem("fName", formData.txtFName);
      localStorage.setItem("lName", formData.txtLName);
      localStorage.setItem("pCode", formData.txtPostCode);
      localStorage.setItem("addressLine1", formData.txtHouseNumber);
      localStorage.setItem("dobYr", formData.DobYear);
      localStorage.setItem("dobMon", formData.DobsignatureDataURLMonth);
      localStorage.setItem("dobDy", formData.DobDay);
      localStorage.setItem("addressLine2", formData.txtAddress2);
      localStorage.setItem("postTown", formData.txtTown);
      localStorage.setItem("country", formData.txtCounty);
      localStorage.setItem("title", formData.lstSalutation);
      localStorage.setItem("addressLine3", formData.txtAddress3);
      localStorage.setItem("county", formData.txtCounty);
      localStorage.setItem(
        "district",
        formData.txtDistrict ? formData.txtDistrict : ""
      );
      let UcFname =
        formData.txtFName.charAt(0).toUpperCase() + formData.txtFName.slice(1);

      localStorage.setItem("UcFname", UcFname);

      localStorage.setItem("formData", JSON.stringify(formData));
      localStorage.setItem("queryString", queryString);

      if (signatureDataURL) {
        const formSUbmitResult = formDataIngestion(
          visitorParameters.visitor_parameters,
          formData,
          "split_form_submit",
          "VAQ_UB_5",
          queryString,
          visitorParameters.data
        );
        const message_type = "signature_store";
        const response = await saveSignature(
          signatureDataURL,
          visitorParameters.visitor_parameters,
          formData,
          visitorParameters.data,
          queryString,
          message_type
        );
        if (response.data.status === "Success") {
          updateMilestone(
            visitorParameters.visitor_parameters.uuid,
            "lead",
            "live"
          );
          updateMilestone(
            visitorParameters.visitor_parameters.uuid,
            "signature",
            "live"
          );
          pageChange();
        }
      }
    } else {
      return "Fail";
    }
  };
  const pageChange = () => {
    let blacklistFlag = false;
    blacklisted.map((value, index) => {
      if ("email" == value.type && value.value == formData.txtEmail) {
        blacklistFlag = true;
      }

      if ("phone" == value.type && value.value == formData.txtPhone) {
        blacklistFlag = true;
      }
    });

    if (blacklistFlag) {
      window.scrollTo(0, 0);
      history.push(
        `/thankyou-bl?uuid=${visitorParameters?.visitor_parameters?.uuid}`
      );
    } else {
      if (queryString != null) {
        if (queryString.indexOf(`&${field}=`) !== -1) {
          if (AppConversionPixel === "true") {
            history.push("/fbpixel?split_name=VAQ_UB_5");
          } else {
            window.scrollTo(0, 0);

            history.push(
              `/questionaireVAQV5?uuid=${visitorParameters?.visitor_parameters?.uuid}`
            );
          }
        } else {
          window.scrollTo(0, 0);
          history.push(
            `/questionaireVAQV5?uuid=${visitorParameters?.visitor_parameters?.uuid}`
          );
        }
      } else {
        window.scrollTo(0, 0);
        history.push(
          `/questionaireVAQV5?uuid=${visitorParameters?.visitor_parameters?.uuid}`
        );
      }
    }
  };


  return (
    <form ref={splitForm}>
      <div className={`sidesection py-2 pt-0 ${divShow}`} id="sidesection">
       
        <div>
          <div className="form-style form-body p-4 pb-5">
            <h2 className="mb-2  title">Register Your Claim Online Now</h2>
            <div className="bulletPoint">
              <ul className="ps-0">
                <li>
                  <img src={moneypic1} />
                  Highest uphold <b>£24,712.83</b>
                </li>
                <li>
                  <img src={moneypic2} />
                  Average uphold <b>£2,200</b>
                </li>
                <li>
                  <img src={clockpic} />
                  Quickest uphold 24 days
                </li>
              </ul>
            </div>
            <div className="row">
              <div className="col-lg-2 mb-2">
                <SelectBox
                  className="form-select select1"
                  OptionValue={SalutationVAQUB4}
                  name="lstSalutation"
                  id="lstSalutation"
                  onChange={() => clearErrors("lstSalutation")}
                  clearErrors={clearErrors}
                  myRef={register({ required: "Please select your title" })}
                  validationMsg={
                    errors?.lstSalutation && errors?.lstSalutation?.message
                  }
                />
              </div>
              <div className="col-lg-5 ps-lg-0">
                <div className="mb-2">
                  <Input
                    type="text"
                    placeholder="First Name"
                    className="form-control"
                    name="txtFName"
                    id="txtFName"
                    rules={{
                      required: "Please enter your first name",
                      minLength: {
                        value: 3,
                        message: "Please enter valid first name",
                      },
                      pattern: {
                        value: /^[a-zA-Z\s]+$/,
                        message: "Your name is invalid. Please recheck",
                      },
                    }}
                    transform={(value) =>
                      value.replace(/^[ ]+/g, "").replace(/[^a-zA-Z\s]/g, "")
                    }
                    message={errors?.txtFName?.message}
                  />
                </div>
              </div>
              <div className="col-lg-5 ps-lg-0">
                <div className="mb-2">
                  <Input
                    type="text"
                    placeholder="Last Name"
                    className="form-control"
                    name="txtLName"
                    id="txtLName"
                    rules={{
                      required: "Please enter your last name",
                      minLength: {
                        value: 3,
                        message: "Please enter valid last name",
                      },
                      pattern: {
                        value: /^[a-zA-Z\s]+$/,
                        message: "Your name is invalid. Please recheck",
                      },
                    }}
                    transform={(value) =>
                      value.replace(/^[ ]+/g, "").replace(/[^a-zA-Z\s]/g, "")
                    }
                    message={errors?.txtLName?.message}
                  />
                </div>
              </div>
              <PostCode />
              <DateOfBirth notDobValid={notDobValid} />
              <ContactDetails />
              <Claim />
              <Sign
                basicDetailsValidation={basicDetailsValidation}
                setSignatureDataURL={setSignatureDataURL}
                formSubmit={formSubmit}

              />
            </div>
          </div>
        </div>
      </div>
    </form>
  );
}
