import React, { useState } from "react";
import RadioButtonQuest from "../../../UI/RadioButtonQuest";
const ExceedingCreditLimit = ({
  className,
  saveQuestAnswer,
  addPending,
  removeFromPending,
  qnNo
}) => {
  const [questResult, setQuestResult] = useState(false);
  const [checkedOne, setCheckedOne] = useState("unchecked");
  const [checkedTwo, setCheckedTwo] = useState("unchecked");
  const [checkedThree, setCheckedThree] = useState("unchecked");
  const [checkedFour, setcheckedFour] = useState("unchecked");
  const [checkedFive, setcheckedFive] = useState("unchecked");
  const optionClicked = async (e) => {
    if (e.target.value === "141") {
      setCheckedOne("checked");
    } else {
      setCheckedOne("unchecked");
    }

    if (e.target.value === "142") {
      setCheckedTwo("checked");
    } else {
      setCheckedTwo("unchecked");
    }

    if (e.target.value === "143") {
      setCheckedThree("checked");
    } else {
      setCheckedThree("unchecked");
    }

    if (e.target.value === "144") {
      setcheckedFour("checked");
    } else {
      setcheckedFour("unchecked");
    }
    if (e.target.value === "145") {
      setcheckedFive("checked");
    } else {
      setcheckedFive("unchecked");
    }
    setQuestResult(false);
  };
  const selectedClick = async (e) => {
    saveQuestAnswer(55, e.target.value);
    removeFromPending("exceedingCredLimit");
  };
  return (
    <>
      <div className={`container ${className}`}>
        <div className="questitem mt-5 scale-down-ver-center px-3 py-lg-3 py-md-3 py-sm-2 py-2 pb-3 ">
          <h1 className="que-title p-3 ">
            How often would you have a{" "}
            <strong>
              {" "}
              balance which was close to, or exceeding your credit limit?{" "}
            </strong>
          </h1>
          <div className="row">
            <div className="col-lg-12 mb-2">
              <ul className="p-0 m-0 option-grid">
                <li className="adjustbalewid">
                  <div className="optionbtn">
                    <RadioButtonQuest
                      name="excdCreditLimit"
                      id="optionztwo"
                      labelClassName={`${checkedOne}`}
                      value="141"
                      labelName="Always"
                      onClick={selectedClick}
                      dataQuestId={55}
                      dataAnsId={146}
                      dataAnsLabel="Always"
                      dataClickId='exceedingCredLimit_always'
                    />
                  </div>
                </li>
                <li className="adjustbalewid">
                  <div className="optionbtn">
                    <RadioButtonQuest
                      name="excdCreditLimit"
                      id="optionztwo2"
                      labelClassName={`${checkedTwo}`}
                      value="142"
                      labelName="Sometimes"
                      onClick={selectedClick}
                      dataQuestId={55}
                      dataAnsId={142}
                      dataAnsLabel="Sometimes"
                      dataClickId='exceedingCredLimit_sometimes'
                    />
                  </div>
                </li>
                <li className="adjustbalewid">
                  <div className="optionbtn">
                    <RadioButtonQuest
                      name="excdCreditLimit"
                      id="optionztwo3"
                      labelClassName={`${checkedThree}`}
                      value="143"
                      labelName="Twice"
                      onClick={selectedClick}
                      dataQuestId={55}
                      dataAnsId={143}
                      dataAnsLabel="Twice"
                      dataClickId='exceedingCredLimit_twice'
                    />
                  </div>
                </li>
                <li className="adjustbalewid">
                  <div className="optionbtn">
                    <RadioButtonQuest
                      name="excdCreditLimit"
                      id="optionztwo4"
                      labelClassName={`${checkedFour}`}
                      value="144"
                      labelName="Once"
                      onClick={selectedClick}
                      dataQuestId={55}
                      dataAnsId={144}
                      dataAnsLabel="Once"
                      dataClickId='exceedingCredLimit_once'
                    />
                  </div>
                </li>
                <li className="adjustbalewid">
                  <div className="optionbtn">
                    <RadioButtonQuest
                      name="excdCreditLimit"
                      id="optionztwo5"
                      labelClassName={`${checkedFive}`}
                      value="145"
                      labelName="Never"
                      onClick={selectedClick}
                      dataQuestId={55}
                      dataAnsId={145}
                      dataAnsLabel="Never"
                      dataClickId='exceedingCredLimit_never'
                    />
                  </div>
                </li>
              </ul>
            </div>
          </div>
          <p className="text-center bck-marwid">
            <button
              name="CredLimitInc"
              className="btn btn-secondary backbtn1a"
              id=""
              value=""
              type="button"
              onClick={() => addPending("credLimitIncrCount")}
              data-click-id='exceedingCredLimit_back'
            >
              Go Back
            </button>
          </p>
        </div>
        <div class="tipbox tip2 show">
        This assists us in evaluating your expenses.
          <div class="arrow  arrow-top"></div>
        </div>
        <div
          id="rangequestions"
          class="qrange d-flex align-items-sm-center text-center"
        >
          Question <br class="d-none d-md-block" />{qnNo}
        </div>
      </div>
    </>
  );
};
export default ExceedingCreditLimit;
