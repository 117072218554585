import "../../../assets/VAQ_UB_5/scss/custom.scss";
import AdtopiaLoadLP from "../../../Utility/AdtopiaLoadLP";
import FbViewPixel from "../../../Utility/FbViewPixel";
import FormVAQ_UB_5 from "../../Forms/FormVAQ_UB_5";
import AccordSection from "../../Includes/Layouts/VAQ_UB_5/AccordSection";
import Footer from "../../Includes/Layouts/VAQ_UB_5/Footer";

export default function VAQ_UB_5() {
  return (
    <>
      <AdtopiaLoadLP pageType="LP" splitName="VAQ_UB_5" />
      <FbViewPixel />
      <div className="VAQ_UB_5 form-font">
        <FormVAQ_UB_5 />
        <AccordSection />
        <Footer />
      </div>
    </>
  );
}
