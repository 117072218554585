import React, { useState } from "react";
import SelectBox from "../../../UI/SelectBox";
import { PossessionMonth, YearPossession } from "../../../../Constants/Constants";
import GtmDataLayer from "../../../../Utility/GtmDataLayer";

const YearOfPossession = ({
    saveInputAns,
    className,
    slideChange,
    textHeadingLine1,
    validationMsg,
    clearErrors,
    validation,
    backClick,
    trigger,
    getValues,
    splitForm,
    prevStill
}) => {



    const nextSlide = async (e) => {
        let errorFlag = 0;
        let yearresult = await trigger("YearOfPossession");
        // let monthresult = await trigger("MonthOfPossession");

        if (!yearresult ) {
            return false;
        }
        else {
            GtmDataLayer({
                question: "loan date",
              });
            // let MonthOfPossession = splitForm.current["MonthOfPossession"].value;
            let yearposession = splitForm.current["YearOfPossession"].value;
            let enteredLoanDate = yearposession;
            localStorage.setItem("enteredLoanDate", enteredLoanDate);
            saveInputAns(e);
        }
    };

    let toggle;
    if(prevStill == "tocarreg"){
      toggle = "CarRegno";
    } else {
      toggle = "keeperDate"
    }

    return (
        <>
            <div
                className={`question-content mt-0 scale-down-ver-center px-3  py-lg-3 py-md-3 py-sm-2 py-2 `}
            >
                <div className="row">
                    {/* <div className="col-lg-6 mb-2">
                        <SelectBox
                            className="form-select "
                            OptionValue={PossessionMonth}
                            name="MonthOfPossession"
                            id="MonthOfPossession"
                            onChange={() => clearErrors("MonthOfPossession")}
                            clearErrors={clearErrors}
                            myRef={validation({ required: "Please Select Month" })}
                            validationMsg={
                                validationMsg.MonthOfPossession &&
                                validationMsg.MonthOfPossession.message
                            }
                        ></SelectBox>
                    </div> */}
                    <div className="col-lg-12 mb-2">
                        <SelectBox
                            className="form-select"
                            OptionValue={YearPossession}
                            name="YearOfPossession"
                            id="YearOfPossession"
                            onChange={() => clearErrors("YearOfPossession")}
                            clearErrors={clearErrors}
                            myRef={validation({ required: "Please Select Year" })}
                            validationMsg={
                                validationMsg.YearOfPossession &&
                                validationMsg.YearOfPossession.message
                            }
                        ></SelectBox>
                    </div>
                </div>
                <div className="row">
                    <div className="col-lg-12 mb-2 mt-2">
                        <button className=" btn-comm2 py-3 w-100 nextBtn"
                            name="possessionyear"
                            type="button"
                            id="year"
                            onClick={nextSlide}
                            data-quest-id={65}
                        >
                            {/* <span> */}
                            {/* Next <i className="bi bi-arrow-right" /> */}
                            Next &#8594;
                            {/* </span> */}
                        </button>
                    </div>
                </div>
                <div className="row">
                    <div className="  text-center mt-3 ">
                        <button className="btn btn-secondary backbtn" type="button" name={toggle} onClick={backClick}>
                            {" "}
                            <i className="bi bi-arrow-left" /> Back
                        </button>
                    </div>
                </div>
            </div>



        </>
    );

};

export default YearOfPossession;