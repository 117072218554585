import { useEffect, useRef, useState } from "react";
import { lenderSuggest } from "../../Constants/Constants";

export default function AutoComplete({validation,clearErrors }) {
  const [inputValue, setInputValue] = useState("");
  const [suggestions, setSuggestions] = useState([]);
  const [showSuggestion, setShowSuggestion] = useState(false);
  const suggestionsRef = useRef(null);

  const handleInputChange = (event) => {
    const value = event.target.value;
    clearErrors("otherlenders");
    setInputValue(value);
    if (value.length > 0) {
      const filteredSuggestions = lenderSuggest.filter((suggestion) =>
        suggestion.toLowerCase().includes(value.toLowerCase())
      );
      setSuggestions(
        filteredSuggestions.length > 0
          ? filteredSuggestions
          : []
      );
    } else {
      setSuggestions([]);
    }
  };

  const handleBlur = () => {
    setInputValue((prevValue) => prevValue.trim());
  };

  const handleSuggestionClick = (value) => {
    setInputValue(value);
    setSuggestions([]);
    clearErrors("otherlenders");
  };

  const handleClickOutside = (event) => {
    if (suggestionsRef.current && !suggestionsRef.current.contains(event.target)) {
      setShowSuggestion(false);
    }
  };

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    document.addEventListener('touchstart', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
      document.removeEventListener('touchstart', handleClickOutside);
    };
  }, []);

  return (
    <>
      <input
        type="text"
        value={inputValue}
        onChange={handleInputChange}
        onBlur={handleBlur}
        onFocus={() => {setShowSuggestion(true)}}
        aria-autocomplete="list"
        aria-controls="autocomplete-list"
        className="form-control p-3 h-56"
        placeholder="Enter your Bank name"
        autoComplete="off"
        id="otherlenders"
        name="otherlenders"
        ref={validation({
            required: "Please Enter Bank Name",
            pattern: {
                value: /^(?![^A-Za-z\s]).*$/,
                message: "Invalid Bank Name",
            },
        })}
    />
      {(showSuggestion && suggestions.length > 0) && (
        <ul id="autocomplete-list" className="suggestions-list" role="listbox" ref={suggestionsRef}>
          {suggestions.map((suggestion, index) => (
            <li
              key={index}
              role="option"
              aria-selected="false"
              onClick={() => handleSuggestionClick(suggestion)}
            >
              {suggestion}
            </li>
          ))}
        </ul>
      )}
    </>
  );
}