import { useFormContext } from "react-hook-form";

function Input({
  label = '',
  name,
  type = "text",
  className = "",
  rules = {},
  transform = (value) => value,
  ...props
}) {
  const { register, setValue, } = useFormContext();


  return (
    <>
      {'' !== label && (
        <label htmlFor={name} className={className}>
          {label}
        </label>
      )}

      <div className="input-group">
        <input
          type={type}
          className={`form-control ${className}`}
          name={name}
          ref={register(rules)}
          onChange={(e) => {
            setValue(name, transform(e.target.value));
          }}
          {...props}
        />
      </div>

    </>
  );
}

export default Input;
