export const TrackUserActions = (e) => {

    const getPageName = () => {
        const url = window.location.href;
        const pageName = url.split('/')[3].split('?')[0];
        return pageName.length > 3 ? pageName : null;
    };

    const getUserActionDetails = (e) => {
        if (e && e.target) {
            const actionType = e.type || '';
            let action = '';

            switch (actionType) {
                case 'click':
                    action = 'btn_click';
                    break;
                default:
                    action = 'page_visit';
                    break;
            }

            const click_type = e.target.getAttribute("data-click-id") || '';

            return { action, click_type };
        }
        return { action: '', click_type: '' };
    };

    const getSource = () => {
        const urlSearchParams = new URLSearchParams(window.location.search);
        return urlSearchParams.get("atp_sub6") || "live";
    };

    const getUuid = () => {
        const query = new URLSearchParams(window.location.search);
        const query_uuid = query.get("uuid");
        const local_uuid = localStorage.getItem("uuid");
        return query_uuid || local_uuid;
    };

    const page = getPageName();
    const { action, click_type } = getUserActionDetails(e);
    const source = getSource();
    const user_uuid = getUuid();

    if ((action && action != '') && (click_type && click_type != '')) {
        return { user_uuid, page, action, click_type, source };
    } else {
        return null;
    }

};

