import React, { useState } from "react";
import { Modal } from "react-bootstrap";
import RadioButton from "../../../UI/RadioButton";
import RadioButtonQuest from "../../../UI/RadioButtonQuest";
import CheckBox from "../../../UI/CheckBox";
const Filed = ({
    onSelectAnswer,
    validationMsg,
    clearErrors,
    validation,
    trigger,
    getValues,
    splitForm,
    backClick,
    bankStatements,
    setBankStatements
}) => {

    const [questResult, setQuestResult] = useState(false);
    const [checkedValues, setCheckedValues] = useState([]);

    const selectedClick = async (e) => {
        e.preventDefault();
        const containsId211 = bankStatements.some(statement => statement.id === 211);
        if (containsId211) {
            localStorage.setItem("OBStatement", 1);
            localStorage.setItem("BankStatement", 1);
        } else {
            localStorage.setItem("OBStatement", 0);
            localStorage.setItem("BankStatement", 0);
        }
        let errorFlag = 0;
        let selectedCount = bankStatements.length;
        if (selectedCount == 0) {
            errorFlag = 1;
            setQuestResult(true);
            return false;
        } else {
            onSelectAnswer(e);
        }
    }


    const labelclicked = async (e) => {
        let newCheckedValues;
        const value = e.target.value;

    if (e.target.checked === true) {
        if(e.target.value === '212'){
            newCheckedValues = [value];
            bankStatements.length = 0;
            bankStatements.push({ id: +e.target.value });
            setQuestResult(false);
        } else {
            bankStatements.push({ id: +e.target.value });
            let arr = bankStatements.filter(item => item.id != 212);
            setBankStatements(arr);
            newCheckedValues = checkedValues.includes('212') ? [value] : [...checkedValues, value];
            setQuestResult(false);
        }
    } else {
        newCheckedValues = checkedValues.filter(val => val !== value);
        let deselect = e.target.value;
        const decre = bankStatements.map((OptionArray, index) => {
            if (deselect == OptionArray.id) {
                 bankStatements.splice(index, 1);
            }
        });
    }
    setCheckedValues(newCheckedValues);
    }
    return (
        <>
            <div className={`question-content mt-0 scale-down-ver-center  px-3 py-lg-3 py-md-3 py-sm-2 py-2`}>
                <div className="row">
                    <div className="col-lg-12 mb-2">
                        <ul className="p-0 m-0 option-grid doculist">
                            <li>
                                <div className="optionbtn">
                                    <CheckBox
                                        dataId="optionzero11"
                                        name="bankstmt"
                                        onChange={labelclicked}
                                        CheckBoxText="Payslips"
                                        value="210"
                                        dataQuestId={74}
                                        checked={checkedValues.includes('210')}
                                    />
                                </div>
                            </li>
                            <li>
                                <div className="optionbtn">
                                    <CheckBox
                                        dataId="optionzero12"
                                        name="bankstmt"
                                        onChange={labelclicked}
                                        CheckBoxText="Bank Statements"
                                        value="211"
                                        dataQuestId={74}
                                        checked={checkedValues.includes('211')}
                                    />
                                </div>
                            </li>
                            <li>
                                <div className="optionbtn">
                                    <CheckBox
                                        dataId="optionzero13"
                                        name="bankstmt"
                                        onChange={labelclicked}
                                        CheckBoxText="Can't remember"
                                        value="212"
                                        dataQuestId={74}
                                        checked={checkedValues.includes('212')}
                                    />
                                </div>
                            </li>
                        </ul>
                        {questResult && (
                            <span className="error_msg m-0 mt-2">Please select an option</span>
                        )}
                    </div>
                    <div className="col-lg-12 mb-2 mt-2">
                        <button className=" btn-comm2 py-3 w-100 nextBtn"
                            name="bankstatement"
                            id="nextscroll"
                            type="button"
                            onClick={selectedClick}
                            data-quest-id={74}
                        >
                            Next &#8594;

                        </button>
                    </div>
                    <div className=" text-center mt-2">
                        <button className="btn btn-secondary backbtn" type="button" name="btn_qest_36" onClick={backClick}>
                            {" "}
                            <i className="bi bi-arrow-left" /> Back
                        </button>
                    </div>
                </div>
            </div>
        </>
    )
}
export default Filed;