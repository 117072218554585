import React, { useState } from "react";
import Email from "../../../UI/Email";
import RadioButtonQuest from "../../../UI/RadioButtonQuest";
import GtmDataLayer from "../../../../Utility/GtmDataLayer";

const Emailconfirmation = ({
  clsName,
  reff,
  onSelectAnswer,
  consentStatus,
  setIsRegisteredEmail,
  setIsNewEmailVisible,
  isRegisteredEmail,
  isNewEmailVisible,
  validation,
  validationMsg,
  trigger,
  backClick,
  getValues,
  splitForm,
  bankStmt,
  obValue2,
  goBack
}) => {
  const [questResult, setQuestResult] = useState(false);
  const [checkedOne, setCheckedOne] = useState("unchecked");
  const [checkedTwo, setCheckedTwo] = useState("unchecked");
  const [checkedThree, setCheckedThree] = useState("unchecked");
  const OBStatementValue = localStorage.getItem("OBStatement");

  const optionSelected = async (e) => {
    if (e.target.value == "162") {
      setCheckedOne("checked");
    } else {
      setCheckedOne("unchecked");
    }
    if (e.target.value == "163") {
      setCheckedTwo("checked");
    } else {
      setCheckedTwo("unchecked");
    }
    if (e.target.value == "195") {
      setCheckedThree("checked");
    } else {
      setCheckedThree("unchecked");
    }
    setQuestResult(false);

  }
  const handleClick = async (e) => {
    GtmDataLayer({
      question: "registered email address",
    });
    e.preventDefault();
    let errorFlag = 0;
    let optionselect = splitForm.current["question_61"].value;
    if (optionselect == '') {
      errorFlag = 1;
      setQuestResult(true);
      return false;
    } else {
      if (optionselect == '163') {
        setIsRegisteredEmail("hide");
        setIsNewEmailVisible("show")
        onSelectAnswer(e);
      } else {
        onSelectAnswer(e);
      }
    }

  }
  const handleClick48 = async (e) => {
    e.preventDefault();
    let errorFlag = 0;
    let question48Result = await trigger("question_48");
    if (!question48Result) {
      errorFlag = 1;
      return false;
    }
    if (errorFlag === 0) {
      GtmDataLayer({
        question: "moneybarn email",
      });
      onSelectAnswer(e);
    }
  }
   const back =async(e) => {
    e.preventDefault();
    setIsRegisteredEmail("show");
    setIsNewEmailVisible("hide")
   }

  return (
    <>
      <div className="question-content mt-0 scale-down-ver-center px-3  py-lg-3 py-md-3 py-sm-2 py-2" ref={reff}>
        <div className="row">
          <div className={`col-lg-12 mb-2 ${isRegisteredEmail == 'hide' ? 'hide' : ''}`}>

            <ul className="p-0 m-0 option-grid">
              <li>
                <div className="optionbtn">
                  <RadioButtonQuest
                    name="question_61"
                    id="optionzero"
                    labelClassName={`${checkedOne}`}
                    value="162"
                    labelName="Yes"
                    onClick={optionSelected}
                    dataQuestId={61}
                    dataAnsId={162}
                  />
                </div>
              </li>

              <li>
                <div className="optionbtn">
                  <RadioButtonQuest
                    name="question_61"
                    id="optionzero2"
                    labelClassName={`${checkedTwo}`}
                    value="163"
                    labelName="No"
                    onClick={optionSelected}
                    dataQuestId={61}
                    dataAnsId={163}
                  />
                </div>
              </li>
              <li>
                <div className="optionbtn">
                  <RadioButtonQuest
                    name="question_61"
                    id="optionzero3"
                    labelClassName={`${checkedThree}`}
                    value="195"
                    labelName="Not sure"
                    onClick={optionSelected}
                    dataQuestId={61}
                    dataAnsId={195}
                  />
                </div>
              </li>

            </ul>
            {questResult && (
              <span className="error_msg">Please select an option</span>
            )}
            <div className="col-lg-12 mb-2 mt-2">
              <button
                type="button"
                className="  btn-comm2 py-3 w-100 nextBtn "
                id="nextscroll"
                name="emailConfirmation" 
                onClick={handleClick}
                data-quest-id={61}      
              >
                {/* <span> */}
                  Next &#8594;
                  {/* <i className="bi bi-arrow-right"></i> */}
                  {/* </span> */}
              </button>
              {/* {
                (consentStatus == true) && (
                  <div className={`text-center pt-2 ${obValue2}`}>
                    {" "}
                    <a
                      className=" text-primary back-btn text-decoration-underline"
                      id="back1"
                      onClick={() => goBack()}
                    >
                      <i className="bi bi-arrow-left" /> Go Back
                    </a>{" "}
                  </div>
                )
              } */}
            </div>
            <div className={`row ${bankStmt != '1' || consentStatus == false ? 'show' : 'hide'}`} >
              <div className="  text-center mt-3 " >
                {/* <div className="backbtn" name={consentStatus ? "wereAlreadyPaying" : "accountNumber"} onClick={backClick}  > <i className="bi bi-arrow-left"></i> Back</div> */}
                {/* <div className="backbtn" name={"dependents"} onClick={backClick}  > <i className="bi bi-arrow-left"></i> Back</div> */}
                {/* {
                      OBStatementValue == 1 ?
                          (
                              <div className="text-primary back-btn" name="goToCheckList" onClick={backClick}>
                                  <i className="bi bi-arrow-left"></i> Go Back
                              </div>
                          ) :
                          (
                              <div className="backbtn" name="dependents" onClick={backClick}>
                                  <i className="bi bi-arrow-left"></i> Back
                              </div>
                          )
                  } */}
                  {
                      OBStatementValue != 1 &&
                          (
                              <div className="btn btn-secondary backbtn" name="dependents" onClick={backClick}>
                                  <i className="bi bi-arrow-left"></i> Back
                              </div>
                          )
                  }
              </div>
            </div>
          </div>

          <div className={`row  ${isNewEmailVisible == 'show' ? '' : 'hide'}`}>
            <div className="col-lg-8 mb-2 mb-lg-0" >
              <Email
                name="question_48"
                className="form-control p-3 h-55"
                id="question_48"
                placeholder="Email Address"
                // onBlur={emailValidCheck}
                validation={validation({
                  required: "Please Enter Valid Email Address",
                  pattern: {
                    value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
                    message: "Invalid Email Address",
                  },
                })}
              ></Email>
              {validationMsg.question_48 && (
                <span className="error_msg " id="email_err">
                  {validationMsg.question_48.message}
                </span>
              )}
            </div>
            <div className="col-lg-4">
              <button
                type="button"
                className="qb15 btn-comm2 py-lg-3 py-md-3 py-sm-2 py-2 w-100 nextBtn"
                id="nextscroll"
                name="btn_qest_48"
                onClick={handleClick48}
                data-quest-id={48}
              >
                Next &#8594;
                {/* <i className="bi bi-arrow-right"></i> */}
                 </button>
            </div>
            <div className="row">

          <div className="  text-center mt-3 " >

            <div className="btn btn-secondary backbtn" name="emailConfirm" onClick={back}  > <i className="bi bi-arrow-left"></i> Back</div>
          </div>

        </div>
          </div>
        </div>
        {/* <div className="row">

<div className="  text-center mt-3 " >
  
  <div className="backbtn"   > 
                    <button
                      name="incmAndMonth"
                      className=""
                      id=""
                      value=""
                      type="button"
                      onClick={backClick}
              
                    ><i className="bi bi-arrow-left"></i> Back</button> */}
        


      </div>
    </>
  )
}
export default Emailconfirmation;