import { useState } from "react";
import { TopSelectedLenders } from "../../../../Constants/Constants";
import RadioButtonQuest from "../../../UI/RadioButtonQuest";
import Autocomplete from "./AutoComplete";

const Banks = ({
  show,
  backClick,
  lenderList,
  SelectBank,
  setSelectBank,
  saveInputAns,
  OldCardbck,
  Latepymnentbck,
  setLenderName,
  validation,
  getValues,
  trigger,
  validationMsg,
  setError,
  clearErrors
}) => {
  const [BankValidationMsg, setBankValidationMsg] = useState("");

  const customStyles = {
    control: (base) => ({
      ...base,
      //height: 50,
      minHeight: 30,
    }),
  };

  const nextSlide = async (e) => {
    let LengthSelectedBank = SelectBank.length;
    let ValueSelectedBank = SelectBank.value;
    let SelectedBankLbel = SelectBank.label;

    if (e.target.name == "bankOptions") {
      const selectedLender = TopSelectedLenders.find(
        (lender) => lender.value === e.target.value
      );
      if (selectedLender) {
        localStorage.setItem("bud_lender", e.target.value);
        setLenderName(selectedLender.label);
        saveInputAns(e);
      }
    } else {
      if (LengthSelectedBank < 1 || ValueSelectedBank == "") {
        setBankValidationMsg("Please select your Bank");
      } else {
        localStorage.setItem("bud_lender", SelectedBankLbel);
        localStorage.setItem("lenderArray", JSON.stringify([SelectBank]));
        setLenderName(ValueSelectedBank);
        saveInputAns(e);
      }
    }
  };

  let BankList = [];

  if (lenderList.length > 0) {
    BankList = BankList.concat(
      lenderList
        .filter(function (val) {
          return (
            !val.display_name.endsWith("Card") &&
            !val.display_name.endsWith("Business") &&
            !val.display_name.endsWith("card") &&
            !val.display_name.endsWith("Cards") &&
            !val.display_name.includes("Barclays") &&
            !val.display_name.includes("NatWest") &&
            !val.display_name.includes("Halifax") &&
            !val.display_name.includes("Lloyds") &&
            !val.display_name.includes("Santander") &&
            !val.display_name.includes("Nationwide") &&
            !val.display_name.includes("HSBC") &&
            !val.display_name.includes("Others")
          );
        })
        .map(function (val) {
          return val.display_name
        })
    );
  }

  const handleNext = async (e) => {
    e.preventDefault();
    const values = getValues();
    const input = values['lenders']
    const otherlender = await trigger("lenders");


    if (input != "" && otherlender) {
      localStorage.setItem("bud_lender", input);
      setLenderName(input);
      saveInputAns(e);
    } else {
      setError("lenders", {
        type: "manual",
        message: "Please Enter Valid Bank Name",
      });
    }
  }

  return (
    <div id="bankNamediv" className={`question-content mt-0 scale-down-ver-center  px-3 py-lg-3 py-md-3 py-sm-2 py-2 ${show}`} >
      <div className="row mb-3">
        <div className="mb-2">
          <ul className="p-0 m-0 option-grid">
            {TopSelectedLenders.map((lender, index) => (
              <div className="optionbtn">
                <li key={lender.label}>
                  <RadioButtonQuest
                    type="button"
                    //labelClassName={`${checkedOne}`}
                    id={`bankOption${index + 1}`}
                    name="bankOptions"
                    value={lender.value}
                    labelName={lender.label}
                    onClick={nextSlide}
                    dataQuestId={64}
                  >
                    {lender.label}
                  </RadioButtonQuest>
                </li>
              </div>
            ))}
          </ul>
        </div>
      </div>
      <div className="row">
        <div className="col-lg-12 mb-2">
          <div className="row">
            <div className="col-lg-8 mb-2 z-2 autocomplete-wrapper">
              <Autocomplete
                validation={validation}
                clearErrors={clearErrors}
                suggestionList={BankList}
                setSelectBank={setSelectBank}
              />
            </div>

            <div className="col-lg-4 mb-2">
              <button
                type="button"
                className=" btn-comm2 py-lg-3 py-md-3 py-sm-2 py-2 w-100 nextBtn"
                id="submitLender"
                onClick={handleNext}
                name="banks"
                data-quest-id={64}
              >
                Next &#8594;
              </button>
            </div>
          </div>
          {validationMsg.lenders && (
            <span id="bank_err" className="error_msg">
              {validationMsg.lenders.message}
            </span>
          )}
        </div>

        <p className="text-center bck-marwid">
          <button
            name="bankruptcy"
            className="btn btn-secondary backbtn1a"
            id=""
            value=""
            type="button"
            onClick={backClick}
          >
            Go Back
          </button>
        </p>
      </div>
    </div>
  );
};
export default Banks;
