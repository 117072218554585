import React from 'react'

const EverIncreCredLimit = ({
    setEverIncreased,
    onSelectAnswer,
    backClick }) => {
    const optionHandle = async (e) => {
 
        if (e.target.value === "247") {
            setEverIncreased("247");
            onSelectAnswer(e);
        } else if (e.target.value === "248") {
            setEverIncreased("248");
            onSelectAnswer(e);
        }

    }
    return (
        <div className="question-content mt-0 scale-down-ver-center px-3 py-lg-3 py-md-3 py-sm-2 py-2 ">
            <div className="row">
                <div className="col-6">
                    <div className=" mb-2">
                        <button
                            type="button"
                            className={`btn-comm2 py-lg-3 py-md-3 py-sm-2 py-2 w-100 nextBtn`}
                            onClick={optionHandle}
                            value="247"
                            name="everincresedCredLimit"
                            id="nextscroll"
                            data-quest-id={92}
                            data-ans-id={247}
                            data-ans-label={null}
                        >Yes
                        </button>
                    </div>
                </div>
                <div className="col-6">
                    <div className=" mb-2">
                        <button
                            type="button"
                            className={`btn-comm2 py-lg-3 py-md-3 py-sm-2 py-2 w-100 noissue`}
                            onClick={optionHandle}
                            value="248"
                            name="everincresedCredLimit"
                            id="nextscroll"
                            data-quest-id={92}
                            data-ans-id={248}
                            data-ans-label={null}
                        >No
                        </button>
                    </div>
                </div>
            </div>
            <p className="text-center bck-marwid">
                <button
                    name="openingCreditLimit"
                    className="mt-1 btn btn-secondary backbtn1a"
                    id=""
                    value=""
                    type="button"
                    onClick={backClick}
                >
                    Go Back
                </button>
            </p>
        </div>
    )
}

export default EverIncreCredLimit;