import React, { useState } from "react";

const DidReceiveIncome = ({
  className,
  saveQuestAnswer,
  addPending,
  removeFromPending,
  qnNo
}) => {
  const [checkedOne, setCheckedOne] = useState("unchecked");
  const [checkedTwo, setCheckedTwo] = useState("unchecked");

  const optionSelected = async (e) => {
    if (e.target.value === "226") {
      setCheckedOne("checked");
      addPending("monthlyIncome");
      removeFromPending("receiveIncome");
    } else {
      setCheckedOne("unchecked");
    }

    if (e.target.value === "227") {
      setCheckedTwo("checked");
      removeFromPending("receiveIncome");
      removeFromPending("monthlyIncome");
    } else {
      setCheckedTwo("unchecked");
    }
    saveQuestAnswer(77, e.target.value);
  };

  return (
    <>
      <div className={`container ${className}`}>
        <div className="questitem mt-5 scale-down-ver-center px-3 py-lg-3 py-md-3 py-sm-2 py-2 pb-3 ">
          <h1 className="que-title p-3 ">
            In the year when you took the card - did you receive any income?
          </h1>
          <div className="row">
            <div className="col-6">
              <div className=" mb-2">
                <button
                  type="button"
                  className={`qb17 btn-comm2 py-lg-3 py-md-3 py-sm-2 py-2 w-100 nextBtn ${checkedTwo}`}
                  onClick={optionSelected}
                  value="226"
                  name="didReceiveIncome"
                  id="nextscroll"
                  data-quest-id={77}
                  data-ans-id={226}
                  data-ans-label={null}
                  data-click-id='receiveIncome_yes'
                >
                  Yes
                </button>
              </div>
            </div>
            <div className="col-6">
              <div className=" mb-2">
                <button
                  type="button"
                  className={`qb17 btn-comm2 py-lg-3 py-md-3 py-sm-2 py-2 w-100 nextBtn ${checkedOne}`}
                  value="227"
                  name="didReceiveIncome"
                  onClick={optionSelected}
                  id="nextscroll"
                  data-quest-id={77}
                  data-ans-id={227}
                  data-ans-label={null}
                  data-click-id='receiveIncome_no'
                >
                  No
                </button>
              </div>
            </div>
          </div>
          <p className="text-center bck-marwid">
            <button
              name="minmumPaymnt"
              className="btn btn-secondary backbtn1a"
              id=""
              value=""
              type="button"
              onClick={() => addPending("minPayment")}
              data-click-id='receiveIncome_back'
            >
              Go Back
            </button>
          </p>
        </div>
        <div
          id="rangequestions"
          class="qrange d-flex align-items-sm-center text-center"
        >
          Question <br class="d-none d-md-block" />{qnNo}
        </div>
      </div>
    </>
  );
};
export default DidReceiveIncome;
