import React from 'react'

const SufferedFinancialHardship = ({
    onSelectAnswer,
    exceedCredit,
    backClick }) => {
    const optionHandle = async (e) => {

        if (e.target.value === "266") {
            onSelectAnswer(e);
        } else if (e.target.value === "265") {
            onSelectAnswer(e);
        }

    }
    let toggle = "";
    if (exceedCredit == "252"){
        toggle = "exceedCredLimit";
    }else{
        toggle = "overlimit";
    }
    return (
        <div className="question-content mt-0 scale-down-ver-center px-3 py-lg-3 py-md-3 py-sm-2 py-2 ">
            <div className="row">
                <div className="col-6">
                    <div className=" mb-2">
                        <button
                            type="button"
                            className={`btn-comm2 py-lg-3 py-md-3 py-sm-2 py-2 w-100 nextBtn`}
                            onClick={optionHandle}
                            value="265"
                            name="financialhardship"
                            id="nextscroll"
                            data-quest-id={98}
                            data-ans-id={265}
                            data-ans-label={"Agree"}
                        >Agree
                        </button>
                    </div>
                </div>
                <div className="col-6">
                    <div className=" mb-2">
                        <button
                            type="button"
                            className={`btn-comm2 py-lg-3 py-md-3 py-sm-2 py-2 w-100 noissue`}
                            onClick={optionHandle}
                            value="266"
                            name="financialhardship"
                            id="nextscroll"
                            data-quest-id={98}
                            data-ans-id={266}
                            data-ans-label={"Disagree"}
                        >Disagree
                        </button>
                    </div>
                </div>
            </div>
            <p className="text-center bck-marwid">
                <button
                    name={toggle}
                    className="btn btn-secondary backbtn1a"
                    id=""
                    value=""
                    type="button"
                    onClick={backClick}
                >
                    Go Back
                </button>
            </p>
        </div>
    )
}

export default SufferedFinancialHardship;