import React, { useState, useEffect, useContext } from "react";
import { Api } from "../../api/Api";
import { queryString } from "../../Utility/QueryString";
import { useHistory } from "react-router-dom";
import Loader from "../Includes/Layouts/FollowupS1/Loader";
import IdFollowup from "../Includes/Layouts/FollowupS1/IdFollowup";
import { useFollowupLoad } from "../../Hooks/useFollowupLoad";
import SignatureForm from "../Includes/Layouts/FollowupS1/SignatureForm";
import MoneyBarnQuestionForm from "../Includes/Layouts/FollowupS1/MoneyBarnQuestionForm";
import LendingStreamQuestionForm from "../Includes/Layouts/FollowupS1/LendingStreamQuestionForm";
import NewDayQuestionForm from "../Includes/Layouts/FollowupS1/NewDayQuestionForm";
import VeryQuestionForm from "../Includes/Layouts/FollowupS1/VeryQuestionForm";
import VanquisQuestionForm from "../Includes/Layouts/FollowupS1/VanquisQuestionForm";
import VanquisQuestionFormTwoV2 from "../Includes/Layouts/FollowupS1/VanquisQuestionFormTwoV2";
import MoneyBarnV3QuestionForm from "../Includes/Layouts/FollowupS1/MoneyBarnV3QuestionForm";
import NewDayV2QuestionForm from "../Includes/Layouts/FollowupS1/NewDayV2QuestionForm";
import "../../assets/css/FollowupS1.scss";
import VisitorParamsContext from "../../Contexts/VisitorParams";
import SignatureFormVAQ from "../Includes/Layouts/FollowupS1/SignatureFormVAQ";
import SignatureFormNDY from "../Includes/Layouts/FollowupS1/SignatureFormNDY";
import VanquisQuestionFormTwoV4 from "../Includes/Layouts/FollowupS1/VanquisQuestionFormTwoV4";
const FollowupS1 = () => {
  const [showLoad, setshowLoad] = useState("show");
  const [showPage, setshowPage] = useState("hide");
  const [allFollowupData, setFollowupData] = useState(false);
  const [sigDiv, setSigDiv] = useState(false);
  const { loadFollowup } = useFollowupLoad();
  const [splitName, setSplitName] = useState(false);
  const [user_uuid, setUser_uuid] = useState(false);
  const [moneyBarn, setMoneyBarn] = useState(false);
  const [moneyBarnV3, setMoneyBarnV3] = useState(false);
  const [lendingStream, setLendingStream] = useState(false);
  const [qstnDiv, setQstnDiv] = useState(false);
  const [pendingQuestionnaire, setPendingQuestionnaire] = useState([]);
  const [t2aApiReqData, setT2aApiReqData] = useState({});
  const [t2aStatus, setT2aStatus] = useState("");
  const [validKeeperDate, setValidKeeperDate] = useState("");
  const [keeperDates, setKeeperDates] = useState([]);
  const [userVehicle, setUserVehicle] = useState([]);
  const history = useHistory();
  const [idUploadFlp, setIdUploadFlp] = useState(false);
  const [previewPage, setPreviewPage] = useState("thankyou");
  const [newDay, setnewDay] = useState(false);
  const [newDayV2, setnewDayV2] = useState(false);
  const [newDayData, setNewDayData] = useState(false);
  const [veryQuest, setVeryQuest] = useState(false);
  const [vanquisQuest, setVanquisQuest] = useState(false);
  const [vanquisQuestTwo, setVanquisQuestTwo] = useState(false);
  const [userEmail, setUserEmail] = useState();
  const { visitorParameters } = useContext(VisitorParamsContext);
  const [vaqSignDiv, setVAQsignDiv] = useState(false);
  const [ndySignDiv, setNDYsignDiv] = useState(false);
  const [vanquisQuestfour, setVanquisQuestfour] = useState(false);
  const [productId, setProductId] = useState(false);
  const [checklistOptions, setChecklistOptions] = useState([]);
  const [cardOpenYear,setCardopenYear] = useState([]);
  const [isLoan, setIsLoan] = useState(false);
  

  let split_name = "";

  useEffect(() => {
    (async () => {
      const response = await Api.get(
        "/api/v1/followup/get-pending-details-s1?query_string=" + queryString,
        {}
      );
      if (response.data.status === "Success") {
        let followupDetails = response.data.response.followup_data;
        localStorage.setItem('allFollowupData', JSON.stringify(followupDetails));
        let uuid = response.data.response.uuid;
        localStorage.setItem('uuid',uuid);
        let completed = response.data.response.completed;
        let signStatus = response.data.response.signed_status;
        let slidePending = response.data.response.qstn_slides;
        let pendingLength = slidePending?.length;
        let qstnStatus = response.data.response.question_status;
        let split_name = response.data.response.split_name;
        let fname = response.data.response.first_name;
        let lname = response.data.response.last_name;
        let postcode = response.data.response.postcode;
        let address_line1 = response.data.response.address_line1;
        let t2a_result = response.data.response.t2a_status;
        let keeper_dates = response.data.response.keeper_date;
        let vehicle = response.data.response.vehicle_name;
        let dob_day = response.data.response.dob_day;
        let dob_month = response.data.response.dob_month;
        let dob_yr = response.data.response.dob_yr;
        let page = "complete";
        let loan_yr = response.data.response.loan_year;
        let email = response.data.response.email;
        let lender = response.data.response.lender;
        let address_line2 = response.data.response.address_line2;
        let town = response.data.response.town;
        let country = response.data.response.country;
        let title = response.data.response.title;
        let addressLine3 = response.data.response.address_line3;
        let addressLine4 = response.data.response.address_line4;
        let county = response.data.response.county;
        let product_id = response.data.response.product_id;
        let InEOptions = response.data.response.InEOptions;
        let cardOpenYear = response.data.response.cardOpenYear;
        let question62 = response.data.response.question62;
        let isLoanRespons = question62 == 167 ? true : false;
        setIsLoan(isLoanRespons);


        localStorage.setItem("split_name", split_name);
        localStorage.setItem("t2a_result", t2a_result);
        localStorage.setItem("product_id", product_id);
        let ucFname = fname.charAt(0).toUpperCase() + fname.slice(1);
        localStorage.setItem("UcFname", ucFname);

        visitorParameters.email = email || false;

        


        setT2aApiReqData({
          uuid: uuid,
          fName: fname,
          lName: lname,
          pCode: postcode,
          addressLine1: address_line1,
          day: dob_day,
          month: dob_month,
          year: dob_yr,
          addressLine2: address_line2,
          postTown: town,
          country: country,
          title: title,
          addressLine3: addressLine3,
          addressLine4: addressLine4,
          county: county


        });

        setNewDayData({
          loan_year: loan_yr,
          email: email,
          lender: lender
        })
        
        setCardopenYear(cardOpenYear);
        setT2aStatus(t2a_result);
        setFollowupData(followupDetails);
        setSplitName(split_name);
        setPendingQuestionnaire(slidePending);
        setKeeperDates(keeper_dates);
        setUserVehicle(vehicle);
        setUser_uuid(uuid);
        setUserEmail(email);
        setProductId(product_id);

        if(InEOptions != ""){
        setChecklistOptions(InEOptions);
        }
        
        if (completed == 1) {
          page = "complete";
        }

        if (signStatus == 0) {
          page = "signature";
        } else if (qstnStatus == 0 && pendingLength >= 1) {
          page = "question";
        } else if (t2a_result == 0) {
          page = "idUpload";
        } else {
          console.log("no data");
        }

        setTimeout(() => {
          setDivShow(page, product_id, uuid);
          setshowLoad("hide");
        }, 2000);

        const flpResponse = await loadFollowup(
          "followup_load",
          followupDetails,
          split_name,
          queryString,
          "v2"
        );
      } else {
        history.push("/thankyou?uuid=" + response.data.response.uuid);
        return false;
      }
    })();
  }, []);

  const setDivShow = (page, productId, uuid) => {
    if (page == "complete") {
      if(productId == '4'){
        const split_name = localStorage.getItem("split_name");
        if (split_name == 'VAQ_UB_4_3_1' || split_name == 'VAQ_UB_4_3_2') {
          history.push("/PreviewVAQ_UB_4_3_1?uuid=" + uuid);
          return false;
        } else {
          history.push("/PreviewVAQ4?uuid=" + uuid);
          return false;
        }
      }else if(productId == '5'){
        history.push("/preview-newday-flp?uuid=" + uuid);
        return false;
      }else{
        history.push("/thankyou?uuid=" + uuid);
      }
    }

    if (page == "signature") {
      showSigForm(productId);
    } else if (page == "question") {
      showQuestion(productId);
    } else if (page == "idUpload") {
      setQstnDiv(false);
      setIdUploadFlp(true);
      if(productId == '4'){
        const split_name = localStorage.getItem("split_name");
        if (split_name == 'VAQ_UB_4_3_1'  || split_name == 'VAQ_UB_4_3_2') {
          setPreviewPage("PreviewVAQ_UB_4_3_1");
        } else {
          setPreviewPage("PreviewVAQ4");
        }
      }else if(productId == '5'){
        setPreviewPage("preview-newday-flp");
      }
    } else {
      history.push("/thankyou?uuid=" + uuid);
    }
  };

  const showSigForm =(productId) => {
    if (productId == "4") {
      setVAQsignDiv(true);
    } else if (productId == "5") {
      setNDYsignDiv(true);
    } else {
      setSigDiv(true);
      }
  }

  const showQuestion = (productId) => {
    setNDYsignDiv(false);
    setVAQsignDiv(false);
    setSigDiv(false);
    setQstnDiv(true);
    setPreviewPage("view_docs_flp");

    if(productId == 2){
      setLendingStream(true)
    }else if(productId == 3){
      setVeryQuest(true);
    }else if(productId == 4){
      setVanquisQuestfour(true);
    }else if(productId == 5){
      setnewDayV2(true);
    }else {
      history.push("/thankyou?uuid=" + user_uuid);
    }
    
    // if (split_name == "MB_UBRF_1") {
    //   setMoneyBarn(true);
    // } else if (split_name == "MB_OBV1") {
    //   setMoneyBarnV3(true);
    // } else if (split_name == "MB_UBRF_3") {
    //   setMoneyBarnV3(true);
    // }
    //   else if (split_name == "LS_UB_1") {
    //   setLendingStream(true);
    // } else if (split_name == "NDY_UB_1") {
    //   setnewDay(true);
    // } else if (split_name == "NDY_UB_2" || split_name == "NDY_OB_UB_2_1" ) {
    //   setnewDayV2(true);
    // } else if (split_name == "VCC_UB_1" || split_name == "VCC_UB_2" || split_name == "VCC_UB_3") {
    //   setVeryQuest(true);
    // } else if (split_name == "VAQ_UB_1") {
    //   setVanquisQuest(true);
    // } else if (split_name == "VAQ_UB_2" || split_name == "VAQ_UB_3"  || split_name == "VAQ_OB_UB_3_1") {
    //   setVanquisQuestTwo(true);
    // } else if( split_name == "VAQ_UB_4" || split_name == "VAQ_UB_4_2" || split_name == "VAQ_UB_4_3"){
    //   setVanquisQuestfour(true)
    // }else {
    //   history.push("/thankyou?uuid=" + user_uuid);
    // }
  };

  const setLoader = (e) => {
    setshowLoad("show");
  };

  const hideLoader = (e) => {
    setshowLoad("hide");
    setshowPage("show");
  };

  return (
    <>
      <div className="FollowupS1">
        <Loader showLoad={showLoad}></Loader>
        {/* <div className={showLoad === "show" ? 'hide' : 'show' }>{viewData}</div> */}
        
        {sigDiv ? (
          <SignatureForm
            allFollowupData={allFollowupData}
            showQuestion={showQuestion}
            productId={productId}
          />
        ) : null}

        {vaqSignDiv ? (
          <SignatureFormVAQ
            allFollowupData={allFollowupData}
            showQuestion={showQuestion}
            productId={productId}
            uuid = {user_uuid}
          />
        ) : null}

        {ndySignDiv ? (
          <SignatureFormNDY
            allFollowupData={allFollowupData}
            showQuestion={showQuestion}
            productId={productId}
            uuid = {user_uuid}
          />
        ) : null}

        {moneyBarn && qstnDiv ? (
          <MoneyBarnQuestionForm
            allFollowupData={allFollowupData}
            pendingQuestionnaire={pendingQuestionnaire}
            setLoader={setLoader}
            hideLoader={hideLoader}
            splitName={splitName}
            currentUuid={user_uuid}
            setValidKeeperDate={setValidKeeperDate}
            validKeeperDate={validKeeperDate}
            keeperDates={keeperDates}
            userVehicle={userVehicle}
            setPendingQuestionnaire={setPendingQuestionnaire}
            setDivShow={setDivShow}
            t2aApiReqData={t2aApiReqData}
            t2aStatus={t2aStatus}
          />
        ) : null}
        {moneyBarnV3 && qstnDiv ? (
          <MoneyBarnV3QuestionForm
            allFollowupData={allFollowupData}
            pendingQuestionnaire={pendingQuestionnaire}
            setLoader={setLoader}
            hideLoader={hideLoader}
            splitName={splitName}
            currentUuid={user_uuid}
            setValidKeeperDate={setValidKeeperDate}
            validKeeperDate={validKeeperDate}
            keeperDates={keeperDates}
            userVehicle={userVehicle}
            userEmail={userEmail}
            setPendingQuestionnaire={setPendingQuestionnaire}
            setDivShow={setDivShow}
            t2aApiReqData={t2aApiReqData}
            t2aStatus={t2aStatus}
          />
        ) : null}
        {lendingStream && qstnDiv ? (
          <LendingStreamQuestionForm
            allFollowupData={allFollowupData}
            pendingQuestionnaire={pendingQuestionnaire}
            setLoader={setLoader}
            hideLoader={hideLoader}
            currentUuid={user_uuid}
            t2aApiReqData={t2aApiReqData}
            setPendingQuestionnaire={setPendingQuestionnaire}
            t2aStatus={t2aStatus}
            setDivShow={setDivShow}
            splitName={splitName}
          />
        ) : null}
        {newDay && qstnDiv ? (
          <NewDayQuestionForm
            allFollowupData={allFollowupData}
            pendingQuestionnaire={pendingQuestionnaire}
            setLoader={setLoader}
            hideLoader={hideLoader}
            currentUuid={user_uuid}
            t2aApiReqData={t2aApiReqData}
            setPendingQuestionnaire={setPendingQuestionnaire}
            t2aStatus={t2aStatus}
            setDivShow={setDivShow}
            splitName={splitName}
            newDayData={newDayData}
          />
        ) : null}
        {newDayV2 && qstnDiv ? (
          <NewDayV2QuestionForm
            allFollowupData={allFollowupData}
            pendingQuestionnaire={pendingQuestionnaire}
            setLoader={setLoader}
            hideLoader={hideLoader}
            currentUuid={user_uuid}
            t2aApiReqData={t2aApiReqData}
            setPendingQuestionnaire={setPendingQuestionnaire}
            t2aStatus={t2aStatus}
            setDivShow={setDivShow}
            splitName={splitName}
            newDayData={newDayData}
            setChecklistOptions={setChecklistOptions}
            checklistOptions={checklistOptions}
          />
        ) : null}
        {veryQuest && qstnDiv ? (
          <VeryQuestionForm
            allFollowupData={allFollowupData}
            pendingQuestionnaire={pendingQuestionnaire}
            setLoader={setLoader}
            hideLoader={hideLoader}
            currentUuid={user_uuid}
            t2aApiReqData={t2aApiReqData}
            setPendingQuestionnaire={setPendingQuestionnaire}
            t2aStatus={t2aStatus}
            setDivShow={setDivShow}
            splitName={splitName}
            splitData={newDayData}
          />
        ) : null}
        {vanquisQuest && qstnDiv ? (
          <VanquisQuestionForm
            allFollowupData={allFollowupData}
            pendingQuestionnaire={pendingQuestionnaire}
            setLoader={setLoader}
            hideLoader={hideLoader}
            currentUuid={user_uuid}
            t2aApiReqData={t2aApiReqData}
            setPendingQuestionnaire={setPendingQuestionnaire}
            t2aStatus={t2aStatus}
            setDivShow={setDivShow}
            splitName={splitName}
            splitData={newDayData}
          />
        ) : null}
        {vanquisQuestTwo && qstnDiv ? (
          <VanquisQuestionFormTwoV2
            allFollowupData={allFollowupData}
            pendingQuestionnaire={pendingQuestionnaire}
            setLoader={setLoader}
            hideLoader={hideLoader}
            currentUuid={user_uuid}
            t2aApiReqData={t2aApiReqData}
            setPendingQuestionnaire={setPendingQuestionnaire}
            t2aStatus={t2aStatus}
            setDivShow={setDivShow}
            splitName={splitName}
            splitData={newDayData}
          />
        ) : null}
         {vanquisQuestfour && qstnDiv ? (
          <VanquisQuestionFormTwoV4
            allFollowupData={allFollowupData}
            pendingQuestionnaire={pendingQuestionnaire}
            setLoader={setLoader}
            hideLoader={hideLoader}
            currentUuid={user_uuid}
            t2aApiReqData={t2aApiReqData}
            setPendingQuestionnaire={setPendingQuestionnaire}
            t2aStatus={t2aStatus}
            setDivShow={setDivShow}
            splitName={splitName}
            cardOpenYear={cardOpenYear}
            setCardopenYear={setCardopenYear}
            splitData={newDayData}
            setChecklistOptions={setChecklistOptions}
            checklistOptions={checklistOptions}
            isLoan={isLoan}
          />
        ) : null}
      </div>
      {idUploadFlp ? (
        <IdFollowup
          allFollowupData={allFollowupData}
          currentUuid={user_uuid}
          previewPage={previewPage}
        />
      ) : null}
    </>
  );
};

export default FollowupS1;
