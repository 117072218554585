import iconOne from "../../assets/VAQ_UB_4_3_1_C2/img/icon01.svg";
import iconTwo from "../../assets/VAQ_UB_4_3_1_C2/img/icon02.svg";
import iconThree from "../../assets/VAQ_UB_4_3_1_C2/img/icon03.svg";
import logo from "../../assets/VAQ_UB_4_3_1_C2/img/logo-white.png";
import banner from "../../assets/VAQ_UB_4_3_1_C2/img/banner-img.png";
import secure from "../../assets/VAQ_UB_4_3_1_C2/img/secure-filed.png";
import alert from "../../assets/VAQ_UB_4_3_1_C2/img/alert.png";
import { Link } from "react-router-dom";
import PersonDetails from "../Includes/Layouts/VAQ_UB_4_3_1_C2/PersonDetails";
import { FormProvider, useForm } from "react-hook-form";
import { useContext, useState } from "react";
import { useClaimReg } from "../../Hooks/useClaimReg";
import VisitorParamsContext from "../../Contexts/VisitorParams";
import GtmDataLayer from "../../Utility/GtmDataLayer";

export default function VAQ_UB_4_3_1_C2() {

  const { visitorParameters } = useContext(VisitorParamsContext);
  const formMethods = useForm({
    mode: "onBlur",
    reValidateMode: "onChange",
    defaultValues: {},
    resolver: undefined,
    context: undefined,
    criteriaMode: "firstError",
    shouldFocusError: true,
    shouldUnregister: true,
  });
  const { updateClaimReg } = useClaimReg();
  const [fromDiv,setFormDiv]=useState('');

  const handleStart = async () => {
    GtmDataLayer({
      question: "start claim",
    });
    const claimReg = await updateClaimReg(
      visitorParameters?.visitor_parameters?.uuid,
      "VAQ_UB_4_3_1_C2",
      "register_claim"
    );
    if ("Success"==claimReg?.data?.status) {
      setFormDiv('is-open');
    }
  };

  return (
    <>
      <FormProvider {...formMethods}>
        <section className="banner id-upload">
          <div className="container">
            <div>
              <div className="brand">
                <div className="logo pt-3">
                  <Link to="/VAQ_UB_4_3_1_C2">                   
                    <img src={logo} height={60} alt="" />
                  </Link>
                </div>
              </div>
            </div>
            <div className="container-xxl">
              <div className="banner-inner">
                <div className="row">
                  <div className="col-lg-7 col-md-7">
                    <div className="banner-caption">
                      <div className="overflow-hidden">
                        <h1 className="fw-bold  drop-in tex-lg-start text-md-start text-sm-center text-center">
                          Taken Out Repeated High-Cost Credit from Vanquis?
                        </h1>
                      </div>
                      <div className="hilighttext-homepage drop-in">
                        <p className="drop-in">
                          Our Average Refund for Vanquis as of&nbsp;      
                          <strong>
                            20/08/2024 is
                            <span className="mark_bg">£2,200</span>
                          </strong>
                        </p>
                      </div>
                      <div className="overflow-hidden">
                        <p className="py-lg-3 py-md-3 py-sm-3 py-1 drop-in-2 subtext tex-lg-start text-md-start text-sm-center text-center mb-1">
                          If you found the credit to be unaffordable and
                          struggled to make your repayments, you could make a
                          claim to see if you are owed a refund of any interest
                          and charges.
                        </p>
                      </div>
                      <div className="overflow-hidden  tex-lg-start text-md-start text-sm-center  text-center mt-lg-0 mt-md-0 mt-sm-0 mt-3">
                        <button
                          className="btn-comm2  drop-in-3 zoom-in-zoom-out  widmob zoom"
                          id="claimNow"
                          onClick={handleStart}
                        >
                          <span>
                            Click Here to Start
                            <i className="bi bi-caret-right-fill" />
                          </span>
                        </button>
                        <img
                          className="drop-in-3 m-3 mt-2"
                          src={secure}
                          alt=""
                        />
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-5 col-md-5">
                    <div className="bannerimage-wraper tex-lg-start text-md-start text-sm-center text-center mt-lg-0 mt-md-0 mt-sm-0 mt-5">
                      <img className="bannerimg fade-in" src={banner} alt="" />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        {/* End Banner */}
        <section className="welcome-highlights py-5">
          <div className="container">
            <div className="row">
              <div className="col-lg-4 col-md-4 col-sm-12 text-center mb-3">
                <div className="px-lg-3 px-md-0 px-sm-0 px-0 items">
                  <img src={iconOne} alt="" />
                  <h2 className="mt-3">
                    Get your Mis-sold Loan Fees and Charges back
                  </h2>
                  <p>
                    Many loans were not sold properly. You could claim back the
                    fees and charges plus 8% interest.
                  </p>
                </div>
              </div>
              <div className="col-lg-4 col-md-4 col-sm-12 text-center mb-3">
                <div className="px-lg-3 px-md-0 px-sm-0 px-0 items">
                  <img src={iconTwo} alt="" />
                  <h2 className="mt-3">
                    Your Claim Fought by Financial &amp; Legal Experts
                  </h2>
                  <p>
                    Many loans were not sold properly. You could claim back the
                    fees and charges plus 8% interest.
                  </p>
                </div>
              </div>
              <div className="col-lg-4 col-md-4 col-sm-12 text-center mb-3">
                <div className="px-lg-3 px-md-0 px-sm-0 px-0 items">
                  <img src={iconThree} alt="" />
                  <h2 className="mt-3">Respecting Your Information Security</h2>
                  <p>
                    Many loans were not sold properly. You could claim back the
                    fees and charges plus 8% interest.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </section>

        {/*  Modal  */}
        <div
          className="modal fade"
          id="CredModal"
          data-bs-backdrop="static"
          data-bs-keyboard="false"
          tabIndex={-1}
          aria-labelledby="staticBackdropLabel"
          aria-hidden="true"
        >
          <div className="modal-dialog modal-dialog-centered credimodal">
            <div className="modal-content">
              <div className="modal-header py-3">
                <button
                  type="button"
                  className="btn-close"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                />
              </div>
              <div className="modal-body  creditpopup">
                <div className="pb-3">
                  <div className="row align-items-center">
                    <div className="col-lg-12 col-md-12 col-sm-3 col-2 text-center">
                      <div className="mb-lg-3 mb-md-3 mb-sm-0 mb-0">
                        
                        <img className="alertimg" src={alert} height={50} alt />
                      </div>
                    </div>
                    <div className="col-lg-12 col-md-12 col-sm-9 col-10">
                      <h6 className="  mb-0 text-lg-center text-md-center text-sm-start text-start px-lg-3 px-md-3 px-sm-0 px-0">
                        Credit check will only be accessible as the final step.
                      </h6>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* End Modal  */}
        {/* Personal Details */}
        <PersonDetails divShow={fromDiv} />
      </FormProvider>
    </>
  );
}
