import { useState } from "react";
// import { useFormContext } from "react-hook-form";
import Select from "react-select";
import { TopSelectedLenders, lenderSuggest } from "../../../../Constants/Constants";
import RadioButtonQuest from "../../../UI/RadioButtonQuest";
import Autocomplete from "./AutoComplete";

const Banks = ({
    lenderList,
    SelectBank,
    setSelectBank,
    className,
    setLenderName,
    lenderName,
    onSelectAnswer,
    saveInputAns,
    getValues,
    setError,
    validationMsg,
    trigger,
    validation,
    clearErrors
}) => {

    const customStyles = {
        control: (base) => ({
            ...base,
            minHeight: 30,
        }),
        menu: (base) => ({
            ...base,
            zIndex: 9999,
        }),
        menuList: (base) => ({
            ...base,
            zIndex: 9999,
        }),
    };

    const nextSlide = async (e) => {
        let ValueSelectedBank = SelectBank;

        if (e.target.name == "bankOptions") {

            const selectedLender = TopSelectedLenders.find(
                lender => lender.value === e.target.value
            );
            if (selectedLender) {
                localStorage.setItem("bud_lender", e.target.value);
                setLenderName(selectedLender.label);
                saveInputAns(e);
            }

            // // setSelectBank(e.target.value)
            // localStorage.setItem("bud_lender", e.target.value);
            // // localStorage.setItem("lenderArray", JSON.stringify([TopSelectedLenders]));
            // setLenderName(e.target.labelName);
            // saveInputAns(e);

        } else {
                localStorage.setItem("bud_lender", ValueSelectedBank);
                localStorage.setItem("lenderArray", JSON.stringify([SelectBank]));
                setLenderName(ValueSelectedBank);
                saveInputAns(e);
  
        }
    };

    const OtherLendfn = async (e) => {
        const input = document.getElementById('otherlenders').value;

        e.preventDefault();
        const otherlender = await trigger("otherlenders");

        if (input != "" && otherlender) {
            localStorage.setItem("bud_lender", input);
            setLenderName(input);
            saveInputAns(e);
        } else {
            setError("otherlenders", {
                type: "manual",
                message: "Please Enter Valid Bank Name",
            });
        }
    };

    let BankList = [];

    if (lenderList.length > 0) {
        BankList = BankList.concat(
            lenderList
                .filter(function (val) {
                    return (
                        !val.display_name.endsWith("Card") &&
                        !val.display_name.endsWith("Business") &&
                        !val.display_name.endsWith("card") &&
                        !val.display_name.endsWith("Cards") &&
                        !val.display_name.includes("Barclays") &&
                        !val.display_name.includes("NatWest") &&
                        !val.display_name.includes("Halifax") &&
                        !val.display_name.includes("Lloyds") &&
                        !val.display_name.includes("Santander") &&
                        !val.display_name.includes("Nationwide") &&
                        !val.display_name.includes("HSBC")&&
                        !val.display_name.includes("Others")
                    );
                })
                .map(function (val) {
                   return val.display_name;
                })
        );
    }



    return (
        <>

            <div className={`container mt-5 ${className}`}>

                <div
                    className={`bg-lender mt-5"`}
                    id="slide-21"
                >
                    <h4 className="px-3 pt-3 fs-5 mb-0"> Your bank transactions provides compelling evidence of your repayment with Vanquis, and also acts as a solid proof to support your hardship and struggles.</h4>
                    <h1 className="que-title qt-1 p-3 mt-0">
                        Please select who were you banking with when you opened your account with Vanquis.
                    </h1>
                    <div
                        id="bankNamediv"
                        className={`question-content mt-0 scale-down-ver-center px-3 py-lg-3 py-md-3 py-sm-2 py-2 `}
                    >
                        <div className="row mb-3">
                            <div className="mb-2">
                                <ul className="p-0 m-0 option-grid">
                                    {TopSelectedLenders.map((lender, index) => (
                                        <li key={lender.label}>
                                            <div className="optionbtn">

                                                <RadioButtonQuest
                                                    type="button"
                                                    //labelClassName={`${checkedOne}`}
                                                    id={`bankOption${index + 1}`}
                                                    name="bankOptions"
                                                    value={lender.value}
                                                    labelName={lender.label}
                                                    onClick={nextSlide}
                                                    dataQuestId={64}
                                                >
                                                    {lender.label}
                                                </RadioButtonQuest>
                                            </div>

                                        </li>
                                    ))}
                                </ul>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-lg-12 mb-2">
                                <div className="row">
                                    <div className="col-lg-8 mb-2 z-3  autocomplete-wrapper">
                                        <Autocomplete 
                                        validation={validation}  
                                        suggestionList={BankList} 
                                        setSelectBank={setSelectBank}
                                        clearErrors={clearErrors}
                                        />
                                        
                                        {validationMsg.otherlenders && (
                                            <span id="bank_err" className="error_msg">
                                                {validationMsg.otherlenders.message}
                                            </span>
                                        )}
                                    </div>
                                    <div className="col-lg-4 mb-2">
                                        <button
                                            type="button"
                                            className=" btn-comm2 py-lg-3 py-md-3 py-sm-2 py-2 w-100 nextBtn "
                                            id="submitLender"
                                            onClick={OtherLendfn}
                                            name="bankname"
                                            data-quest-id={64}
                                        >
                                            Next &#8594;
                                        </button>
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>

                </div>
            </div>

        </>
    );
};
export default Banks;
