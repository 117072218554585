import React, { useState, useEffect, useContext } from "react";
import TextField from "../../../UI/TextField";
import { useQuestionnaire } from "../../../../Hooks/useQuestionnaire";
import VisitorParamsContext from "../../../../Contexts/VisitorParams";
import { useSkippedLeads } from "../../../../Hooks/useSkippedLeads";

export default function AgreementNoPopup({
  setAgreementPopup,
  setSupportDocPopup,
  setSupportDocSucess,
}) {
  const { visitorParameters } = useContext(VisitorParamsContext);
  const { skippedLeads } = useSkippedLeads();
  const { isLoading, SaveQuestionnaire } = useQuestionnaire();
  const [errorMessage, setErrorMessage] = useState("");
  const [agreementNo, setAgreementNo] = useState("");

  const query_string = localStorage.getItem("querystring");
  const formdata = JSON.parse(localStorage.getItem("formData"));
  const split_name = localStorage.getItem("split_name");

  const agreementValidation = () => {
    const digitsOnly = /^\d*[1-9]\d*$/;
    if ("" === agreementNo) {
      setErrorMessage("Please Enter Agreement Number");
      return false;
    }
    if (agreementNo.length <= 3) {
      setErrorMessage("Please Enter Valid Agreement Number");
      return false;
    }
    if (!digitsOnly.test(agreementNo)) {
      setErrorMessage("Agreement Number is Invalid. Please Recheck");
      return false;
    }
    return true;
  };

  const handleInputChange = (event) => {
    const { value } = event?.target;
    if ("" !== value.trim()) {
      setAgreementNo(value.trim());
    }
  };

  const handleInputSubmit = async (e) => {
    let selectedQuest = parseInt(e?.target?.getAttribute("data-quest-id"));
    const validAgreementNo = agreementValidation();
    if (validAgreementNo) {
      const question_data = {
        question_id: selectedQuest,
        option_id: null,
        answer_text: "",
        input_answer: agreementNo,
      };

      const response = await SaveQuestionnaire(
        visitorParameters.visitor_parameters,
        question_data,
        "question_store",
        visitorParameters.data,
        query_string,
        formdata
      );
      setAgreementPopup(false);
      setSupportDocPopup(false);
      setSupportDocSucess(true);
    }
  };

  const SkipAgreementNo = async () => {
    const skippedLeadsResponse = await skippedLeads(
      "SkipAgreementNo",
      visitorParameters?.visitor_parameters?.uuid,
      split_name
    );
    setAgreementPopup(false);
    setSupportDocPopup(false);
    setSupportDocSucess(true);
  };

  return (
    <>
      <div
        className={`modal fade show`}
        id="CredCardAgreement"
        data-bs-backdrop="static"
        data-bs-keyboard="false"
        tabIndex={-1}
        aria-labelledby="staticBackdropLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-dialog-centered">
          <div className="modal-content">
            <div className="modal-body">
              <div className="row align-items-center text-center">
                <h5 className="text-black">
                  Enter your Credit Agreement Number
                </h5>
                <div className="input-group mb-3 justify-content-center">
                  <div className="mb-3 mt-3 col-12 p-0">
                    <div className="input-group mb-3">
                      <TextField
                        type="tel"
                        className="form-control p-3"
                        placeholder="Enter your Credit Agreement Number"
                        name="question_45"
                        onChange={handleInputChange}
                        validationMsg={errorMessage}
                      />
                    </div>
                    <button
                      name="agreement_no"
                      className="btn-comm2 btn-comm2--low-zindex w-100"
                      id=""
                      value="Next"
                      type="button"
                      onClick={handleInputSubmit}
                      data-quest-id="45"
                    >
                      Next
                    </button>
                  </div>
                  <p className="text-center">
                    <button
                      name="skip_agree_no"
                      className="btn btn-link"
                      id=""
                      value=""
                      type="button"
                      onClick={SkipAgreementNo}
                    >
                      SKIP for now and follow up ASAP
                    </button>
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
