import { useContext, useState } from "react";
import VisitorParamsContext from "../../../../Contexts/VisitorParams";
import ErrorMessage from "./ErrorMessage";
import lapIcon from "../../../../assets/VAQ_UB_5/img/lap-icon.png";
import { useIdUpload } from "../../../../Hooks/useIdUpload";
import { queryString } from "../../../../Utility/QueryString";
import { useSkippedLeads } from "../../../../Hooks/useSkippedLeads";

export default function SupportingDocUpload({
  docUpload,
  setSupportDocPopup,
  setSupportDocSucess,
}) {
  const { visitorParameters } = useContext(VisitorParamsContext);
  const { saveIdUpload, isLoading } = useIdUpload();
  const { skippedLeads } = useSkippedLeads();
  const [isInValidfFile, setIsInValidfFile] = useState(false);
  const [uploadedFile, setUploadedFile] = useState(null);
  const [selectedFileType, setSelectedFileType] = useState("");
  const [base64Image, setBase64Image] = useState(null);
  const [btnDisabled, setBtnDisabled] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [selectedOption, setSelectedOption] = useState("");

  const formParameters = JSON.parse(localStorage.getItem("formData"));
  const split_name = localStorage.getItem("split_name");

  const changeFilesHandler = (event) => {
    setSelectedOption("fileupload");
    let imageFile = event.target.files[0];
    if (imageFile) {
      let extension = event.target.files[0].type;
      let imgsize = event.target.files[0].size;
      let allowedExtensions = [
        "image/jpeg",
        "image/png",
        "application/zip",
        "application/msword",
        "application/pdf",
      ];
      if (allowedExtensions.indexOf(extension) === -1) {
        setIsInValidfFile(true);
        setErrorMessage(
          "The uploaded ID type should be an image in jpeg,png or jpg"
        );
      } else if (imgsize > 1024 * 1024 * 20) {
        setIsInValidfFile(true);
        setErrorMessage("Please choose a file less than 20mb");
      } else {
        setSelectedFileType(event.target.name);
        setIsInValidfFile(false);
      }
      setUploadedFile(event?.target?.files[0]);

      if (imageFile) {
        let reader = new FileReader();
        reader.onload = _handleReaderLoaded.bind(this);
        reader.readAsDataURL(imageFile);
      }
    } else {
      setIsInValidfFile(true);
    }
  };

  const _handleReaderLoaded = (readerEvt) => {
    let binaryString = readerEvt.target.result;
    setBase64Image({
      base64TextString: binaryString,
    });
  };

  const savedocument = async () => {
    try {
      if (
        visitorParameters.data !== null ||
        visitorParameters.data !== undefined
      ) {
        let uploadData = "";
        if (null !== base64Image && null !== uploadedFile) {
          uploadData = {
            imageFile: base64Image?.base64TextString,
            captureType: selectedFileType.substring(
              selectedFileType.indexOf("doc")
            ),
            documentType: selectedFileType.substr(
              0,
              selectedFileType.indexOf("_")
            ),
          };
          const response = saveIdUpload(
            uploadData,
            visitorParameters,
            formParameters,
            queryString,
            "support_docsv2"
          );

          window.scrollTo(0, 0);
          setSupportDocPopup(false);
          setSupportDocSucess(true);
        } else {
          setIsInValidfFile(true);
          setErrorMessage("Please choose an option");
          setSelectedOption("");
        }
      }
    } catch (e) {
      console.warn("error");
      console.warn(e);
    }
  };

  const removeFile = () => {
    setBase64Image(null);
    setUploadedFile(null);
    setBtnDisabled(false);
  };

  const onProceed = (e) => {
    // setLoader(true);
    if (selectedOption == "fileupload") {
      savedocument();
    } else if (selectedOption == "email") {
      SkipSupportDocs("EmailDoc");
    } else if (selectedOption == "skip") {
      SkipSupportDocs("SkipDoc");
    } else {
      setIsInValidfFile(true);
      setErrorMessage("Please choose an option");
    }
  };
  const SkipSupportDocs = async (type) => {
    setBtnDisabled(true);
    const skippedLeadsResponse = await skippedLeads(
      type,
      visitorParameters?.visitor_parameters?.uuid,
      split_name
    );
    setSupportDocPopup(false);
    setSupportDocSucess(true);
  };

  const onSelectOption = (e) => {
    setIsInValidfFile(false);
    setBtnDisabled(false);
    if (e.target.name == "FinanceAgreementDocEmail") {
      setUploadedFile(null);
      setSelectedOption("email");
    } else if (e.target.name == "FinanceAgreementDocSkip") {
      setUploadedFile(null);
      setSelectedOption("skip");
    }
  };

  return (
    <>
      <div
        className="questitem q17"
        id="slide17"
        style={docUpload ? {} : { display: "none" }}
      >
        <h1 className="que-title p-3 text-style">Supporting Documentation</h1>
        <div className="question-content mt-0 scale-down-ver-center px-3 py-lg-3 py-md-3 py-sm-2 py-2 pb-3 ">
          <div className="row">
            <div className="col-lg-12 mb-2">
              <ul className="p-0 m-0 option-grid">
                <li className="adjustbalewidfull">
                  <div className="optionbtn">
                    <input
                      type="file"
                      id="FinanceAgreementDoc"
                      name="FinanceAgreementDoc"
                      onChange={changeFilesHandler}
                      onClick={(e) => (e.target.value = null)}
                    />
                    <label
                      htmlFor="FinanceAgreementDoc"
                      className={
                        "fileupload" == selectedOption ? "activeclass" : ""
                      }
                    >
                      <img src={lapIcon} height={24} alt="" />
                      UPLOAD file from your device
                    </label>
                  </div>
                </li>
                <li className="adjustbalewidfull">
                  <div className="optionbtn">
                    <input
                      type="radio"
                      id="FinanceAgreementDocEmail"
                      name="FinanceAgreementDocEmail"
                      onClick={onSelectOption}
                      checked={"email" == selectedOption}
                    />
                    <label htmlFor="FinanceAgreementDocEmail">
                      EMAIL file to "documents@themoneysolicitor.com"
                    </label>
                  </div>
                </li>
                <li className="adjustbalewidfull">
                  <div className="optionbtn">
                    <input
                      type="radio"
                      id="FinanceAgreementDocSkip"
                      name="FinanceAgreementDocSkip"
                      onClick={onSelectOption}
                      checked={"skip" == selectedOption}
                    />
                    <label htmlFor="FinanceAgreementDocSkip">
                      SKIP for now and follow up ASAP
                    </label>
                  </div>
                </li>
              </ul>
            </div>
              {isInValidfFile && <ErrorMessage message={errorMessage} />}
            <div className="col-lg-12 mb-2 mt-2">
              {uploadedFile && (
                <div className="d-flex justify-content-center my-2">
                  {uploadedFile?.name}
                  <button
                    className="btn btn-secondary btn-sm ms-2"
                    name="closefile"
                    onClick={removeFile}
                  >
                    Remove
                  </button>
                </div>
              )}

              <button
                className="btn-comm2 py-lg-3 py-md-3 py-sm-2 py-2 w-100 nextBtn"
                data-bs-dismiss="modal"
                disabled={btnDisabled}
                onClick={onProceed}
              >
                <span>
                  Proceed <i className="bi bi-arrow-right" />
                </span>
              </button>
              <p className="text-center">
                <small>
                  For your convenience, copies of supporting documents can be
                  emailed "documents@themoneysolicitor.com"
                </small>
              </p>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
