import React, { useEffect, useState, useRef } from "react";
import TextField from "../../../UI/TextField";
import RadioButtonQuest from "../../../UI/RadioButtonQuest";
const IncomeAndExpV2 = ({
  clsName,
  reff,
  questArr,
  saveSlide80,
  validation,
  validationMsg,
  getValues,
  trigger,
  splitForm,
  backClick,
  checklistOptions,
  setError
}) => {
  const [htmlState, setHtmlState] = useState("");

  const [selectedOptions, setSelectedOptions] = useState([]);
  const que1Ref = useRef(null);
  // const que2Ref = useRef(null);
  const que3Ref = useRef(null);
  const que4Ref = useRef(null);
  const que5Ref = useRef(null);
  const que6Ref = useRef(null);
  const que7Ref = useRef(null);
  const que8Ref = useRef(null);
  // const que57Ref = useRef(null);
  // const que58Ref = useRef(null);

  const questionId = questArr.questionId ? questArr.questionId : 0;

  const selectClick = async (e) => {
    e.preventDefault();
    let errorFlag = 0;
    const values = getValues();

    const selectedValues = {};

    const checklistTriggers = [
      { id: 228, ref: que1Ref, name: "question_228_229" },
      { id: 229, ref: que1Ref, name: "question_228_229" },
      { id: 230, ref: que3Ref, name: "question_230" },
      { id: 231, ref: que4Ref, name: "question_231" },
      { id: 232, ref: que5Ref, name: "question_232" },
      { id: 233, ref: que6Ref, name: "question_233" },
      { id: 234, ref: que7Ref, name: "question_234" },
      { id: 235, ref: que8Ref, name: "question_235" },
    ];

    for (let triggerItem of checklistTriggers) {
      if (checklistOptions.some((option) => option.id === triggerItem.id)) {
        let questionResult = await trigger(triggerItem.name);
        if (!questionResult) {
          scrollToElement(triggerItem.ref);
          errorFlag = 1;
          return false;
        }
        selectedValues[triggerItem.name] =
          splitForm.current[triggerItem.name].value;
          console.log(selectedValues);
          if(selectedValues[triggerItem.name] == "£0" || selectedValues == "" || selectedValues[triggerItem.name] == "0" || selectedValues[triggerItem.name] == null){
            setError(triggerItem.name,{type:"custom", message:"Invalid Value"});
            scrollToElement(triggerItem.ref);
            errorFlag = 1;
            return false;
          }
      }
    }

    if (errorFlag === 0) {
      saveSlide80(e, selectedValues);
    }
  };

  const handleChange = (e) => {
    // const re = /^[0-9\b]+$/;
    const re = /^[1-9]\d*$/;
    if (e.target.value !== "" && !re.test(e.target.value)) {
      e.target.value = e.target.value.replace(/\D/g, "") * 1;
    }
    if (e.target.value !== "") {
      e.target.value =
       e.target.value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    }
  };

  const scrollToElement = (ref) => {
    ref.current.scrollIntoView({ behavior: "smooth", block: "start" });
    ref.current.focus();
  };

  const renderInputFields = () => {
    let renderedMortgageOrRent = false;

    return checklistOptions.map((option) => {
      if ((option.id === 228 || option.id === 229) && !renderedMortgageOrRent) {
        renderedMortgageOrRent = true;
        return (
          <div
            className="input-group mb-3"
            ref={que1Ref}
            key="question_228_229"
          >
            <span className="w-sm-100 input-group-text input-mobile odflpoundSymbol" id="basic-addon1">
              My monthly mortgage / Rent was:
            </span>
            <TextField
              type="tel"
              className="form-control cursor-placeholder odflpoundSymbol_input"
              // placeholder="£"
              name="question_228_229"
              onChange={handleChange}
              validation={validation({
                required: "Please Enter Monthly Mortgage / Rent",
                pattern: {
                  value: /^[ A-Za-z0-9_@./#&+-£]*$/,
                  message: "monthly mortgage / Rent is Invalid. Please Recheck",
                },
              })}
              validationMsg={
                (validationMsg.question_228_229 &&
                  validationMsg.question_228_229.message) ||
                (validationMsg.question_228_229 &&
                  validationMsg.question_228_229.message)
              }
            />
          </div>
        );
      }

      switch (option.id) {
        case 230: // Utility Bills
          return (
            <div className="input-group mb-3" ref={que3Ref} key="question_230">
              <span className="w-sm-100 input-group-text input-mobile odflpoundSymbol" id="basic-addon1">
                My monthly TOTAL utility bills were:
              </span>
              <TextField
                type="tel"
                className="form-control cursor-placeholder odflpoundSymbol_input"
                // placeholder="£"
                name="question_230"
                onChange={handleChange}
                validation={validation({
                  required: "Please Enter Monthly TOTAL utility bills",
                  pattern: {
                    value: /^[ A-Za-z0-9_@./#&+-£]*$/,
                    message:
                      "Monthly TOTAL utility bills is Invalid. Please Recheck",
                  },
                })}
                validationMsg={
                  validationMsg.question_230 &&
                  validationMsg.question_230.message
                }
              />
            </div>
          );
        case 231: // Loans
          return (
            <div className="input-group mb-3" ref={que4Ref} key="question_231">
              <span className="w-sm-100 input-group-text input-mobile odflpoundSymbol" id="basic-addon1">
                My monthly loan repayments were:
              </span>
              <TextField
                type="tel"
                className="form-control cursor-placeholder odflpoundSymbol_input"
                // placeholder="£"
                name="question_231"
                onChange={handleChange}
                validation={validation({
                  required: "Please Enter Monthly loan repayments",
                  pattern: {
                    value: /^[ A-Za-z0-9_@./#&+-£]*$/,
                    message:
                      "Monthly loan repayments is Invalid. Please Recheck",
                  },
                })}
                validationMsg={
                  validationMsg.question_231 &&
                  validationMsg.question_231.message
                }
              />
            </div>
          );
        case 232: // Credit Cards
          return (
            <div className="input-group mb-3" ref={que5Ref} key="question_232">
              <span className="w-sm-100 input-group-text input-mobile odflpoundSymbol" id="basic-addon1">
                My monthly credit card repayments were:
              </span>
              <TextField
                type="tel"
                className="form-control cursor-placeholder odflpoundSymbol_input"
                // placeholder="£"
                name="question_232"
                onChange={handleChange}
                validation={validation({
                  required: "Please Enter Monthly credit card repayments",
                  pattern: {
                    value: /^[ A-Za-z0-9_@./#&+-£]*$/,
                    message:
                      "Monthly credit card repayments is Invalid. Please Recheck",
                  },
                })}
                validationMsg={
                  validationMsg.question_232 &&
                  validationMsg.question_232.message
                }
              />
            </div>
          );
        case 233: // Car Finance
          return (
            <div className="input-group mb-3" ref={que6Ref} key="question_233">
              <span className="w-sm-100 input-group-text input-mobile odflpoundSymbol" id="basic-addon1">
                My monthly car finance payments were:
              </span>
              <TextField
                type="tel"
                className="form-control cursor-placeholder odflpoundSymbol_input"
                // placeholder="£"
                name="question_233"
                onChange={handleChange}
                validation={validation({
                  required: "Please Monthly car finance payments",
                  pattern: {
                    value: /^[ A-Za-z0-9_@./#&+-£]*$/,
                    message:
                      "Monthly car finance payments is Invalid. Please Recheck",
                  },
                })}
                validationMsg={
                  validationMsg.question_233 &&
                  validationMsg.question_233.message
                }
              />
            </div>
          );
        case 234: // Store Cards
          return (
            <div className="input-group mb-3" ref={que7Ref} key="question_234">
              <span className="w-sm-100 input-group-text input-mobile odflpoundSymbol" id="basic-addon1">
                My monthly store card payments were:
              </span>
              <TextField
                type="tel"
                className="form-control cursor-placeholder odflpoundSymbol_input"
                // placeholder="£"
                name="question_234"
                onChange={handleChange}
                validation={validation({
                  required: "Please Enter Monthly store card payments",
                  pattern: {
                    value: /^[ A-Za-z0-9_@./#&+-£]*$/,
                    message:
                      "Monthly store card payments is Invalid. Please Recheck",
                  },
                })}
                validationMsg={
                  validationMsg.question_234 &&
                  validationMsg.question_234.message
                }
              />
            </div>
          );
        case 235: // Other
          return (
            <div className="input-group mb-3" ref={que8Ref} key="question_235">
              <span className="w-sm-100 input-group-text input-mobile odflpoundSymbol" id="basic-addon1">
                My other monthly payment commitments were:
              </span>
              <TextField
                type="tel"
                className="form-control cursor-placeholder odflpoundSymbol_input"
                // placeholder="£"
                name="question_235"
                onChange={handleChange}
                validation={validation({
                  required: "Please Enter Other monthly payment",
                  pattern: {
                    value: /^[ A-Za-z0-9_@./#&+-£]*$/,
                    message: "Other monthly payment is Invalid. Please Recheck",
                  },
                })}
                validationMsg={
                  validationMsg.question_235 &&
                  validationMsg.question_235.message
                }
              />
            </div>
          );
        default:
          return null;
      }
    });
  };

  return (
    <>
      <div className="question-content mt-0 scale-down-ver-center px-3 py-lg-3 py-md-3 py-sm-2 py-2 pb-3 ">
        <div className="row">

          <div className="col-lg-12 mb-2">{renderInputFields()}</div>

          <div className="col-lg-12 mb-2 mt-2">
            <button
              className="qb14 btn-comm2 py-lg-3 py-md-3 py-sm-2 py-2 w-100 nextBtn"
              type="button"
              onClick={selectClick}
              data-quest-id={questionId}
              data-ans-id={null}
              data-ans-label={null}
              name="incmAndexp"
            >
              Next &#8594;
            </button>
          </div>
        </div>
        <p className="text-center bck-marwid">
          <button
            name="wereAlreadyPaying"
            className="mt-1 btn btn-secondary backbtn1a"
            id=""
            value=""
            type="button"
            onClick={backClick}
          >
            Go Back
          </button>
        </p>
      </div>

      <div dangerouslySetInnerHTML={{ __html: htmlState }} />
    </>
  );
};
export default IncomeAndExpV2;
