import React, { useState } from "react";
import { Dependent } from "../../../../Constants/Constants";
import SelectBox from "../../../UI/SelectBox";
import RadioButtonQuest from "../../../UI/RadioButtonQuest";

const DependentsV2 = ({
  clsName,
  didReceiveIncome,
  onSelectAnswer,
  validationMsg,
  clearErrors,
  validation,
  trigger,
  getValues,
  backClick,
  splitForm
}) => {
  const [questResult, setQuestResult] = useState(false);
  const [checkedOne, setCheckedOne] = useState("unchecked");
  const [checkedTwo, setCheckedTwo] = useState("unchecked");
  const [checkedThree, setCheckedThree] = useState("unchecked");
  const [checkedFour, setCheckedFour] = useState("unchecked");
  const [checkedFive, setCheckedFive] = useState("unchecked");
  const [checkedSix, setCheckedSix] = useState("unchecked");

  const optionSelected = async (e) => {
    if (e.target.value == "0") {
      setCheckedOne("checked");
    } else {
      setCheckedOne("unchecked");
    }
    if (e.target.value == "1") {
      setCheckedTwo("checked");
    } else {
      setCheckedTwo("unchecked");
    }
    if (e.target.value == "2") {
      setCheckedThree("checked");
    } else {
      setCheckedThree("unchecked");
    }
    if (e.target.value == "3") {
      setCheckedFour("checked");
    } else {
      setCheckedFour("unchecked");
    }
    if (e.target.value == "4") {
      setCheckedFive("checked");
    } else {
      setCheckedFive("unchecked");
    }
    if (e.target.value == "4+") {
      setCheckedSix("checked");
    } else {
      setCheckedSix("unchecked");
    }

    setQuestResult(false);

  }
  const handleClick = async (e) => {
    let errorFlag = 0;
    let optionselect = splitForm.current["dependents"].value;
    if (optionselect == '') {
      errorFlag = 1;
      setQuestResult(true);
      return false;
    } else {
      onSelectAnswer(e);
    }
  };
  // const optionSelected = async (e) => {
  //   let errorFlag = 0;
  //   let result = await trigger("dependents");

  //   if (!result) {
  //     errorFlag = 1;
  //     return false;
  //   } else {
  //     onSelectAnswer(e);
  //   }
  // };
  return (
    <>
      <div className="question-content mt-0 scale-down-ver-center px-3 py-lg-3 py-md-3 py-sm-2 py-2 ">
        <div className="col-lg-12">
          <div className="col-lg-12 mb-2">
            <ul class="p-0 m-0 option-grid">
              <li>
                <div class="optionbtn">
                  <RadioButtonQuest
                    name="dependents"
                    id="dependents0"
                    labelClassName={`${checkedOne}`}
                    value="0"
                    labelName="0"
                    onClick={optionSelected}
                    dataQuestId={3}
                    dataAnsId={7}
                  />
                </div>
              </li>
              <li>
                <div class="optionbtn">
                  <RadioButtonQuest
                    name="dependents"
                    id="dependents1"
                    labelClassName={`${checkedTwo}`}
                    value="1"
                    labelName="1"
                    onClick={optionSelected}
                    dataQuestId={3}
                    dataAnsId={8}
                  />
                </div>
              </li>
              <li>
                <div class="optionbtn">
                  <RadioButtonQuest
                    name="dependents"
                    id="dependents2"
                    labelClassName={`${checkedThree}`}
                    value="2"
                    labelName="2"
                    onClick={optionSelected}
                    dataQuestId={3}
                    dataAnsId={9}
                  />
                </div>
              </li>
              <li>
                <div class="optionbtn">
                  <RadioButtonQuest
                    name="dependents"
                    id="dependents3"
                    labelClassName={`${checkedFour}`}
                    value="3"
                    labelName="3"
                    onClick={optionSelected}
                    dataQuestId={3}
                    dataAnsId={279}
                  />
                </div>
              </li>
              <li>
                <div class="optionbtn">
                  <RadioButtonQuest
                    name="dependents"
                    id="dependents4"
                    labelClassName={`${checkedFive}`}
                    value="4"
                    labelName="4"
                    onClick={optionSelected}
                    dataQuestId={3}
                    dataAnsId={280}
                  />
                </div>
              </li>
              <li>
                <div class="optionbtn">
                  <RadioButtonQuest
                    name="dependents"
                    id="dependents4plus"
                    labelClassName={`${checkedSix}`}
                    value="4+"
                    labelName="4+"
                    onClick={optionSelected}
                    dataQuestId={3}
                    dataAnsId={281}
                  />
                </div>
              </li>
            </ul>
          </div>
          <div className="col-lg-12 mb-2 mt-2">
            <button
              type="button"
              className="  btn-comm2 py-3 w-100 nextBtn "
              id="nextscroll"
              name="dependents"
              onClick={handleClick}
              data-quest-id={3}
            >
              Next &#8594;
              {/* <i className="bi bi-arrow-right"></i> */}
            </button>
          </div>
        </div>
        <div className="row">
          <div className="text-center mt-2">
            <div
              className="btn btn-secondary backbtn"
              name={
                didReceiveIncome == 226
                  ? "cardMonthlyIncome"
                  : "didReceiveIncome"
              }
              onClick={backClick}
            >
              <i className="bi bi-arrow-left"></i> Back
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default DependentsV2;
