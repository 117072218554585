import React from "react";
import { Amount } from "../../../../Constants/Constants";
import SelectBox from "../../../UI/SelectBox";
const HighestBalance = ({
  className,
  validation,
  validationMsg,
  trigger,
  saveQuestAnswer,
  backClick,
  getValues,
  removeFromPending,
  addPending,
  qnNo
}) => {
  const handleClick = async (e) => {
    let highestBalance = await trigger("highest_balance");
    let errorFlag = 0;
    if (!highestBalance) {
      errorFlag = 1;
    }
    if (errorFlag === 0) {
        let data = getValues();
        saveQuestAnswer(53,null,data.highest_balance);
        removeFromPending("highestBalance");
    } else {
      return false;
    }
  };
  return (
    <>
      <div className={`container ${className}`}>
        <div className="questitem mt-5 scale-down-ver-center px-3 py-lg-3 py-md-3 py-sm-2 py-2 pb-3">
          <h1 className="que-title p-3 ">
            Approximately what was the{" "}
            <b>highest limit you ever had with Vanquis</b> credit account?
          </h1>
          <div className="row">
            <div className="col-lg-8 mb-2">
              <SelectBox
                className="form-select onSelect"
                OptionValue={Amount}
                name="highest_balance"
                id="highest_balance"
                myRef={validation({
                  required: "Please select amount",
                })}
                validationMsg={
                  validationMsg.highest_balance &&
                  validationMsg.highest_balance.message
                }
              />
            </div>
            <div className="col-lg-4 col-12 mb-2">
              <button
                type="button"
                className="qb13 btn-comm2 py-lg-3 py-md-3 py-sm-2 py-2 w-100 nextBtn "
                id="nextscroll"
                name="high_balance"
                onClick={handleClick}
                data-quest-id={53}
                data-click-id='highestBalance_next'
              >
                Next &#8594;{" "}
              </button>
            </div>
          </div>
          <p className="text-center bck-marwid">
            <button
              name="employmentsts"
              className="btn btn-secondary backbtn1a"
              id=""
              value=""
              type="button"
              onClick={()=>addPending("empStatus")}
              data-click-id='highestBalance_back'
            >
              Go Back
            </button>
          </p>
        </div>
        <div class="tipbox tip2 show">
        Your response to this question helps us to evaluate your financial stability.
          <div class="arrow  arrow-top"></div>
        </div>
        <div
          id="rangequestions"
          class="qrange d-flex align-items-sm-center text-center"
        >
          Question <br class="d-none d-md-block" />{qnNo}
        </div>
      </div>
    </>
  );
};
export default HighestBalance;
