import React, { useEffect, useState,useContext } from 'react';
import '../../assets/Preview_Vaq_V5/scss/merit_Vaq_V5_custom.scss';
import '../../assets/Preview_Vaq_V5/scss/preview_Vaq_V5_preview.scss';
import { useClaimReg } from '../../Hooks/useClaimReg';
import {
    VanquisProducts,
    ReliantBorrowing,
    Overindebtness,
    DebtEssentialBills,
    CreditFileInformation,
    ImpactedNegatively,
    HighUtilisation,
    StruggledRepayments,
    ClientFinancialArrears,
    CardNotManaged,
    UnableRepayments,
} from "../../Constants/Constants";
import { usePreviewData } from '../../Hooks/usePreviewData';
import { useHistory } from "react-router-dom";
import PreviewContentComponent from './PreviewContentComponent';
import { useFollowupUserQuestions } from "../../Hooks/useFollowupUserQuestions";
import { useQuestionnaire } from "../../Hooks/useQuestionnaire";
import VisitorParamsContext from "../../Contexts/VisitorParams";
import GtmDataLayer from "../../Utility/GtmDataLayer";
import PreviewOneVaq_V5 from './PreviewOneVaq_V5';
import { Api } from '../../api/Api';
import TC from '../../assets/pdf/TOBA_New.pdf';

const MeritPage_Vaq_V5 = () => {
    const [meritPopUpLoader, setMeritPopUpLoader] = useState('show');
    const { clickPreviewVisit, clickMeritPageVisit } = useClaimReg();
    const { savePreviewData, getMeritPagePreviewData } = usePreviewData();
    const [ConstantMeritList, setConstantMeritList] = useState({
        ReliantBorrowing: false,
        Overindebtness: false,
        DebtEssentialBills: false,
        CreditFileInformation: false,
        ImpactedNegatively: false,
        HighUtilisation: false,
        StruggledRepayments: false,
        ClientFinancialArrears: false,
        CardNotManaged: false,
        UnableRepayments: false,
    });
    const [combinedMeritPageData, setCombinedMeritPageData] = useState([]);
    const history = useHistory();
    const { saveFollowupUserQuestions } = useFollowupUserQuestions();
    const { SaveQuestionnaire } = useQuestionnaire();
    const { visitorParameters } = useContext(VisitorParamsContext);

    let local_flp_source = localStorage.getItem("flp_source");
    let source = local_flp_source ? local_flp_source : "live";
    const query = new URLSearchParams(window.location.search);
    const query_uuid = query.get("uuid");
    const local_uuid = localStorage.getItem("uuid");
    let customUuid = query_uuid ? query_uuid : local_uuid;

    let split_name = localStorage.getItem("split_name") || "";

    const [showModal, setShowModal] = useState(0);

    // const [showModal, setShowModal] = useState({
    //     reliant: 1,
    //     overindebtedness: 2,
    //     debtEssentialBills: 3,
    //     creditFileInfo: 4,
    //     impacted: 5,
    //     highUtilisation: 6,
    //     struggledRepayments: 7,
    //     financialArrears: 8,
    //     cardNotManaged: 9,
    //     unableRepayments: 10
    // });

    // Function to open modal
    // const openModal = (modalType) => {
    //     setShowModal(prevState => ({ ...prevState, [modalType]: true }));
    // };

    // Function to close modal
    const closeModal = () => {
        // setShowModal(prevState => ({ ...prevState, [modalType]: false }));
        console.log("inside close modal");
        setShowModal(0);
    };

    useEffect(() => {
        const formdata = JSON.parse(localStorage.getItem("formData"));
        setTimeout(() => {
            setMeritPopUpLoader('hide');
        }, 2000);
        if ((formdata === undefined || formdata === null || formdata == "") || (source != 'live')) {
            getMeritPageResult();

        } else {

            const questionData = JSON.parse(localStorage.getItem("questionData"));
            if (questionData) {

                let further36 = questionData ? questionData.find((obj) => obj.question_id == '36') : "";
                const further36Lists = further36 ? further36?.input_answer : [];

                let further76 = questionData ? questionData.find((obj) => obj.question_id == '76') : "";
                const further76Lists = further76 ? further76?.input_answer : [];

                const quest82 = questionData ? questionData.find((obj) => obj.question_id == '82') : "";
                let quest82value = questionData ? quest82?.option_id : "";

                const quest29 = questionData ? questionData.find((obj) => obj.question_id == '29') : "";
                let quest29value = questionData ? quest29?.option_id : "";

                const quest9 = questionData ? questionData.find((obj) => obj.question_id == '9') : "";
                let quest9value = questionData ? quest9?.option_id : "";

                const quest71 = questionData ? questionData.find((obj) => obj.question_id == '71') : "";
                let quest71value = questionData ? quest71?.option_id : "";

                const quest55 = questionData ? questionData.find((obj) => obj.question_id == '55') : "";
                let quest55value = questionData ? Number(quest55?.option_id) : "";


                const combinedMeritList = [
                    quest29value,
                    quest9value,
                    ...further36Lists.map(item => item.id),
                    ...further76Lists.map(item => item.id),
                    quest82value,
                    quest71value,
                    quest55value
                ].filter(item => item !== null && item !== undefined);

                setCombinedMeritPageData(combinedMeritList);

                const finalMeritPageList = combinedMeritList.forEach(id => {
                    if (ReliantBorrowing[id.toString()]) ConstantMeritList.ReliantBorrowing = true;
                    if (Overindebtness[id.toString()]) ConstantMeritList.Overindebtness = true;
                    if (DebtEssentialBills[id.toString()]) ConstantMeritList.DebtEssentialBills = true;
                    if (CreditFileInformation[id.toString()]) ConstantMeritList.CreditFileInformation = true;
                    if (ImpactedNegatively[id.toString()]) ConstantMeritList.ImpactedNegatively = true;
                    if (HighUtilisation[id.toString()]) ConstantMeritList.HighUtilisation = true;
                    if (StruggledRepayments[id.toString()]) ConstantMeritList.StruggledRepayments = true;
                    if (ClientFinancialArrears[id.toString()]) ConstantMeritList.ClientFinancialArrears = true;
                    if (CardNotManaged[id.toString()]) ConstantMeritList.CardNotManaged = true;
                    if (UnableRepayments[id.toString()]) ConstantMeritList.UnableRepayments = true;
                });
            }

        }
    }, []);

    const getMeritPageResult = async () => {

        let MeritPageResponse = await getMeritPagePreviewData(customUuid);
        var combinedMeritList = MeritPageResponse.data.result.MeritPreviewData;

        setCombinedMeritPageData(combinedMeritList);

        const finalMeritPageList = combinedMeritList.forEach(id => {
            if (ReliantBorrowing[id.toString()]) ConstantMeritList.ReliantBorrowing = true;
            if (Overindebtness[id.toString()]) ConstantMeritList.Overindebtness = true;
            if (DebtEssentialBills[id.toString()]) ConstantMeritList.DebtEssentialBills = true;
            if (CreditFileInformation[id.toString()]) ConstantMeritList.CreditFileInformation = true;
            if (ImpactedNegatively[id.toString()]) ConstantMeritList.ImpactedNegatively = true;
            if (HighUtilisation[id.toString()]) ConstantMeritList.HighUtilisation = true;
            if (StruggledRepayments[id.toString()]) ConstantMeritList.StruggledRepayments = true;
            if (ClientFinancialArrears[id.toString()]) ConstantMeritList.ClientFinancialArrears = true;
            if (CardNotManaged[id.toString()]) ConstantMeritList.CardNotManaged = true;
            if (UnableRepayments[id.toString()]) ConstantMeritList.UnableRepayments = true;
        });

        return combinedMeritList;
    }
    const onSelectAnswerV2 = async (e) => {
        // GtmDataLayer({
        //     question: "faced difficulties",
        // });

        let question_data = {
            question_id: 29,
            option_id: 99,
            answer_text: null,
            input_answer: "",
        };

        const allFollowupData = JSON.parse(localStorage.getItem("allFollowupData"));
        if (allFollowupData) {
            await storeFollowupQuestionnire(question_data);
            var existingEntries = JSON.parse(localStorage.getItem("questionData"));
            if (existingEntries == null) existingEntries = [];
            localStorage.setItem(
                "currentquestionData",
                JSON.stringify(question_data)
            );
            existingEntries.push(question_data);
            localStorage.setItem("questionData", JSON.stringify(existingEntries));
        } else {
            await storeQuestionnire(question_data);
            let _existingEntries = JSON.parse(localStorage.getItem("questionData"));
            if (_existingEntries == null) _existingEntries = [];
            let existingIndex = _existingEntries.findIndex(entry => entry.question_id === question_data.question_id);
            if (existingIndex !== -1) {
                _existingEntries.splice(existingIndex, 1);
            }
            _existingEntries.push(question_data);
            localStorage.setItem("questionData", JSON.stringify(_existingEntries));
        }
    }


    const storeQuestionnire = async (question_data) => {
        const query_string = localStorage.getItem("querystring");
        const formdata = JSON.parse(localStorage.getItem("formData"));
        const response = await SaveQuestionnaire(
            visitorParameters.visitor_parameters,
            question_data,
            "question_store",
            visitorParameters.data,
            query_string,
            formdata
        );
    };

    const storeFollowupQuestionnire = async (question_data) => {
        const allFollowupData = JSON.parse(localStorage.getItem("allFollowupData"));
        const response = await saveFollowupUserQuestions(
            question_data,
            allFollowupData,
            "followup_question_store"
        );
    };

    const handleMeritPopUp = async () => {
        try {

            await onSelectAnswerV2();
            const responsepdf = await Api.get("/api/v1/vaq-pdf-generate?uuid=" + visitorParameters.visitor_parameters.uuid, {});
        GtmDataLayer({
          question: "yes confirm",
        });
        const claimReg = await clickMeritPageVisit(
            query_uuid,
            "merit_page",
            "preview_submit",
            source
        );
        window.scrollTo(0, 0);
        history.push(
            "/questionnaireV29?uuid=" + customUuid
        );
        } catch (error) {
            console.error("Error:", error);
            window.scrollTo(0, 0);
            history.push("/questionnaireV29?uuid=" + query_uuid);
        }
    };

    return (
        <div className="MeritPage_Vaq_V5 PreviewOneVaq_V5" >
            <section className="banner qs-section">
                <div className="container">
                    <div>
                        <div className="brand">
                            <div className="logo pt-3 text-center">
                                <a>
                                    {" "}
                                    <img src="assets/VAQ_UB_5/prviewPage/img/logo-white.png" height={60} alt="" />
                                </a>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="container my-4">
                    {/* Loader */}
                    <div className={`loader-wrap text-center text-light ${meritPopUpLoader == "show" ? "show" : "hide"}`}>
                        <div className='analyzeLoader'>
                            <img height={80} src="assets/VAQ_UB_5/prviewPage/img/loader-review2.svg" alt="" />
                            <h6 className="mt-3">Analysing your answers</h6>
                        </div>
                    </div>
                </div>

                <div className={`container mb-5 gn_slide"  ${meritPopUpLoader == "hide" ? "show" : "hide"}`}>
                    <div className="row">
                        <div className="col-lg-8 offset-lg-2 col-12 goodnews_popup p-0">
                            <div className="goodnewshow p-0">


                                    <div className="text-center title mt-2 mb-2">
                                        <strong className="textgood text-success">GOOD NEWS!</strong>
                                    </div>
                                    <h4 className="text-center fs-6">We believe your case has merit because you told us:</h4>
                         



                                <div className="pb-3 text-center px-lg-4 px-md-4 px-sm-0 p-2">
                
                                    <div className="d-flex justify-content-center">
                                        <ul className=" m-0 mt-2 text-start row justify-content-center listfi">
                                            {ConstantMeritList.ReliantBorrowing && (
                                                <li className="align-items-center">
                                                    <a onClick={() => setShowModal(1)}>
                                                        Reliant on further borrowing
                                                    </a>                                                </li>
                                            )}
                                            {ConstantMeritList.Overindebtness && (
                                                <li className="align-items-center">
                                                    <a onClick={() => setShowModal(2)}>
                                                        Overindebtedness
                                                    </a>                                                </li>
                                            )}
                                            {ConstantMeritList.DebtEssentialBills && (
                                                <li className="align-items-center">
                                                    <a onClick={() => setShowModal(3)}>
                                                        Caused further debt with essential bills
                                                    </a>                                                </li>
                                            )}
                                            {ConstantMeritList.CreditFileInformation && (
                                                <li className="align-items-center">
                                                    <a onClick={() => setShowModal(4)}>
                                                        Adverse credit file information
                                                    </a>                                                </li>
                                            )}
                                            {ConstantMeritList.ImpactedNegatively && (
                                                <li className="align-items-center">
                                                    <a onClick={() => setShowModal(5)}>
                                                    Negative impact on circumstances
                                                    </a>                                                </li>
                                            )}
                                            {ConstantMeritList.HighUtilisation && (
                                                <li className="align-items-center">
                                                    <a onClick={() => setShowModal(6)}>
                                                        High utilisation of existing credit
                                                    </a>                                                </li>
                                            )}
                                            {ConstantMeritList.StruggledRepayments && (
                                                <li className="align-items-center">
                                                    <a onClick={() => setShowModal(7)}>
                                                        Struggling to make repayments
                                                    </a>                                                </li>
                                            )}
                                            {ConstantMeritList.ClientFinancialArrears && (
                                                <li className="align-items-center">
                                                    <a onClick={() => setShowModal(8)}>
                                                    Financial Arrears
                                                    </a>                                                </li>
                                            )}
                                            {ConstantMeritList.CardNotManaged && (
                                                <li className="align-items-center">
                                                    <a onClick={() => setShowModal(9)}>
                                                        Credit account was not managed well
                                                    </a>                                                </li>
                                            )}
                                            {ConstantMeritList.UnableRepayments && (
                                                <li className="align-items-center">
                                                    <a onClick={() => setShowModal(10)}>
                                                        Unable to maintain sustainable repayments
                                                    </a>                                                </li>
                                            )}
                                        </ul>
                                    </div>
                                    </div>
                                    <div className="background_div">
                            <div class="pt-4 mb-3">
                                    {/* <h4 className="fs-5 mb-3">
                                    It sounds like you've been finding it tough to keep up with your Vanquis repayments while meeting your other commitments. </h4>
                                   
                                
                                    <h6>To instruct TMS Legal to pursue your claim, click the button below.
                                    </h6> */}
                                     <h6 className='p-align'><strong>Please read the following guidance.</strong>
                                     </h6> 
                                    <p className="fst-italic p-align ms-3" >1. Please note that you have the right to pursue a complaint directly with your lender and thereafter directly to the Financial Ombudsman Service for free and without using the firm or any solicitor or claims handler.</p>
                                    <p className="fst-italic p-align ms-3">2. We operate on a ‘No Win No Fee’ basis. Our fees range from 18% to 36% including VAT, which is dependent on the amount of redress awarded. Please <a className='text-primary' href={TC} target="_blank">click here</a> for further details.</p>
                                    <p className="fst-italic p-align ms-3">3. The lender may reduce the credit limit/ suspend the card or remove/ close the product (credit card/ overdraft cases) or may repossess the car (car loan cases) you acknowledge that by making the unaffordable/ irresponsible lending complaint that you may not be able to borrow from the lender in future.</p>
                                    <p className="fst-italic p-align ms-3">4. If you decide to sign up with the firm the next steps are that the firm will assess the paperwork, initiate a background check, carry out the onboarding process, then if this all proceeds successfully the firm will send its advice on the merits and, if merits are reasonable or more than reasonable, the firm will process your claim and send it to the lender. Should the claim to the lender be rejected and the firm see merit in it, or should the lender not respond in time and the firm see merit in doing so, the firm will send it to the Financial Ombudsman Service and will inform you of that decision at that time.</p>
                                    </div>
                                    <div className="mt-4 mb-3">
                                        <p className=""><strong> I understand the merits of my claim and have read and understood the above guidance. To instruct TMS Legal to pursue your claim, Click the button below. </strong></p>
                                    </div>
                                    <div className="mb-2">
                                        <button
                                            id="proceedBtn"
                                            className="btnwidth btn-comm2 p-2    py-3 px-4 pulse"
                                            onClick={handleMeritPopUp}
                                        >
                                            <span id="spanbtn">Proceed with my claim</span>

                                        </button>
                                    </div>

                                    <div class="mt-3 mb-3">
                                    {/* <p className="">
                                           <i> I understand the merits of my claim and that I am able to self represent.</i>
                                        </p> */}
                                         <p className="">
                                         There are however many benefits of choosing us to handle your claim. We will manage all communication with your lender, working closely with them to ensure that you receive a fair level of compensation. If your lender encounters any difficulties in locating your details, we will assist them to enable your claim to proceed. After you register with us, you can simply sit back and relax while we handle everything, keeping you updated via email throughout the process.</p>
                                    </div>
                                   
                                    </div>
                                    {/* <div className="mt-4 mb-3">
                                        <h5 className="text-arrow fs-6">
                                            I understand the merits of my claim and that I am able to
                                            self-represent. To instruct TMS Legal to pursue your claim,
                                            click the button below.
                                        </h5>
                                    </div> */}
                                    {/* <div className="mb-3">
                                        <button
                                            id="proceedBtn"
                                            className=" btn-comm2 p-2    py-3 px-4 pulse"
                                            onClick={handleMeritPopUp}
                                        >
                                            <span id="spanbtn">Yes, I confirm</span>

                                        </button>
                                    </div> */}
                                <div class="pb-3 text-center px-lg-4 px-md-4 px-sm-0 p-2 mt-5 box3">
                                    <p className="fst-italic">
                                        <img className="doc-icon" src="https://cdn-icons-png.freepik.com/512/6747/6747196.png" alt />
                                        <br />
                                        <br />
                                        <br /> You just need to review and confirm our online letter of authority, your questionnaire, our terms, DSAR request for your bank statements, FOS documentation, and we will then initiate our background checking and onboarding
                                        process.
                                    </p>
                                    <div id="doc-preview" className="docp"><b>Document Preview</b></div>

                                        <PreviewOneVaq_V5
                                        onSelectAnswerV2={onSelectAnswerV2}
                                        />


                                   
                                    <p className="fst-italic">
                                        We will complete our KYC (know your customer) checks, process your
                                        claim, and send it to the lender.
                                    </p>
                                    <p className="fst-italic">
                                        Should your claim be rejected, and we see merit in sending it to
                                        the Financial Ombudsman, we will inform you of this decision at
                                        that time.
                                    </p>
                                    <p className="fst-italic">
                                        Good to know: You do not need to use a no win no fee solicitor to
                                        make a complaint to your lender. If your complaint is not
                                        successful, you can refer it to the Financial Ombudsman service
                                        for free.
                                    </p>
                                   <p className="fst-italic">
                                    There are however many benefits of choosing us to handle your claim. We will manage all communication with your lender, working closely with them to ensure that you receive a fair level of compensation. If your lender encounters any difficulties in locating your details, we will assist them to enable your claim to proceed. After you register with us, you can simply sit back and relax while we handle everything, keeping you updated via email throughout the process.
                                    </p>

                                    <p className="fst-italic">
                                        Please note the lender may reduce the credit limit, suspend the
                                        card, or remove/close the product. You acknowledge that by making
                                        this unaffordable/irresponsible lending complaint, it may mean
                                        that you won't be able to borrow from the lender in the future.
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

            </section>
            <PreviewContentComponent
                category={showModal}
                InputValues={combinedMeritPageData}
                setShowModal={setShowModal}
            />

            {/* Modal */}
            {/* <div
                // className="modal fade"
                className={`modal fade ${showModal.reliant ? "show" : "hide"}`}
                id="staticBackdrop1"
                data-bs-backdrop="static"
                data-bs-keyboard="false"
                tabIndex={-1}
                aria-labelledby="staticBackdrop1Label"
                aria-hidden="true"
            >
                <div className="modal-dialog modal-dialog-centered modal-lg">
                    <div className="modal-content">
                        <div className="modal-body">
                            <div className="text-end">
                                <div className="flex-container">
                                    <button
                                        className="item-1"
                                        data-bs-dismiss="modal"
                                        aria-label="Close"
                                        onClick={() => closeModal('reliant')}
                                    >
                                        <span className="inner">
                                            <span className="label">Close</span>
                                        </span>
                                    </button>
                                </div>
                            </div>
                            <div className="text-center max-400 m-auto">
                                <div className="pb-3">
                                    <img height={50} src="dist/img/logo.png" alt="" />
                                </div>
                                <div className="">
                                    <h5 className="h4-thakyou text-black">
                                        Thank you <strong>Sdsdfg</strong> Lorem ipsum dolor sit amet
                                        consectetur adipisicing elit. Incidunt odit explicabo nulla eius
                                        natus ipsum, dicta est enim hic at doloribus reprehenderit
                                        debitis, optio velit illo maiores aspernatur veritatis? Eum?
                                    </h5>
                                    <h5 className="fst-italic fs-6 text-black">
                                        Lorem ipsum dolor sit amet consectetur adipisicing elit.
                                    </h5>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div> */}

            {/* Modal */}
            {/* <div
                // className="modal fade"
                className={`modal fade ${showModal.overindebtedness ? "show" : "hide"}`}
                id="staticBackdrop2"
                data-bs-backdrop="static"
                data-bs-keyboard="false"
                tabIndex={-1}
                aria-labelledby="staticBackdrop2Label"
                aria-hidden="true"
            >
                <div className="modal-dialog modal-dialog-centered modal-lg">
                    <div className="modal-content">
                        <div className="modal-body">
                            <div className="text-end">
                                <div className="flex-container">
                                    <button
                                        className="item-1"
                                        data-bs-dismiss="modal"
                                        aria-label="Close"
                                        onClick={() => closeModal('overindebtedness')}
                                    >
                                        <span className="inner">
                                            <span className="label">Close</span>
                                        </span>
                                    </button>
                                </div>
                            </div>
                            <div className="text-center max-400 m-auto">
                                <div className="pb-3">
                                    <img height={50} src="dist/img/logo.png" alt="" />
                                </div>
                                <h5 className=""></h5>
                                <h5 className="h4-thakyou text-black">
                                    Thank you <strong>Sdsdfg</strong> Lorem ipsum dolor sit amet
                                    consectetur adipisicing elit. Incidunt odit explicabo nulla eius
                                    natus ipsum, dicta est enim hic at doloribus reprehenderit
                                    debitis, optio velit illo maiores aspernatur veritatis? Eum? Lorem
                                    ipsum dolor sit amet consectetur adipisicing elit. Incidunt odit
                                    explicabo nulla eius natus ipsum, dicta est enim hic at doloribus
                                    reprehenderit debitis, optio velit illo maiores aspernatur
                                    veritatis? Eum? Lorem ipsum dolor sit amet consectetur adipisicing
                                    elit. Incidunt odit explicabo nulla eius natus ipsum, dicta est
                                    enim hic at doloribus reprehenderit debitis, optio velit illo
                                    maiores aspernatur veritatis? Eum? Lorem ipsum dolor sit amet
                                    consectetur adipisicing elit. Incidunt odit explicabo nulla eius
                                    natus ipsum, dicta est enim hic at doloribus reprehenderit
                                    debitis, optio velit illo maiores aspernatur veritatis? Eum? Lorem
                                    ipsum dolor sit amet consectetur adipisicing elit. Incidunt odit
                                    explicabo nulla eius natus ipsum, dicta est enim hic at doloribus
                                    reprehenderit debitis, optio velit illo maiores aspernatur
                                    veritatis? Eum? Lorem ipsum dolor sit amet consectetur adipisicing
                                    elit. Incidunt odit explicabo nulla eius natus ipsum, dicta est
                                    enim hic at doloribus reprehenderit debitis, optio velit illo
                                    maiores aspernatur veritatis? Eum? Lorem ipsum dolor sit amet
                                    consectetur adipisicing elit. Incidunt odit explicabo nulla eius
                                    natus ipsum, dicta est enim hic at doloribus reprehenderit
                                    debitis, optio velit illo maiores aspernatur veritatis? Eum? Lorem
                                    ipsum dolor sit amet consectetur adipisicing elit. Incidunt odit
                                    explicabo nulla eius natus ipsum, dicta est enim hic at doloribus
                                    reprehenderit debitis, optio velit illo maiores aspernatur
                                    veritatis? Eum? Lorem ipsum dolor sit amet consectetur adipisicing
                                    elit. Incidunt odit explicabo nulla eius natus ipsum, dicta est
                                    enim hic at doloribus reprehenderit debitis, optio velit illo
                                    maiores aspernatur veritatis? Eum? Lorem ipsum dolor sit amet
                                    consectetur adipisicing elit. Incidunt odit explicabo nulla eius
                                    natus ipsum, dicta est enim hic at doloribus reprehenderit
                                    debitis, optio velit illo maiores aspernatur veritatis? Eum? Lorem
                                    ipsum dolor sit amet consectetur adipisicing elit. Incidunt odit
                                    explicabo nulla eius natus ipsum, dicta est enim hic at doloribus
                                    reprehenderit debitis, optio velit illo maiores aspernatur
                                    veritatis? Eum? Lorem ipsum dolor sit amet consectetur adipisicing
                                    elit. Incidunt odit explicabo nulla eius natus ipsum, dicta est
                                    enim hic at doloribus reprehenderit debitis, optio velit illo
                                    maiores aspernatur veritatis? Eum? Lorem ipsum dolor sit amet
                                    consectetur adipisicing elit. Incidunt odit explicabo nulla eius
                                    natus ipsum, dicta est enim hic at doloribus reprehenderit
                                    debitis, optio velit illo maiores aspernatur veritatis? Eum? Lorem
                                    ipsum dolor sit amet consectetur adipisicing elit. Incidunt odit
                                    explicabo nulla eius natus ipsum, dicta est enim hic at doloribus
                                    reprehenderit debitis, optio velit illo maiores aspernatur
                                    veritatis? Eum? Lorem ipsum dolor sit amet consectetur adipisicing
                                    elit. Incidunt odit explicabo nulla eius natus ipsum, dicta est
                                    enim hic at doloribus reprehenderit debitis, optio velit illo
                                    maiores aspernatur veritatis? Eum? Lorem ipsum dolor sit amet
                                    consectetur adipisicing elit. Incidunt odit explicabo nulla eius
                                    natus ipsum, dicta est enim hic at doloribus reprehenderit
                                    debitis, optio velit illo maiores aspernatur veritatis? Eum? Lorem
                                    ipsum dolor sit amet consectetur adipisicing elit. Incidunt odit
                                    explicabo nulla eius natus ipsum, dicta est enim hic at doloribus
                                    reprehenderit debitis, optio velit illo maiores aspernatur
                                    veritatis? Eum? Lorem ipsum dolor sit amet consectetur adipisicing
                                    elit. Incidunt odit explicabo nulla eius natus ipsum, dicta est
                                    enim hic at doloribus reprehenderit debitis, optio velit illo
                                    maiores aspernatur veritatis? Eum? Lorem ipsum dolor sit amet
                                    consectetur adipisicing elit. Incidunt odit explicabo nulla eius
                                    natus ipsum, dicta est enim hic at doloribus reprehenderit
                                    debitis, optio velit illo maiores aspernatur veritatis? Eum? Lorem
                                    ipsum dolor sit amet consectetur adipisicing elit. Incidunt odit
                                    explicabo nulla eius natus ipsum, dicta est enim hic at doloribus
                                    reprehenderit debitis, optio velit illo maiores aspernatur
                                    veritatis? Eum? Lorem ipsum dolor sit amet consectetur adipisicing
                                    elit. Incidunt odit explicabo nulla eius natus ipsum, dicta est
                                    enim hic at doloribus reprehenderit debitis, optio velit illo
                                    maiores aspernatur veritatis? Eum? Lorem ipsum dolor sit amet
                                    consectetur adipisicing elit. Incidunt odit explicabo nulla eius
                                    natus ipsum, dicta est enim hic at doloribus reprehenderit
                                    debitis, optio velit illo maiores aspernatur veritatis? Eum?{" "}
                                </h5>
                                <h5 className="fst-italic fs-6 text-black">
                                    Lorem ipsum dolor sit amet consectetur adipisicing elit.
                                </h5>
                            </div>
                        </div>
                    </div>
                </div>
            </div> */}
            {/* Modal */}
            {/* <div
                // className="modal fade"
                className={`modal fade ${showModal.debtEssentialBills ? "show" : "hide"}`}
                id="staticBackdrop3"
                data-bs-backdrop="static"
                data-bs-keyboard="false"
                tabIndex={-1}
                aria-labelledby="staticBackdrop3Label"
                aria-hidden="true"
            >
                <div className="modal-dialog modal-dialog-centered modal-lg">
                    <div className="modal-content">
                        <div className="modal-body">
                            <div className="text-end">
                                <div className="flex-container">
                                    <button
                                        className="item-1"
                                        data-bs-dismiss="modal"
                                        aria-label="Close"
                                        onClick={() => closeModal('debtEssentialBills')}
                                    >
                                        <span className="inner">
                                            <span className="label">Close</span>
                                        </span>
                                    </button>
                                </div>
                            </div>
                            <div className="text-center max-400 m-auto">
                                <div className="pb-3">
                                    <img height={50} src="dist/img/logo.png" alt="" />
                                </div>
                                <div className="">
                                    <h5 className="h4-thakyou text-black">
                                        Thank you <strong>Sdsdfg</strong> Lorem ipsum dolor sit amet
                                        consectetur adipisicing elit. Incidunt odit explicabo nulla eius
                                        natus ipsum, dicta est enim hic at doloribus reprehenderit
                                        debitis, optio velit illo maiores aspernatur veritatis? Eum?
                                    </h5>
                                    <h5 className="fst-italic fs-6 text-black">
                                        Lorem ipsum dolor sit amet consectetur adipisicing elit.
                                    </h5>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div> */}
            {/* Modal */}
            <div
                // className="modal fade"
                className={`modal fade ${showModal.creditFileInfo ? "show" : "hide"}`}
                id="staticBackdrop4"
                data-bs-backdrop="static"
                data-bs-keyboard="false"
                tabIndex={-1}
                aria-labelledby="staticBackdrop4Label"
                aria-hidden="true"
            >
                <div className="modal-dialog modal-dialog-centered modal-lg">
                    <div className="modal-content">
                        <div className="modal-body">
                            <div className="text-end">
                                <div className="flex-container">
                                    <button
                                        className="item-1"
                                        data-bs-dismiss="modal"
                                        aria-label="Close"
                                        onClick={() => closeModal('creditFileInfo')}
                                    >
                                        <span className="inner">
                                            <span className="label">Close</span>
                                        </span>
                                    </button>
                                </div>
                            </div>
                            <div className="text-center max-400 m-auto">
                                <div className="pb-3">
                                    <img height={50} src="dist/img/logo.png" alt="" />
                                </div>
                                <div className="">
                                    <h5 className="h4-thakyou text-black">
                                        Thank you <strong>Sdsdfg</strong> Lorem ipsum dolor sit amet
                                        consectetur adipisicing elit. Incidunt odit explicabo nulla eius
                                        natus ipsum, dicta est enim hic at doloribus reprehenderit
                                        debitis, optio velit illo maiores aspernatur veritatis? Eum?
                                    </h5>
                                    <h5 className="fst-italic fs-6 text-black">
                                        Lorem ipsum dolor sit amet consectetur adipisicing elit.
                                    </h5>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {/* Modal */}
            {/* <div
                // className="modal fade"
                className={`modal fade ${showModal.impacted ? "show" : "hide"}`}
                id="staticBackdrop5"
                data-bs-backdrop="static"
                data-bs-keyboard="false"
                tabIndex={-1}
                aria-labelledby="staticBackdrop5Label"
                aria-hidden="true"
            >
                <div className="modal-dialog modal-dialog-centered modal-lg">
                    <div className="modal-content">
                        <div className="modal-body">
                            <div className="text-end">
                                <div className="flex-container">
                                    <button
                                        className="item-1"
                                        data-bs-dismiss="modal"
                                        aria-label="Close"
                                        onClick={() => closeModal('impacted')}
                                    >
                                        <span className="inner">
                                            <span className="label">Close</span>
                                        </span>
                                    </button>
                                </div>
                            </div>
                            <div className="text-center max-400 m-auto">
                                <div className="pb-3">
                                    <img height={50} src="dist/img/logo.png" alt="" />
                                </div>
                                <div className="">
                                    <h5 className="h4-thakyou text-black">
                                        Thank you <strong>Sdsdfg</strong> Lorem ipsum dolor sit amet
                                        consectetur adipisicing elit. Incidunt odit explicabo nulla eius
                                        natus ipsum, dicta est enim hic at doloribus reprehenderit
                                        debitis, optio velit illo maiores aspernatur veritatis? Eum?
                                    </h5>
                                    <h5 className="fst-italic fs-6 text-black">
                                        Lorem ipsum dolor sit amet consectetur adipisicing elit.
                                    </h5>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div> */}
            {/* Modal */}
            {/* <div
                // className="modal fade"
                className={`modal fade ${showModal.highUtilisation ? "show" : "hide"}`}
                id="staticBackdrop6"
                data-bs-backdrop="static"
                data-bs-keyboard="false"
                tabIndex={-1}
                aria-labelledby="staticBackdrop6Label"
                aria-hidden="true"
            >
                <div className="modal-dialog modal-dialog-centered modal-lg">
                    <div className="modal-content">
                        <div className="modal-body">
                            <div className="text-end">
                                <div className="flex-container">
                                    <button
                                        className="item-1"
                                        data-bs-dismiss="modal"
                                        aria-label="Close"
                                        onClick={() => closeModal('highUtilisation')}
                                    >
                                        <span className="inner">
                                            <span className="label">Close</span>
                                        </span>
                                    </button>
                                </div>
                            </div>
                            <div className="text-center max-400 m-auto">
                                <div className="pb-3">
                                    <img height={50} src="dist/img/logo.png" alt="" />
                                </div>
                                <div className="">
                                    <h5 className="h4-thakyou text-black">
                                        Thank you <strong>Sdsdfg</strong> Lorem ipsum dolor sit amet
                                        consectetur adipisicing elit. Incidunt odit explicabo nulla eius
                                        natus ipsum, dicta est enim hic at doloribus reprehenderit
                                        debitis, optio velit illo maiores aspernatur veritatis? Eum?
                                    </h5>
                                    <h5 className="fst-italic fs-6 text-black">
                                        Lorem ipsum dolor sit amet consectetur adipisicing elit.
                                    </h5>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div> */}
            {/* Modal */}
            {/* <div
                // className="modal fade"
                className={`modal fade ${showModal.struggledRepayments ? "show" : "hide"}`}
                id="staticBackdrop7"
                data-bs-backdrop="static"
                data-bs-keyboard="false"
                tabIndex={-1}
                aria-labelledby="staticBackdrop7Label"
                aria-hidden="true"
            >
                <div className="modal-dialog modal-dialog-centered modal-lg">
                    <div className="modal-content">
                        <div className="modal-body">
                            <div className="text-end">
                                <div className="flex-container">
                                    <button
                                        className="item-1"
                                        data-bs-dismiss="modal"
                                        aria-label="Close"
                                        onClick={() => closeModal('struggledRepayments')}
                                    >
                                        <span className="inner">
                                            <span className="label">Close</span>
                                        </span>
                                    </button>
                                </div>
                            </div>
                            <div className="text-center max-400 m-auto">
                                <div className="pb-3">
                                    <img height={50} src="dist/img/logo.png" alt="" />
                                </div>
                                <div className="">
                                    <h5 className="h4-thakyou text-black">
                                        Thank you <strong>Sdsdfg</strong> Lorem ipsum dolor sit amet
                                        consectetur adipisicing elit. Incidunt odit explicabo nulla eius
                                        natus ipsum, dicta est enim hic at doloribus reprehenderit
                                        debitis, optio velit illo maiores aspernatur veritatis? Eum?
                                    </h5>
                                    <h5 className="fst-italic fs-6 text-black">
                                        Lorem ipsum dolor sit amet consectetur adipisicing elit.
                                    </h5>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div> */}
            {/* Modal */}
            {/* <div
                // className="modal fade"
                className={`modal fade ${showModal.financialArrears ? "show" : "hide"}`}
                id="staticBackdrop8"
                data-bs-backdrop="static"
                data-bs-keyboard="false"
                tabIndex={-1}
                aria-labelledby="staticBackdrop8Label"
                aria-hidden="true"
            >
                <div className="modal-dialog modal-dialog-centered modal-lg">
                    <div className="modal-content">
                        <div className="modal-body">
                            <div className="text-end">
                                <div className="flex-container">
                                    <button
                                        className="item-1"
                                        data-bs-dismiss="modal"
                                        aria-label="Close"
                                        onClick={() => closeModal('financialArrears')}
                                    >
                                        <span className="inner">
                                            <span className="label">Close</span>
                                        </span>
                                    </button>
                                </div>
                            </div>
                            <div className="text-center max-400 m-auto">
                                <div className="pb-3">
                                    <img height={50} src="dist/img/logo.png" alt="" />
                                </div>
                                <div className="">
                                    <h5 className="h4-thakyou text-black">
                                        Thank you <strong>Sdsdfg</strong> Lorem ipsum dolor sit amet
                                        consectetur adipisicing elit. Incidunt odit explicabo nulla eius
                                        natus ipsum, dicta est enim hic at doloribus reprehenderit
                                        debitis, optio velit illo maiores aspernatur veritatis? Eum?
                                    </h5>
                                    <h5 className="fst-italic fs-6 text-black">
                                        Lorem ipsum dolor sit amet consectetur adipisicing elit.
                                    </h5>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div> */}
            {/* Modal */}
            {/* <div
                // className="modal fade"
                className={`modal fade ${showModal.cardNotManaged ? "show" : "hide"}`}
                id="staticBackdrop9"
                data-bs-backdrop="static"
                data-bs-keyboard="false"
                tabIndex={-1}
                aria-labelledby="staticBackdrop9Label"
                aria-hidden="true"
            >
                <div className="modal-dialog modal-dialog-centered modal-lg">
                    <div className="modal-content">
                        <div className="modal-body">
                            <div className="text-end">
                                <div className="flex-container">
                                    <button
                                        className="item-1"
                                        data-bs-dismiss="modal"
                                        aria-label="Close"
                                        onClick={() => closeModal('cardNotManaged')}
                                    >
                                        <span className="inner">
                                            <span className="label">Close</span>
                                        </span>
                                    </button>
                                </div>
                            </div>
                            <div className="text-center max-400 m-auto">
                                <div className="pb-3">
                                    <img height={50} src="dist/img/logo.png" alt="" />
                                </div>
                                <div className="">
                                    <h5 className="h4-thakyou text-black">
                                        Thank you <strong>Sdsdfg</strong> Lorem ipsum dolor sit amet
                                        consectetur adipisicing elit. Incidunt odit explicabo nulla eius
                                        natus ipsum, dicta est enim hic at doloribus reprehenderit
                                        debitis, optio velit illo maiores aspernatur veritatis? Eum?
                                    </h5>
                                    <h5 className="fst-italic fs-6 text-black">
                                        Lorem ipsum dolor sit amet consectetur adipisicing elit.
                                    </h5>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div> */}
            {/* Modal */}
            {/* <div
                // className="modal fade"
                id="staticBackdrop10"
                data-bs-backdrop="static"
                data-bs-keyboard="false"
                tabIndex={-1}
                aria-labelledby="staticBackdrop10Label"
                aria-hidden="true"
                className={`modal fade ${showModal.unableRepayments ? "show" : "hide"}`}
            >
                <div className="modal-dialog modal-dialog-centered modal-lg">
                    <div className="modal-content">
                        <div className="modal-body">
                            <div className="text-end">
                                <div className="flex-container">
                                    <button
                                        className="item-1"
                                        data-bs-dismiss="modal"
                                        aria-label="Close"
                                        onClick={() => closeModal('unableRepayments')}
                                    >
                                        <span className="inner">
                                            <span className="label">Close</span>
                                        </span>
                                    </button>
                                </div>
                            </div>
                            <div className="text-center max-400 m-auto">
                                <div className="pb-3">
                                    <img height={50} src="dist/img/logo.png" alt="" />
                                </div>
                                <div className="">
                                    <h5 className="h4-thakyou text-black">
                                        Thank you <strong>Sdsdfg</strong> Lorem ipsum dolor sit amet
                                        consectetur adipisicing elit. Incidunt odit explicabo nulla eius
                                        natus ipsum, dicta est enim hic at doloribus reprehenderit
                                        debitis, optio velit illo maiores aspernatur veritatis? Eum?
                                    </h5>
                                    <h5 className="fst-italic fs-6 text-black">
                                        Lorem ipsum dolor sit amet consectetur adipisicing elit.
                                    </h5>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div> */}



        </div>
    )
}

export default MeritPage_Vaq_V5