import React from "react";
import "../../../../assets/MeritPage/scss/MeritPage.scss"
import "../../../../assets/Quest_VAQ_UB_4_2/scss/modules/custom.scss";
import { useClaimReg } from '../../../../Hooks/useClaimReg';
import { useUpdateMilestone } from "../../../../Hooks/useUpdateMilestone";
import { Api } from "../../../../api/Api";

const DocumentClaimPreview = ({ className,uuid,allFollowupData,removeFromPending }) => {

  const { clickPreviewVisit } = useClaimReg();
  const { updateMilestone } = useUpdateMilestone();

  const viewPreviewDoc = async () => {
    try {
        const previewresponse = await clickPreviewVisit(
           uuid, "ReviewSubmit", "show_preview", allFollowupData.atp_sub6
        );

        if (previewresponse.data.status == "Success") {
            // updateMilestone(uuid, "show_preview", allFollowupData.atp_sub6);
            removeFromPending('documentClaim');
        }
    } catch (error) {
        console.error("Error:", error);
    }
    
};

const Redirect = async () => {
  try {
      const previewresponse = await clickPreviewVisit(
        uuid, "ReviewSubmit", "preview_submit", allFollowupData.atp_sub6
      );
      const responsepdf = await Api.get("/api/v1/vaq-pdf-generate?uuid=" + uuid, {});

      if (previewresponse.data.status == "Success") {
          updateMilestone(uuid, "first_preview_submit", allFollowupData.atp_sub6);
          removeFromPending('documentClaim');
          removeFromPending('preview');       
      }
  } catch (error) {
      console.error("Error:", error);
  }
};

  return (
    <>
      <div className="MeritPage">
        <div className={`modal blackover fade ${className ? "show" : "hide"}`} id="sample" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true">
          <div className="modal-dialog modal-dialog-centered modal-lg">
            <div className={`modal-content  goodnews_popup`}>
              <div className="modal-body">
                <div className={`text-center max-400 m-auto thakyoumsg`}>
                  <div className="pb-lg-2 mt-5 ">
                    <img
                      height="50"
                      src="assets/MeritPage/img/logo.png"
                      alt=""
                    />
                  </div>

                  <div className="questionnaire19">
                    <div className="container">
                      <div
                        id="quetWraper"
                        class="questionnair-section form-style mb-5 ps-0"
                      >
                        <ul className="p-0 m-0 questionist mb-5">
                          <li>
                            <div className="questitem_v2 mt-2" id="slide9">
                              <h4 className="que-title p-3 ">
                                <b>
                                  Your claim document is ready for submission to
                                  Vanquis with all your information and
                                  signature.
                                </b>
                              </h4>

                              <div className="question-content mt-0 scale-down-ver-center px-3 py-lg-3 py-md-3 py-sm-2 py-2 pb-3">
                                <div className="row">
                                  <div className="col-lg-6 col-md-12">
                                    <div className=" mb-2">
                                      <button
                                        className="mt-2 btn-comm2 py-lg-3 py-md-3 py-sm-2 py-2 w-100  noissue custom-font-size "
                                        id="review"
                                        data-click-id='reviewMyClaimDoc_next'
                                        onClick={viewPreviewDoc}
                                      >
                                        <i className="bi bi-file-earmark-break me-2"></i>
                                        Review My Claim Documents Online
                                      </button>
                                    </div>
                                  </div>
                                  <div className="col-lg-6 col-md-12">
                                    <div className=" mb-2">
                                      <button
                                        className="mt-2 btn-comm2 py-lg-3 py-md-3 py-sm-2 py-2 w-100  noissue"
                                        onClick={Redirect}
                                        data-click-id='emailMyClaimDoc_next'
                                      >
                                        <i className="bi bi-envelope me-2"></i>{" "}
                                        Email My Claim Documents
                                      </button>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default DocumentClaimPreview;
