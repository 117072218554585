import React, { useEffect, useState, useContext } from 'react';
import Modal from 'react-bootstrap/Modal';
import "../../assets/Thankyou/css/Thankyou.scss";
import Footer from '../Includes/Layouts/Common/Footer';
import { useUpdateMilestone } from "../../Hooks/useUpdateMilestone";
// import VisitorParamsContext from "../../Contexts/VisitorParams";
import GetVisitorsParams from '../../Utility/GetVisitorsParams';

const Thankyou = () => {

  // const { visitorParameters } = useContext(VisitorParamsContext);
  const urlSearchParams = new URLSearchParams(window.location.search);
  const uuid = urlSearchParams.get('uuid');
  const {updateMilestone} = useUpdateMilestone();
  const [kycPopup, setKycPopup] = useState(false);
  const split_name = localStorage.getItem("split_name");

  useEffect(() => {
    let t2aStatus = localStorage.getItem("t2aValidation");

    if ((t2aStatus == 1 || t2aStatus == "1") && split_name == "MB_UFGN_4_2") {
      setKycPopup(true);
    } 
    const allFollowupData = JSON.parse(localStorage.getItem('allFollowupData'));
    if (allFollowupData) {
        let flp_source = allFollowupData.atp_sub6;
      updateMilestone(uuid,"completed",flp_source);
    }
    else{
      updateMilestone(uuid,"completed","live");
    }
    clearLocal();
  }, []);

  const clearLocal = () => {
    localStorage.clear();
  }

  

  return (
    <>
      <div className="thankyou">
        <header>
          <div className="container">
            <div className="col-lg-12 col-12 text-center">
              <img src="/assets/img/logo.png" alt="" />
            </div>
          </div>
        </header>
        <div className="container inner-height">
          <div className="row ">
            <div className="col-lg-10 offset-lg-1 col-sm-02 col-12 mp0  text-center animated bounceInDown">
              <img src="thumb.png" alt="" />
              <h1 className="animated zoomInUp">Thank you</h1>
              {/* <p className="thankuparag">
                You have successfully submitted your claim
              </p> */}
              {kycPopup ? (
                <p className="thankuparag">
                  Thank you for submitting your claim to TMS Legal. We have
                  completed your digital ID check and it was successful. We will
                  now email you copies of all signed documents and we will call
                  you shortly to verify your details and answer any questions.
                </p>
              ) : (
                <p className="thankuparag">
                  You have successfully submitted your claim
                </p>
              )}
            </div>
          </div>
        </div>
        <div className="footerclass">
          <Footer />
        </div>
      </div>
    </>
  );
}

export default Thankyou;
