import React, { useState } from "react";
import RadioButtonQuest from "../../../UI/RadioButtonQuest";
const MinimumPayment = ({
  className,
  saveQuestAnswer,
  addPending,
  removeFromPending,
  qnNo
}) => {
  const [questResult, setQuestResult] = useState(false);
  const [checkedOne, setCheckedOne] = useState("unchecked");
  const [checkedTwo, setCheckedTwo] = useState("unchecked");
  const [checkedThree, setCheckedThree] = useState("unchecked");
  const [checkedFour, setcheckedFour] = useState("unchecked");
  const [checkedFive, setcheckedFive] = useState("unchecked");
  const optionClicked = async (e) => {
    if (e.target.value === "146") {
      setCheckedOne("checked");
    } else {
      setCheckedOne("unchecked");
    }

    if (e.target.value === "147") {
      setCheckedTwo("checked");
    } else {
      setCheckedTwo("unchecked");
    }

    if (e.target.value === "148") {
      setCheckedThree("checked");
    } else {
      setCheckedThree("unchecked");
    }

    if (e.target.value === "149") {
      setcheckedFour("checked");
    } else {
      setcheckedFour("unchecked");
    }
    if (e.target.value === "150") {
      setcheckedFive("checked");
    } else {
      setcheckedFive("unchecked");
    }
    setQuestResult(false);
  };
  const selectedClick = async (e) => {
    saveQuestAnswer(56, e.target.value);
    removeFromPending("minPayment");
  };
  return (
    <>
      <div className={`container ${className}`}>
        <div className="questitem mt-5 scale-down-ver-center px-3 py-lg-3 py-md-3 py-sm-2 py-2 pb-3 ">
          <h1 className="que-title p-3 ">
            How often did you only{" "}
            <strong>
              {" "}
              make the minimum payment due on the monthly account statement
            </strong>{" "}
            (rather than paying off a larger amount)?
          </h1>
          <div className="row">
            <div className="col-lg-12 mb-2">
              <ul className="p-0 m-0 option-grid">
                <li className="adjustbalewid">
                  <div className="optionbtn">
                    <RadioButtonQuest
                      name="minmumPaymnt"
                      id="optionzthree"
                      labelClassName={`${checkedOne}`}
                      value="146"
                      labelName="Always"
                      onClick={selectedClick}
                      dataQuestId={56}
                      dataAnsId={146}
                      dataAnsLabel="Always"
                      dataClickId='minPayment_always'
                    />
                  </div>
                </li>

                <li className="adjustbalewid">
                  <div className="optionbtn">
                    <RadioButtonQuest
                      name="minmumPaymnt"
                      id="optionzthree2"
                      labelClassName={`${checkedTwo}`}
                      value="147"
                      labelName="Sometimes"
                      onClick={selectedClick}
                      dataQuestId={56}
                      dataAnsId={147}
                      dataAnsLabel="Sometimes"
                      dataClickId='minPayment_sometimes'
                    />
                  </div>
                </li>
                <li className="adjustbalewid">
                  <div className="optionbtn">
                    <RadioButtonQuest
                      name="minmumPaymnt"
                      id="optionzthree3"
                      labelClassName={`${checkedThree}`}
                      value="148"
                      labelName="Twice"
                      onClick={selectedClick}
                      dataQuestId={56}
                      dataAnsId={148}
                      dataAnsLabel="Twice"
                      dataClickId='minPayment_twice'
                    />
                  </div>
                </li>
                <li className="adjustbalewid">
                  <div className="optionbtn">
                    <RadioButtonQuest
                      name="minmumPaymnt"
                      id="optionzthree4"
                      labelClassName={`${checkedFour}`}
                      value="149"
                      labelName="Once"
                      onClick={selectedClick}
                      dataQuestId={56}
                      dataAnsId={149}
                      dataAnsLabel="Once"
                      dataClickId='minPayment_once'
                    />
                  </div>
                </li>
                <li className="adjustbalewid">
                  <div className="optionbtn">
                    <RadioButtonQuest
                      name="minmumPaymnt"
                      id="optionzthree5"
                      labelClassName={`${checkedFive}`}
                      value="150"
                      labelName="Never"
                      onClick={selectedClick}
                      dataQuestId={56}
                      dataAnsId={150}
                      dataAnsLabel="Never"
                      dataClickId='minPayment_never'
                    />
                  </div>
                </li>
              </ul>
              {/* {questResult && (
                            <span className="error_msg">Please select an option</span>
                        )} */}
            </div>
            {/* <div className="col-lg-12 mb-2 mt-2">
                    <button 
                    className="qb16 btn-comm2 py-lg-3 py-md-3 py-sm-2 py-2 w-100 nextBtn"
                    name="minmumPaymnt"
                    type="button"
                    onClick={selectedClick}
                    data-quest-id={56}
                >
                    Next &#8594; 
                </button>
                        
                  </div> */}
          </div>
          <p className="text-center bck-marwid">
            <button
              name="excdCreditLimit"
              className="btn btn-secondary backbtn1a"
              id=""
              value=""
              type="button"
              onClick={() => addPending("exceedingCredLimit")}
              data-click-id='minPayment_back'
            >
              Go Back
            </button>
          </p>
        </div>
        <div class="tipbox tip2 show">
        This is to understand your repaying pattern every month.
          <div class="arrow  arrow-top"></div>
        </div>
        <div
          id="rangequestions"
          class="qrange d-flex align-items-sm-center text-center"
        >
          Question <br class="d-none d-md-block" />{qnNo}
        </div>
      </div>
    </>
  );
};
export default MinimumPayment;
