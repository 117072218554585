import React, { useEffect, useState, useReducer, useContext } from "react";
import { Api } from "../../api/Api";
import { queryString, urlParams } from "../../Utility/QueryString";
import { useHistory } from "react-router-dom";
import { useFollowupLoad } from "../../Hooks/useFollowupLoad";
import { useFollowupUserQuestions } from "../../Hooks/useFollowupUserQuestions";
import { useSkippedLeads } from "../../Hooks/useSkippedLeads";
import VisitorParamsContext from "../../Contexts/VisitorParams";
import { useForm } from "react-hook-form";
import {
  questinnaire76Vanquis,
  questinnaire36Vanquis,
  questinnaire76FlpVanquis,
} from "../../Constants/Questions";
import logowhite from "../../assets/FollowupS4/img/logo-white.png";
import "../../assets/FollowupS4/scss/modules/questionnaire.scss";
import "../../assets/FollowupS4/scss/modules/custom.scss";
import Loader from "../Includes/Layouts/FollowupS4/Loader";
import Signature from "../Includes/Layouts/FollowupS4/Signature";
import Hardship from "../Includes/Layouts/FollowupS4/Hardship";
import Further1 from "../Includes/Layouts/FollowupS4/Further1";
import Further2 from "../Includes/Layouts/FollowupS4/Further2";
import Banks from "../Includes/Layouts/FollowupS4/Banks";
import StillActive from "../Includes/Layouts/FollowupS4/StillActive";
import RecentCreditIncrease from "../Includes/Layouts/FollowupS4/RecentCreditIncrease";
import ClosedYear from "../Includes/Layouts/FollowupS4/ClosedYear";
import OutstandBal from "../Includes/Layouts/FollowupS4/OutstandBal";
import OutBalAmount from "../Includes/Layouts/FollowupS4/OutBalAmount";
import EmploymentStatus from "../Includes/Layouts/FollowupS4/EmploymentStatus";
import SoldOnDept from "../Includes/Layouts/FollowupS4/SoldOnDept";
import HighestBalance from "../Includes/Layouts/FollowupS4/HighestBalance";
import CreditLimitIncreased from "../Includes/Layouts/FollowupS4/CreditLimitIncreased";
import ExceedingCreditLimit from "../Includes/Layouts/FollowupS4/ExceedingCreditLimit";
import MinimumPayment from "../Includes/Layouts/FollowupS4/MinimumPayment";
import DidReceiveIncome from "../Includes/Layouts/FollowupS4/DidReceiveIncome";
import CardMonthlyIncome from "../Includes/Layouts/FollowupS4/CardMonthlyIncome";
import Dependents from "../Includes/Layouts/FollowupS4/Dependents";
import Emailconfirmation from "../Includes/Layouts/FollowupS4/Emailconfirmation";
import WhatYearHappenV2 from "../Includes/Layouts/FollowupS4/WhatYearHappenV2";
import CreditCardOpenYear from "../Includes/Layouts/FollowupS4/CreditCardOpenYear";
import PreviewOne_FLP_S4 from "../Includes/Layouts/FollowupS4/PreviewOne_FLP_S4";
import PreviewTwo_FLP_S4 from "../Includes/Layouts/FollowupS4/PreviewTwo_FLP_S4";
import ID_Upload_FLP_S4 from "../Includes/Layouts/FollowupS4/ID_Upload_FLP_S4";
import MeritPage_FLP_S4 from "../Includes/Layouts/FollowupS4/MeritPage_FLP_S4";
import { useUserClickStore } from "../../Hooks/useClickDetailStore";
import { TrackUserActions } from "../../Utility/TrackUserActions";
import VerifyData_FLP_S4 from "../Includes/Layouts/FollowupS4/VerifyData_FLP_S4";
import DocumentClaimPreview from "../Includes/Layouts/FollowupS4/DocumentClaimPreview";

const initialState = {
  loader: true,
  signature: false,
  hardship: false,
  further1: false,
  further2: false,
  verify: false,
  meritPage: false,
  documentClaim: false,
  preview: false,
  chooseLender: false,
  credCardOpenYear: false,
  stillActive: false,
  acntCloseYear: false,
  credLimitIncrYear: false,
  debtBalance: false,
  debtBalValue: false,
  soldOnDebt: false,
  whatYearHappen: false,
  empStatus: false,
  highestBalance: false,
  credLimitIncrCount: false,
  exceedingCredLimit: false,
  minPayment: false,
  receiveIncome: false,
  monthlyIncome: false,
  dependents: false,
  emailConfirm: false,
  emailAddress: false,
  second_preview: false,
  idUpload: false,
  supportDoc: false,
};

const QuestionnaireReducer = (state, action) => {
  switch (action.type) {
    case "showSlideChange": {
      const updatedState = Object.keys(state).reduce((acc, key) => {
        acc[key] = key === action.payload ? true : false;
        setTimeout(() => {
          window.scrollTo(0, 0);
        }, 500);
        return acc;
      }, {});
      return { ...state, ...updatedState };
    }
    default:
      return state;
  }
};

const FollowupS4 = () => {
  const history = useHistory();
  const { loadFollowup } = useFollowupLoad();
  const { saveFollowupUserQuestions } = useFollowupUserQuestions();
  const { skippedLeads } = useSkippedLeads();
  const [state, dispatch] = useReducer(QuestionnaireReducer, initialState);
  const { visitorParameters } = useContext(VisitorParamsContext);
  const [pendingQuestionnaire, setPendingQuestionnaire] = useState(["loader"]);
  const [showUserName, setUserName] = useState(null);
  const [uuid, setUuid] = useState(null);
  const [allFollowupData, setFollowupData] = useState(false);
  const [optionSelected] = useState([]);
  const [optionSelected76] = useState([]);
  const [t2aApiReqData, setT2aApiReqData] = useState({});
  const [stillAct, setStillAct] = useState("");
  const [cardOpenYr, setCardOpenYr] = useState([]);
  const [userEmail, setUserEmail] = useState();
  const { userClickStore } = useUserClickStore();
  const { register, errors, trigger, setError, clearErrors, getValues } =
    useForm({
      mode: "onBlur",
      reValidateMode: "onChange",
      defaultValues: {},
      resolver: undefined,
      context: undefined,
      criteriaMode: "firstError",
      shouldFocusError: true,
      shouldUnregister: true,
    });

  useEffect(() => {
    (async () => {
      const response = await Api.get(
        "/api/v1/followup/get-details-s4?query_string=" + queryString,
        {}
      );
      if (response.data.status === "Success") {
        let slidePending = response.data.response.slides;
        let username = response.data.response.username;
        let uuid = response.data.response.uuid;
        let email = response.data.response.email;
        let followup_data = response.data.response.followup_data;
        localStorage.setItem("uuid", uuid);
        let fname = response.data.response.first_name;
        let lname = response.data.response.last_name;
        let postcode = response.data.response.postcode;
        let address_line1 = response.data.response.address_line1;
        let dob_day = response.data.response.dob_day;
        let dob_month = response.data.response.dob_month;
        let dob_yr = response.data.response.dob_yr;
        let address_line2 = response.data.response.address_line2;
        let town = response.data.response.town;
        let country = response.data.response.country;
        let title = response.data.response.title;
        let addressLine3 = response.data.response.address_line3;
        let addressLine4 = response.data.response.address_line4;
        let county = response.data.response.county;
        let t2a_status = response.data.response.t2a_status;
        let split_name = response.data.response.split_name;
        let openYear = response.data.response.credCardOpenYear;
        localStorage.setItem("flp_source", followup_data.atp_sub6);
        localStorage.setItem("allFollowupData", JSON.stringify(followup_data));
        localStorage.setItem("t2aValidation", t2a_status ? t2a_status : '0');
        localStorage.setItem("split_name", split_name);
        visitorParameters.email = email || false;

        setT2aApiReqData({
          uuid: uuid,
          fName: fname,
          lName: lname,
          pCode: postcode,
          addressLine1: address_line1,
          day: dob_day,
          month: dob_month,
          year: dob_yr,
          addressLine2: address_line2,
          postTown: town,
          country: country,
          title: title,
          addressLine3: addressLine3,
          addressLine4: addressLine4,
          county: county,
        });

        setUserName(username);
        setUuid(uuid);
        setFollowupData(followup_data);
        setUserEmail(email);
        setCardOpenYr(openYear);
        const flpResponse = await loadFollowup(
          "followup_load",
          followup_data,
          "followup-s4",
          queryString,
          "s4"
        );
        setTimeout(() => {
          setPendingQuestionnaire(slidePending);
        }, 3000);
      } else {
        history.push("/Vanquisthankyou?uuid=" + response.data.response.uuid);
        return false;
      }
    })();
  }, []);

  const saveUserAction = (e) => {
    try {
      const userDetails = TrackUserActions(e);
      if (userDetails) {
        const { page, action, click_type, source, user_uuid } = userDetails;

        userClickStore(user_uuid, page, action, click_type, source);  
      }
    } catch (error) {
      console.error("Error saving user action:", error);
    }
  };


  useEffect(() => {
    const handleClick = (e) => {
      if (e.target.getAttribute('data-click-id')) {
         saveUserAction(e);
      }
    };
    
    window.addEventListener('click', handleClick);
    return () => {
      window.removeEventListener('click', handleClick);
    };
  }, []);
  


  useEffect(() => {
    slideChange(pendingQuestionnaire);
  }, [pendingQuestionnaire]);

  const slideChange = (pendingArr) => {
    let pendingCount = pendingArr.length;
    if (pendingCount == 0) {
      history.push("/Vanquisthankyou?uuid=" + uuid);
      return false;
    }
    pendingArr.every((element) => {
      dispatch({ type: "showSlideChange", payload: element });
    });
  };

  const removeFromPending = (slidename) => {
    const index = pendingQuestionnaire.indexOf(slidename);
    if (index > -1) {
      pendingQuestionnaire.splice(index, 1);
    }
    slideChange(pendingQuestionnaire);
  };

  const addPending = (slidename) => {
    let index = pendingQuestionnaire.indexOf(slidename);
    if (index !== -1) {
      pendingQuestionnaire.splice(index, 1);
    }

    pendingQuestionnaire.unshift(slidename);
    slideChange(pendingQuestionnaire);
  };
  
  
  

  const unQualifiedAnswer = (e) => {
    let selectedAns = parseInt(e.target.getAttribute("data-ans-id"));
    let selectedQuest = parseInt(e.target.getAttribute("data-quest-id"));
    let flag = false;
    let question_data = {};

    if (selectedQuest && selectedAns) {
      flag = true;
      question_data = {
        question_id: selectedQuest,
        option_id: selectedAns,
        answer_text: "",
        input_answer: null,
      };
    }

    if (flag == true) {
      storeFollowupQuestionnire(
        question_data,
        "unqualified_question_store_flp"
      );
    }
  };

  const onselectAnswer = async (e) => {
    let selectedAns = parseInt(e.target.getAttribute("data-ans-id"));
    let selectedQuest = parseInt(e.target.getAttribute("data-quest-id"));
    let anslabel = e.target.getAttribute("data-ans-label");
    let flage = false;
    let question_data = {};

    if (e.target.name == "question_29") {
      if (selectedAns && selectedQuest) {
        flage = true;
        question_data = {
          question_id: selectedQuest,
          option_id: selectedAns,
          answer_text: anslabel,
          input_answer: "",
        };
      }
      removeFromPending("hardship");
    } else if (e.target.name == "question_76") {
      if (selectedQuest) {
        flage = true;
        question_data = {
          question_id: 76,
          option_id: null,
          answer_text: anslabel,
          input_answer: optionSelected76,
        };
      }
      removeFromPending("further1");
    } else if (e.target.name == "question_36") {
      if (selectedQuest) {
        flage = true;
        question_data = {
          question_id: 36,
          option_id: null,
          answer_text: anslabel,
          input_answer: optionSelected,
        };
      }
      removeFromPending("further2");
    } else {
      if (selectedQuest) {
        flage = true;
        question_data = {
          question_id: selectedQuest,
          option_id: null,
          answer_text: null,
          input_answer: anslabel,
        };
      }
    }

    if (flage) {
      storeFollowupQuestionnire(question_data, "followup_question_store");
      var existingEntries = JSON.parse(localStorage.getItem("questionData"));
      if (existingEntries == null) existingEntries = [];
      localStorage.setItem(
        "currentquestionData",
        JSON.stringify(question_data)
      );
      existingEntries.push(question_data);
      localStorage.setItem("questionData", JSON.stringify(existingEntries));
    }
  };
  const saveQuestAnswer = async (qstn, val = null, label = null) => {
    let selectedAns = val;
    let selectedQuest = qstn;
    let selectedLabel = label;
    let flage = true;
    let question_data = {};

    question_data = {
      question_id: selectedQuest,
      option_id: selectedAns,
      answer_text: null,
      input_answer: selectedLabel,
    };

    storeFollowupQuestionnire(question_data, "followup_question_store");
    var existingEntries = JSON.parse(localStorage.getItem("questionData"));
    if (existingEntries == null) existingEntries = [];
    localStorage.setItem("currentquestionData", JSON.stringify(question_data));
    existingEntries.push(question_data);
    localStorage.setItem("questionData", JSON.stringify(existingEntries));
  };

  const storeFollowupQuestionnire = async (question_data, msg_type) => {
    const response = await saveFollowupUserQuestions(
      question_data,
      allFollowupData,
      msg_type
    );
  };

  const SkippedLeads = async (type, flag = true) => {
    const skippedLeadsResponse = await skippedLeads(type, uuid, "Followup-s4");
    if (flag && skippedLeadsResponse.data.status == "Success") {
      // redirectToPreview();
    }
  };

  return (
    <>
      <div className={`FollowupS4`}>
        {!state.meritPage && !state.preview && !state.second_preview && !state.idUpload && !state.supportDoc ? (
          <section className="banner qs-section form-style">
            <div className="container">
              <div>
                <div className="brand">
                  <div className="logo pt-3 text-center">
                    <a>
                      <img src={logowhite} style={{ height: 60 }} alt="" />
                    </a>
                  </div>
                </div>
              </div>
            </div>
            {state.loader ? (
              <Loader showUserName={showUserName} showLoad={state.loader} />
            ) : null}
            {state.signature ? (
              <Signature
                allFollowupData={allFollowupData}
                uuid={uuid}
                cfirstName={showUserName}
                removeFromPending={removeFromPending}
              />
            ) : null}
            {state.hardship ? (
              <Hardship
                onselectAnswer={onselectAnswer}
                unQualifiedAnswer={unQualifiedAnswer}
              />
            ) : null}
            <Banks
              backClick={addPending}
              className={state.chooseLender ? "show" : "hide"}
              saveQuestAnswer={saveQuestAnswer}
              SkippedLeads={SkippedLeads}
              removeFromPending={removeFromPending}
              trigger={trigger}
              setError={setError}
              validation={register}
              clearErrors={clearErrors}
              validationMsg={errors}
              qnNo="1 /20"
            />
            <Further1
              questArr={questinnaire76FlpVanquis}
              onselectAnswer={onselectAnswer}
              optionSelected76={optionSelected76}
              addPending={addPending}
              className={state.further1 ? "show" : "hide"}
              qnNo="2 /20"
            />
            <Further2
              questArr={questinnaire36Vanquis}
              onselectAnswer={onselectAnswer}
              optionSelected={optionSelected}
              addPending={addPending}
              className={state.further2 ? "show" : "hide"}
              qnNo="3 /20"
            />
            {state.verify ? (
              <VerifyData_FLP_S4
                currentUuid={uuid}
                t2aApiReqData={t2aApiReqData}
                allFollowupData={allFollowupData}
                removeFromPending={removeFromPending}
              />
            ) : null}
            <CreditCardOpenYear
              backClick={addPending}
              className={state.credCardOpenYear ? "show" : "hide"}
              saveQuestAnswer={saveQuestAnswer}
              removeFromPending={removeFromPending}
              validation={register}
              clearErrors={clearErrors}
              trigger={trigger}
              validationMsg={errors}
              getValues={getValues}
              addPending={addPending}
              setCardOpenYr={setCardOpenYr}
              qnNo="4 /20"
            />
            <StillActive
              className={state.stillActive ? "show" : "hide"}
              saveQuestAnswer={saveQuestAnswer}
              removeFromPending={removeFromPending}
              setStillAct={setStillAct}
              addPending={addPending}
              qnNo="5 /20"
            />
            <RecentCreditIncrease
              className={state.credLimitIncrYear ? "show" : "hide"}
              saveQuestAnswer={saveQuestAnswer}
              validation={register}
              clearErrors={clearErrors}
              trigger={trigger}
              validationMsg={errors}
              getValues={getValues}
              addPending={addPending}
              cardOpenYr={cardOpenYr}
              removeFromPending={removeFromPending}
              qnNo="6 /20"
            />
            <ClosedYear
              className={state.acntCloseYear ? "show" : "hide"}
              saveQuestAnswer={saveQuestAnswer}
              validation={register}
              clearErrors={clearErrors}
              trigger={trigger}
              validationMsg={errors}
              getValues={getValues}
              removeFromPending={removeFromPending}
              cardOpenYr={cardOpenYr}
              addPending={addPending}
              qnNo="7 /20"
            />
            <OutstandBal
              className={state.debtBalance ? "show" : "hide"}
              saveQuestAnswer={saveQuestAnswer}
              validation={register}
              addPending={addPending}
              removeFromPending={removeFromPending}
              stillAct={stillAct}
              qnNo="8 /20"
            />
            <OutBalAmount
              className={state.debtBalValue ? "show" : "hide"}
              saveQuestAnswer={saveQuestAnswer}
              validation={register}
              clearErrors={clearErrors}
              trigger={trigger}
              validationMsg={errors}
              getValues={getValues}
              addPending={addPending}
              removeFromPending={removeFromPending}
              qnNo="9 /20"
            />
            <SoldOnDept
              className={state.soldOnDebt ? "show" : "hide"}
              saveQuestAnswer={saveQuestAnswer}
              validation={register}
              stillAct={stillAct}
              addPending={addPending}
              removeFromPending={removeFromPending}
              qnNo="10 /20"
            />
            <WhatYearHappenV2
              className={state.whatYearHappen ? "show" : "hide"}
              saveQuestAnswer={saveQuestAnswer}
              validation={register}
              clearErrors={clearErrors}
              trigger={trigger}
              validationMsg={errors}
              getValues={getValues}
              addPending={addPending}
              removeFromPending={removeFromPending}
              cardOpenYr={cardOpenYr}
              qnNo="11 /20"
            />
            <EmploymentStatus
              className={state.empStatus ? "show" : "hide"}
              saveQuestAnswer={saveQuestAnswer}
              addPending={addPending}
              removeFromPending={removeFromPending}
              qnNo="12 /20"
            />
            <HighestBalance
              className={state.highestBalance ? "show" : "hide"}
              saveQuestAnswer={saveQuestAnswer}
              validation={register}
              clearErrors={clearErrors}
              trigger={trigger}
              validationMsg={errors}
              addPending={addPending}
              getValues={getValues}
              removeFromPending={removeFromPending}
              qnNo="13 /20"
            />
            <CreditLimitIncreased
              className={state.credLimitIncrCount ? "show" : "hide"}
              saveQuestAnswer={saveQuestAnswer}
              addPending={addPending}
              removeFromPending={removeFromPending}
              qnNo="14 /20"
            />
            <ExceedingCreditLimit
              className={state.exceedingCredLimit ? "show" : "hide"}
              saveQuestAnswer={saveQuestAnswer}
              addPending={addPending}
              removeFromPending={removeFromPending}
              qnNo="15 /20"
            />
            <MinimumPayment
              className={state.minPayment ? "show" : "hide"}
              saveQuestAnswer={saveQuestAnswer}
              addPending={addPending}
              removeFromPending={removeFromPending}
              qnNo="16 /20"
            />
            <DidReceiveIncome
              className={state.receiveIncome ? "show" : "hide"}
              saveQuestAnswer={saveQuestAnswer}
              addPending={addPending}
              removeFromPending={removeFromPending}
              qnNo="17 /20"
            />
            <CardMonthlyIncome
              className={state.monthlyIncome ? "show" : "hide"}
              validation={register}
              clearErrors={clearErrors}
              trigger={trigger}
              validationMsg={errors}
              getValues={getValues}
              saveQuestAnswer={saveQuestAnswer}
              addPending={addPending}
              removeFromPending={removeFromPending}
              qnNo="18 /20"
            />
            <Dependents
              className={state.dependents ? "show" : "hide"}
              saveQuestAnswer={saveQuestAnswer}
              addPending={addPending}
              removeFromPending={removeFromPending}
              qnNo="19 /20"
            />
            <Emailconfirmation
              className={state.emailConfirm ? "show" : "hide"}
              userEmail={userEmail}
              saveQuestAnswer={saveQuestAnswer}
              getValues={getValues}
              validation={register}
              clearErrors={clearErrors}
              trigger={trigger}
              validationMsg={errors}
              addPending={addPending}
              removeFromPending={removeFromPending}
              uuid={uuid}
              allFollowupData={allFollowupData}
              qnNo="20 /20"
            />
          </section>
        ) : null}

        {state.meritPage ? (
          <MeritPage_FLP_S4
            uuid={uuid}
            allFollowupData={allFollowupData}
            removeFromPending={removeFromPending}
          />
        ) : null}

        {state.documentClaim ? (
          <DocumentClaimPreview
            className={state.documentClaim ? "show" : "hide"}
            uuid={uuid}
            allFollowupData={allFollowupData}
            removeFromPending={removeFromPending}
          />
        ) : null}

        {state.preview ? (
          <PreviewOne_FLP_S4
            uuid={uuid}
            removeFromPending={removeFromPending}
          />
        ) : null}

        {state.second_preview ? (
          <PreviewTwo_FLP_S4
            uuid={uuid}
            removeFromPending={removeFromPending}
          />
        ) : null}

         {state.idUpload || state.supportDoc ? (
          <ID_Upload_FLP_S4
            uuid={uuid}
            removeFromPending={removeFromPending}
            pendingQuestionnaire={pendingQuestionnaire}
          />
        ) : null}

      </div>
    </>
  );
};

export default FollowupS4;
