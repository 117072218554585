import React, { useState } from "react";
const SoldOnDept = ({
  clsName,
  onSelectAnswer,
  validation,
  backClick,
  setPrevious,
  stillAct,
}) => {
  const [checkedOne, setCheckedOne] = useState("unchecked");
  const [checkedTwo, setCheckedTwo] = useState("unchecked");

  const optionSelected = async (e) => {
    if (e.target.value === "207") {
      setCheckedOne("checked");
      setPrevious("207");
      onSelectAnswer(e);
    } else {
      setCheckedOne("unchecked");
    }

    if (e.target.value === "206") {
      setCheckedTwo("checked");
      setPrevious("206");
      onSelectAnswer(e);
    } else {
      setCheckedTwo("unchecked");
    }
  };

  return (
    <>
      <div className="question-content qc-10 mt-0 scale-down-ver-center px-3 py-lg-3 py-md-3 py-sm-2 py-2 ">
        <div className="row">
          <div className="col-6">
            <div className=" mb-2">
              <button
                type="button"
                className={`qb10 btn-comm2 py-lg-3 py-md-3 py-sm-2 py-2 w-100 nextBtn ${checkedTwo}`}
                onClick={optionSelected}
                value="206"
                name="SoldOnDebt"
                id="nextscroll"
                data-quest-id={71}
                data-ans-id={206}
                data-ans-label={null}
              >
                Yes
              </button>
            </div>
          </div>
          <div className="col-6">
            <div className=" mb-2">
              <button
                type="button"
                className={`qb10 btn-comm2 py-lg-3 py-md-3 py-sm-2 py-2 w-100 nextBtn ${checkedOne}`}
                value="207"
                name="SoldOnDebt"
                onClick={optionSelected}
                id="nextscroll"
                data-quest-id={71}
                data-ans-id={207}
                data-ans-label={null}
              >
                No
              </button>
            </div>
          </div>
        </div>
        <p className="text-center bck-marwid">
          <button
            name="outBalAmount"
            className="btn btn-secondary backbtn1a"
            id=""
            value=""
            type="button"
            onClick={backClick}
          >
            Go Back
          </button>
        </p>
      </div>
    </>
  );
};
export default SoldOnDept;
