import React, { useContext, useEffect, useRef, useState } from "react";
import Modal from "react-bootstrap/Modal";
import Email from "../../../UI/Email";
import Telephone from "../../../UI/Telephone";
import { useFormContext } from "react-hook-form";
import { useEmailValidation } from "../../../../Hooks/useEmailValidation";
import { usePhoneValidation } from "../../../../Hooks/usePhoneValidation";
import privacy from "../../../../assets/VAQ_UB_4_3_1_C2/img/privacyicon.png";
import ErrorMessage from "./ErrorMessage";

const ContactDetails = () => {
  const { register, errors, getValues, setError } = useFormContext();
  const { emailValidation } = useEmailValidation();
  const { phoneValidation } = usePhoneValidation();

  const phoneValidCheck = async () => {
    const values = getValues();
    const phone = values["txtPhone"];
    const email = values["txtEmail"];

    if (phone !== "" && email !== "") {
      document.getElementById("formSubmitBtn").classList.add("anim_ylw");
    } else {
      document.getElementById("formSubmitBtn").classList.remove("anim_ylw");
    }
    if (phone !== "") {
      const getPhoneValidation = await phoneValidation(phone);
      if (getPhoneValidation.data.status_code === 0) {
        setError("txtPhone", {
          type: "manual",
          message: "Please Enter Valid Working Phone Number",
        });
        return 0;
      } else if (getPhoneValidation.data.status_code === 2) {
        setError("txtPhone", {
          type: "manual",
          message: "Phone Number Already Exist",
        });
        return 0;
      } else if (getPhoneValidation.data.status_code === 3) {
        setError("txtPhone", {
          type: "manual",
          message: "Please Enter Valid Phone Number",
        });
        return 0;
      } else {
        return 1;
      }
    }
  };

  const emailValidCheck = async () => {
    const values = getValues();
    const email = values["txtEmail"];
    const phone = values["txtPhone"];
    if (phone !== "" && email !== "") {
      document.getElementById("formSubmitBtn").classList.add("anim_ylw");
    } else {
      document.getElementById("formSubmitBtn").classList.remove("anim_ylw");
    }
    if (email !== "") {
      const getEmailValidation = await emailValidation(email);
      if (getEmailValidation.data.status_code === 1) {
        return 1;
      } else {
        setError("txtEmail", {
          type: "manual",
          message: getEmailValidation.data.message,
        });
      }
    }
  };

  return (
    <>
      <div className="row">
        <div className="col-lg-6  ">
          <div className="mb-2 input-group">
            <Telephone
              name="txtPhone"
              id="txtPhone"
              className="form-control"
              placeholder="Mobile Number"
              onBlur={phoneValidCheck}
              onKeyPress={(e) => !/[0-9]+$/.test(e.key) && e.preventDefault()}
              maxlength="11"
              validation={register({
                pattern: {
                  value: /^[0-9]*$/i,
                  message: "Please enter a valid phone number",
                },
                required: "Please enter a valid phone number",
              })}
            />
            <span className="input-group-text privacyico">
              <img src={privacy} alt="" />
            </span>
            {errors?.txtPhone && (
              <ErrorMessage message={errors?.txtPhone?.message} />
            )}
          </div>
        </div>
        <div className="col-lg-6 ps-lg-0 ">
          <div className="mb-2 input-group">
            <Email
              name="txtEmail"
              className="form-control"
              id="txtEmail"
              placeholder="Email Address"
              onBlur={emailValidCheck}
              validation={register({
                required: "Please enter valid email address",
                pattern: {
                  value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
                  message: "Invalid email address",
                },
              })}
            />
            <span className="input-group-text privacyico">
              <img src={privacy} alt="" />
            </span>
            {errors?.txtEmail && (
              <ErrorMessage message={errors?.txtEmail?.message} />
            )}
          </div>
        </div>
      </div>
    </>
  );
};
export default ContactDetails;
