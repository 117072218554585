import React, {
  useEffect,
  useState,
  useReducer,
  useRef,
  useContext,
} from "react";
import { useForm } from "react-hook-form";
import "../../assets/QuestionaireMBGN_4_2/scss/questionnaire1.scss";
import "../../assets/QuestionaireMBGN_4_2/scss/customGN4.scss";
import "../../assets/QuestionaireMBGN_4_2/scss/checklistGN4.scss";
import "../../assets/Quest_VAQ_UB_4_3/scss/modules/custom.scss";
import GetVisitorsParams from "../../Utility/GetVisitorsParams";
import { useSkippedLeads } from "../../Hooks/useSkippedLeads";
import { useQuestionnaire } from "../../Hooks/useQuestionnaire";
import VisitorParamsContext from "../../Contexts/VisitorParams";
import { FormData } from "../../Utility/FormData";
import { userInfo } from "../../Hooks/userInfo";
import { useHistory } from "react-router-dom";
import { MonthNumberArray } from "../../Constants/Constants";
import GtmDataLayer from "../../Utility/GtmDataLayer";
import tmslogo from "../../assets/QuestionaireMBGN_4_2/img/logo-white.png";
import LoaderIst from "../Includes/Layouts/QuestionaireMB_UFGN_4_2/LoaderOne";
import tickgif from "../../assets/QuestionaireMBGN_4_2/img/5ZCggE3Tml.gif";
import Banks from "../Includes/Layouts/QuestionaireMB_UFGN_4_2/Banks";
import LoaderTwo from "../Includes/Layouts/QuestionaireMB_UFGN_4_2/LoaderTwo";
import Footer from "../Includes/Layouts/QuestionaireMB_UFGN_4_2/Footer";
import BankStatement from "../Includes/Layouts/QuestionaireMB_UFGN_4_2/BankStatement";
import CarRegistration from "../Includes/Layouts/QuestionaireMB_UFGN_4_2/RegistrationNo";
import KeeperDate from "../Includes/Layouts/QuestionaireMB_UFGN_4_2/KeeperDate";
import YearOfPossession from "../Includes/Layouts/QuestionaireMB_UFGN_4_2/YearOfPossession";
import FurtherDetails from "../Includes/Layouts/QuestionaireMB_UFGN_4_2/FurtherDetails";
import Confirmsituation from "../Includes/Layouts/QuestionaireMB_UFGN_4_2/Confirmsituation";
import { questinnaire76MB_4_2, questinnare2MB } from "../../Constants/Questions";
import { obLender } from "../../Hooks/useOblender";
import Struggle from "../Includes/Layouts/QuestionaireMB_UFGN_4_2/Struggle";

import OldCard from "../Includes/Layouts/QuestionaireMB_UFGN_4_2/OldCard";
import DidYouAware from "../Includes/Layouts/QuestionaireMB_UFGN_4_2/DidYouAware";
import LatePayment from "../Includes/Layouts/QuestionaireMB_UFGN_4_2/LatePayment";
import Difficulties from "../Includes/Layouts/QuestionaireMB_UFGN_4_2/Difficulties";
import DescribeProblem from "../Includes/Layouts/QuestionaireMB_UFGN_4_2/DescribeProblem";

const QuestionaireMBGN_4_2 = () => {
  const initialState = {
    LoaderOne: "show",
    banks_slide: "hide",
    LoaderTwo: "hide",
    sectionquest: "",
    showSlide1: "hide",
    showSlide1a: "hide",
    showSlide2: "hide",
    showSlide2a: "hide",
    showSlide3: "hide",
    showSlide3a: "hide",
    showSlide4: "hide",
    showSlide4a: "hide",
    showSlide5: "hide",
    showSlide5a: "hide",
    showSlide6: "hide",
    showSlide6a: "hide",
    showSlide_oldCard: "hide",
    showSlide_didYouAware: "hide",
    showSlide_LatePayment: "hide",
    Prompt_LatePayment: "hide",
    showSlide_Difficulties: "hide",
    showSlide_DescribeProblem: "hide",
    Prompt_DescribeProblem: "hide",
    prev: "",
    closedPrev: "",
    IandEPrev: "",
    showSlide22: "hide",
  };
  const [prevStill, setPrevStill] = useState("");
  const [prevKeep, setPrevKeep] = useState("");
  const [prev76, setPrev76] = useState("");
  const FormReducer = (state, action) => {
    switch (action.type) {
      case "showSlide": {
        if (action.payload.clickedSlide.slide == "Loader1") {
          return {
            ...state,
            LoaderOne: "hide",
            banks_slide: "show",
          };
        } else if (action.payload.clickedSlide.slide == "banks") {
          return {
            ...state,
            banks_slide: "hide",
            LoaderTwo: "show",
          };
        } else if (action.payload.clickedSlide.slide == "Loader2") {
          return {
            ...state,
            banks_slide: "hide",
            LoaderTwo: "hide",
            sectionquest: "hide",
            showSlide5: "show",
            showSlide5a: "show",
          };
        } else if (action.payload.clickedSlide.slide == "bankstatement") {
          GtmDataLayer({
            question: "provide any details",
          });
          return {
            ...state,
            showSlide1: "show",
            showSlide1a: "hide",
            showSlide2: "show",
            showSlide2a: "show",
          };
        } else if (action.payload.clickedSlide.slide == "CarRegno") {
          return {
            ...state,
            showSlide2: "show",
            showSlide2a: "hide",
            showSlide3: "show",
            showSlide3a: "show",
          };
        } else if (action.payload.clickedSlide.slide == "skipSlide") {
          return {
            ...state,
            showSlide4: "show",
            showSlide4a: "show",
            showSlide2a: "hide",
          };
        } else if (
          action.payload.clickedSlide.slide == "keeperDate" && state.prev == 0
        ) {
          GtmDataLayer({
            question: "start the finance",
          });
          return {
            ...state,
            showSlide3a: "hide",
            showSlide4: "hide",
            showSlide4a: "hide",
            // showSlide_didYouAware: "show",
          };
        } else if (
          action.payload.clickedSlide.slide == "keeperDate" && state.prev == 1
        ) {                    
          GtmDataLayer({
            question: "start the finance",
          });
          return {
            ...state,
            showSlide3a: "hide",
             showSlide4: "show",
            showSlide4a: "show",
          };
        } else if (action.payload.clickedSlide.slide == "possessionyear") {
          return {
            ...state,
            showSlide4a: "hide",
            showSlide_didYouAware: "show",
          };
        } else if (action.payload.clickedSlide.slide == "btn_qest_76") {
          GtmDataLayer({
            question: "finance statement",
          });
          return {
            ...state,
            showSlide5: "show",
            showSlide5a: "hide",
            showSlide6: "show",
            showSlide6a: "show",
          };
        } else if (action.payload.clickedSlide.slide == "struggle") {
          GtmDataLayer({
            question: "hardships or struggle in making the repayments",
          });
          return {
            ...state,
            showSlide22: "hide",
            showSlide1: "show",
            showSlide1a: "show",
          };
        } else if (action.payload.clickedSlide.slide == "btn_qest_36") {
          return {
            ...state,
            showSlide6a: "hide",
            showSlide1: "show",
            showSlide1a: "show",
          };
        } else if (action.payload.clickedSlide.slide == "didYouAware") {
          GtmDataLayer({
            question: "aware of claim",
          });
          return {
            ...state,
            showSlide_LatePayment: "show",
            Prompt_LatePayment: "show",
          };
        } else if (action.payload.clickedSlide.slide == "latePayment" && action.payload.clickedSlide.slide_value == "238") {
          return {
            ...state,
            Prompt_LatePayment: "hide",
            showSlide_Difficulties: "show",
          };
        } else if (action.payload.clickedSlide.slide == "difficulties") {
          return {
            ...state,
            // showSlide_DescribeProblem: "show",
            // Prompt_DescribeProblem: "show",
          };
        } else if (action.payload.clickedSlide.slide == "describeProblem") {
          return {
            ...state,
            Prompt_DescribeProblem: "hide",
          };
        }
        else {
          return {
            ...state,
          };
        }
      }

      case "previousSlides": {
        if (action.payload.previousSlide.slide == "bankstatement") {
          return {
            ...state,
            showSlide2: "hide",
            showSlide2a: "hide",
            showSlide1: "show",
            showSlide1a: "show",
          };
        } else if (action.payload.previousSlide.slide == "CarRegno") {
          return {
            ...state,
            showSlide3:"hide",
            showSlide3a:"hide",
            showSlide4: "hide",
            showSlide4a: "hide",
            showSlide2: "show",
            showSlide2a: "show",
          };
        } else if (action.payload.previousSlide.slide == "prevconfirm") {
          if (prev76 == "from76") {
            return {
              ...state,
              showSlide3: "show",
              showSlide3a: "show",
              showSlide4: "hide",
              showSlide4a: "hide",
              showSlide_oldCard: "hide",
            };
          } else {
            return {
              ...state,
              showSlide4: "show",
              showSlide4a: "show",
              showSlide_oldCard: "hide",
            };
          }
        } else if (action.payload.previousSlide.slide == "btn_qest_76") {
          return {
            ...state,
            showSlide5a: "show",
            showSlide5: "show",
            showSlide6: "hide",
            showSlide6a: "hide",
          };
        } else if (action.payload.previousSlide.slide == "struggle") {
          return {
            ...state,
            // showSlide1a: "show",
            showSlide22: "show",
            showSlide1: "hide",
            showSlide1a: "hide",
          };
        } else if (action.payload.previousSlide.slide == "keeperDate") {
          return {
            ...state,
            // showSlide1a: "show",
            showSlide3: "show",
            showSlide3a: "show",
            showSlide4: "hide",
            showSlide4a: "hide",
            showSlide_didYouAware: "hide",
          };
        } else if (action.payload.previousSlide.slide == "possessionyear") {
          return {
            ...state,
            // showSlide1a: "show",
            showSlide4: "show",
            showSlide4a: "show",
            showSlide_didYouAware: "hide",
          };
        } else if (action.payload.previousSlide.slide == "btn_qest_36") {
          return {
            ...state,
            showSlide6: "show",
            showSlide6a: "show",
            showSlide1: "hide",
            showSlide1a: "hide",
          };
        } else if (action.payload.previousSlide.slide == "oldCard") {
          return {
            ...state,
            showSlide_didYouAware: "hide",
            showSlide_oldCard: "show",
          };
        } else if (action.payload.previousSlide.slide == "didYouAware") {
          return {
            ...state,
            showSlide_LatePayment: "hide",
            Prompt_LatePayment: "hide",
            showSlide_didYouAware: "show",
          };
        } else if (action.payload.previousSlide.slide == "latePayment") {
          return {
            ...state,
            showSlide_Difficulties: "hide",
            showSlide_LatePayment: "show",
            Prompt_LatePayment: "show",
          };
        } else if (action.payload.previousSlide.slide == "difficulties") {
          return {
            ...state,
            showSlide_DescribeProblem: "hide",
            Prompt_DescribeProblem: "hide",
            showSlide_Difficulties: "show",
          };
        }

      }
      default:
        return state;
    }
  };

  const splitForm = useRef(null);
  const scrollRef = useRef([]);
  const history = useHistory();
  const [clickedSlide, setClickedSlide] = useState([]);
  const [state, dispatch] = useReducer(FormReducer, initialState);
  const [optionSelected] = useState([]);
  const [optionSelected76] = useState([]);
  const [bankStatements, setBankStatements] = useState([]);
  const [SelectedOptions] = useState([]);
  const [completedQuestArr, setCompletedQuestArr] = useState([]);
  const { isLoading, SaveQuestionnaire } = useQuestionnaire();
  const { visitorParameters } = useContext(VisitorParamsContext);
  const query_string = localStorage.getItem("querystring");
  const formdata = JSON.parse(localStorage.getItem("formData"));
  const { setFormData } = FormData();
  const [splitName, setSplitName] = useState();
  const [splitNameForSkip, setSplitNameForSkip] = useState();
  const [showUserName, setUserName] = useState("");
  const { getUserInfo } = userInfo();
  const [supportDocType, setSupportDocType] = useState("");
  const [userEmail, setUserEmail] = useState();
  const [questionCounter, setQuestionCounter] = useState(0);
  let [totalQuestArr, setTotalQuestArr] = useState([
    61, 56, 55, 54, 53, 3, 2, 72, 71, 70, 26, 31, 36, 76, 9, 30, 62, 46,
  ]);
  const [previousSlide, setPreviousSlide] = useState([]);
  const [previous, setPrevious] = useState("");
  const [IandEPrev, setiandEPrev] = useState("");
  const [percentage, SetPercentage] = useState(0);
  const { getLenderlist } = obLender();
  const [lenderList, setLenderList] = useState([]);
  const [SelectBank, setSelectBank] = useState([]);

  const [stillAct, setStillAct] = useState("");
  const [vehicle, setVehicle] = useState("");
  const [totalCarCheck, settotalCarCheck] = useState("");
  const [validKeeperDate, setValidKeeperDate] = useState("");
  const [skip, setSkip] = useState(false);

  const [OldCardbck, setoldCardbck] = useState("");
  const [Latepymnentbck, setlatepymnentbck] = useState("");

  const [sortCode, setSortCode] = useState(false);
  const [accNum, setAccNum] = useState(false);
  const [lenderName, setLenderName] = useState('');
  const { skippedLeads } = useSkippedLeads();
  const [showError36, setshowError36] = useState("hide");
  const [showError76, setshowError76] = useState("hide");


  useEffect(() => {
    getLender();
    const timer = setTimeout(() => {
      setClickedSlide({ slide: "Loader1" });
    }, 4000);
  }, []);

  const { register, errors, trigger, setError, clearErrors, getValues } =
    useForm({
      mode: "onBlur",
      reValidateMode: "onChange",
      defaultValues: {},
      resolver: undefined,
      context: undefined,
      criteriaMode: "firstError",
      shouldFocusError: true,
      shouldUnregister: true,
    });

  const getUuid = () => {
    const visitorData = visitorParameters.visitor_parameters;
    if (visitorData != "") {
      var uuid = visitorData.uuid;
    } else {
      const query = new URLSearchParams(window.location.search);
      const query_uuid = query.get("uuid");
      const local_storage_uuid = localStorage.getItem("uuid");
      var uuid = query_uuid ? query_uuid : local_storage_uuid;
    }
    return uuid;
  };
  const uuid = getUuid();

  const getLender = async () => {
    try {
      const response = await getLenderlist();
      const dataLength = response.data.providers.length;

      if (dataLength > 0) {
        setLenderList(response.data.providers);
      }
    } catch (error) {
      console.error("Error fetching lender list:", error);
    }
  };

  useEffect(() => {
    if (formdata === undefined || formdata === null || formdata == "") {
      (async () => {
        const name = localStorage.getItem("UcFname");
        const response = await getUserInfo(uuid);
        const set_formdata = setFormData(response);
        let dataLength = response.data.response.length;
        if (dataLength > 0) {
          const pageName = response.data.response[0].page_name
            ? response.data.response[0].page_name
            : " ";
          setSplitName(pageName);
          if (
            localStorage.getItem("split_name") != undefined &&
            localStorage.getItem("split_name") != undefined
          ) {
            setSplitNameForSkip(localStorage.getItem("split_name"));
          } else {
            setSplitNameForSkip(pageName);
          }
          const userName = response.data.response[0].first_name
            ? response.data.response[0].first_name +
            " " +
            response.data.response[0].last_name
            : " ";
          var userDobN = response.data.response[0].user_dob.split("/");
          var DobDayF = userDobN[0];
          var DobMonthF = userDobN[1];
          var DobYearF = userDobN[2];
          setSupportDocType(
            response.data.response[0]?.questionnaire[42]["answerId"]
          );
          setUserName(name);
          setUserEmail(response.data.response[0].email);
        }
      })();
    } else {
      const name = localStorage.getItem("UcFname");
      const pageName = formdata.page_name;
      setSplitName(pageName);
      if (
        localStorage.getItem("split_name") != undefined &&
        localStorage.getItem("split_name") != undefined
      ) {
        setSplitNameForSkip(localStorage.getItem("split_name"));
      } else {
        setSplitNameForSkip(pageName);
      }
      const userName = formdata.txtFName + " " + formdata.txtLName;
      setUserName(name);
      const dobMonth = formdata.DobMonth
        ? MonthNumberArray[formdata.DobMonth]
        : "";
      setSupportDocType(formdata.question_42);
      setUserEmail(formdata.txtEmail);
    }
  }, [splitName]);

  state.prev = previous;
  state.closedPrev = stillAct;
  state.IandEPrev = IandEPrev;

  useEffect(() => {
    if (clickedSlide) {
      dispatch({ type: "showSlide", payload: { clickedSlide } });
      scrollToElement(clickedSlide.slide);
      setTickToElement(clickedSlide);
    }
  }, [clickedSlide]);

  const previousSlideChange = (e) => {
    setPreviousSlide({ slide: e.target.getAttribute("name") });
  };

  useEffect(() => {
    if (previousSlide) {
      dispatch({ type: "previousSlides", payload: { previousSlide } });
      scrollToElement(previousSlide.slide);
      slideBack(previousSlide);
    }
  }, [previousSlide]);

  const saveVehicleDetails = (vehicleData) => {
    setVehicle(vehicleData);
  };
  const savetotalCarCheck = (totalCarCheck) => {
    settotalCarCheck(totalCarCheck);
  };

  const MobBtnClick = async (e) => {
    if(e.target.name === "btn_qest_36"){
      let selectedCount = optionSelected76.length;
      if (selectedCount == 0) {
        setshowError36("show");
    } else {
        onSelectAnswer(e);
    }
    } else {
    let selectedCount = optionSelected.length;
    if (selectedCount == 0) {
      setshowError76("show");
    } else {
      onSelectAnswer(e);
    }
    }
};
  const checkCarRegistration = (e) => {
    let selectedQuest = parseInt(e.target.getAttribute("data-quest-id"));
    // let selectedAns = parseInt(e.target.getAttribute("data-ans-id"));
    // let anslabel = e.target.getAttribute("data-ans-label");
    let selectedAns = null;
    let anslabel = splitForm.current["question_10"].value;
    setCompletedQuestArr([...completedQuestArr, selectedQuest]);

    if (selectedQuest) {
      let question_data = {
        question_id: selectedQuest,
        option_id: selectedAns,
        answer_text: anslabel,
        input_answer: anslabel,
      };
      storeQuestionnire(question_data);
      var existingEntries = JSON.parse(localStorage.getItem("questionData"));
      if (existingEntries == null) existingEntries = [];
      localStorage.setItem(
        "currentquestionData",
        JSON.stringify(question_data)
      );
      existingEntries.push(question_data);
      localStorage.setItem("questionData", JSON.stringify(existingEntries));
    }
    setClickedSlide({ slide: e.target.name });
  };

  const storeQuestionnire = async (question_data) => {
    const response = await SaveQuestionnaire(
      visitorParameters.visitor_parameters,
      question_data,
      "question_store",
      visitorParameters.data,
      query_string,
      formdata
    );
  };

  const SkippedLeads = async (type) => {
    const skippedLeadsResponse = await skippedLeads(type, uuid, splitName);
    let array = JSON.parse(localStorage.getItem('questionData'));
    if(type == "skipAccountNumber") {
      array = array.filter(item => item.question_id !== 87);
      setClickedSlide({ slide: 'accountNumber' });
    } else if(type == "skipSortCode") {
      array = array.filter(item => item.question_id !== 86);
      setClickedSlide({ slide: 'sortCode' });
    }
    localStorage.setItem('questionData', JSON.stringify(array));
  }

  const slideSkip = () => {
    setPrevStill("tocarreg");
    setClickedSlide({ slide: "skipSlide" });
    setTickToElement({ slide: "skipSlide" });
  };
  const shareInfoSave = async (val) => {
    let selectedAns = val;
    let selectedQuest = 107;
    let flage = true;
    let question_data = {};
    setCompletedQuestArr([...completedQuestArr, selectedQuest]);
  
      question_data = {
        question_id: selectedQuest,
        option_id: selectedAns,
        answer_text: null,
        input_answer: null,
      };

    if (flage) {
      storeQuestionnire(question_data);

      let _existingEntries = JSON.parse(localStorage.getItem("questionData"));
      if (_existingEntries == null) _existingEntries = [];
      let existingIndex = _existingEntries.findIndex(
        (entry) => entry.question_id === question_data.question_id
      );
      if (existingIndex !== -1) {
        _existingEntries.splice(existingIndex, 1);
      }
      _existingEntries.push(question_data);
      localStorage.setItem("questionData", JSON.stringify(_existingEntries));
    }
  };

  const onSelectAnswer = async (e) => {
    let selectedAns = parseInt(e.target.getAttribute("data-ans-id"));
    let selectedQuest = parseInt(e.target.getAttribute("data-quest-id"));
    let anslabel = e.target.getAttribute("data-ans-label");
    const valuesreg = getValues();
    let bank_statement = splitForm.current["bankstmt"].value;
    let didYouAware = parseInt(splitForm.current["didYouAware"].value);
    let flage = false;
    let question_data = {};
    setCompletedQuestArr([...completedQuestArr, selectedQuest]);
    if (e.target.name == "bankstatement") {
      setClickedSlide({ slide: "bankstatement" });
      flage = true;
      question_data = {
        question_id: selectedQuest,
        option_id: null,
        answer_text: "",
        input_answer: bankStatements,
      };
    } else if (e.target.name == "btn_qest_76") {
      setClickedSlide({ slide: "btn_qest_76" });
      if (selectedQuest) {
        flage = true;
        question_data = {
          question_id: selectedQuest,
          option_id: null,
          answer_text: "",
          input_answer: optionSelected,
        };
      }
    } else if (e.target.name == "shareInfo") {
      flage = true;
      question_data = {
        question_id: selectedQuest,
        option_id: selectedAns,
        answer_text: null,
        input_answer: null,
      };
    } else if (e.target.name == "btn_qest_36") {
      setClickedSlide({ slide: "btn_qest_36" });
      if (selectedQuest) {
        flage = true;
        question_data = {
          question_id: selectedQuest,
          option_id: null,
          answer_text: "",
          input_answer: optionSelected76,
        };
        GtmDataLayer({
          question: "situation statement",
        });
      }
    } else if (e.target.name == "didYouAware") {
      setClickedSlide({ slide: "didYouAware" });
      if (selectedQuest) {
        flage = true;
        question_data = {
          question_id: selectedQuest,
          option_id: didYouAware,
          answer_text: "",
          input_answer: "",
        };
      }
    } else if (e.target.name == "difficulties") {
      if (selectedQuest) {
        flage = true;
        question_data = {
          question_id: selectedQuest,
          option_id: selectedAns,
          answer_text: "",
          input_answer: "",
        };
      }
      window.scrollTo(0, 0);
    } else {
      if (selectedAns && selectedQuest) {
        flage = true;
        question_data = {
          question_id: selectedQuest,
          option_id: selectedAns,
          answer_text: null,
          input_answer: "",
        };
      }
    }
    if (flage) {
      storeQuestionnire(question_data);

      let _existingEntries = JSON.parse(localStorage.getItem("questionData"));
      if (_existingEntries == null) _existingEntries = [];
      let existingIndex = _existingEntries.findIndex(
        (entry) => entry.question_id === question_data.question_id
      );
      if (existingIndex !== -1) {
        _existingEntries.splice(existingIndex, 1);
      }
      _existingEntries.push(question_data);
      localStorage.setItem("questionData", JSON.stringify(_existingEntries));
    }
  };
  const saveInputAns = async (e) => {
    let selectedQuest = parseInt(e.target.getAttribute("data-quest-id"));
    let anslabel = e.target.getAttribute("data-ans-label");
    const valuesreg = getValues();
    let bud_lender = SelectBank.value;
    // let MonthOfPossession = splitForm.current["MonthOfPossession"].value;
    let yearposession = splitForm.current["YearOfPossession"].value;
    let possessionyearvalue =yearposession;
    setCompletedQuestArr([...completedQuestArr, selectedQuest]);

    let flage = false;
    let question_data = {};

    if (e.target.name == "banks" && bud_lender) {
      flage = true;
      question_data = {
        question_id: selectedQuest,
        option_id: null,
        answer_text: "",
        input_answer: bud_lender,
      };
    } else if (e.target.name == "sortcode") {
      setClickedSlide({ slide: "banks" });
      const timer = setTimeout(() => {
        setClickedSlide({ slide: "Loader2" });
      }, 4000);
      flage = true;
      question_data = {
        question_id: selectedQuest,
        option_id: null,
        answer_text: null,
        input_answer: sortCode,
      };
    } else if (e.target.name == "bank_acc_no") {
      flage = true;
      question_data = {
        question_id: selectedQuest,
        option_id: null,
        answer_text: null,
        input_answer: accNum,
      };
    } else if (e.target.name == "possessionyear" && possessionyearvalue) {
      // setClickedSlide({ slide: "possessionyear" });
      flage = true;
      question_data = {
        question_id: selectedQuest,
        option_id: null,
        answer_text: "",
        input_answer: possessionyearvalue,
      };
      redirectToAnalysis();
    } else if (e.target.name == "keeperDate") {
      setClickedSlide({ slide: "keeperDate" });
      flage = true;
      question_data = {
        question_id: 11,
        option_id: null,
        answer_text: "",
        input_answer: valuesreg["keeperDate"],
        valid_keeper_date: previous,
      };
      if(previous == 0){
        redirectToAnalysis();
      }
    } else if (e.target.name == "didYouAware") {
      setClickedSlide({ slide: "didYouAware" });
      flage = true;
      question_data = {
        question_id: 85,
        option_id: 244,
        answer_text: "",
        input_answer: valuesreg["awareSpecify"].trim(),
      };
    } else if (e.target.name == "describeProblem") {
      setClickedSlide({ slide: "describeProblem" });
      flage = true;
      question_data = {
        question_id: selectedQuest,
        option_id: null,
        answer_text: "",
        input_answer: valuesreg["descProblem"].trim(),
      };
      window.scrollTo(0, 0);
      // redirectToAnalysis();
    }
    if (flage) {
      storeQuestionnire(question_data);
      let _existingEntries = JSON.parse(localStorage.getItem("questionData"));
      if (_existingEntries == null) _existingEntries = [];
      let existingIndex = _existingEntries.findIndex(
        (entry) => entry.question_id === question_data.question_id
      );
      if (existingIndex !== -1) {
        _existingEntries.splice(existingIndex, 1);
      }
      _existingEntries.push(question_data);
      localStorage.setItem("questionData", JSON.stringify(_existingEntries));
    }
  };

  const SaveBank = async (e) => {
    setClickedSlide({ slide: "banks" });
    const timer = setTimeout(() => {
      setClickedSlide({ slide: "Loader2" });
    }, 4000);
  };

  useEffect(() => {
    var value = questionCounter;
    if (value == 1) {
      SetPercentage(0);
    } else {
      SetPercentage(Math.round(((value - 1) * 100) / totalQuestArr.length));
    }
  }, [questionCounter]);

  const scrollToElement = (element) => {
    scrollRef.current[element]?.scrollIntoView({
      behavior: "smooth",
      block: "start",
      inline: "center",
    });
  };

  const slideBack = (element) => {
    scrollRef.current[element.slide]?.classList.remove("active");

    scrollRef.current[element.slide]
        ?.querySelector(".question-content")
        .classList.remove("hide");
  };

  const setTickToElement = (element) => {
    if (element.slide === "skipSlide") {      
      scrollRef.current["CarRegno"]?.querySelector(".question-content").classList.add("hide");
      scrollRef.current["CarRegno"]?.classList.add("active");
      scrollRef.current["possessionyear"]?.querySelector(".question-content").classList.remove("hide");
    }

    scrollRef.current[element.slide]?.classList.add("active");
    scrollRef.current[element.slide]
      ?.querySelector(".question-content")
      .classList.add("hide");
  };

  const redirectToAnalysis = async () => {
    window.scrollTo(0, 0);
    history.push(
      "/questionnaireV28?uuid=" + visitorParameters.visitor_parameters.uuid
    );
  };

  return (
    <>
      <GetVisitorsParams />
      <div className="Question_section_oneMB ">
        <section className="banner lenderSection">
          <div className="container">
            <div>
              <div className="brand">
                <div className="logo pt-3 text-center">
                  <a href="index.html">
                    {" "}
                    <img src={tmslogo} height={60} alt="" />
                  </a>
                </div>
              </div>
            </div>
          </div>
          <div className="container mt-5">
            <LoaderIst LoaderOne={`${state.LoaderOne}`} />
            <LoaderTwo LoaderTwo={`${state.LoaderTwo}`} />

            <div
              id={`quetWraper `}
              className={` pt-2   section-lender ${state.banks_slide} `}
            >
              <Banks
                SaveBank={SaveBank}
                lenderList={lenderList}
                SelectBank={SelectBank}
                setSelectBank={setSelectBank}
                saveInputAns={saveInputAns}
                validation={register}
                clearErrors={clearErrors}
                trigger={trigger}
                validationMsg={errors}
                getValues={getValues}
                setSortCode={setSortCode}
                setAccNum={setAccNum}
                setClickedSlide={setClickedSlide}
                SkippedLeads={SkippedLeads}
                setLenderName={setLenderName}
                lenderName={lenderName}
                shareInfoSave={shareInfoSave}
              />
            </div>
            <div
              className="questionnair-section form-style pb-5"
              id="questionBld"
              style={
                `${state.sectionquest}` === "hide"
                  ? { display: "block" }
                  : { display: "none " }
              }
            >
              <form
                ref={splitForm}
                name="split_form"
                id="user_form"
                autoComplete="off"
              >
                <ul className="p-0 m-0 questionist mb-5">
                <li>
                    <div
                      className={`questitem ${state.showSlide5}`}
                      id="slide4"
                      ref={(el) => (scrollRef.current["btn_qest_76"] = el)}
                    >
                      <h1 className="que-title p-3 ">
                        {" "}
                        At the time you secured your finance with MoneyBarn,
                        which of the below statements accurately describe your
                        situation
                      </h1>
                      <FurtherDetails
                        onSelectAnswer={onSelectAnswer}
                        backClick={previousSlideChange}
                        questArr={questinnaire76MB_4_2}
                        validation={register}
                        validationMsg={errors}
                        getValues={getValues}
                        trigger={trigger}
                        setError={setError}
                        optionSelected={optionSelected}
                        showError76={showError76}
                        setshowError76={setshowError76}
                      />
                    </div>
                    <div className={`tipbox tip2 ${state.showSlide5a}`}>
                      Your feedbacks helps support the basis of claims.
                      <div className="arrow  arrow-top" />
                    </div>
                  </li>
                  <li>
                    <div
                      className={`questitem ${state.showSlide6}`}
                      id="slide5"
                      ref={(el) => (scrollRef.current["btn_qest_36"] = el)}
                    >
                      <h1 className="que-title p-3 ">
                        {" "}
                        Thanks for that, please confirm if any of these
                        statements also accurately describe your situation.
                      </h1>

                      <Confirmsituation
                        onSelectAnswer={onSelectAnswer}
                        backClick={previousSlideChange}
                        questArr={questinnare2MB}
                        validation={register}
                        validationMsg={errors}
                        getValues={getValues}
                        trigger={trigger}
                        setError={setError}
                        optionSelected76={optionSelected76}
                        showError36={showError36}
                        setshowError36={setshowError36}
                      />
                    </div>
                    <div className={`tipbox tip2 ${state.showSlide6a}`}>
                      Your feedbacks helps support the basis of claims.
                      <div className="arrow  arrow-top" />
                    </div>
                  </li>
                  <li>
                    <div
                       className={`questitem ${state.showSlide22}`}
                      id="slide9"
                      ref={(el) => (scrollRef.current["struggle"] = el)}
                    >
                      <h1 className="que-title p-3 ">
                        Have you experienced any hardships or struggle in making
                        the repayments to Moneybarn on time while still meeting
                        all your other commitments?{" "}
                      </h1>
                      <Struggle
                        clsName={state.showSlide22}
                        onSelectAnswer={onSelectAnswer}
                        validation={register}
                        clearErrors={clearErrors}
                        trigger={trigger}
                        validationMsg={errors}
                        getValues={getValues}
                        splitForm={splitForm}
                      />
                    </div>
                    <div className={`tipbox tip2 ${state.showSlide22}`}>
                      This is to understand you have any hardships or struggle
                      in making the repayments.
                      <div className="arrow  arrow-top" />
                    </div>
                  </li>
                  <li>
                    <div
                      className={`questitem ${state.showSlide1}`}
                      id="slide1"
                      ref={(el) => (scrollRef.current["bankstatement"] = el)}
                    >
                      <h1 className="que-title p-3 ">
                        {" "}
                        Did Moneybarn ask you to provide any of the below when
                        you bought the car?
                      </h1>
                      <BankStatement
                        onSelectAnswer={onSelectAnswer}
                        validation={register}
                        clearErrors={clearErrors}
                        trigger={trigger}
                        validationMsg={errors}
                        getValues={getValues}
                        splitForm={splitForm}
                        backClick={previousSlideChange}
                        bankStatements={bankStatements}
                        setBankStatements={setBankStatements}
                      />
                    </div>
                    <div className={`tipbox tip2 ${state.showSlide1a}`}>
                      Your response to this question helps us to evaluate your
                      financial stability.
                      <div className="arrow  arrow-top" />
                    </div>
                  </li>
                  <li>
                    <div
                      className={`questitem ${state.showSlide2}`}
                      id="slide2"
                      ref={(el) => (scrollRef.current["CarRegno"] = el)}
                    >
                      <h1 className="que-title p-3 ">
                        {" "}
                        Enter your Car Registration Number?
                      </h1>
                      <CarRegistration
                        backClick={previousSlideChange}
                        uuid={uuid}
                        validation={register}
                        validationMsg={errors}
                        getValues={getValues}
                        trigger={trigger}
                        setError={setError}
                        split_name={splitName}
                        saveVehicleDetails={saveVehicleDetails}
                        savetotalCarCheck={savetotalCarCheck}
                        checkCarRegistration={checkCarRegistration}
                        slideSkip={slideSkip}
                        setSkip={setSkip}
                        setPrevStill={setPrevStill}
                      />
                    </div>
                    <div className={`tipbox tip2 ${state.showSlide2a}`}>
                      To start your claim evaluation, we requires your car
                      registration
                      <div className="arrow  arrow-top" />
                    </div>
                  </li>
                  <li>
                    <div
                      className={`questitem ${state.showSlide3}`}
                      id="slide3"
                      ref={(el) => (scrollRef.current["keeperDate"] = el)}
                    >
                      <h1 className="que-title p-3 ">
                        When did you start the finance?
                      </h1>
                      <KeeperDate
                        saveInputAns={saveInputAns}
                        backClick={previousSlideChange}
                        page_name={splitName}
                        uuid={visitorParameters.visitor_parameters.uuid}
                        vehicle={vehicle}
                        totalCarCheck={totalCarCheck}
                        savetotalCarCheck={savetotalCarCheck}
                        setValidKeeperDate={setValidKeeperDate}
                        setPrevious={setPrevious}
                        validation={register}
                        splitForm={splitForm}
                        setPrevKeep={setPrevKeep}
                        setPrev76={setPrev76}
                      />
                    </div>
                    <div className={`tipbox tip2 ${state.showSlide3a}`}>
                      To assess your claim, we need information regarding the
                      commencement of your finance.
                      <div className="arrow  arrow-top" />
                    </div>
                  </li>
                  <li>
                    <div
                      className={`questitem ${state.showSlide4}`}
                      id="slide3"
                      ref={(el) => (scrollRef.current["possessionyear"] = el)}
                    >
                      <h1 className="que-title p-3 ">
                      When did you take out this loan with MoneyBarn?{" "}
                      </h1>
                      <YearOfPossession
                        saveInputAns={saveInputAns}
                        backClick={previousSlideChange}
                        validation={register}
                        clearErrors={clearErrors}
                        //slideChange={slideChange}
                        getValues={getValues}
                        trigger={trigger}
                        validationMsg={errors}
                        splitForm={splitForm}
                        prevStill={prevStill}
                      />
                    </div>
                    <div className={`tipbox tip2 ${state.showSlide4a}`}>
                      Acquiring information about the obtained loan is vital.
                      <div className="arrow  arrow-top" />
                    </div>
                  </li>
                  <li>
                    <div
                      className={`questitem ${state.showSlide_oldCard}`}
                      id="slide-27"
                      ref={(el) => (scrollRef.current["oldCard"] = el)}
                    >
                      <h1 className="que-title p-3 ">
                        Did you take the car finance over 6 years ago?
                      </h1>
                      <OldCard
                        setoldCardbck={setoldCardbck}
                        onSelectAnswer={onSelectAnswer}
                        backClick={previousSlideChange}
                        previous={previous}
                      />
                    </div>
                  </li>
                  <li>
                    <div
                      className={`questitem ${state.showSlide_didYouAware}`}
                      id="slide-85"
                      ref={(el) => (scrollRef.current["didYouAware"] = el)}
                    >
                      <h1 className="que-title p-3 ">
                        When did you become aware you could make a claim against MoneyBarn?
                      </h1>
                      <DidYouAware
                        onSelectAnswer={onSelectAnswer}
                        saveInputAns={saveInputAns}
                        splitForm={splitForm}
                        backClick={previousSlideChange}
                        validation={register}
                        trigger={trigger}
                        validationMsg={errors}
                        setError={setError}
                        previous={previous}
                      />
                    </div>
                  </li>
                  <li>
                    <div
                      className={`questitem ${state.showSlide_LatePayment}`}
                      id="slide-28"
                      ref={(el) => (scrollRef.current["latePayment"] = el)}
                    >
                      <h1 className="que-title p-3 ">
                        Did you receive any late payment letters / any other
                        notifications from them or was your account passed to a
                        debt company?
                      </h1>
                      <LatePayment
                        setlatepymnentbck={setlatepymnentbck}
                        onSelectAnswer={onSelectAnswer}
                        backClick={previousSlideChange}
                      />
                    </div>
                    <div className={`tipbox tip2 ${state.Prompt_LatePayment}`}>
                      Do you recall ever receiving any messages from MoneyBarn
                      regarding late payments / issues / sold to Lowell / Arrow
                      Global?
                      <div className="arrow  arrow-top" />
                    </div>
                  </li>

                  <li>
                    <div
                      className={`questitem ${state.showSlide_Difficulties}`}
                      id="slide-29"
                      ref={(el) => (scrollRef.current["difficulties"] = el)}
                    >
                      <h1 className="que-title p-3 ">
                        When you received the communications from the lender,
                        did you feel that any difficulties you had repaying /
                        financially were:
                      </h1>
                      <Difficulties
                        onSelectAnswer={onSelectAnswer}
                        backClick={previousSlideChange}
                      />
                    </div>
                  </li>
                  <li>
                    <div
                      className={`questitem ${state.showSlide_DescribeProblem}`}
                      id="slide-30"
                      ref={(el) => (scrollRef.current["describeProblem"] = el)}
                    >
                      <h1 className="que-title p-3 ">
                        Please describe what really caused you to have problems
                        in repaying / financially?
                      </h1>
                      <DescribeProblem
                        saveInputAns={saveInputAns}
                        validation={register}
                        trigger={trigger}
                        validationMsg={errors}
                        backClick={previousSlideChange}
                        setError={setError}
                        getValues={getValues}
                      />
                    </div>
                    <div className={`tipbox tip2 ${state.Prompt_DescribeProblem}`}>
                      e.g. A change in circumstances / job loss / illness /
                      divorce etc
                      <div className="arrow  arrow-top" />
                    </div>
                  </li>
                </ul>
              </form>
            </div>
          </div>
        </section>
        <Footer />
        <div className= {`${state.showSlide5a}`}>  
        <button className={`regNextBtn-2 d-sm-none`}
            type="button"
            name="btn_qest_76"
            id="q76"
            onClick={MobBtnClick}
            data-quest-id={76}
            >
            Next &#8594;
        </button>
        </div>

        <div className= {`${state.showSlide6a}`}>   
        <button className={`regNextBtn-2 d-sm-none`}
          type="button"
          name="btn_qest_36"
          id="q76"
          onClick={MobBtnClick}
          data-quest-id={36}
          >
          Next &#8594;
          </button>
        </div> 
      </div>
    </>
  );
};
export default QuestionaireMBGN_4_2;
