import React, { useEffect, useState, useReducer, useContext } from 'react';
import { Api } from "../../api/Api";
import { queryString } from "../../Utility/QueryString";
import { useHistory } from "react-router-dom";
import { useFollowupLoad } from "../../Hooks/useFollowupLoad";
import { useFollowupUserQuestions } from "../../Hooks/useFollowupUserQuestions";
import VisitorParamsContext from "../../Contexts/VisitorParams";
import { questinnaire76Vanquis, questinnaire36Vanquis } from "../../Constants/Questions";
import logowhite from "../../assets/FollowupVaqV2/img/logo-white.png";
import "../../assets/FollowupVaqV2/scss/modules/questionnaire.scss";
import "../../assets/FollowupVaqV2/scss/modules/custom.scss";
import Loader from "../Includes/Layouts/FollowupVaqV2/Loader";
import Signature from "../Includes/Layouts/FollowupVaqV2/Signature";
import Hardship from "../Includes/Layouts/FollowupVaqV2/Hardship";
import Further1 from "../Includes/Layouts/FollowupVaqV2/Further1";
import Further2 from "../Includes/Layouts/FollowupVaqV2/Further2";
import VerifyData_FLP_VAQ_V2 from './VerifyData_FLP_VAQ_V2';
import PreviewFLP_VAQ_V2 from './PreviewFLP_VAQ_V2';
import { useUpdateMilestone } from "../../Hooks/useUpdateMilestone";
import { obLender } from "../../Hooks/useOblender";
import Banks from "../Includes/Layouts/FollowupVaqV2/Banks";
import { useForm } from "react-hook-form";

const initialState = {
  loader: true,
  signature: false,
  hardship: false,
  bank_name: false,
  further1: false,
  further2: false,
  verify: false,
  meritPage: false,
  preview: false
}

const QuestionnaireReducer = (state, action) => {
  switch (action.type) {
    case "showSlideChange": {
      const updatedState = Object.keys(state).reduce((acc, key) => {
        acc[key] = key === action.payload ? true : false;
        setTimeout(() => {
          window.scrollTo(0, 0);
        }, 500);
        return acc;
      }, {});
      return { ...state, ...updatedState };
    }
    default:
      return state;
  }
};

const FlpVaqV2 = () => {
  const history = useHistory();
  const { loadFollowup } = useFollowupLoad();
  const { saveFollowupUserQuestions } = useFollowupUserQuestions();
  const { updateUserMilestoneStatsQuest } = useUpdateMilestone();
  const [state, dispatch] = useReducer(QuestionnaireReducer, initialState);
  const { visitorParameters } = useContext(VisitorParamsContext);
  const [pendingQuestionnaire, setPendingQuestionnaire] = useState(['loader']);
  const [showUserName, setUserName] = useState(null);
  const [uuid, setUuid] = useState(null);
  const [allFollowupData, setFollowupData] = useState(false);
  const [optionSelected] = useState([]);
  const [optionSelected76] = useState([]);
  const [t2aApiReqData, setT2aApiReqData] = useState({});
  const [lenderList, setLenderList] = useState([]);
  const [SelectBank, setSelectBank] = useState([]);
  const { getLenderlist } = obLender();
  const [lenderName, setLenderName] = useState("");
  
  useEffect(() => {
    (async () => {
      const response = await Api.get(
        "/api/v1/followup/get-details-vaq-v2?query_string=" + queryString,
        {}
      );
      if (response.data.status === "Success") {
        let slidePending = response.data.response.slides;
        let username = response.data.response.username;
        let uuid = response.data.response.uuid;
        let email = response.data.response.email;
        let followup_data = response.data.response.followup_data;
        localStorage.setItem('uuid', uuid);
        let fname = response.data.response.first_name;
        let lname = response.data.response.last_name;
        let postcode = response.data.response.postcode;
        let address_line1 = response.data.response.address_line1;
        let dob_day = response.data.response.dob_day;
        let dob_month = response.data.response.dob_month;
        let dob_yr = response.data.response.dob_yr;
        let address_line2 = response.data.response.address_line2;
        let town = response.data.response.town;
        let country = response.data.response.country;
        let title = response.data.response.title;
        let addressLine3 = response.data.response.address_line3;
        let addressLine4 = response.data.response.address_line4;
        let county = response.data.response.county;
        localStorage.setItem('flp_source', followup_data.atp_sub6);
        localStorage.setItem('allFollowupData', JSON.stringify(followup_data));
        localStorage.setItem('UcFname', username);
        visitorParameters.email = email || false;
        setT2aApiReqData({
          uuid: uuid,
          fName: fname,
          lName: lname,
          pCode: postcode,
          addressLine1: address_line1,
          day: dob_day,
          month: dob_month,
          year: dob_yr,
          addressLine2: address_line2,
          postTown: town,
          country: country,
          title: title,
          addressLine3: addressLine3,
          addressLine4: addressLine4,
          county: county
        });

        setUserName(username);
        setUuid(uuid);
        setFollowupData(followup_data);
        const flpResponse = await loadFollowup(
          "followup_load",
          followup_data,
          'followup-vaq-v2',
          queryString,
          "vaq-v2"
        );
        setTimeout(() => {
          setPendingQuestionnaire(slidePending);
        }, 3000);
      } else {
        history.push("/Vanquisthankyou?uuid=" + response.data.response.uuid);
        return false;
      }
    })();
  }, []);

  useEffect(() => {
    slideChange(pendingQuestionnaire);
  }, [pendingQuestionnaire]);

  const slideChange = (pendingArr) => {

    let pendingCount = pendingArr.length;
    if (pendingCount == 0) {
      history.push("/Vanquisthankyou?uuid=" + uuid);
      return false;
    }
    pendingArr.every((element) => {
      dispatch({ type: "showSlideChange", payload: element });
    });
  };

  const removeFromPending = (slidename) => {
    const index = pendingQuestionnaire.indexOf(slidename);
    if (index > -1) {
      pendingQuestionnaire.splice(index, 1);
    }
    slideChange(pendingQuestionnaire);
  };

  const addPending = (slidename) => {
    pendingQuestionnaire.unshift(slidename);
    slideChange(pendingQuestionnaire);
  };


  const unQualifiedAnswer = (e) => {
    let selectedAns = parseInt(e.target.getAttribute("data-ans-id"));
    let selectedQuest = parseInt(e.target.getAttribute("data-quest-id"));
    let flag = false;
    let question_data = {};

    if (selectedQuest && selectedAns) {
      flag = true;
      question_data = {
        question_id: selectedQuest,
        option_id: selectedAns,
        answer_text: "",
        input_answer: null,
      };
    }

    if (flag == true) {
      storeFollowupQuestionnire(question_data, "unqualified_question_store_flp");
    }
  };

  const onselectAnswer = async (e) => {
    let selectedAns = parseInt(e.target.getAttribute("data-ans-id"));
    let selectedQuest = parseInt(e.target.getAttribute("data-quest-id"));
    let anslabel = e.target.getAttribute("data-ans-label");
    let flage = false;
    let question_data = {};

    if (e.target.name == "question_29") {
      if (selectedAns && selectedQuest) {
        flage = true;
        question_data = {
          question_id: selectedQuest,
          option_id: selectedAns,
          answer_text: anslabel,
          input_answer: "",
        };
      }
      removeFromPending("hardship");
    } else if (e.target.name == "question_76") {
      if (selectedQuest) {
        flage = true;
        question_data = {
          question_id: 76,
          option_id: null,
          answer_text: anslabel,
          input_answer: optionSelected76,
        };
      }
      removeFromPending("further1");
    } else if (e.target.name == "question_36") {
      if (selectedQuest) {
        flage = true;
        question_data = {
          question_id: 36,
          option_id: null,
          answer_text: anslabel,
          input_answer: optionSelected,
        };
      }
      updateUserMilestoneStatsQuest(uuid,allFollowupData.atp_sub6);
      removeFromPending("further2");
    }

    if (flage) {
      storeFollowupQuestionnire(question_data, "followup_question_store");
      var existingEntries = JSON.parse(localStorage.getItem("questionData"));
      if (existingEntries == null) existingEntries = [];
      localStorage.setItem(
        "currentquestionData",
        JSON.stringify(question_data)
      );
      existingEntries.push(question_data);
      localStorage.setItem("questionData", JSON.stringify(existingEntries));
    }
  };

  const storeFollowupQuestionnire = async (question_data, msg_type) => {
    const response = await saveFollowupUserQuestions(
      question_data,
      allFollowupData,
      msg_type
    );
  };

  // bank lenders
  const getLender = async () => {
    try {
      const response = await getLenderlist();
      const dataLength = response.data.providers.length;

      if (dataLength > 0) {
        setLenderList(response.data.providers);
      }
    } catch (error) {
      console.error("Error fetching lender list:", error);
    }
  };
  useEffect(() => {
    getLender();
  }, []);


  const saveInputAns = async (e) => {
    let selectedAns = parseInt(e.target.getAttribute("data-ans-id"));
    let selectedQuest = parseInt(e.target.getAttribute("data-quest-id"));
    const valuesreg = getValues();
    let bud_lender = SelectBank;
    let flage = false;
    let question_data = {};

    if (e.target.name == "bankname") {
      flage = true;
      question_data = {
        question_id: selectedQuest,
        option_id: null,
        answer_text: bud_lender,
        input_answer: bud_lender,
      };
      removeFromPending("bank_name");
    }

    if (e.target.name == "bankOptions" && e.target.value) {
      flage = true;
      question_data = {
        question_id: selectedQuest,
        option_id: null,
        answer_text: e.target.value,
        input_answer: e.target.value,
      }
      removeFromPending("bank_name");
    }

    if (flage) {
      storeFollowupQuestionnire(question_data, "followup_question_store");
      var existingEntries = JSON.parse(localStorage.getItem("questionData"));
      if (existingEntries == null) existingEntries = [];
      localStorage.setItem(
        "currentquestionData",
        JSON.stringify(question_data)
      );
      existingEntries.push(question_data);
      localStorage.setItem("questionData", JSON.stringify(existingEntries));
    }
  };

  const { register, errors, trigger, setError, clearErrors, getValues } =
    useForm({
      mode: "onBlur",
      reValidateMode: "onChange",
      defaultValues: {},
      resolver: undefined,
      context: undefined,
      criteriaMode: "firstError",
      shouldFocusError: true,
      shouldUnregister: true,
    });

  return (
    <>
      <div className={`FollowupVaqV2`}>
        {!state.meritPage && !state.preview ? (
          <section className="banner qs-section">
            <div className="container">
              <div>
                <div className="brand">
                  <div className="logo pt-3 text-center">
                    <a>
                      <img src={logowhite} style={{ height: 60 }} alt="" />
                    </a>
                  </div>
                </div>
              </div>
            </div>
            {state.loader ? (
              <Loader showUserName={showUserName} showLoad={state.loader} />
            ) : null}
            {state.signature ? (
              <Signature
                allFollowupData={allFollowupData}
                uuid={uuid}
                cfirstName={showUserName}
                removeFromPending={removeFromPending}
              />
            ) : null}
            {state.hardship ? (
              <Hardship
                onselectAnswer={onselectAnswer}
                unQualifiedAnswer={unQualifiedAnswer}
              />
            ) : null}
              <Banks
                lenderList={lenderList}
                SelectBank={SelectBank}
                setSelectBank={setSelectBank}
                saveInputAns={saveInputAns}
                setLenderName={setLenderName}
                lenderName={lenderName}
                onselectAnswer={onselectAnswer}
                className={state.bank_name ? "show" : "hide"}
                getValues={getValues}
                setError={setError}
                trigger={trigger}
                validationMsg={errors}
                validation={register}
                clearErrors={clearErrors}
              />
            <Further1
              questArr={questinnaire76Vanquis}
              onselectAnswer={onselectAnswer}
              optionSelected76={optionSelected76}
              className={state.further1 ? "show" : "hide"}
              addPending={addPending}
            />
            <Further2
              questArr={questinnaire36Vanquis}
              onselectAnswer={onselectAnswer}
              optionSelected={optionSelected}
              addPending={addPending}
              className={state.further2 ? "show" : "hide"}
            />
            {state.verify ? (
              <VerifyData_FLP_VAQ_V2
                currentUuid={uuid}
                t2aApiReqData={t2aApiReqData}
                allFollowupData={allFollowupData}
                removeFromPending={removeFromPending}
              />
            ) : null}
          </section>
        ) : null}

        {state.meritPage || state.preview ? (
          <PreviewFLP_VAQ_V2
            state={state}
            removeFromPending={removeFromPending}
          />
        ) : null}


      </div>
    </>
  )
}

export default FlpVaqV2;
