import React, { useState } from "react";
import RadioButton from "../../../UI/RadioButtonOutLabel";

const KeeperDate = ({ 
    saveInputAns, 
    backClick,
    page_name,
    uuid,
    vehicle,
    totalCarCheck,
    savetotalCarCheck,
    setValidKeeperDate,
    validation,
    setPrevious,
    validationMsg,
    splitForm,
    setPrevKeep,
    setPrev76
}) => {

    let eventValue;
    const [keeperErr, setKeeperErr] = useState(null);

    const nextSlide = async (e) => {
        setKeeperErr(null);
        let date_index = e.target.id;
        let dataNsId = e.target.getAttribute('data-ans-id') ? e.target.getAttribute('data-ans-id') : '';
        if (date_index == '0') {
            setPrevKeep("");
            setPrevious("0");
            setPrev76("from76");
            setValidKeeperDate(dataNsId);
            localStorage.setItem('ValidKeeper', 1);
        } else {
            setPrevKeep("tokeeperdate");
            setPrevious("1");
            setPrev76("");
            localStorage.setItem('ValidKeeper', 0);
            setValidKeeperDate("");
        }   
    };

    const sendData = (e) => {
        var kdate = splitForm.current["keeperDate"].value;
        if (kdate) {
            saveInputAns(e);
        } else {
            setKeeperErr('Select Keeper Date');
        }
    }

    let keeperArray = [];
    let keeperData = [];
    if (typeof vehicle.keeper_dates !== "undefined") {
        keeperData = JSON.parse(vehicle.keeper_dates);
        keeperArray = keeperData.keeper_dates;
    }

    return (
        <>
            <div
                className={`question-content mt-0 scale-down-ver-center px-3  py-lg-3 py-md-3 py-sm-2 py-2`}

            >
                <div className="row">
                    <div className="col-lg-12 mb-2">
                        <ul className="p-0 m-0 option-grid">
                            {keeperArray.map((text, index, { length }) => (
                                <li>
                                    <div className="optionbtn" style={{ marginBottom: '10px' }}>
                                        <div style={{ marginBottom: '10px' }}>

                                            <RadioButton
                                                key={text.toString()}
                                                id={index}
                                                value={text}
                                                htmlFor="previously"
                                                tabIndex="1"
                                                name="keeperDate"
                                                className="radio-button"
                                                labelName={text.slice(2)}
                                                labelClassName="option_but"
                                                dataAnsId={text}
                                                dataQuestId={11}
                                                onClick={nextSlide}
                                                validation={validation({ required: "Please Select" })}
                                            />
                                        </div>
                                    </div>
                                </li>
                            ))}
                            <div className="optionbtn more-option" style={{ marginBottom: '10px' }}>

                                <RadioButton
                                    key="Other"
                                    id="Other"
                                    value="Other"
                                    htmlFor="previously"
                                    name="keeperDate"
                                    className="radio-button"
                                    labelName="Other"
                                    labelClassName="option_but"
                                    dataAnsId="80other"
                                    dataQuestId={11}
                                    onClick={nextSlide}
                                    validation={validation({ required: "Please Select" })}
                                />
                            </div>
                        </ul>
                    </div>
                    <div className="col-lg-12 mb-2 mt-2">
                        <span className="error_msg text-center">{keeperErr}</span>
                        <button
                            className=" btn-comm2 py-3 w-100 nextBtn"
                            type="button"
                            name="keeperDate"
                            //onClick={nextSlide}
                            onClick={sendData}
                        >
                            Next &#8594;
                        </button>
                    </div>
                </div>
                <div className="row">
                    <div className="  text-center mt-3 ">
                        <button className="btn btn-secondary backbtn" type="button" name="CarRegno" onClick={backClick}>
                            {" "}
                            <i className="bi bi-arrow-left" /> Back
                        </button>
                    </div>
                </div>
            </div>







        </>

    );
};

export default KeeperDate;