import React, { useState } from "react";
import OtpInputBox from "../../../UI/OtpInputBox";
import Accordion from "react-bootstrap/Accordion";
import GtmDataLayer from "../../../../Utility/GtmDataLayer";

const BankAccNo = ({
  saveInputAns,
  backClick,
  SkippedLeads,
  setClickedSlide,
  setAccNum,
  lenderName,
  accNumPopUp,
  setAccNumPopUp,
  setSortCodePopUp,
  closeModal
}) => {
  const [accNo, setAccNo] = useState("");
  const [error, setError] = useState(false);

  const handlebtnChange = (accNo) => {
    setAccNo(accNo);
    setAccNum(accNo);
  };
  const skipNext = () => {
    let array = JSON.parse(localStorage.getItem("questionData"));
    array = array.filter((item) => item.question_id !== 87);
    localStorage.setItem("questionData", JSON.stringify(array));
    SkippedLeads("skipAccountNumber", false);
    // setClickedSlide({ slide: "bank_acc_no" });
    setAccNumPopUp("hide");
    setSortCodePopUp("show");
    GtmDataLayer({
      question: "account number",
    });
  };

  const handleNext = async (e) => {
    if (accNo.length != 8) {
      setError(true);
      return;
    } else if (parseInt(accNo) === 0) {
      setError(true);
      return;
    } else {
      GtmDataLayer({
        question: "account number",
      });
      setError(false);
      setAccNumPopUp("hide");
      setSortCodePopUp("show");
      saveInputAns(e);
    }
  };

  return (
    <>
      <div
        className={`modal fade ${accNumPopUp}`}
        style={{ backgroundColor: "#000000e3" }}
        id="CredCardAgreement"
        data-bs-backdrop="static"
        data-bs-keyboard="false"
        tabIndex={-1}
        aria-labelledby="staticBackdropLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-dialog-centered modal-lg">
          <div className="modal-content">
          <div className="modal-header border border-0 popmodalbg">
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
                onClick={closeModal}
              />
            </div>
            <div className="modal-body">
            <ul className="p-0 m-0 questionistmod mb-3">
            {"others" !== lenderName && "" !== lenderName && (
                <h2 className="px-3 p-0 m-0 fs-4">
                  <b>Bank: {lenderName}</b>
                </h2>
              )}
              <li className="mt-0">
                <div class="" id="slide161">
                  <h1 className="que-title pb-0 p-3 text-style">Please confirm your <b>
                    <u>Account Number</u>
                  </b></h1>
                  <div className="question-content mt-0 scale-down-ver-center px-3 py-lg-3 py-md-3 py-sm-2 py-2 pb-3 ">

                    <div className="row">
                      <div className="col-12 mb-3">
                        <Accordion>
                          <Accordion.Item className="custom-accordion" eventKey="0">
                            <Accordion.Header>
                              <div className="p-1 text-primary text-decoration-underline">
                                Why are we asking for this information?
                              </div>
                            </Accordion.Header>
                            <Accordion.Body>
                              When submitting a DSAR, it is essential to provide the
                              bank with sufficient details to identify the correct
                              records associated with your account. The bank account
                              number and sort code are crucial identifiers that
                              allow the bank to locate all pertinent information
                              quickly and accurately. This ensures that your DSAR is
                              processed efficiently and that all relevant data is
                              included in the response
                              <br />
                              <br />
                              Please rest assured that this information will be used
                              solely for the purpose of submitting your DSAR to{" "}
                              {lenderName} and will be handled with the utmost
                              confidentiality in accordance with applicable data
                              protection laws
                            </Accordion.Body>
                          </Accordion.Item>
                        </Accordion>
                      </div> 
                    </div>

                    <div className="row mb-4">

                      <div className="col-lg-12 col-md-12 col-sm-12">
                        <div className="otp-input">
                          <OtpInputBox
                          length={8}
                          onChange={handlebtnChange}
                          className={"otp-input-field"}
                        />
                        </div>
                        {error && (
                          <span className="error_msg mt-2">
                            Please enter a valid Bank Account Number
                          </span>
                        )}
                      </div>
                    </div>

                    <div className="row">



                      <div className="col-lg-12 mb-3">
                        <button
                        type="button"
                        className="nextMod btn-comm2 fs-6 py-lg-3 py-md-3 py-sm-2 py-2 w-100 nextBtn  "
                        id="nextscroll"
                        name="bank_acc_no"
                        onClick={handleNext}
                        data-quest-id={87}
                      >
                        Next &#8594;
                      </button>
                      </div>

                      <div className="col-lg-12 mb-2">
                       
                        <button
                        name="sortcode"
                        className="nextMod btn-comm2 fs-6 btn-green-skip py-lg-3 py-md-3 py-sm-2 py-2 w-100 nextBtn"
                        id=""
                        value=""
                        type="button"
                        onClick={skipNext}
                      >
                        Skip
                      </button>
                      </div>
                      <span className="warning-text">(if you prefer not to provide it, you can skip this step)</span>
                      
                    </div>

                    <div className="col-12 text-center">
                      <img src="../../../../assets/Quest_VAQ_UB_4_2/img/secure_iconV2.webp" alt="" className="pt-3"/>
                    </div>


                  </div>
                </div>

              </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default BankAccNo;
