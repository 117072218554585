import React, { useState } from "react";
import { Modal } from "react-bootstrap";
import InputButton from "../../../UI/InputButton";

const Hardship = ({
  onselectAnswer,
  unQualifiedAnswer,
}) => {
  const [checkedOne, setCheckedOne] = useState("unchecked");
  const [checkedTwo, setCheckedTwo] = useState("unchecked");
  const [claimModal, setshowClaimModal] = useState(false);
  const handleCloseclaimModal = () => setshowClaimModal(false);

  const spanClick = async (e) => {
    optionHandle(e);
  }

  const optionHandle = async (e) => {
    if (e.target.value === "100") {
      setCheckedOne("checked");
      setshowClaimModal(true);
      unQualifiedAnswer(e);
    } else {
      setCheckedOne("unchecked");
    }

    if (e.target.value === "99") {
      setCheckedTwo("checked");
      onselectAnswer(e);
    } else {
      setCheckedTwo("unchecked");
    }

  }
  return (
    <>
      <div className="container">
        <div class="questitem mt-5 col-12">
          <h1 class="que-title p-3 ">Have you <b>experienced any hardships or struggle in making the repayments to Vanquis</b> on time while still meeting all your other commitments? </h1>
          <div class="question-content mt-0 scale-down-ver-center px-3 py-lg-3 py-md-3 py-sm-2 py-2 pb-3 undefined">
            <div class="row">
              <div class="col-lg-6 col-md-12">
                <div class=" mb-2">
                  <button
                    type="button"
                    className={`btn-comm2 py-lg-3 py-md-3 py-sm-2 py-2 w-100 noissue  ${checkedOne}`}
                    onClick={optionHandle}
                    value="99"
                    name="question_29"
                    id="struggle2"
                    data-quest-id={29}
                    data-ans-id={99}
                    data-ans-label={null}
                    data-click-id='struggle_yes'
                  >Yes, I Have Struggled to Repay
                  </button>
                </div>
              </div>
              <div class="col-lg-6 col-md-12">
                <div class=" mb-2">
                  <button
                    type="button"
                    className={`btn-comm2 py-lg-3 py-md-3 py-sm-2 py-2 w-100 nextBtn  ${checkedTwo}`}
                    onClick={optionHandle}
                    value="100"
                    name="question_29"
                    id="struggle1"
                    data-quest-id={29}
                    data-ans-id={100}
                    data-ans-label={null}
                    data-click-id='struggle_no'
                  >No Issues Repaying
                  </button>
                </div>
              </div>
              {/* <p class="text-center bck-marwid"><button name="bankruptcy" class="btn btn-secondary backbtn1a" id="" value="" type="button">Go Back</button>
              </p> */}
            </div>
          </div>
        </div>
        <div class="tipbox tip2 show">
          This is to understand you have any hardships or struggle in making the repayments.
          <div class="arrow  arrow-top"></div>
        </div>
      </div>

      <Modal size="md" show={claimModal} onHide={handleCloseclaimModal} centered backdrop="static" className="modal fade " id="cannotClaim">
        <div className="modal-content">
          <Modal.Body>
            <div className="text-center my-3">
              <svg width="50px" height="50px" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M8.5 9C8.22386 9 8 8.77614 8 8.5C8 8.22386 8.22386 8 8.5 8C8.77614 8 9 8.22386 9 8.5C9 8.77614 8.77614 9 8.5 9Z" fill="#EF8019" stroke="#EF8019" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                <path d="M15.5 9C15.2239 9 15 8.77614 15 8.5C15 8.22386 15.2239 8 15.5 8C15.7761 8 16 8.22386 16 8.5C16 8.77614 15.7761 9 15.5 9Z" fill="#EF8019" stroke="#EF8019" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                <path d="M12 22C17.5228 22 22 17.5228 22 12C22 6.47715 17.5228 2 12 2C6.47715 2 2 6.47715 2 12C2 17.5228 6.47715 22 12 22Z" stroke="#EF8019" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                <path d="M7.5 15.5C7.5 15.5 9 13.5 12 13.5C15 13.5 16.5 15.5 16.5 15.5" stroke="#EF8019" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
              </svg>
            </div>

            <h4 className="text-center my-4">Sorry, we cannot accept your claim</h4>
            <div className="input-group  prevname-Btnpop justify-content-center">
              <InputButton
                className="btn-comm2modalreject py-lg-3 py-md-3 py-sm-2 py-2 w-100"
                name="modalBack"
                value="Close"
                btnType="button"
                onClick={handleCloseclaimModal}
              />
            </div>
          </Modal.Body>
        </div>
      </Modal>
    </>
  )
}
export default Hardship;