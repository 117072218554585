import React, { useState, useContext, useEffect } from "react";
import VisitorParamsContext from "../../Contexts/VisitorParams";
import { useHistory } from "react-router-dom";
//import TextField from "../../../../UI/TextField";
import { useIdUpload } from "../../Hooks/useIdUpload";
import { useSkippedLeads } from "../../Hooks/useSkippedLeads";
import { queryString } from "../../Utility/QueryString";
import "../../assets/MB_UFGN_1/css/openbank.scss";
import GtmDataLayer from "../../Utility/GtmDataLayer";
import GetVisitorsParams from "../../Utility/GetVisitorsParams";

import { Api } from "../../api/Api";
const BankStmtUploadPageMB1 = ({ display }) => {
  const history = useHistory();
  const { skippedLeads, skippedLeadsFLP } = useSkippedLeads();
  const { saveIdUpload, isLoading } = useIdUpload();
  const query_string = localStorage.getItem("querystring");
  const split_name = localStorage.getItem("split_name");
  // const allFollowupData = localStorage.getItem("allFollowupData");
  const { visitorParameters } = useContext(VisitorParamsContext);
  const formParameters = JSON.parse(localStorage.getItem("formData"));
  const [selectedFile, setSelectedFile] = useState("");
  const [selectedFileType, setSelectedFileType] = useState();
  const [isFilePicked, setIsFilePicked] = useState(false);
  const [isInValidfFile, setIsInValidfFile] = useState(false);
  const [base64Image, setBase64Image] = useState();
  const [errorMessage, setErrorMessage] = useState("");
  const [document, setDocument] = useState(false);
  const [file, setFile] = useState("");
  const [activeClass, setActiveClass] = useState("disabled");
  const [btnDisabled, SetBtnDisabled] = useState(true);
  const [allFollowupData, setFollowupData] = useState(false);
  const queryParams = localStorage.getItem("queryString");
  const uuid = localStorage.getItem("uuid");
  const LoanDate = localStorage.getItem("LoanDate");
  const [DateContent,SetDateContent] =useState("");
  // Split the input string into year and month
  
  // const formattedDate = parsedDate.toLocaleString('default', { month: 'long', year: 'numeric' });

  // // Subtract 3 months
  // const newDate = new Date(formattedDate);
  // newDate.setMonth(newDate.getMonth() - 3);

  // // Format the new date as "MMMM yyyy"
  // const MothsBefore = newDate.toLocaleString('default', { month: 'long', year: 'numeric' });

  const userfile = {
    preview: undefined,
    title: undefined,
  };

  useEffect(() => {
    if (LoanDate != "undefined") {
      
      
      let [year, month] = LoanDate.split(" ");

      var months = [
        'January',
        'February',
        'March',
        'April',
        'May',
        'June',
        'July',
        'August',
        'September',
        'October',
        'November',
        'December'
      ];
      let monthArr = (months.indexOf(month) + 1); 
    
      monthArr =  String(monthArr).replace(/^(\d)$/, '0$1');
    
        // Format the result
      let parsedDate = `${year}/${monthArr}/15`;

      var dateObj = new Date(parsedDate);


      // Get the month and year from the Date object
      var monthPr = dateObj.toLocaleString('en-US', { month: 'long' });
      var yearPr = dateObj.getFullYear();
      var monthYr = monthPr+' '+yearPr;


      // Subtract 3 months
      dateObj.setMonth(dateObj.getMonth() - 3);

      var dateObjBef = new Date(dateObj);
      
      //Get the month and year from the Date object
      var monthBefore= dateObjBef.toLocaleString('en-US', { month: 'long' });
      var yearBefore = dateObjBef.getFullYear();
      var monthYrBefore = monthBefore+' '+yearBefore;

      if (monthYrBefore !== null && !isNaN(monthYrBefore) && monthYrBefore !== undefined && monthYrBefore !== "") {
        SetDateContent("Please upload your Bank statements for the past three months, commencing from the month of your agreement.");
      }else{
        SetDateContent(`Please upload your Bank statements covering a period of three months, starting from ${monthYrBefore} up to ${monthYr}`);
      } 
    } else {
      SetDateContent("Please upload your Bank statements for the past three months, commencing from the month of your agreement.");
    }
  }, [LoanDate])

  const changeCameraHandler = (event) => {
    setActiveClass("camera");
    SetBtnDisabled(false);
    setSelectedFile(event.target.files[0]);
    let imageFile = event.target.files[0];
    if (imageFile) {
      // if (selectedFile) {

      setIsFilePicked(true);
      let extension = event.target.files[0].type;
      let imgsize = event.target.files[0].size;
      let allowedExtensions = ["image/jpeg", "image/png"];
      if (allowedExtensions.indexOf(extension) === -1) {
        setIsInValidfFile(true);
        setErrorMessage(
          "The uploaded Doc type should be an image in jpeg,png or jpg"
        );
      } else if (imgsize > 1024 * 1024 * 20) {
        setIsInValidfFile(true);
        setErrorMessage("Please choose a file less than 20mb");
      } else {
        setSelectedFileType(event.target.name);
        setIsInValidfFile(false);
      }

      userfile.preview = URL.createObjectURL(imageFile);
      userfile.title = imageFile.name;

      if (imageFile) {
        let reader = new FileReader();
        reader.onload = _handleReaderLoaded.bind(this);
        reader.readAsDataURL(imageFile);
      }
    } else {
      setIsFilePicked(false);
      userfile.preview = undefined;
      userfile.title = undefined;
    }
  };

  const changeFilesHandler = (event) => {
    setActiveClass("fileupload");
    SetBtnDisabled(false);
    setSelectedFile(event.target.files[0]);
    let imageFile = event.target.files[0];
    if (imageFile) {
      setIsFilePicked(true);
      let extension = event.target.files[0].type;
      let imgsize = event.target.files[0].size;
      let allowedExtensions = [
        "image/jpeg",
        "image/png",
        "application/zip",
        "application/msword",
        "application/pdf",
      ];
      if (allowedExtensions.indexOf(extension) === -1) {
        setIsInValidfFile(true);
        setErrorMessage(
          "The uploaded Doc type should be an image in jpeg,png,jpg,pdf,doc, or docx"
        );
      } else if (imgsize > 1024 * 1024 * 20) {
        setIsInValidfFile(true);
        setErrorMessage("Please choose a file less than 20mb");
      } else {
        setSelectedFileType(event.target.name);
        setIsInValidfFile(false);
        //_handleFileRead.bind(event.target.files[0]);
      }
      setDocument(false);

      if ("nationalIDCard_doc" == event.target.name) {
        setDocument(true);
      }

      userfile.preview = URL.createObjectURL(imageFile);
      userfile.title = imageFile.name;

      if (imageFile) {
        let reader = new FileReader();
        reader.onload = _handleReaderLoaded.bind(this);
        reader.readAsDataURL(imageFile);
      }
    } else {
      setIsFilePicked(false);
      userfile.preview = undefined;
      userfile.title = undefined;
    }
  };

  const onSelectOption = (e) => {
    setIsInValidfFile(false);
    SetBtnDisabled(false);
    if (e.target.name == "FinanceAgreementDocEmail") {
      setSelectedFile(undefined);
      setActiveClass("email");
    } else if (e.target.name == "FinanceAgreementDocSkip") {
      setSelectedFile(undefined);
      setActiveClass("skip");
    } else if (e.target.name == "closefile") {
      setActiveClass("disabled");
      SetBtnDisabled(true);
      setSelectedFile(undefined);
    }
  };

  const _handleReaderLoaded = (readerEvt) => {
    let binaryString = readerEvt.target.result;
    setBase64Image(binaryString);
  };

  const onProceed = (e) => {
    if (activeClass == "fileupload" || activeClass == "camera") {
      savedocument(e);
    } else if (activeClass == "email") {
      SkipSupportDocs("EmailDoc");
    } else if (activeClass == "skip") {
      SkipSupportDocs("SkipBankStmt");
    } else {
      setIsInValidfFile(true);
      setErrorMessage("Please choose an option");
    }
  };

  const SkipSupportDocs = async (type) => {
    SetBtnDisabled(true);
    setActiveClass("disabled");
    const skippedLeadsResponse = await skippedLeads(
      "SkipBankStmt",
      visitorParameters.visitor_parameters.uuid,
      split_name
    );
    if (skippedLeadsResponse.data.status == "Success") {
      localStorage.setItem("BankStatement", 2);
      redirecttoQuestion();
    }
  };

  const redirecttoQuestion = async () => {
    let spliName = localStorage.getItem("split_name");
    if (spliName == "MB_UFGN_4" || spliName == "MB_UFGN_4_1" || spliName == "MB_UFGN_4_2") {
      window.location.href = "/CheckListGN_4?uuid=" + visitorParameters.visitor_parameters.uuid;
      /* history.push(
        "/CheckListGN_4?uuid=" + visitorParameters.visitor_parameters.uuid
      ); */
    } else {
      history.push(
        "/CheckList?uuid=" + visitorParameters.visitor_parameters.uuid
      );
    }
    
  };

  const savedocument = async (e) => {
    if (selectedFile) {
      GtmDataLayer({
        question: "upload statement",
      }); 

      setIsInValidfFile(false);
      try {
        let uploadData = "";
        uploadData = {
          imageFile: base64Image,
          captureType: selectedFileType.substring(
            selectedFileType.indexOf("doc")
          ),
          documentType: "BankStmt",
        };

        const response = await saveIdUpload(
          uploadData,
          visitorParameters,
          formParameters,
          queryString,
          'support_docs'
        );


        if (response.data.status == "Success") {
          localStorage.setItem("OBStatement", 1);
          localStorage.setItem("BankStatement", 1);
          redirecttoQuestion();
        }
      } catch (e) {
        console.warn("error");
        console.warn(e);
        setIsInValidfFile(true);
        setErrorMessage("Please try again");
      }
    } else {
      setIsInValidfFile(true);
      setErrorMessage("Please choose an option");
    }
  };

  return (
    <>
      <GetVisitorsParams />
      <div className="tms-ob-mbugf1">
        <div className="tms-wrap2 bg-main-pr">
          <div className="questtmsloan">
            {/* <div className={`${reloader == 1 ? 'show' : 'hide'}`}> */}
            <div className={`main text-center main-div`}>
              <div className="brand-logo mt-lg-0 mt-md-2 mt-sm-2 mt-2">
                <img height="65" src="assets/img/logo_1.png" alt="" />
              </div>
              <div className="main-form-wraper form-part">
                <div className="container">
                  <div className="item-cent-flx ">
                    <div class="openbank-slide2 mt-l-r pt-4">
                      <div class=" pt-lg-3 pt-md-3 pt-sm-3  pt-0    text-center">
                        <div class="row">
                          <div class="col-lg-6 offset-lg-3">
                            <h1 class=" mb-4 mt-md-2 mt-sm-2 mt-0 h4">
                              {/* Please upload your Bank statements
                              covering a period of three months, starting from
                              October 2017 up to December 2017{" "} */}
                               {/* Please upload your Bank statements
                              covering a period of three months, starting from{" "}
                              <span>{MothsBefore}</span> up to <span>{formattedDate}</span> */}
                              {DateContent}
                            </h1>
                            <div class="slot-filed mb-3 ">
                              <div class="d-flex align-items-center">
                                <input
                                  type="file"
                                  className="take_picture"
                                  id="FinanceAgreementDoc"
                                  name="FinanceAgreementDoc"
                                  onChange={changeFilesHandler}
                                  onClick={(e) => (e.target.value = null)}
                                />
                                <div className="icon">
                                  <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    width="25"
                                    height="25"
                                    fill="currentColor"
                                    class="bi bi-upload"
                                    viewBox="0 0 16 16"
                                  >
                                    <path d="M.5 9.9a.5.5 0 0 1 .5.5v2.5a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1v-2.5a.5.5 0 0 1 1 0v2.5a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2v-2.5a.5.5 0 0 1 .5-.5" />
                                    <path d="M7.646 1.146a.5.5 0 0 1 .708 0l3 3a.5.5 0 0 1-.708.708L8.5 2.707V11.5a.5.5 0 0 1-1 0V2.707L5.354 4.854a.5.5 0 1 1-.708-.708z" />
                                  </svg>
                                </div>
                                <div class="ps-3">
                                  Upload files from your device
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>

                        <div class="row   mt-md-2 mt-sm-3 mt-0">
                          <div class="col-lg-6 offset-lg-3  mb-2 text-center">
                            {/* <div> <a><button class=" option_but skip-btn w-100 mb-3"> Skip for Now
                          </button></a> </div> */}
                            <div>
                              <div className="option_but skip-btn w-100 mb-3 skip-btn skipfor-now ">
                                &nbsp; Skip for Now
                                <input
                                  type="radio"
                                  className="take_picture "
                                  name="FinanceAgreementDocSkip"
                                  onClick={SkipSupportDocs}
                                />
                              </div>
                              {isInValidfFile && (
                                <p className="error-msg">{errorMessage}</p>
                              )}
                              <div className="col-xl-12 col-lg-12 text-center p-1 btn_proceed_outer">
                                {selectedFile && (
                                  <>
                                    {selectedFile.name}{" "}
                                    <button
                                      className="btn btn-secondary btn-sm"
                                      name="closefile"
                                      onClick={onSelectOption}
                                    >
                                      Remove
                                    </button>
                                  </>
                                )}
                                {selectedFile && (
                                <input
                                  type="button"
                                  name="btn_qest_45"
                                  disabled={btnDisabled}
                                  className={`btn-comm2 mt-lg-3 mt-md-3 mt-sm-3 mt-0 second ${activeClass == "disabled" && "disabledclass"
                                    }`}
                                  onClick={onProceed}
                                  value="Proceed"
                                />
                                )}
                              </div>
                            </div>

                            {/* <div><button class=" btn-comm2 mt-lg-3 mt-md-3 mt-sm-3 mt-0 second" id="Proceed"> Proceed
                        </button> </div> */}
                            <p class="fs-6 mt-2"> </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="mb-lg-5  pt-3 ">
                  <img
                    height="50"
                    className="safe-secure-img"
                    src="assets/img/safe and secure.png"
                    alt=""
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default BankStmtUploadPageMB1;
