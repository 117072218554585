import React, {
    useState,
    useContext,
} from "react";
import "../../assets/MB_UFGN_4_2/scss/modules/idupload.scss";
import VisitorParamsContext from "../../Contexts/VisitorParams";
import GetVisitorsParams from "../../Utility/GetVisitorsParams";
import { useHistory } from "react-router-dom";
import Idupload_MB_UFGN_4 from "./Idupload_MB_UFGN_4";

const PersonKycMB_GN_4_2 = ({ }) => {

    const history = useHistory();
    const [editKycDiv, setEditKycDiv] = useState("hide");
    const [iduploadDiv, setIdUploadDiv] = useState("hide");
    const [showfirstloader, setShowfirstloader] = useState("hide");
    const [optionDiv, setOptionDiv] = useState("show");
    const { visitorParameters } = useContext(VisitorParamsContext);
    const splitName = localStorage.getItem("split_name");



    const formParameters = JSON.parse(localStorage.getItem("formData"));

    const editKycDetails = () => {
        const targetInput = document.getElementById("editdet");
          targetInput.scrollIntoView({ behavior: "smooth", block: "center" });
        setShowfirstloader("hide");
        setEditKycDiv("show");
        setIdUploadDiv("hide");
    };

    const uploadIdDoc = () => {
        setTimeout(() => {
        const targetInput = document.getElementById("idupld");
          targetInput.scrollIntoView({ behavior: "smooth", block: "center" });
        }, 100);
        setShowfirstloader("hide");
        setIdUploadDiv("show");
        setEditKycDiv("hide");

    };

    const goBack = () =>{
        history.push(
            "/CheckListGN_4?uuid=" + visitorParameters.visitor_parameters.uuid
        );
    }


    return (
        <div className="ID_MB_UFGN_4_2">
            <GetVisitorsParams />
                <div>
                <div className="logo-two text-center pt-3" >
                    <img src="../../assets/MB_UFGN_4/img/logo.png" alt="" />
                </div>
                <div className="container-fluid">

                    <div className={`slide  page-loader loader1  ${showfirstloader}`}>
                        <div className="wrap-app mt-3 text-center">
                            <div className="mb-5 pb-5">
                                <img height="100" className="mb-2" src="../../assets/MB_UFGN_4/img/loader-review2.gif" alt="" />
                                <p className="mb-5">Please Wait</p>
                            </div>
                        </div>
                    </div>

                    <div className="slide connect-account kycupload pb-1 paddstyle">
                        <div className="wrap-app mt-3 text-lg-center text-md-center text-sm-center text-start">
                            <div className={`text-center cntnt-style ${optionDiv}`}>
                               Thank you. Your claim is now
                               registered with us, and we will commence the onboarding process.
                            </div>
                            <div className="form-section">
                                <ul
                                    className={`nav-block nav-pills mb-3 p-0  ${optionDiv}`}
                                    id="pills-tab"
                                    role="tablist"
                                >  
                                    <li className="nav-item " role="presentation">
                                        <button
                                            className="w-100  btn-comm2 inline-block  py-3 px-3 uploadID"
                                            id="pills-profile-tab "
                                            data-bs-toggle="pill"
                                            data-bs-target="#pills-profile"
                                            type="button"
                                            role="tab"
                                            aria-controls="pills-profile"
                                            aria-selected="false"
                                            onClick={uploadIdDoc}
                                        >
                                            {" "}
                                            <span>
                                                {" "}
                                                <i className="bi bi-upload" />
                                                &nbsp;&nbsp; Upload Your ID Proof
                                                &nbsp;&nbsp;
                                                <i className="bi bi-arrow-right-circle" />{" "}
                                            </span>
                                        </button>
                                    </li>
                                </ul>

                                <div className="tab-content" id="pills-tabContent">

                                    {/* // id upload section */}
                                    <Idupload_MB_UFGN_4
                                        className={iduploadDiv}
                                        setIdUploadDiv={setIdUploadDiv}
                                    />

                                </div>

                                {splitName != "MB_UFGN_4_2" && (<div className={`text-center ${optionDiv}`}>
                                    <a onClick={()=>goBack()} >
                                    <div className="mt-5 text-primary mb-5 back-btn text-decoration-underline" id="back2">
                                        <i className="bi bi-arrow-left"></i> Go Back</div>
                                    </a>
                                </div>
                                )}
                            </div>
                        </div>
                    </div>
                </div>
                <div className="text-center py-4 mb-lg-5 mb-md-5 mb-sm-4 mb-4 secure">
                    <img height="35" src="../../assets/MB_UFGN_4/img/secure.png" alt=""/>
                </div>
            
            </div>

        </div>
    );



};

export default PersonKycMB_GN_4_2;