import React, {
  useState,
  useReducer,
  useRef,
  useContext,
  useEffect,
} from "react";
import PersonalDetails from "../Includes/Layouts/MB_UFGN_4_2/PersonalDetails";
import { FormProvider, useForm } from "react-hook-form";
import { useHistory } from "react-router-dom";
import { useDataIngestion } from "../../Hooks/useDataIngestion";
import QueryStringContext from "../../Contexts/QueryString";
import { blacklist } from "../../Hooks/blacklist";
import GtmDataLayer from "../../Utility/GtmDataLayer";
import VisitorParamsContext from "../../Contexts/VisitorParams";
import * as EnvConstants from "../../Constants/EnvConstants";
import { useSignature } from "../../Hooks/useSignature";
import { useClaimReg } from "../../Hooks/useClaimReg";
import { useUpdateMilestone } from "../../Hooks/useUpdateMilestone";
import Trustpilot from "../../Components/Includes/Layouts/MB_UFGN_4_2/Trustpilot"
import SSL from "../../assets/MB_UFGN_4_2/img/secureSSL.png";
import TrustpilotLogo from "../../assets/MB_UFGN_4_2/img/Truspilot-3.png";
import sra from "../../assets/MB_UFGN_4_2/img/sra.png";

const FormMB_UFGN_4_2 = () => {

  const formMethods = useForm({
    mode: "onBlur",
    reValidateMode: "onChange",
    defaultValues: {},
    resolver: undefined,
    context: undefined,
    criteriaMode: "firstError",
    shouldFocusError: true,
    shouldUnregister: true,
  });
  const {
    register,
    errors,
    trigger,
    setError,
    clearErrors,
    getValues,
    watch,
    handleSubmit,
  } = formMethods;

  const {updateMilestone} = useUpdateMilestone();
  const { visitorParameters } = useContext(VisitorParamsContext);
  const formdata = JSON.parse(localStorage.getItem("formData"));
  const [previousAddressData, setPreviousAddressData] = useState({});
  const formParameters = JSON.parse(localStorage.getItem("formData"));
  const { saveSignature } = useSignature();
  const [signatureDataURL, setSignatureDataURL] = useState('');
  const history = useHistory();
  const { updateClaimReg } = useClaimReg();
  const splitForm = useRef(null);
  const signPadRef = useRef(null);
  const [divShow, setDivShow] = useState('');
  const [blacklisted, setBlacklisted] = useState([]);
  const field = "pid";
  const { getBlacklist } = blacklist();
  const { saveDataIngestion, formDataIngestion, isLoading } =
    useDataIngestion();
  const { queryString } = useContext(QueryStringContext);
  useEffect(() => {
    let responsesss = [];
    (async () => {
      const response = await getBlacklist();
      let dataLength = response.data.response.length;
      responsesss = response.data.response;
      if (dataLength > 0) {
        setBlacklisted(response.data.response);
      }
    })();
  }, []);


  useEffect(() => {
    if (signatureDataURL !== '') {
      setSignatureDataURL(signatureDataURL);
    }
  }, [signatureDataURL]);
  const form = splitForm.current;
  const values = getValues();
  const formData = values;

  const formSubmit = async () => {
    if (formData.address1 !== "") {
      const txtUdprn = form["txtUdprn"].value;
      const txtDeliveryPointSuffix = form["txtDeliveryPointSuffix"].value;
      const txtPz_mailsort = form["txtPz_mailsort"].value;
      const txtStreet = form["txtStreet"].value;
      const txtAddress3 = form["txtAddress3"].value;
      const txtDistrict = form["txtDistrict"].value;

      formData.txtDistrict = txtDistrict;
      formData.txtAddress3 = txtAddress3;
      formData.txtUdprn = txtUdprn;
      formData.txtDeliveryPointSuffix = txtDeliveryPointSuffix;
      formData.txtPz_mailsort = txtPz_mailsort;
      formData.txtStreet = txtStreet;
    }

    // //***previous_address ****/
    // formData.previous_postcode = previousAddressData.postcode;
    // formData.previous_address_line1 = previousAddressData.line_1;
    // formData.previous_address_line2 = previousAddressData.line_2;
    // formData.previous_address_line3 = previousAddressData.line_3;
    // formData.previous_address_county = previousAddressData.county;
    // formData.previous_address_town = previousAddressData.town;
    // formData.previous_address_country = previousAddressData.country;
    // formData.previous_district = previousAddressData.district;
    // formData.previous_address_id = previousAddressData.prevAddressId;

    if (form["addressprev1"].value) {
      formData.previous_postcode = form["txtPrevPostCode"].value.toUpperCase();
      formData.previous_address_line1 = form["txtHouseNumberPrev"].value;
      formData.previous_address_line2 = form["txtAddress3prev"].value;
      formData.previous_address_line3 = form["txtAddress3Previous"]?.value;
      formData.previous_address_county = form["txtCountyprev"].value;
      formData.previous_address_town = form["txtTownprev"].value;
      formData.previous_address_country = form["txtCountryprev"].value;
      formData.previous_district = form["txtDistrictPrev"].value;
      formData.previous_address_id = form["addressprev1"].value;
    }
    // if (form["addressprev2"].value) {
    //   console.log("addressprev2",form["addressprev2"].value);
    //   formData.previous_postcodev2 = form["txtPrevPostCode2"].value.toUpperCase();
    //   formData.previous_address_line1v2 = form["txtHouseNumberPrev2"].value;
    //   formData.previous_address_line2v2 = form["txtAddress3prev2"].value;
    //   formData.previous_address_line3v2 = form["txtAddress3previousv2"]?.value;
    //   formData.previous_address_countyv2 = form["txtPrevCounty2prev2"].value;
    //   formData.previous_address_townv2 = form["txtTownprev2"].value;
    //   formData.previous_address_countryv2 = form["txtCountryprev2"].value;
    //   formData.previous_districtv2 = form["txtDistrictPrev2"].value;
    //   formData.previous_address_idv2 = form["addressprev2"].value;
    // }

    formData.user_sign = signatureDataURL;
    formData.Previousname = form["txtPreName"].value;

    formData.page_name = "MB_UFGN_4_2";
    formData.pCode_manual = form["pCode_EntryType"].value;
    if (
      visitorParameters.data !== null ||
      visitorParameters.data !== undefined
    ) {
      // let dobMonth = formData.DobMonth ? MonthNameArray[formData.DobMonth] : '';
      localStorage.setItem("fName", formData.txtFName);
      localStorage.setItem("lName", formData.txtLName);
      localStorage.setItem("pCode", formData.txtPostCode);
      localStorage.setItem("addressLine1", formData.txtHouseNumber);
      localStorage.setItem("dobYr", formData.DobYear);
      localStorage.setItem("dobMon", formData.DobMonth);
      localStorage.setItem("dobDy", formData.DobDay);
      localStorage.setItem("addressLine2", formData.txtAddress2);
      localStorage.setItem("postTown", formData.txtTown);
      localStorage.setItem("country", formData.txtCounty);
      localStorage.setItem("title", formData.lstSalutation);
      localStorage.setItem("addressLine3", formData.txtAddress3);
      localStorage.setItem("county", formData.txtCounty);
      localStorage.setItem(
        "district",
        formData.txtDistrict ? formData.txtDistrict : ""
      );
      let UcFname =
        formData.txtFName.charAt(0).toUpperCase() + formData.txtFName.slice(1);

      localStorage.setItem("UcFname", UcFname);

      localStorage.setItem("formData", JSON.stringify(formData));
      localStorage.setItem("queryString", queryString);

      GtmDataLayer({
        question: "signature",
      });

      if (signatureDataURL) {
        const formSUbmitResult = formDataIngestion(
          visitorParameters.visitor_parameters,
          formData,
          "split_form_submit",
          "MB_UFGN_4_2",
          queryString,
          visitorParameters.data
        );
        const message_type = "signature_store";
        const response = await saveSignature(
          signatureDataURL,
          visitorParameters.visitor_parameters,
          formData,
          visitorParameters.data,
          queryString,
          message_type
        );
        if (response.data.status === "Success") {
          updateMilestone(visitorParameters.visitor_parameters.uuid,"lead","live");
          updateMilestone(visitorParameters.visitor_parameters.uuid,"signature","live");
          pageChange();
        }
      }
    } else {
      return "Fail";
    }
  };
  const pageChange = () => {

    let blacklistFlag = false;
    blacklisted.map((value, index) => {
      if ("email" == value.type && value.value == formData.txtEmail) {
        blacklistFlag = true;
      }

      if ("phone" == value.type && value.value == formData.txtPhone) {
        blacklistFlag = true;
      }
    });

    if (blacklistFlag) {
      window.scrollTo(0, 0);
      history.push(
        "/thankyou-bl?uuid=" + visitorParameters.visitor_parameters.uuid
      );
    } else {
      if (queryString != null) {
        if (queryString.indexOf("&" + field + "=") !== -1) {
          if (EnvConstants.AppConversionPixel === "true") {
            history.push("/fbpixel?split_name=MB_UFGN_4_2");
          }
          else {
            window.scrollTo(0, 0);

            history.push(
              "/questionnaireMBGN_4_2?uuid=" + visitorParameters.visitor_parameters.uuid
            );
          }
        }
        else {
          window.scrollTo(0, 0);
          history.push(
            "/questionnaireMBGN_4_2?uuid=" + visitorParameters.visitor_parameters.uuid
          );
        }
      } else {
        window.scrollTo(0, 0);
        history.push(
          "/questionnaireMBGN_4_2?uuid=" + visitorParameters.visitor_parameters.uuid
        );
      }
    }
  }

  const getUuid = () => {
    const visitorData = visitorParameters.visitor_parameters;
    if (visitorData != "") {
      var uuid = visitorData.uuid;
    } else {
      const query = new URLSearchParams(window.location.search);
      const query_uuid = query.get("uuid");
      const local_storage_uuid = localStorage.getItem("uuid");
      var uuid = query_uuid ? query_uuid : local_storage_uuid;
    }
    return uuid;
  };
  const uuid = getUuid();
  const slideChange = async () => {
    const claimReg = await updateClaimReg(
      uuid,
      "MB_UFGN_4_2",
      "register_claim"
    );
    if (claimReg.data.status == "Success") {
      GtmDataLayer({
        'question': 'register now'
      });
      setDivShow('is-open')
    }
  };


  return (
    <>
      <FormProvider {...formMethods}>
        <form
          ref={splitForm}
          name="split_form"
          id="user_form"
          method="POST"
          autoComplete="off"
        >
          <section className="banner">
            <div className="container">
              <div>
                <div className="brand">
                  <div className="logo pt-3">
                    <a href="index.html">
                      {" "}
                      <img
                        src="../../assets/MB_UFGN_4_2/img/logo-white.png"
                        height={60}
                      />
                    </a>
                  </div>
                </div>
              </div>
              <div className="container-xxl">
                <div className="banner-inner">
                  <div className="row">
                    <div className="col-lg-7 col-md-12 col-sm-12  ">
                      <div className="banner-caption">
                        <div className="overflow-hidden">
                          <h1 className="fw-bold  drop-in tex-lg-start text-md-start text-sm-center text-center">
                            Were you sold a car on unaffordable finance?</h1>
                        </div>
                        <div className="overflow-hidden">
                          <p
                            className="  pt-0 drop-in-2 subtext tex-lg-start text-md-start text-sm-center text-center">
                            If you couldn't afford to make repayments, you may qualify to pursue compensation.</p>
                          <h3 className="pt-lg-1 pt-md-1 pt-sm-1 pt-1 drop-in-2   tex-lg-start text-md-start text-sm-center text-center mb-4 payout">Average Payouts of  <span className="px-3 amount">
                            <svg width="134" height="140" viewBox="0 0 84 40" fill="none" xmlns="http://www.w3.org/2000/svg">
                              <path className="circle"
                                d="M0.5 12.5C20.5 6.66667 77.5 -4.50045 82 15.5C86.5 35.5005 28.5004 43.5 13.5002 35C-1.5 26.5 8.5 11 17 1"
                                stroke="yellow" stroke-width="1" />
                            </svg>
                            £5000!*</span> </h3>

                        </div>

                        <div className="d-block d-lg-none d-md-block d-sm-block py-3 ">
                          <div className="banner-car   text-lg-start text-md-center text-sm-center text-center mt-lg-0 mt-md-0 mt-sm-0 mt-2">
                            {/* <div className="review drop-in-3 ">
                              <div className="overflow-hidden ">
                                <img src={SSL} className="drop-in-3 " alt="" />
                              </div>
                            </div> */}
                            <img className=" car-img fade-in" src="assets/MB_UFGN_4_2/img/car.png" alt="" />
                          </div>
                        </div>

                        <div className=" overflow-hidden tex-lg-start text-md-start text-sm-center text-center mt-lg-3 mt-md-3 mt-sm-0 mt-3">
                          <div className="row">
                            <div className="col-lg-9">
                              <button
                                type="button"
                                className="btn-comm2  w-100 drop-in-3 fw-bold fs-5"
                                id="claimNow"
                                onClick={slideChange}
                              >
                                <span>Register  Now <i className="bi bi-caret-right-fill"></i></span>
                              </button>
                              <p className="mt-3 drop-in-3 text-center" style={{ fontSize: '11px' }}>(Don't miss out on potential compensation. Checking takes only a minute. Take action now)
                              </p>

                            <div className="mb-4 text-center">
                            <img className="drop-in-3 mx-2"
                            src={TrustpilotLogo} height={40} alt="" />
                            {/* <img className="drop-in-3 mx-2" src={sra}
                            height={40}/> */}
                            </div>
                            </div>         
                          </div>
                          </div>
                      </div>
                    </div>
                    <div className="col-lg-5 col-md-12 col-sm-12  d-lg-block d-md-none d-none">
                      <div className="bannerimage-wraper text-lg-start text-md-center text-sm-center text-center mt-lg-0 mt-md-0 mt-sm-0 mt-2">
                        <img className="bannerimg fade-in" src="assets/MB_UFGN_4_2/img/car.png" alt="" />
                      </div>
                    </div>
                 
                  </div>  
                </div>

                <Trustpilot/>
              </div>
            </div>
          </section>
          <PersonalDetails
            formSubmit={formSubmit}
            pageChange={pageChange}
            divShow={divShow}
            setPreviousAddressData={setPreviousAddressData}
            setSignatureDataURL={setSignatureDataURL}
          />
        </form>

      </FormProvider>
    </>
  );
};

export default FormMB_UFGN_4_2;
