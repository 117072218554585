import { useEffect, useState } from "react";
import { lenderSuggest } from "../../../../Constants/Constants";

export default function Autocomplete({validation,suggestionList,setSelectBank,clearErrors}) {
  const [inputValue, setInputValue] = useState("");
  const [suggestions, setSuggestions] = useState([]);

  useEffect(()=>{
    if(inputValue!==''){
        setSelectBank(inputValue);
    }
  },[inputValue]);

  const handleInputChange = (event) => {
    const value = event.target.value;
    clearErrors('otherlenders');
    setInputValue(value);
    if (value.length > 0) {
      const filteredSuggestions =[ ...lenderSuggest,...suggestionList].filter((suggestion) =>
        typeof suggestion === 'string' && suggestion.toLowerCase().includes(value.toLowerCase())
      );
      setSuggestions(
        filteredSuggestions.length > 0
          ? filteredSuggestions
          : []
      );
    } else {
      setSuggestions([]);
    }
  };

  const handleSuggestionClick = (value) => {
    
    setInputValue(value);
    setSuggestions([]);
   
  };

  const handleBlur = () => {
    setInputValue((prevValue) => prevValue.trim());
  };

  return (
    <>
      <input
        type="text"
        value={inputValue}
        onChange={handleInputChange}
        aria-autocomplete="list"
        aria-controls="autocomplete-list"
        className="form-control p-3 h-55 borderboxxx"
        placeholder="Enter your Bank name"
        id="otherlenders"
        name="otherlenders"
        ref={validation({
            required: "Please Enter Valid Bank Name",
            pattern: {
                value: /^(?![^A-Za-z\s]).*$/,
                message: "Invalid Bank Name",
            },
        })}
        onBlur={handleBlur}
    />
      {suggestions.length > 0 && (
        <ul id="autocomplete-list" className="suggestions-list" role="listbox">
          {suggestions.map((suggestion, index) => (
            <li
              key={index}
              onClick={() => handleSuggestionClick(suggestion)}
              role="option"
              aria-selected="false"
              // Additional props
            >
              {suggestion}
            </li>
          ))}
        </ul>
      )}
    </>
  );
}
