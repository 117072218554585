import React, { useState } from "react";
import RadioButtonQuest from "../../../UI/RadioButtonQuest";
import CheckBox from "../../../UI/CheckBox";
import CheckboxOutLabel from "../../../UI/CheckboxOutLabel";

const FurtherBorrowing = ({ onSelectAnswer, backClick, setCheckedOptions , checkedOptions}) => {

    const [questResult, setQuestResult] = useState(false);

    const optionClicked = (e) => {

        if (e.target.checked === true) {
            setQuestResult(false);
            checkedOptions.push({ id: +e.target.value });
          } else {
            let deselect = e.target.value;
            const decre = checkedOptions.map((OptionArray, index) => {
              if (deselect == OptionArray.id) {
                checkedOptions.splice(index, 1);
                if (checkedOptions.length == 0) {
                }
              }
            });
          }
    };

    const selectedClick = (e) => {
        e.preventDefault();
       
        let selectedCount = checkedOptions.length;
    if (selectedCount == 0) {
      setQuestResult(true);
    } else {
      setCheckedOptions(checkedOptions);
      onSelectAnswer(e);
    }
    };

    return (
        <>
            <div className="question-content mt-0 scale-down-ver-center px-3 py-lg-3 py-md-3 py-sm-2 py-2 pb-3">
                <div className="row">
                    <div className="col-lg-12 mb-2">
                        <ul className="p-0 m-0 option-grid">
                            <li className="adjustbalewid li-20">
                                <div className="optionbtn">
                                  <div className="odnewscss">
                                    <CheckboxOutLabel
                                        name="furtherborrowingforfinance"
                                        id="optionztwo"
                                        labelClassName="tick-check-box"
                                        value="282"
                                        labelName="Payday lending"
                                        onClick={optionClicked}
                                        dataQuestId={102}
                                        dataAnsId={282}
                                        dataAnsLabel="Payday lending"
                                    />
                                </div>
                              </div>
                            </li>
                            <li className="adjustbalewid li-20">
                                <div className="optionbtn">
                                  <div className="odnewscss">
                                    <CheckboxOutLabel
                                        name="furtherborrowingforfinance"
                                        id="optionztwo2"
                                        labelClassName="tick-check-box"
                                        value="283"
                                        labelName="Loans"
                                        onClick={optionClicked}
                                        dataQuestId={102}
                                        dataAnsId={283}
                                        dataAnsLabel="Loans"
                                    />
                                </div>
                              </div>
                            </li>
                            <li className="adjustbalewid li-20">
                                <div className="optionbtn">
                                 <div className="odnewscss">
                                    <CheckboxOutLabel
                                        name="furtherborrowingforfinance"
                                        id="optionztwo3"
                                        labelClassName="tick-check-box"
                                        value="284"
                                        labelName="Credit cards"
                                        onClick={optionClicked}
                                        dataQuestId={102}
                                        dataAnsId={284}
                                        dataAnsLabel="Credit cards"
                                    />
                                </div>
                              </div>
                            </li>
                            <li className="adjustbalewid li-lastchld">
                                <div className="optionbtn">
                                  <div className="odnewscss">
                                    <CheckboxOutLabel
                                        name="furtherborrowingforfinance"
                                        id="optionztwo4"
                                        labelClassName="tick-check-box"
                                        value="285"
                                        labelName="Borrowing from friends and family"
                                        onClick={optionClicked}
                                        dataQuestId={102}
                                        dataAnsId={285}
                                        dataAnsLabel="Borrowing from friends and family"
                                    />
                                  </div>
                                </div>
                            </li>
                        </ul>
                        {questResult && (
                            <span className="error_msg">Please select at least one option</span>
                        )}
                    </div>
                    <div className="col-lg-12">
                        <button
                            className="qb12 btn-comm2 py-lg-3 py-md-3 py-sm-2 py-2 w-100 nextBtn"
                            name="furtherborrowing"
                            type="button"
                            onClick={selectedClick}
                            data-quest-id={102}
                        >
                           Next &#8594;
                        </button>
                    </div>
                </div>
                <p className="text-center bck-marwid">
                    <button
                      name="incmAndexp"
                      className="mt-2 btn btn-secondary backbtn1a"
                      id=""
                      value=""
                      type="button"
                      onClick={backClick}
                    >
                      Go Back
                    </button>
                </p>
            </div>
        </>
    );
};

export default FurtherBorrowing;
