import React, { useReducer, useState } from "react";
import { usePostCodeAddress } from "../../../../Hooks/usePostCodeAddress";
import AddressTextField from "../../../UI/AddressTextField";
import { useFormContext } from "react-hook-form";
import HiddenField from "../../../UI/HiddenField";
import AddressSelectBox from "../../../UI/AddressSelectBox";
import AddressTextField3 from "../../../UI/AddressTextField3";
import GtmDataLayer from "../../../../Utility/GtmDataLayer";

const initialState = {
  lookUp: true,
  nextClick: false,
  checkSelect: false,
  getDetails: [],
  getAddress: [],
  showManualAddress: "hide",
  getPostcode: "#",
  clickManualLink: 0,
  readonly: true,
};

const postcodeReducer = (state, action) => {
  switch (action.type) {
    case "validationSucess": {
      return {
        ...state,
        lookUp: action.payload.lookup,
        nextClick: action.payload.next_click,
        getPostcode: action.payload.postcode,
        getAddress: action.payload.get_address,
        checkSelect: action.payload.check_select,
        showManualAddress: action.payload.manual_address,
      };
    }
    case "getExtraAddressDetails": {
      return {
        ...state,
        getDetails: action.payload.get_details,
        showManualAddress: action.payload.manual_address,
      };
    }
    case "showManualDiv": {
      return {
        ...state,
        showManualAddress: "show",
        lookUp: false,
        nextClick: true,
        checkSelect: false,
        clickManualLink: action.payload.manual_click,
        readonly: false,
      };
    }
    case "resetaddress": {
      return {
        ...state,
        getDetails: [],
        getAddress: [],
        showManualAddress: "hide",
        lookUp: true,
        nextClick: false,
        checkSelect: false,
      };
    }
    default:
      return state;
  }
};

const PostCode = () => {
  const {
    trigger,
    clearErrors,
    register,
    getValues,
    errors,
    watch,
    setValue,
    setError,
    splitForm,
  } = useFormContext();

  const [postgetModalone, setPostgetModalone] = useState(false);
  const { getPostcodeValidation, getPostcodeAddress, getSplitPostcodeAddress } =
    usePostCodeAddress();
  const [state, dispatch] = useReducer(postcodeReducer, initialState);
  const [postCodeAddress, setPostCodeAddress] = useState([]);
  const [postCodeEntry, setPostCodeEntry] = useState("0");

  const checkValidation = async (e) => {
    e.preventDefault();
    dispatch({
      type: "resetaddress",
      get_address: [],
    });
    const postcode = e.target.value.trim();
    const txtPostCode = await trigger("txtPostCode");
    if (txtPostCode) {
      await getPostcodeAddress(postcode)
        .then((res) => {
          if (res.data.status === "0") {
            setError("txtPostCode", {
              type: "manual",
              message: "Please Enter Valid Postcode",
            });
          }
          if (res.data.length > 0) {
            GtmDataLayer({
              question: "postcode",
            });
            dispatch({
              type: "validationSucess",
              payload: {
                lookup: false,
                next_click: true,
                postcode: postcode,
                get_address: res.data,
                check_select: true,
                manual_address: "hide",
              },
            });
          }
        })
        .catch(function () {
          setError("txtPostCode", {
            type: "manual",
            message: "Please Enter Valid Postcode",
          });
        });
    }
  };
  const getValue = async (e) => {
    document.getElementById("address1").classList.remove("pulse2");
    document.getElementById("address1").classList.remove("shadow2");
    document.getElementById("DobDay").classList.add("shadow2");
    document.getElementById("DobDay").classList.add("pulse2");
    e.preventDefault();
    clearErrors([
      "address1",
      "txtHouseNumber",
      "txtAddress2",
      "txtCounty",
      "txtTown",
      "txtCountry",
    ]);
    if (e.target.value != "") {
      const getData = await getSplitPostcodeAddress(
        e.target.value,
        state.getPostcode
      );
      dispatch({
        type: "getExtraAddressDetails",
        payload: { get_details: getData.data, manual_address: "show" },
      });
      setPostCodeAddress(getData.data);
      clearErrors([
        "address1",
        "txtHouseNumber",
        "txtAddress2",
        "txtCounty",
        "txtTown",
        "txtCountry",
      ]);
    } else {
      dispatch({
        type: "getExtraAddressDetails",
        payload: { get_details: [], manual_address: "hide" },
      });
      setPostCodeAddress([]);
    }
    const targetInput = document.getElementById("date-birth");
    if (targetInput) {
      targetInput.scrollIntoView({ behavior: "smooth", block: "start" });
    }
  };

  const manualEntry = () => {
    dispatch({
      type: "showManualDiv",
      payload: { get_details: [], manual_address: "hide" },
    });

  };

  const lookupValidation = async (e) => {
    document.getElementById("lookUp").classList.remove("shadow2");
    document.getElementById("lookUp").classList.remove("pulse2");

    await trigger("txtPostCode");
  };

  const checkPostCodeEntry = () => {
    const addressLine1 = document.getElementById("txtHouseNumber").value;
    const addressLine2 = document.getElementById("txtAddress2").value;
    const town = document.getElementById("txtTown").value;
    const county = document.getElementById("txtCounty").value;
    const country = document.getElementById("txtCountry").value;

    if (
      postCodeAddress.length === 0 ||
      postCodeAddress.Country !== country ||
      postCodeAddress.County !== county ||
      postCodeAddress.Line1 !== addressLine1 ||
      postCodeAddress.Line2 !== addressLine2 ||
      postCodeAddress.Town !== town
    ) {
      setPostCodeEntry("1");
    }
  };

  const addressValidation = async (e) => {
    let errorFlag = 0;
    const txtPostCode = await trigger("txtPostCode");
    var manual_txtHouseNumber;
    var manual_txtTown;
    var manual_txtCountry;
    var txtAddress;
    if (txtPostCode) {
      manual_txtHouseNumber = await trigger("txtHouseNumber");
      txtAddress = await trigger("address1");
    } else {
      document.getElementById("txtPostCode").focus();
      return false;
    }
    if (manual_txtHouseNumber) {
      manual_txtTown = await trigger("txtTown");
    } else {
      return false;
    }
    if (manual_txtTown) {
      manual_txtCountry = await trigger("txtCountry");
    } else {
      return false;
    }
    if (
      !txtPostCode ||
      !manual_txtHouseNumber ||
      !manual_txtTown ||
      !manual_txtCountry
    ) {
      errorFlag = 1;
    }

    if (state.clickManualLink === 0) {
      const address1 = await trigger("address1");
      if (!address1) {
        errorFlag = 1;
      }
    }
    if (errorFlag === 0) {
      checkPostCodeEntry();
      setPostgetModalone(true);
    } else {
      return false;
    }
  };

  const HiddenFields = () => (
    <>
      <HiddenField
        name="txtDistrict"
        inputId="txtDistrict"
        inputValue={state?.getDetails !== "#" ? state?.getDetails?.district : ""}
        validation={register()}
      />
      <HiddenField
        name="txtAddress3"
        inputId="txtAddress3"
        inputValue={state?.getDetails !== "#" ? state?.getDetails?.line_3 : ""}
        validation={register()}
      />
      <HiddenField
        name="txtUdprn"
        inputId="txtUdprn"
        inputValue={state?.getDetails !== "#" ? state?.getDetails?.Udprn : ""}
      />
      <HiddenField
        name="txtDeliveryPointSuffix"
        inputId="txtDeliveryPointSuffix"
        inputValue={
          state?.getDetails !== "#" ? state?.getDetails?.deliverypointsuffix : ""
        }
      />
      <HiddenField
        name="txtPz_mailsort"
        inputId="txtPz_mailsort"
        inputValue={
          state?.getDetails !== "#" ? state?.getDetails?.pz_mailsort : ""
        }
      />
      <HiddenField
        name="txtStreet"
        inputId="txtStreet"
        inputValue={
          state?.getDetails?.line_3 !== "#" ? state?.getDetails?.line_3 : ""
        }
      />
    </>
  );
  return (
    <>
      <HiddenField
        name="pCode_EntryType"
        inputId="pCode_EntryType"
        inputValue={postCodeEntry}
      />
      <div className="mt-2">
        <fieldset className="scheduler-border d-block">
          <legend className="scheduler-border">Add your current address</legend>
          <div className="col-lg-12">
            <div className="row">
              <div className="col-lg-8 col-md-8 col-12 pe-2">
                <div className="mb-2">
                  <AddressTextField3
                    type="text"
                    placeholder="Postcode"
                    name="txtPostCode"
                    id="txtPostCode"
                    className="form-control"
                    dataId="txtPostCode"
                    onBlur={(e) => {
                      e.target.value = e.target.value.trim(); 
                      checkValidation(e);                      
                    }}
                    autoComplete="off"
                    maxlength="8"
                    validation={register({
                      required: "Please enter your postcode",
                      pattern: {
                        value:
                          /^(([A-Z]{1,2}[0-9]{1,2} ?[0-9][A-Z]{2})|([A-Z]{1,2}[0-9][A-Z] ?[0-9][A-Z]{2,3}))$/i,
                        message: "Please enter a valid postcode",
                      },
                      minLength: {
                        value: 5,
                        message: "Please enter a valid postcode",
                      },
                    })}
                    validationMsg={
                      errors?.txtPostCode && errors?.txtPostCode?.message
                    }
                  />
                </div>
              </div>
              <div className="col-lg-4 ps-lg-0 mb-2">
                <button
                  className="btn-comm2 p-2 w-100 shadow2 pulse2"
                  id="lookUp"
                  name="next"
                  type="button"
                  style={{ marginLeft: "5px" }}
                  onClick={lookupValidation}
                >
                  <span>Look Up Address</span>
                </button>
              </div>
            </div>
            <div
              className={`col-lg-12 mb-3`}
              id="currentAddressCollapse"
              style={
                { display:state?.checkSelect ?  "block" : "none" }
              }
            >
              <AddressSelectBox
                className="form-select watermark shadow2 pulse2 animated-effect"
                OptionValue={state?.getAddress}
                name="address1"
                id="address1"
                onChange={getValue}
                myRef={register({ required: "Please select your address" })}
                validationMsg={errors?.address1 && errors?.address1?.message}
              />
            </div>
            <div
              className="col-12 text-center"
              style={
                { display: state.getDetails.length === 0
                  ?  "block" : "none" }
              }
            ></div>
            <div className={`col-lg-12 ${state?.showManualAddress}`}>
              <div className={`mb-3`}>
                <AddressTextField
                  type="text"
                  placeholder="Address Line 1 *"
                  name="txtHouseNumber"
                  id="txtHouseNumber"
                  className="form-control"
                  dataId="txtHouseNumber"
                  autoComplete="off"
                  readonly={state?.readonly}
                  validation={register({
                    required: "Please enter address line 1",
                  })}
                  validationMsg={
                    errors?.txtHouseNumber && errors?.txtHouseNumber?.message
                  }
                  value={state?.getDetails?.line_1}
                />
                <i
                  className="validate validate_success"
                  aria-hidden="true"
                  style={{ display: "none" }}
                ></i>
              </div>

              <div className={`col-lg-12 form-group adj_width mb-3`}>
                <AddressTextField
                  type="text"
                  placeholder="Address Line 2"
                  name="txtAddress2"
                  id="txtAddress2"
                  className="form-control"
                  dataId="txtAddress2"
                  autoComplete="off"
                  readonly={state?.readonly}
                  validation={register()}
                  validationMsg=""
                  value={state?.getDetails?.line_2}
                />
                <i
                  className="validate validate_success"
                  aria-hidden="true"
                  style={{ display: "none" }}
                ></i>
              </div>

              <div className={`col-lg-12 form-group adj_width mb-3`}>
                <AddressTextField
                  type="text"
                  placeholder="County"
                  name="txtCounty"
                  id="txtCounty"
                  className="form-control"
                  dataId="txtCounty"
                  autoComplete="off"
                  readonly={state?.readonly}
                  validation={register()}
                  validationMsg=""
                  value={state?.getDetails?.county}
                />
                <i
                  className="validate validate_success"
                  aria-hidden="true"
                  style={{ display: "none" }}
                ></i>
              </div>

              <div className={`col-lg-12 form-group adj_width mb-3`}>
                <AddressTextField
                  type="text"
                  placeholder="Town *"
                  name="txtTown"
                  id="txtTown"
                  className="form-control"
                  dataId="txtTown"
                  autoComplete="off"
                  readonly={state?.readonly}
                  validation={register({ required: "Please enter town" })}
                  validationMsg={errors?.txtTown && errors?.txtTown?.message}
                  value={state?.getDetails?.town}
                />
                <i
                  className="validate validate_success"
                  aria-hidden="true"
                  style={{ display: "none" }}
                ></i>
              </div>
              <div className={`col-lg-12 form-group adj_width mb-3`}>
                <AddressTextField
                  type="text"
                  placeholder="Country *"
                  name="txtCountry"
                  id="txtCountry"
                  className="form-control"
                  dataId="txtCountry"
                  autoComplete="off"
                  readonly={state?.readonly}
                  validation={register({ required: "Please enter country" })}
                  validationMsg={errors?.txtCountry && errors?.txtCountry?.message}
                  value={state?.getDetails?.country}
                />
                <i
                  className="validate validate_success"
                  aria-hidden="true"
                  style={{ display: "none" }}
                ></i>
              </div>
              {Object.keys(state?.getDetails)?.length > 0 && (
                <HiddenFields />
              )}
            </div>

            <div
              className="col-lg-12 col-12 text-center p-0"
              style={
                { display:state?.nextClick ?  "block"  :  "none" }
              }
            ></div>
          </div>
        </fieldset>
      </div>
    </>
  );
};
export default PostCode;
