import React, { useState } from "react";
import TextArea from "../../../UI/TextArea";
import GtmDataLayer from "../../../../Utility/GtmDataLayer";

const  DescribeProblem = ({ 
    saveInputAns, 
    backClick,
    validation,
    validationMsg,
    trigger,
    setError,
    getValues
}) => {

    const handleClick = async (e) => {
        let errorFlag = 0;
        let result = await trigger("descProblem");
        if (!result) {
            errorFlag = 1;
            return false;
        } else {
            const fieldResult = getValues();
            var reason = fieldResult["descProblem"].trim();
            if (reason == "" || reason.length < 3) {
 
             setError("descProblem", {
                 type: "manual",
                 message: "Please Enter Valid Reason",
             });
             return false;
            } else {
            GtmDataLayer({
                question: "problems in repaying",
            });
             saveInputAns(e);
            }   
        }
    };

    return (
        <>
            <div
                className={`question-content mt-0 scale-down-ver-center px-3  py-lg-3 py-md-3 py-sm-2 py-2 `}
            >
                   
                   <TextArea
                            type="textarea"
                            className="form-control"
                            name="descProblem"
                            validation={validation({
                                required: "Please Enter the Reason",
                                minLength: {
                                    value: 3,
                                    message: "Please Enter Valid Reason",
                                },
                            })}
                            validationMsg={
                                validationMsg.descProblem &&
                                validationMsg.descProblem.message
                            }
                        />
                   
                    <div className="col-lg-12 mb-2 mt-2">
            <button
              className="qb14 btn-comm2 py-lg-3 py-md-3 py-sm-2 py-2 w-100 nextBtn"
              type="button"
              onClick={handleClick}
              data-quest-id={84}
              data-ans-id={null}
              name="describeProblem"
            >
              Next <i className="bi bi-arrow-right" />
            </button>
          </div>

                <div className=" text-center mt-2">
                    <button className="btn btn-secondary backbtn" type="button" name="difficulties" onClick={backClick}>
                        {" "}
                        <i className="bi bi-arrow-left" /> Back
                    </button>
                </div>

            </div>

        </>

    );
}


export default DescribeProblem;