import React from "react";
const MonthlyIncome = ({
    onSelectAnswer,
    backClick
}) => {
    const optionClicked = async (e) => {
        onSelectAnswer(e);
    };

    return (
        <>
            <div className="question-content mt-0 scale-down-ver-center px-3 py-lg-3 py-md-3 py-sm-2 py-2 ">
                <div className="row">
                    <div className="col-lg-3 col-md-6 col-sm-12">
                        <div className=" mb-2">
                            <button
                                type="button"
                                className="btn-comm2 py-lg-3 py-md-3 py-sm-2 py-2 w-100 nextBtn"
                                onClick={optionClicked}
                                value="269"
                                name="monthlyincome"
                                id="nextscrolla"
                                data-quest-id={101}
                                data-ans-id={269}
                                data-ans-label={"Less than £ 500"}
                            >Less than £500
                            </button>
                        </div>
                    </div>

                    <div className="col-lg-3 col-md-6 col-sm-12">
                        <div className=" mb-2">
                            <button
                                type="button"
                                className="btn-comm2 py-lg-3 py-md-3 py-sm-2 py-2 w-100 nextBtn"
                                onClick={optionClicked}
                                value="270"
                                name="monthlyincome"
                                id="nextscrolla"
                                data-quest-id={101}
                                data-ans-id={270}
                                data-ans-label={"£ 500 - £ 1000"}
                            >£500 - £1000
                            </button>
                        </div>
                    </div>
                    <div className="col-lg-3 col-md-6 col-sm-12">
                        <div className=" mb-2">
                            <button
                                type="button"
                                className="btn-comm2 py-lg-3 py-md-3 py-sm-2 py-2 w-100 nextBtn"
                                onClick={optionClicked}
                                value="271"
                                name="monthlyincome"
                                id="nextscrolla"
                                data-quest-id={101}
                                data-ans-id={271}
                                data-ans-label={"£ 1000 - £ 1500"}
                            >£1000 - £1500
                            </button>
                        </div>
                    </div>

                    <div className="col-lg-3 col-md-6 col-sm-12">
                        <div className=" mb-2">
                            <button
                                type="button"
                                className="btn-comm2 py-lg-3 py-md-3 py-sm-2 py-2 w-100 nextBtn"
                                onClick={optionClicked}
                                value="272"
                                name="monthlyincome"
                                id="nextscrolla"
                                data-quest-id={101}
                                data-ans-id={272}
                                data-ans-label={"£ 1500 - £ 2000"}
                            >£1500 - £2000
                            </button>
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col-lg-3 col-md-6 col-sm-12">
                        <div className=" mb-2">
                            <button
                                type="button"
                                className="btn-comm2 py-lg-3 py-md-3 py-sm-2 py-2 w-100 nextBtn"
                                onClick={optionClicked}
                                value="273"
                                name="monthlyincome"
                                id="nextscrolla"
                                data-quest-id={101}
                                data-ans-id={273}
                                data-ans-label={"£ 2500 - £ 3000"}
                            >£2500 - £3000
                            </button>
                        </div>
                    </div>

                    <div className="col-lg-3 col-md-6 col-sm-12">
                        <div className=" mb-2">
                            <button
                                type="button"
                                className="btn-comm2 py-lg-3 py-md-3 py-sm-2 py-2 w-100 nextBtn"
                                onClick={optionClicked}
                                value="274"
                                name="monthlyincome"
                                id="nextscrolla"
                                data-quest-id={101}
                                data-ans-id={274}
                                data-ans-label={"£ 3000 - £ 4000"}
                            >£3000 - £4000
                            </button>
                        </div>
                    </div>
                    <div className="col-lg-3 col-md-6 col-sm-12">
                        <div className=" mb-2">
                            <button
                                type="button"
                                className="btn-comm2 py-lg-3 py-md-3 py-sm-2 py-2 w-100 nextBtn"
                                onClick={optionClicked}
                                value="275"
                                name="monthlyincome"
                                id="nextscrolla"
                                data-quest-id={101}
                                data-ans-id={275}
                                data-ans-label={"More than £ 4000"}
                            >More than £4000
                            </button>
                        </div>
                    </div>
                </div>
                <p className="text-center bck-marwid">
                    <button
                        name="recieveanyincome"
                        className="btn btn-secondary backbtn1a"
                        id=""
                        value=""
                        type="button"
                        onClick={backClick}

                    >Go Back</button>
                </p>
            </div>
        </>
    )
}
export default MonthlyIncome; 