import { Api } from "../api/Api";
import * as EnvConstants from "../Constants/EnvConstants";

export const useUserClickStore = () => {
  const userClickStore = async (
    user_uuid,
    page,
    action,
    click_type,
    source
  ) => {
    const response = Api.post("api/v1/reporting/actions/save", {
        user_uuid,
        page,
        action,
        click_type,
        source
    });
    return response;
  };

  return { userClickStore };
};
