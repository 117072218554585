import { useFormContext } from "react-hook-form";
import ErrorMessage from "./ErrorMessage";

function Input({
  label,
  name,
  type = "text",
  className = "",
  rules = {},
  transform = (value) => value,
  message,
  secured = false,
  ...props
}) {
  const { register, setValue, trigger, errors } = useFormContext();

  const error = errors[name];

  return (
    <>
      {!!label && (
        <label htmlFor={name} className={className}>
          {label}
        </label>
      )}

      <div className="input-group">
        <input
          type={type}
          className={`form-control ${className}`}
          name={name}
          ref={register(rules)}
          onChange={(e) => {
            setValue(name, transform(e.target.value));
          }}
          {...props}
        />

        {!!secured && (
          <span className="input-group-text">
            {/* <img src={PrivacyImage} alt="Secured" /> */}
          </span>
        )}
      </div>

      {message && <ErrorMessage message={message} />}
    </>
  );
}

export default Input;
