import React from 'react'

const ExceedCreditLimit = ({
    everIncreased,
    onSelectAnswer,
    backClick,
    setExceedCredit }) => {
    const optionHandle = async (e) => {

        if (e.target.value === "251") {
            setExceedCredit("251");
            onSelectAnswer(e);
        } else if (e.target.value === "252") {
            setExceedCredit("252");
            onSelectAnswer(e);
        }

    }
    let toggle = "";
    if (everIncreased == "248") {
        toggle = "everincresedCredLimit";
    } else {
        toggle = "highestcreditLimit";
    }
    return (
        <div className="question-content mt-0 scale-down-ver-center px-3 py-lg-3 py-md-3 py-sm-2 py-2 ">
            <div className="row">
                <div className="col-6">
                    <div className=" mb-2">
                        <button
                            type="button"
                            className={`btn-comm2 py-lg-3 py-md-3 py-sm-2 py-2 w-100 nextBtn`}
                            onClick={optionHandle}
                            value="251"
                            name="exceedCredLimit"
                            id="nextscroll"
                            data-quest-id={95}
                            data-ans-id={251}
                            data-ans-label={null}
                        >Yes
                        </button>
                    </div>
                </div>
                <div className="col-6">
                    <div className=" mb-2">
                        <button
                            type="button"
                            className={`btn-comm2 py-lg-3 py-md-3 py-sm-2 py-2 w-100 noissue`}
                            onClick={optionHandle}
                            value="252"
                            name="exceedCredLimit"
                            id="nextscroll"
                            data-quest-id={95}
                            data-ans-id={252}
                            data-ans-label={null}
                        >No
                        </button>
                    </div>
                </div>
            </div>
            <p className="text-center bck-marwid">
                <button
                    name={toggle}
                    className="btn btn-secondary backbtn1a"
                    id=""
                    value=""
                    type="button"
                    onClick={backClick}
                >
                    Go Back
                </button>
            </p>
        </div>
    )
}

export default ExceedCreditLimit;