import React, { useState } from "react";
const SoldOnDept = ({
  className,
  saveQuestAnswer,
  validation,
  addPending,
  removeFromPending,
  qnNo,
}) => {
  const [checkedOne, setCheckedOne] = useState("unchecked");
  const [checkedTwo, setCheckedTwo] = useState("unchecked");

  const optionSelected = async (e) => {
    if (e.target.value === "206") {
      setCheckedTwo("checked");
      removeFromPending("soldOnDebt");
      addPending("whatYearHappen");
    } else {
      setCheckedTwo("unchecked");
    }

    if (e.target.value === "207") {
      setCheckedOne("checked");
      removeFromPending("soldOnDebt");
      removeFromPending("whatYearHappen");
    } else {
      setCheckedOne("unchecked");
    }
    saveQuestAnswer(71, e.target.value);
  };

  return (
    <>
      <div className={`container ${className}`}>
        <div className="questitem mt-5 qc-10 mt-0 scale-down-ver-center px-3 py-lg-3 py-md-3 py-sm-2 py-2 ">
          <h1 className="que-title qt-10 p-3 ">
            Has Vanquis sold on your debt, if any, to a company such as Lowell
            Portfolio / Arrow Global?
          </h1>
          <div className="row">
            <div className="col-6">
              <div className=" mb-2">
                <button
                  type="button"
                  className={`qb10 btn-comm2 py-lg-3 py-md-3 py-sm-2 py-2 w-100 nextBtn ${checkedTwo}`}
                  onClick={optionSelected}
                  value="206"
                  name="SoldOnDebt"
                  id="nextscroll"
                  data-quest-id={71}
                  data-ans-id={206}
                  data-ans-label={null}
                  data-click-id='soldOnDebt_yes'
                >
                  Yes
                </button>
              </div>
            </div>
            <div className="col-6">
              <div className=" mb-2">
                <button
                  type="button"
                  className={`qb10 btn-comm2 py-lg-3 py-md-3 py-sm-2 py-2 w-100 nextBtn ${checkedOne}`}
                  value="207"
                  name="SoldOnDebt"
                  onClick={optionSelected}
                  id="nextscroll"
                  data-quest-id={71}
                  data-ans-id={207}
                  data-ans-label={null}
                  data-click-id='soldOnDebt_no'
                >
                  No
                </button>
              </div>
            </div>
          </div>
          <p className="text-center bck-marwid">
            <button
              name="outBalAmount"
              className="btn btn-secondary backbtn1a"
              id=""
              value=""
              type="button"
              onClick={() => addPending("debtBalance")}
              data-click-id='soldOnDebt_back'
            >
              Go Back
            </button>
          </p>
        </div>
        <div class="tipbox tip2 show">
          Answer to this question will help us identify your debt profile.
          <div class="arrow  arrow-top"></div>
        </div>
        <div
          id="rangequestions"
          class="qrange d-flex align-items-sm-center text-center"
        >
          Question <br class="d-none d-md-block" />
          {qnNo}
        </div>
      </div>
    </>
  );
};
export default SoldOnDept;
