import react, { useState } from "react";
import { MonthlyIncome } from "../../../../Constants/Constants";
import SelectBox from "../../../UI/SelectBox";
const CardMonthlyIncome = ({
  className,
  saveQuestAnswer,
  validationMsg,
  clearErrors,
  validation,
  trigger,
  getValues,
  addPending,
  removeFromPending,
  qnNo
}) => {
  const [pulse, setPulse] = useState(true);
  const optionSelected = async (e) => {
    let errorFlag = 0;
    let result = await trigger("cardMonthlyIncome");

    if (!result) {
      errorFlag = 1;
      return false;
    } else {
      let data = getValues();
      saveQuestAnswer(78,null,data.cardMonthlyIncome);
      removeFromPending("monthlyIncome");
    }
  };
  return (
    <>
      <div className={`container ${className}`}>
        <div className="questitem mt-5  mt-0 scale-down-ver-center px-3 py-lg-3 py-md-3 py-sm-2 py-2 pb-3 ">
          <h1 className="que-title p-3 ">
            When you took out your card what was your monthly income?
          </h1>
          <div className="row">
            <div className="col-lg-8 mb-2">
              <SelectBox
                className={
                  pulse == true
                    ? "form-select onSelect pulse3 "
                    : "form-select onSelect"
                }
                OptionValue={MonthlyIncome}
                name="cardMonthlyIncome"
                id="cardMonthlyIncome"
                onChange={() => {
                  setPulse(false);
                  clearErrors("cardMonthlyIncome");
                }}
                clearErrors={clearErrors}
                myRef={validation({
                  required: "Please select your monthly income",
                })}
                validationMsg={
                  validationMsg.cardMonthlyIncome &&
                  validationMsg.cardMonthlyIncome.message
                }
              ></SelectBox>
            </div>
            <div className="col-lg-4 mb-2 ">
              <button
                type="button"
                className=" btn-comm2 py-lg-3 py-md-3 py-sm-2 py-2 w-100 nextBtn "
                id="nextscroll"
                name="cardMonthlyIncome"
                onClick={optionSelected}
                data-quest-id={78}
                data-click-id='monthlyIncome_next'
              >
                Next &#8594;
              </button>
            </div>
          </div>
          <p className="text-center bck-marwid">
            <button
              name="didReceiveIncome"
              className="btn btn-secondary backbtn1a"
              id=""
              value=""
              type="button"
              onClick={()=>addPending("receiveIncome")}
              data-click-id='monthlyIncome_back'
            >
              Go Back
            </button>
          </p>
        </div>
        <div
          id="rangequestions"
          class="qrange d-flex align-items-sm-center text-center"
        >
          Question <br class="d-none d-md-block" />{qnNo}
        </div>
      </div>
    </>
  );
};
export default CardMonthlyIncome;
