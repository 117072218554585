import React, { useState } from "react";

const DidReceiveIncome = ({
  clsName,
  onSelectAnswer,
  validation,
  backClick,
  setquest77Previous,
}) => {
  const [checkedOne, setCheckedOne] = useState("unchecked");
  const [checkedTwo, setCheckedTwo] = useState("unchecked");

  const optionSelected = async (e) => {
    if (e.target.value === "226") {
      setCheckedOne("checked");
      setquest77Previous("226");
      onSelectAnswer(e);
    } else {
      setCheckedOne("unchecked");
    }

    if (e.target.value === "227") {
      setCheckedTwo("checked");
      setquest77Previous("227");
      onSelectAnswer(e);
    } else {
      setCheckedTwo("unchecked");
    }
  };

  return (
    <>
      <div className="question-content mt-0 scale-down-ver-center px-3 py-lg-3 py-md-3 py-sm-2 py-2 pb-3 ">
        <div className="row">
          <div className="col-6">
            <div className=" mb-2">
              <button
                type="button"
                className={`qb17 btn-comm2 py-lg-3 py-md-3 py-sm-2 py-2 w-100 nextBtn ${checkedTwo}`}
                onClick={optionSelected}
                value="226"
                name="didReceiveIncome"
                id="nextscroll"
                data-quest-id={77}
                data-ans-id={226}
                data-ans-label={null}
              >
                Yes
              </button>
            </div>
          </div>
          <div className="col-6">
            <div className=" mb-2">
              <button
                type="button"
                className={`qb17 btn-comm2 py-lg-3 py-md-3 py-sm-2 py-2 w-100 nextBtn ${checkedOne}`}
                value="227"
                name="didReceiveIncome"
                onClick={optionSelected}
                id="nextscroll"
                data-quest-id={77}
                data-ans-id={227}
                data-ans-label={null}
              >
                No
              </button>
            </div>
          </div>
        </div>
        <p className="text-center bck-marwid">
          <button
            name="minmumPaymnt"
            className="btn btn-secondary backbtn1a"
            id=""
            value=""
            type="button"
            onClick={backClick}
          >
            Go Back
          </button>
        </p>
      </div>
    </>
  );
};
export default DidReceiveIncome;
