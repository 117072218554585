import React, {useEffect} from 'react';
import "../../assets/OD_UFL_4/scss/main.scss"
import Logo from '../../assets/OD_UFL_4/img/logo-white.png';
import Thankyou from '../../assets/OD_UFL_4/img/thankyou.gif';
import Footer from '../Includes/Layouts/OD_UFL_4/Footer'
import { useUpdateMilestone } from '../../Hooks/useUpdateMilestone';

const ThankyouODUFL4 = () => {
  const urlSearchParams = new URLSearchParams(window.location.search);
  const uuid = urlSearchParams.get('uuid');
  const { updateMilestone } = useUpdateMilestone();

  useEffect(() => {
    const allFollowupData = JSON.parse(localStorage.getItem('allFollowupData'));
        if (allFollowupData) {
            let flp_source = allFollowupData.atp_sub6;
            updateMilestone(uuid, "completed", flp_source);
        }
        else {
            updateMilestone(uuid, "completed", "live");
        }
    localStorage.clear();
  }, []);
    return (
        <>
            <div className='OD_UFL_4'>
                <section className="banner qs-section">
                    <div className="container">
                        <div>
                            <div className="brand">
                                <div className="logo pt-3 text-center">
                                    <a href="index.html"> <img src={Logo} height={60} /></a>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="container">
                        <div className="mt-5 text-center pt-5">
                            <div className="mb-0 mt-5">
                                <img src={Thankyou} height={150}  />
                            </div>
                            <h1 className="text-white">Thank you</h1>
                            <p className="text-white">We will get in touch with you shortly
                            </p>
                        </div>
                    </div>
                </section>
                <Footer />
            </div>


        </>
    )
}

export default ThankyouODUFL4