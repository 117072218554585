import React from "react";
import "../../assets/ClaimOutcome/scss/modules/modal_design.scss"

const ClaimOutcome = () => {
  return (
    <>
    <div className="claim-outcome">
      <section class="bg_gray">
        <div class="container">
          <div class="row">
            <div class="col-lg-8 offset-lg-2 col-12">
              <div class="goodntoknowshow pb-4">
                <div class="pb-4 px-lg-4 px-md-4 px-sm-0">
                  <div class="title mb-2">
                    <strong class="textgood">
                      Please review our vehicle finance information below to see
                      possible outcomes from your claim.
                    </strong>
                  </div>
                  <p class="fst-italic">
                    <i class="bi bi-exclamation-circle pe-1"></i> Note, some
                    lenders will txt you saying the vehicle may be taken back -
                    don't be put off until you understand your situation, this
                    is often not applicable):
                  </p>
                  <div class="mt-3 mb-3">
                    <h5 class="text-arrow fs-6">
                      There are 4 typical scenarios, please check which one
                      applies to you:
                    </h5>
                  </div>
                  <p class="fw-bold title_ques">Vehicle Finance is Repaid</p>
                  <p class="">For example</p>
                  <ul>
                    <li>
                      You have come to the end of the agreement, and you own the
                      vehicle
                    </li>
                    <li>
                      Your vehicle was used in part exchange for another one
                    </li>
                    <li>
                      Your vehicle was sold by you to settle the agreement
                    </li>
                  </ul>
                  <h5 class="text-arrow fs-6">
                    What happens if the claim is upheld?
                  </h5>
                  <p class="mb-0">
                    You will receive a refund of all interest and charges you
                    paid on the agreement. 8% interest is then added to this
                    amount.
                  </p>
                  <p class="">There is no requirement to return the vehicle.</p>
                </div>

                <div class="pb-4 px-lg-4 px-md-4 px-sm-0">
                  <p class="fw-bold title_ques">
                    Vehicle Finance is still running & you have paid MORE than
                    the original amount borrowed
                  </p>
                  <h5 class="text-arrow fs-6">
                    What happens if the claim is upheld?
                  </h5>
                  <p class="mb-0">
                    Because you have paid more than the original amount
                    borrowed, any payments you made beyond this should be
                    refunded. The balance still owing is cleared.
                  </p>
                  <p class="">The vehicle is transferred into your name.</p>
                </div>

                <div class="pb-4 px-lg-4 px-md-4 px-sm-0">
                  <p class="fw-bold title_ques">
                    You handed back the vehicle, or the lender repossessed it
                  </p>
                  <p class="">For example</p>
                  <ul>
                    <li>Voluntary termination</li>
                    <li>Voluntary surrender</li>
                    <li>Repossession by lender</li>
                  </ul>
                  <h5 class="text-arrow fs-6">
                    What happens if the claim is upheld?
                  </h5>
                  <p class="mb-0">
                    Generally you would have paid less than the original amount
                    borrowed for the vehicle in this scenario.
                  </p>
                  <p class="mb-0">
                    You would receive a cash refund of your deposit, plus the
                    monthly payments you paid when you no longer had the
                    vehicle, less a fair usage charge. Any remaining balance you
                    owe should be cleared.
                  </p>
                  <p>There is no requirement to return the vehicle.</p>
                </div>

                <div class="pb-4 px-lg-4 px-md-4 px-sm-0">
                  <p class="fw-bold title_ques">
                    The vehicle finance is still running and you have paid LESS
                    than the original amount borrowed (tends to happen early in
                    the vehicle finance agreement).
                  </p>
                  <p class="fst-italic fw-bold">
                    <i class="bi bi-exclamation-circle pe-1"></i> this scenario
                    could be problematic for some clients.
                  </p>
                  <h5 class="text-arrow fs-6">
                    What happens if the claim is upheld?
                  </h5>
                  <p class="mb-0">
                    You would receive a refund of your deposit plus the payments
                    you have made, less a fair usage charge.
                  </p>
                  <p class="mb-0">
                    Any remaining balance you owe should be cleared.
                  </p>
                  <p>The lender may take back the vehicle.</p>
                </div>

                <div class="pb-4 px-lg-4 px-md-4 px-sm-0">
                  <h5 class="text-arrow fs-6">
                    What happens if the claim is rejected?
                  </h5>
                  <p class="mb-0">
                    in all cases your current arrangement would remain the same
                    / the lender may try to help you with a payment plan.
                  </p>
                </div>

                <p class="fst-italic fw-bold">
                  <i class="bi bi-exclamation-circle pe-1"></i> This information
                  is based on typical Financial Ombudsman decisions relating to
                  vehicle finance
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>
      </div>
    </>
  );
};

export default ClaimOutcome;
