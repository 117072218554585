import React, { useState } from "react";
const OutstandBal = ({
  className,
  saveQuestAnswer,
  validation,
  addPending,
  stillAct,
  removeFromPending,
  qnNo
}) => {
  const [checkedOne, setCheckedOne] = useState("unchecked");
  const [checkedTwo, setCheckedTwo] = useState("unchecked");


  const optionSelected = async (e) => {
    if (e.target.value === "245") {
      setCheckedOne("checked");
      removeFromPending("debtBalance");
      addPending("debtBalValue");
    } else {
      setCheckedOne("unchecked");
    }

    if (e.target.value === "246") {
      setCheckedTwo("checked");
      removeFromPending("debtBalance");
      removeFromPending("debtBalValue");
    } else {
      setCheckedTwo("unchecked");
    }
    saveQuestAnswer(89,e.target.value)
  };

  return (
    <>
    <div className={`container ${className}`}>
      <div className="questitem mt-5  qc-8 mt-0 scale-down-ver-center px-3 py-lg-3 py-md-3 py-sm-2 py-2 pb-3">
      <h1 className="que-title qt-8 p-3 ">
          Do you have any debt/outstanding balance with Vanquis?
        </h1>
        <div className="row">
          <div className="col-6">
            <div className=" mb-2">
              <button
                type="button"
                className={`qb8 btn-comm2 py-lg-3 py-md-3 py-sm-2 py-2 w-100 nextBtn ${checkedTwo}`}
                onClick={optionSelected}
                value="245"
                name="OutstandBalance"
                id="nextscroll"
                data-quest-id={89}
                data-ans-id={245}
                data-ans-label={null}
                data-click-id='debtBalance_yes'
              >
                Yes
              </button>
            </div>
          </div>
          <div className="col-6">
            <div className=" mb-2">
              <button
                type="button"
                className={`qb8 btn-comm2 py-lg-3 py-md-3 py-sm-2 py-2 w-100 nextBtn ${checkedOne}`}
                value="246"
                name="OutstandBalance"
                onClick={optionSelected}
                id="nextscroll"
                data-quest-id={89}
                data-ans-id={246}
                data-ans-label={null}
                data-click-id='debtBalance_no'
              >
                No
              </button>
            </div>
          </div>
        </div>
        <p className="text-center bck-marwid">
          <button
            name="outstandBack"
            className="btn btn-secondary backbtn1a"
            id=""
            value=""
            type="button"
            onClick={()=>addPending("stillActive")}
            data-click-id='debtBalance_back'
          >
            Go Back
          </button>
        </p>
      </div>
      <div
          id="rangequestions"
          class="qrange d-flex align-items-sm-center text-center"
        >
          Question <br class="d-none d-md-block" />{qnNo}
        </div>
    </div>
    </>
  );
};
export default OutstandBal;
