import { useRef, useState, useContext, useReducer, useEffect } from "react";
import SignatureCanvas from "react-signature-canvas";
import { CheckUUID } from "../../../../Utility/CheckUUID";
import { useSignature } from "../../../../Hooks/useSignature";
import { useHistory } from "react-router-dom";
import VisitorParamsContext from "../../../../Contexts/VisitorParams";
import { queryString } from "../../../../Utility/QueryString";
import GtmDataLayer from "../../../../Utility/GtmDataLayer";
import CheckBox2 from "./Checkbox2";
import ErrorMessage from "./ErrorMessage";

import LOA from "../../../../assets/pdf/LOA_Vanquis.pdf";
import TOB from "../../../../assets/pdf/Business_Terms_TMS.pdf";
import DECL from "../../../../assets/pdf/Vaq_Declaration.pdf";
import VQ from "../../../../assets/pdf/Questionnaire_Vanquis.pdf";
import DSAR from "../../../../assets/pdf/DSAR_LOA_NEWDAY.pdf";
import CFD from "../../../../assets/pdf/FOS_Vanquis.pdf";

const initialState = {
  isChecked: false,
  isAgreed: false,
  isCanvasEmpty: false,
  isValid: false,
};
const signatureReducer = (state, action) => {
  switch (action.type) {
    case "signPadChange": {
      return {
        ...state,
        isCanvasEmpty: action.payload.value,
      };
    }
    case "privacyAgreed": {
      return {
        ...state,
        isAgreed: action.payload.value,
      };
    }
    case "validSignature": {
      return {
        ...state,
        isValid: action.payload.value,
      };
    }
    default:
      return state;
  }
};

const Sign = ({
  pageChange,
  basicDetailsValidation,
  setSignatureDataURL,
  openPrevPopup,
}) => {
  const message_type = "signature_store";
  const [state, dispatch] = useReducer(signatureReducer, initialState);
  const signatureWrapRef = useRef(null);
  const signPadRef = useRef();
  const [isChecked, setIsChecked] = useState(true);
  const [signatureWrapWidth, setSignatureWrapWidth] = useState(0);
  const [signatureWrapHeight, setSignatureWrapHeight] = useState(0);
  const { saveSignature, isLoading } = useSignature();
  const { visitorParameters } = useContext(VisitorParamsContext);
  const history = useHistory();
  // const [btnDisable, setBtnDisable]=useState(false);

  const formParameters = JSON.parse(localStorage.getItem("formData"));

  const handleClick = async (e) => {
    
    
    const validcheck = await basicDetailsValidation();
    if (validcheck) {
      
      try {
        e.preventDefault();
        const signPad = signPadRef.current;
        if (signPad.isEmpty()) {
          return dispatch({ type: "signPadChange", payload: { value: true } });
        }
        if (!isChecked) {
          return dispatch({ type: "privacyAgreed", payload: { value: true } });
        }
        const rpdata = signPad.toData();
        let signLength = 0;
        for (let i = 0; i < rpdata.length; i++) {
          signLength += Object.keys(rpdata[i]).length;
        }
        if (signLength > 13 ) {
          const canvasDataURL = signPadRef.current.getCanvas().toDataURL("image/png");
          setSignatureDataURL(canvasDataURL);
          // setBtnDisable(true);
          GtmDataLayer({
            question: "signature",
          });
          openPrevPopup();

        } else {
          return dispatch({ type: "validSignature", payload: { value: true } });
        }
      } catch (e) {
        console.warn(e);
      }
    }
  };

  useEffect(() => {
    if (!isChecked) {
      dispatch({ type: "privacyAgreed", payload: { value: false } });
    }
  }, [isChecked]);

  useEffect(() => {
    function handleResize() {
      setSignatureWrapWidth(signatureWrapRef.current.clientWidth);
      setSignatureWrapHeight(signatureWrapRef.current.clientHeight);
    }
    setSignatureWrapWidth(signatureWrapRef.current.clientWidth);
    setSignatureWrapHeight(signatureWrapRef.current.clientHeight);

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  const clearSign = () => {
    if (signPadRef.current) signPadRef.current.clear();
  };

  return (
    <div>
      <div className="sign-sec" id="sign-div">
        <h5 className=" mb-3 mt-4">
          Please provide your signature as in Vanquis records. Your signature
          will authorise us to draft the claim application on your behalf.
        </h5>
        <div className="signbox" ref={signatureWrapRef}>
          <SignatureCanvas
            canvasProps={{
              id: "myID",
              width: signatureWrapWidth,
              height: 200,
            }}
            ref={signPadRef}
            onEnd={() => {
              if (!signPadRef?.current?.isEmpty()) {
                dispatch({
                  type: "signPadChange",
                  payload: { value: false },
                });
                dispatch({
                  type: "validSignature",
                  payload: { value: false },
                });
              }
            }}
          />
        </div>
        {state?.isCanvasEmpty && (
          <ErrorMessage message={"Signature is required!"} />
        )}
        {state?.isValid && <ErrorMessage message={"Draw valid signature!"} />}
        <input
          type="button"
          id=""
          name=""
          className="clearbutton"
          value="Clear"
          onClick={clearSign}
        />
        <div>
          <CheckBox2
            dataId="checkbox1"
            anchorText=""
            anchorStyle=""
            href=""
            // onChange={(e) => setIsChecked(!isChecked)}
            onChange={() => {
              setIsChecked(!isChecked);
              dispatch({
                type: "privacyAgreed",
                payload: { value: isChecked },
              });
            }}
            className="checkinput me-2"
            value={isChecked}
            checked={isChecked}
            CheckBoxText="By clicking Save, I confirm that I have not previously
                    filed a claim with Vanquis and I am not currently subject to
                    Bankruptcy or a Trust Deed"
          />
        </div>
        {state?.isAgreed && (
          <ErrorMessage
            message={
              "Please tick to show your authorisation for us to proceed!"
            }
          />
        )}
      </div>
      <div>
        <button
          className=" btn-comm2 p-2 w-100 mb-lg-0 mb-md-0 mb-sm-0 mb-3 py-3 zoom-in-zoom-out "
          id="formSubmitBtn"
          name="formSubmitBtn"
          type="button"
          onClick={handleClick}
          // disabled={btnDisable}
        >
          <span>
            Save Now <i className="bi bi-caret-right-fill" />
          </span>
        </button>
      </div>

      <div className="row mt-3 mb-2 pdf-doc-links">
        <p className="card_head fw-bold">
          We require all of our clients to ensure they have read all our
          following documents.
        </p>

        <div className="col-md-6">
          <ul className="m-0 p-0">
            <li className="mt-0">
              <a href={LOA} target="_blank">
                <i className="bi bi-arrow-up-right-square" /> Letter of
                Authority
              </a>
            </li>
            <li className="mt-0">
              <a href={TOB} target="_blank">
                <i className="bi bi-arrow-up-right-square" /> Terms of Business
                Agreement
              </a>
            </li>
            <li className="mt-0">
              <a href={DSAR} target="_blank">
                <i className="bi bi-arrow-up-right-square" /> DSAR Letter of
                Authority
              </a>
            </li>
          </ul>
        </div>
        <div className="col-md-6">
          <ul className="m-0 p-0 mb-2">
            <li className="mt-0">
              <a href={VQ} target="_blank">
                <i className="bi bi-arrow-up-right-square" /> Questionnaire
              </a>
            </li>
            <li className="mt-0">
              <a href={DECL} target="_blank">
                <i className="bi bi-arrow-up-right-square" /> Declaration Form
              </a>
            </li>
          </ul>
        </div>
      </div>
    </div>
  );
};

export default Sign;
