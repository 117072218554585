import "../../../assets/VAQ_UB_4_3_1_C2/scss/custom.scss";
import AdtopiaLoadLP from "../../../Utility/AdtopiaLoadLP";
import FbViewPixel from "../../../Utility/FbViewPixel";
import FormVAQ_UB_4_3_1_C2 from "../../Forms/FormVAQ_UB_4_3_1_C2";
import AccordSection from "../../Includes/Layouts/VAQ_UB_4_3_1_C2/AccordSection";
import Footer from "../../Includes/Layouts/VAQ_UB_4_3_1_C2/Footer";

export default function VAQ_UB_4_3_1_C2() {
  return (
    <>
      <AdtopiaLoadLP pageType="LP" splitName="VAQ_UB_4_3_1_C2" />
      <FbViewPixel />
      <div className="VAQ_UB_4_3_1_C2 form-font">
        <FormVAQ_UB_4_3_1_C2 />
        <AccordSection />
        <Footer />
      </div>
    </>
  );
}
