import React  from "react";
const StuckInOverdraft = ({
    onSelectAnswer,
    backClick,
    setStuckinOverdraftbck
}) => {
    const optionClicked = async (e) => {
        if(e.target.value == "293"){
            setStuckinOverdraftbck("293");
        }
        onSelectAnswer(e);
    };

    return (
        <>
            <div className="question-content mt-0 scale-down-ver-center px-3 py-lg-3 py-md-3 py-sm-2 py-2 ">
                <div className="row">
                    <div className="col-lg-3 col-md-6 col-sm-12">
                        <div className=" mb-2">
                            <button
                                type="button"
                                className="btn-comm2 py-lg-3 py-md-3 py-sm-2 py-2 w-100 nextBtn"
                                onClick={optionClicked}
                                value="290"
                                name="stuckinoverdraft"
                                id="nextscrolla"
                                data-quest-id={105}
                                data-ans-id={290}
                                data-ans-label={"Always"}
                            >Always
                            </button>
                        </div>
                    </div>

                    <div className="col-lg-3 col-md-6 col-sm-12">
                        <div className=" mb-2">
                            <button
                                type="button"
                                className="btn-comm2 py-lg-3 py-md-3 py-sm-2 py-2 w-100 nextBtn"
                                onClick={optionClicked}
                                value="291"
                                name="stuckinoverdraft"
                                id="nextscrolla"
                                data-quest-id={105}
                                data-ans-id={291}
                                data-ans-label={"Sometimes"}
                            >Sometimes
                            </button>
                        </div>
                    </div>
                    <div className="col-lg-3 col-md-6 col-sm-12">
                        <div className=" mb-2">
                            <button
                                type="button"
                                className="btn-comm2 py-lg-3 py-md-3 py-sm-2 py-2 w-100 nextBtn"
                                onClick={optionClicked}
                                value="292"
                                name="stuckinoverdraft"
                                id="nextscrolla"
                                data-quest-id={105}
                                data-ans-id={292}
                                data-ans-label={"Rarely"}
                            >Rarely
                            </button>
                        </div>
                    </div>

                    <div className="col-lg-3 col-md-6 col-sm-12">
                        <div className=" mb-2">
                            <button
                                type="button"
                                className="btn-comm2 py-lg-3 py-md-3 py-sm-2 py-2 w-100 nextBtn"
                                onClick={optionClicked}
                                value="293"
                                name="stuckinoverdraft"
                                id="nextscrolla"
                                data-quest-id={105}
                                data-ans-id={293}
                                data-ans-label={"Never"}
                            >Never
                            </button>
                        </div>
                    </div>
                </div>
                <p className="text-center bck-marwid">
                    <button
                        name="maintainedcreditbalance"
                        className="btn btn-secondary backbtn1a"
                        id=""
                        value=""
                        type="button"
                        onClick={backClick}

                    >Go Back</button>
                </p>
            </div>
        </>
    )
}
export default StuckInOverdraft; 