import React from "react";
import loader_review2 from "../../../../assets/Quest_VAQ_UB_4_2/img/loader-review2.svg";
const Loader = ({ showLoad }) => {
    return (
        <>
            <div className={`loader-wrap text-center text-light ${showLoad} `}>
                <div>
                    <img height="80" src={loader_review2} alt="" />
                    <h6 className="mt-3">Please wait while we save your details</h6>
                </div>
            </div>

        </>
    )
}
export default Loader;