import React from 'react';
import starimg from "../../../../assets/MB_UFGN_4_2/img/stars-5.svg";
import { Carousel } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';



const Trustpilot = () => {
    return (
    <>
   <Carousel id="carouselExampleCaptions" className="carousel slide">
   <Carousel.Item className="carousel-item">
                <a href="https://www.trustpilot.com/review/pba-claims.co.uk" target="_blank" rel="noopener noreferrer">
                    <img src={starimg} alt="Trustpilot star rating" style={{ height: '24px' }} />
                        <h5>Great review from me contact was very good</h5>
                        <p>
                            Great review from me contact was very good, always replied when messaged, quick response too.<br />
                            A long process but not in any way TMC's fault. Can’t fault, would recommend. For me, a nice payout too thank you, that’s helped towards my wedding. Brilliant, thanks again.
                        </p>
                        <p><b>Paul Hammers Jackson</b></p>
                        <p><b>Date of experience:</b> September 03, 2024</p>
                </a>
            </Carousel.Item>
            <Carousel.Item className="carousel-item">
                <a href="https://www.trustpilot.com/review/pba-claims.co.uk" target="_blank" rel="noopener noreferrer">
                    <img src={starimg} alt="Trustpilot star rating" style={{ height: '24px' }} />
                        <h5>Excellent experience</h5>
                        <p>
                            Excellent experience. They did everything for me. Within a week of winning the claim, the money was in my account. It does take time but it’s worth it. Don’t worry if they don’t contact you for a few months; they are going through the process. I started to worry with no contact, but I emailed them and they responded within a day or two.
                        </p>
                        <p><b>Dean</b></p>
                        <p><b>Date of experience:</b> September 05, 2024</p>
                </a>
            </Carousel.Item>
            <Carousel.Item className="carousel-item ">
                <a href="https://www.trustpilot.com/review/pba-claims.co.uk" target="_blank" rel="noopener noreferrer">
                    <img src={starimg} alt="Trustpilot star rating" style={{ height: '24px' }} />
                        <h5>I got back almost £1,500</h5>
                        <p>
                            I'm stunned they got back almost £1,500 for me after I saw their ad on Facebook.<br />
                            Yes, their commission is a hefty 45%, but I didn't expect to get any money back, so I am not complaining.
                        </p>
                        <p><b>Lorraine King</b></p>
                        <p><b>Date of experience:</b> September 13, 2024</p>
                </a>
            </Carousel.Item>
            <Carousel.Item className="carousel-item">
                <a href="https://www.trustpilot.com/review/pba-claims.co.uk" target="_blank" rel="noopener noreferrer">
                    <img src={starimg} alt="Trustpilot star rating" style={{ height: '24px' }} />
                        <h5>The service from TMS was second to none</h5>
                        <p>
                            The service from TMS was second to none. Very informative, kept me up to date with all the proceedings.<br />
                            Extremely professional. Can't praise highly enough for the incredible work and amazing result.
                        </p>
                        <p><b>Michael Crosbie</b></p>
                        <p><b>Date of experience:</b> September 09, 2024</p>
                </a>
            </Carousel.Item>
            <Carousel.Item className="carousel-item">
                <a href="https://www.trustpilot.com/review/pba-claims.co.uk" target="_blank" rel="noopener noreferrer">
                    <img src={starimg} alt="Trustpilot star rating" style={{ height: '24px' }} />
                 
                        <h5>I've received great service from this company</h5>
                        <p>
                            I've received great service from this company since making a claim last year. The claim was taking quite a long time but they always kept in contact with regular updates. When I won the claim, they were very quick to get the money paid directly to my bank account. Would definitely use them again!
                        </p>
                        <p><b>Sam</b></p>
                        <p><b>Date of experience:</b> September 06, 2024</p>
                </a>
            </Carousel.Item>
           
        </Carousel>

    </>
    )

};
export default Trustpilot;