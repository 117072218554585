import React from 'react'

const MaintainedCreditBalance = ({
    onSelectAnswer,
    backClick }) => {
    const optionHandle = async (e) => {

        if (e.target.value === "288") {
            onSelectAnswer(e);
        } else if (e.target.value === "289") {
            onSelectAnswer(e);
        }

    }

    return (
        <div className="question-content mt-0 scale-down-ver-center px-3 py-lg-3 py-md-3 py-sm-2 py-2 ">
            <div className="row">
                <div className="col-6">
                    <div className=" mb-2">
                        <button
                            type="button"
                            className={`btn-comm2 py-lg-3 py-md-3 py-sm-2 py-2 w-100 nextBtn`}
                            onClick={optionHandle}
                            value="288"
                            name="maintainedcreditbalance"
                            id="nextscroll"
                            data-quest-id={104}
                            data-ans-id={288}
                            data-ans-label={null}
                        >Agree
                        </button>
                    </div>
                </div>
                <div className="col-6">
                    <div className=" mb-2">
                        <button
                            type="button"
                            className={`btn-comm2 py-lg-3 py-md-3 py-sm-2 py-2 w-100 noissue`}
                            onClick={optionHandle}
                            value="289"
                            name="maintainedcreditbalance"
                            id="nextscroll"
                            data-quest-id={104}
                            data-ans-id={289}
                            data-ans-label={null}
                        >Disagree
                        </button>
                    </div>
                </div>
            </div>
            <p className="text-center bck-marwid">
                <button
                    name="struggledrepayments"
                    className="btn btn-secondary backbtn1a"
                    id=""
                    value=""
                    type="button"
                    onClick={backClick}
                >
                    Go Back
                </button>
            </p>
        </div>
    )
}

export default MaintainedCreditBalance;