import { useState, useEffect, useRef } from "react";
// import { useFormContext } from "react-hook-form";
import Select from "react-select";
import { TopSelectedLenders } from "../../../../Constants/Constants";
import { obLender } from "../../../../Hooks/useOblender";
import RadioButtonQuest from "../../../UI/RadioButtonQuest";
import BankAccNo from "./BankAccNo";
import SortCode from "./SortCode";
import ShareInfo from "./ShareInfo";
import Autocomplete from "./AutoComplete";

const Banks = ({
  className,
  backClick,
  saveQuestAnswer,
  SkippedLeads,
  removeFromPending,
  shareInfoSave,
  qnNo,
  setError,
  trigger,
  validation,
  clearErrors,
  validationMsg
}) => {
  const [BankValidationMsg, setBankValidationMsg] = useState("");
  const [accNumPopUp, setAccNumPopUp] = useState("hide");
  const [sortCodePopUp, setSortCodePopUp] = useState(false);
  const [shareInfoPopUp, setShareInfoPopUp] = useState("hide");
  const { getLenderlist } = obLender();
  const [lenderList, setLenderList] = useState([]);
  const [SelectBank, setSelectBank] = useState([]);
  const [lenderName, setLenderName] = useState("");
  const [accNum, setAccNum] = useState(false);
  const [sortCode, setSortCode] = useState(false);

  const customStyles = {
    control: (base) => ({
      ...base,
      minHeight: 30,
    }),
    menu: (base) => ({
      ...base,
      zIndex: 9999,
    }),
    menuList: (base) => ({
      ...base,
      zIndex: 9999,
    }),
  };

  const nextSlide = async (e) => {
    let LengthSelectedBank = SelectBank.length;
    let ValueSelectedBank = SelectBank.value;

    if (e.target.name == "bankOptions") {
      const selectedLender = TopSelectedLenders.find(
        (lender) => lender.value === e.target.value
      );
      if (selectedLender) {
        localStorage.setItem("bud_lender", e.target.value);
        setLenderName(selectedLender.label);
        setShareInfoPopUp("show");
        saveQuestAnswer(64, null, selectedLender.value);
      }
    } else {
      if (LengthSelectedBank < 1 || ValueSelectedBank == "") {
        setBankValidationMsg("Please select your Bank");
      } else {
        localStorage.setItem("bud_lender", ValueSelectedBank);
        localStorage.setItem("lenderArray", JSON.stringify([SelectBank]));
        setLenderName(ValueSelectedBank);
        setShareInfoPopUp("show");
        saveQuestAnswer(64, null, ValueSelectedBank);
      }
    }
  };

  const getLender = async () => {
    try {
      const response = await getLenderlist();
      const dataLength = response.data.providers.length;

      if (dataLength > 0) {
        setLenderList(response.data.providers);
      }
    } catch (error) {
      console.error("Error fetching lender list:", error);
    }
  };

  useEffect(() => {
    getLender();
  }, [className]);

  let BankList = [];

  if (lenderList.length > 0) {
    BankList = BankList.concat(
      lenderList
        .filter(function (val) {
          return (
            !val.display_name.endsWith("Card") &&
            !val.display_name.endsWith("Business") &&
            !val.display_name.endsWith("card") &&
            !val.display_name.endsWith("Cards") &&
            !val.display_name.includes("Barclays") &&
            !val.display_name.includes("NatWest") &&
            !val.display_name.includes("Halifax") &&
            !val.display_name.includes("Lloyds") &&
            !val.display_name.includes("Santander") &&
            !val.display_name.includes("Nationwide") &&
            !val.display_name.includes("HSBC") &&
            !val.display_name.includes("Others")
          );
        })
        .map(function (val) {
          return val.display_name;
        })
    );
  }
  const OtherLendfn = async (e) => {
    const input = document.getElementById("otherlenders").value;

    e.preventDefault();
    const otherlender = await trigger("otherlenders");

    if (input != "" && otherlender) {
      localStorage.setItem("bud_lender", input);
      setLenderName(input);
      saveQuestAnswer(64, null, input);
      setShareInfoPopUp("show");
    } else {
      setError("otherlenders", {
        type: "manual",
        message: "Please Enter Valid Bank Name",
      });
    }
  };

  const closeModal = (e) => {
    setShareInfoPopUp("hide");
    setAccNumPopUp("hide");
    setSortCodePopUp("hide");
  };

  return (
    <>
      <div className={`container ${className}`}>
        <div className="questitem mt-5">
          <h4 className="px-3 pt-3 fs-5 mb-0">
            Your bank transactions provides compelling evidence of your
            repayment with Vanquis, and also acts as a solid proof to support
            your hardship and struggles.
          </h4>
          <h1 className="que-title qt-1 p-3 mt-0">
            Please select who were you banking with when you opened your account
            with Vanquis.
          </h1>
          <div className="question-content mt-0 scale-down-ver-center  px-3 py-lg-3 py-md-3 py-sm-2 py-2">
            <div className="mb-4">
              <ul className="p-0 m-0 option-grid">
                {TopSelectedLenders.map((lender, index) => (
                  <li key={lender.label}>
                    <div className="optionbtn">
                      <RadioButtonQuest
                        type="button"
                        //labelClassName={`${checkedOne}`}
                        id={`bankOption${index + 1}`}
                        name="bankOptions"
                        value={lender.value}
                        labelName={lender.label}
                        onClick={nextSlide}
                        dataQuestId={64}
                        dataAnsId={lender.value}
                        dataAnsLabel={lender.label}
                        dataClickId="chooseLender_option_next"
                      >
                        {lender.label}
                      </RadioButtonQuest>
                    </div>
                  </li>
                ))}
              </ul>
            </div>
            <div className="row">
              <div className="col-lg-8 mb-2 z-3 autocomplete-wrapper">
                <Autocomplete
                  validation={validation}
                  suggestionList={BankList}
                  setSelectBank={setSelectBank}
                  clearErrors={clearErrors}
                />

                {validationMsg.otherlenders && (
                  <span id="bank_err" className="error_msg">
                    {validationMsg.otherlenders.message}
                  </span>
                )}
              </div>
              <div className="col-lg-4 mb-2">
                <button
                  type="button"
                  className=" btn-comm2 py-lg-3 py-md-3 py-sm-2 py-2 w-100 nextBtn "
                  id="submitLender"
                  onClick={OtherLendfn}
                  name="banks"
                  data-quest-id={64}
                  data-click-id="chooseLender_next"
                >
                  Next &#8594;
                </button>
              </div>
            </div>
          </div>
        </div>
        <div class="tipbox tip2 show">
          We need to confirm transactional details with your bank to produce a
          compelling complaint to Vanquis.<div class="arrow  arrow-top"></div>
        </div>
        <div
          id="rangequestions"
          class="qrange d-flex align-items-sm-center text-center"
        >
          Question <br class="d-none d-md-block" />
          {qnNo}
        </div>
      </div>
      {accNumPopUp && (
        <>
          <BankAccNo
            backClick={backClick}
            saveQuestAnswer={saveQuestAnswer}
            SkippedLeads={SkippedLeads}
            setAccNum={setAccNum}
            lenderName={lenderName}
            accNumPopUp={accNumPopUp}
            setAccNumPopUp={setAccNumPopUp}
            setSortCodePopUp={setSortCodePopUp}
            closeModal={closeModal}
            validation={validation}
            trigger={trigger}
          />
        </>
      )}

      {sortCodePopUp && (
        <SortCode
          backClick={backClick}
          saveQuestAnswer={saveQuestAnswer}
          SkippedLeads={SkippedLeads}
          removeFromPending={removeFromPending}
          setSortCode={setSortCode}
          lenderName={lenderName}
          sortCodePopUp={sortCodePopUp}
          setSortCodePopUp={setSortCodePopUp}
          closeModal={closeModal}
        />
      )}
      {shareInfoPopUp && (
        <>
          <ShareInfo
            backClick={backClick}
            saveQuestAnswer={saveQuestAnswer}
            SkippedLeads={SkippedLeads}
            removeFromPending={removeFromPending}
            setAccNum={setAccNum}
            lenderName={lenderName}
            accNumPopUp={accNumPopUp}
            setAccNumPopUp={setAccNumPopUp}
            setSortCodePopUp={setSortCodePopUp}
            shareInfoPopUp={shareInfoPopUp}
            closeModal={closeModal}
            setShareInfoPopUp={setShareInfoPopUp}
          />
        </>
      )}
    </>
  );
};
export default Banks;
