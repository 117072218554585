import React, { useRef, useState, useContext, useReducer, useEffect, } from "react";
import { questinnare2LS, questinnare2, questinnare2Very, questinnare38, questinnare39, employmentVAQ3, answerArrayVery, questionnaire50, questinnaire76Vanquis, questinnaire36Vanquis, wereUalreadyPaying } from "../../../../Constants/Questions";
import { usePreviewData } from "../../../../Hooks/usePreviewData";
import '../../../../assets/FollowupS4/scss/modules/preview_flp_S4.scss';
import {
  VanquisProducts,
  ReliantBorrowing,
  Overindebtness,
  DebtEssentialBills,
  CreditFileInformation,
  ImpactedNegatively,
  HighUtilisation,
  StruggledRepayments,
  ClientFinancialArrears,
  CardNotManaged,
  UnableRepayments,
} from "../../../../Constants/Constants";
import { useHistory } from "react-router-dom";
import VisitorParamsContext from "../../../../Contexts/VisitorParams";
import GetVisitorsParams from "../../../../Utility/GetVisitorsParams";
import { useUpdateMilestone } from "../../../../Hooks/useUpdateMilestone";
import GtmDataLayer from "../../../../Utility/GtmDataLayer";
import { useClaimReg } from "../../../../Hooks/useClaimReg";




const PreviewTwo_FLP_S4 = ({uuid,removeFromPending}) => {

  const [showNextBtn, SetNextBtn] = useState(true);
  const [showSubmitBtn, SetShowSubmitBtn] = useState(false);
  const [productClaim, setProductClaim] = useState("");
  const [confirmation, setConfirmation] = useState();
  const [productType, setProductType] = useState('');
  const [credcardOpenYr, setCredcardOpenYr] = useState();
  const [financeAgreement, setFinanceAgreement] = useState();
  const [recentCredit, setRecentCredit] = useState();
  const [soldOnDebt, setSoldOnDebt] = useState();
  const [whatYearThisHappen, setWhatYearThisHappen] = useState();
  const [employmentVal, setEmploymentVal] = useState();
  const [repayment, SetRepayment] = useState();
  const [hightBalCred, setHightBalCred] = useState();
  const [accClosed_year, setAccClosedyear] = useState();
  const [creditCardlimitInc, setCreditCardlimitInc] = useState();
  const [exceedCredLimit, setExceedCredLimit] = useState();
  const [minPaymentMonDue, setMinPaymentMonDue] = useState();
  const [outstandingBalance, setOutstandingBalance] = useState();
  const [approximateOutstandingBalance, setApproximateOutstandingBalance] = useState();
  const [didReceiveIncome, setDidReceiveIncome] = useState();
  const [cardMonthlyIncome, setCardMonthlyIncome] = useState();
  const [dependent, SetDependent] = useState();
  const [question79, setQuestion79] = useState([]);
  const [slide80, setSlide80] = useState([]);
  const [userMortages, setUserMortage] = useState('');
  const [userRentss, setUserRent] = useState('');
  const [userTotalUtilityBillss, setUserTotalUtilityBills] = useState('');
  const [userMonthlyLoanRepaymentss, setUserMonthlyLoanRepayments] = useState('');
  const [userCreditCardRepaymentss, setUserCreditCardRepayments] = useState('');
  const [userMonthlyCarFinancess, setUserMonthlyCarFinance] = useState('');
  const [userMonthlyStoreCardPaymentss, setUserMonthlyStoreCardPayments] = useState('');
  const [userOtherMonthlyPaymentss, setUserOtherMonthlyPayments] = useState('');
  const [RegisEmail, SetRegisEmail] = useState();
  const [otherChoose, setOtherChoose] = useState([]);
  const [question76, setQuestion76] = useState([]);
  const [userName, setUserName] = useState();
  const [userSign, setUserSign] = useState();
  const [currentDate, setCurrentDate] = useState();
  const [productClaimTypeId, setProductClaimTypeId] = useState("");
  const [t2aStatus, SetT2AStatus] = useState();
  const { visitorParameters } = useContext(VisitorParamsContext);
  const { updateMilestone } = useUpdateMilestone();
  const { clickPreviewVisit, clickMeritPageVisit } = useClaimReg();
  const history = useHistory();
  const [signReview, setSignReview] = useState(0);
  const [bankruptcy, setBankruptcy] = useState("");
  const [hardshipQuest, setHardshipQuest] = useState("");

  const divRefQuest = useRef(null);
  let local_flp_source = localStorage.getItem("flp_source");
  let source = local_flp_source ? local_flp_source : "live";

  const { savePreviewData, getMeritPagePreviewData } = usePreviewData();
  const [btndisabled, setBtndisabled] = useState(false);

  const pushPageChange = async () => {
    setBtndisabled(true);
    try {
      const previewresponse = await clickPreviewVisit(
        uuid, 'followup-s4', "second_preview_submit", source
      );
      if (previewresponse.data.status == "Success") {
        // GtmDataLayer({
        //   'question': 'letter of authority'
        // });
        updateMilestone(uuid, "questionnaire_preview_submit", source);

        window.scrollTo(0, 0);
        removeFromPending('second_preview');
      }
    } catch (error) {
      console.error("Error:", error);
    }
  };

  const scrollToElement = () => {
    setSignReview(prev => prev + 1);
    SetNextBtn(false);
    SetShowSubmitBtn(true);
    divRefQuest.current.scrollIntoView({ behavior: 'smooth', block: 'start' })

  };

  const MonthlyIncomeList = () => {
    let letter = ["a", "b", "c", "d", "e", "f", "g", "h"];
    if (question79.length > 0) {
      return question79.map((ps, index) => {
        return (
          <p key={index}>
            {letter[index]}) {ps}
          </p>
        );
      });
    }
  };

  const OtherChooseStrCombined = () => {
    let letter = [
      "a", "b", "c", "d", "e", "f", "g", "h", "i", "j", "k", "l", "m", "n", "o", "p", "q", "r",
      "s", "t"];
    // let combinedArray = otherChoose.concat(question76);
    let combinedArray = [...otherChoose, ...question76];


    if (combinedArray.length > 0) {
      return combinedArray.map((ps, index) => {
        let letterIndex = index < letter.length ? index : index % letter.length;
        return (
          <p key={index}>
            {letter[letterIndex]}) {ps}
          </p>
        );
      });
    } else {
      setQuestion76(false);
      setOtherChoose(false);
      return null;
    }
  };

  function Capitalize(string) {
    return string.charAt(0).toUpperCase() + string.slice(1).toLowerCase();
  }

  useEffect(
    () => {
      window.scrollTo(0, 0);
      const ProductId = localStorage.getItem("ProductType");
      if (ProductId == "167") {
        setProductType("loan");
      } else {
        setProductType("card");
      }

      let userOtherChoose = [];
      let userSituation76 = [];
      let quest79List = [];

      const getResult = async () => {
        let resp = await savePreviewData(uuid);
        var result = resp.data.result;
        return result;
      };
      getResult().then((res) => {
        console.log("inside form undefined After Api call");


        const userVehicleReg = res.questionData.vehicleRegistration
          ? res.questionData.vehicleRegistration.toUpperCase()
          : "";
        const userKeeperDate = res.questionData.keeperDate
          ? res.questionData.keeperDate
          : "";
        const userIsVehicle = res.questionData.isVehicle
          ? res.questionData.isVehicle
          : "";

        const userMonthlyIncome = res.questionData.monthlyIncome
          ? res.questionData.monthlyIncome
          : "";
        const userRentExpense = res.questionData.rentExpense
          ? res.questionData.rentExpense
          : "";
        const userCarFinanceExpense = res.questionData.carFinanceExpense
          ? res.questionData.carFinanceExpense
          : "";
        const userOtherMonthlyCredit = res.questionData.otherMonthlyCredit
          ? res.questionData.otherMonthlyCredit
          : "";

        const isSele = res.questionData.otherChoose
          ? res.questionData.otherChoose
          : "";

        const still_active = res.questionData.stillactiveStat
          ? res.questionData.stillactiveStat
          : "";


        let dependent_status = res.questionData.dependent
          ? res.questionData.dependent
          : "";

        const repayment_status = res.questionData.HaveClaimwithlender
          ? res.questionData.HaveClaimwithlender
          : "";

        const hardshipQuest_val = res.questionData.verify
          ? res.questionData.verify
          : "";

        let bankruptcy_value = res.questionData.Bankruptcy
          ? res.questionData.Bankruptcy
          : "";

          if (bankruptcy_value === 22) {
            bankruptcy_value = 'NO';
          } else if (bankruptcy_value === 21){
            bankruptcy_value = 'YES';
          } else {
            bankruptcy_value = '';
          }

        const emp_statu = res.questionData.emp_status
          ? res.questionData.emp_status
          : "";

        const supp_docu = res.questionData.supp_document
          ? res.questionData.supp_document
          : "";

        const regis_email = res.questionData.reg_email
          ? res.questionData.reg_email
          : "";
        const product_claim = res.questionData.product_claimed_vanquis
          ? res.questionData.product_claimed_vanquis
          : "";
        let chooseObj = JSON.parse(isSele);

        chooseObj.forEach((ele) => {
          const found = questinnaire36Vanquis.answers.find(
            (obj) => obj.value == ele.id
          );
          if (found.value != '155') {
            userOtherChoose.push(found.label);
          }
        });

        const isSele76 = res.questionData.otherChoose76
          ? res.questionData.otherChoose76
          : "";

        let chooseObj76 = JSON.parse(isSele76);

        chooseObj76.forEach((ele) => {
          const foundopt = questinnaire76Vanquis.answers.find(
            (obj) => obj.value == ele.id
          );
          if (foundopt.value != '225') {
            userSituation76.push(foundopt.label);
          }
        });

        const hightestCredBal = res.questionData.hightBalCred
          ? res.questionData.hightBalCred
          : "";

        const accClosedyear = res.questionData.accCloseYear
          ? res.questionData.accCloseYear
          : "";

        const creditCardlimitInc = res.questionData.creditCardlimitInc
          ? res.questionData.creditCardlimitInc
          : "";

        const exceedCredLimit = res.questionData.exceedCredLimit
          ? res.questionData.exceedCredLimit
          : "";

        const minPaymentMonDue = res.questionData.minPaymentMonDue
          ? res.questionData.minPaymentMonDue
          : "";



        let what_year_happen = res.questionData.what_year_happen
          ? res.questionData.what_year_happen
          : "";



        let sold_debt_opt = res.questionData.sold_on_debt
          ? res.questionData.sold_on_debt
          : "";
        let sold_debt_on;

        if (sold_debt_opt === "YES") {
          sold_debt_on = 206;
        } else if (sold_debt_opt === "NO"){
          sold_debt_on = 207;
        } else {
          sold_debt_on = '';
        }

        const creditlimit_inc_year = res.questionData.creditlimit_inc_year
          ? res.questionData.creditlimit_inc_year
          : "";


        const isSele79 = res.questionData.quest_79
          ? res.questionData.quest_79
          : "";

        if (isSele79) {
          let chooseObj79 = JSON.parse(isSele79);

          if (chooseObj79?.length > 0) {
            chooseObj79.forEach((ele) => {
              const foundopts = wereUalreadyPaying?.answers.find(
                (obj) => obj.value == ele.id
              );
              if (foundopts.value) {
                quest79List.push(foundopts?.label);
              }
            });
          }
        }

        let mnthlyIncomeval = res.questionData.mnthlyIncome
          ? res.questionData.mnthlyIncome
          : "";

        let incomeYorN = res.questionData.incYesorNo
          ? res.questionData.incYesorNo
          : "";

        if (incomeYorN === 226) {
          incomeYorN = "YES";
        } else if (incomeYorN === 227){
          incomeYorN = "NO";
        } else {
          incomeYorN = "";
        }

        let OutStandBlncVal = res.questionData.OutStandBlnc
          ? res.questionData.OutStandBlnc
          : "";

        if (OutStandBlncVal == 245) {
          OutStandBlncVal = "YES";
        } else if (OutStandBlncVal == 246) {
          OutStandBlncVal = "NO";
        } else {
          OutStandBlncVal = "";
        }

        let ApproxOutStandBlncVal = res.questionData.ApproxOutStandBlnc
          ? res.questionData.ApproxOutStandBlnc
          : "";


        let slide80find = res.questionData ? res.questionData.newiande : "";


        let userMortage = '';
        let userRent = '';
        let userTotalUtilityBills = '';
        let userCreditCardRepayments = '';
        let userMonthlyLoanRepayments = '';
        let userMonthlyCarFinance = '';
        let userMonthlyStoreCardPayments = '';
        let userOtherMonthlyPayments = '';

        if (slide80find) {

          slide80find = JSON.parse(slide80find);

          let userMortage_find = slide80find.find((arr) => arr[0] === '228');
          if (userMortage_find) {
            userMortage = userMortage_find[1];
          }

          let userRent_find = slide80find.find((arr) => arr[0] === '229');
          if (userRent_find) {
            userRent = userRent_find[1];
          }

          let userTotalUtilityBills_find = slide80find.find((arr) => arr[0] === '230');
          if (userTotalUtilityBills_find) {
            userTotalUtilityBills = userTotalUtilityBills_find[1];
          }

          let userMonthlyLoanRepayments_find = slide80find.find((arr) => arr[0] === '231');
          if (userMonthlyLoanRepayments_find) {
            userMonthlyLoanRepayments = userMonthlyLoanRepayments_find[1];
          }

          let userCreditCardRepayments_find = slide80find.find((arr) => arr[0] === '232');
          if (userCreditCardRepayments_find) {
            userCreditCardRepayments = userCreditCardRepayments_find[1];
          }

          let userMonthlyCarFinance_find = slide80find.find((arr) => arr[0] === '233');
          if (userMonthlyCarFinance_find) {
            userMonthlyCarFinance = userMonthlyCarFinance_find[1];
          }

          let userMonthlyStoreCardPayments_find = slide80find.find((arr) => arr[0] === '234');
          if (userMonthlyStoreCardPayments_find) {
            userMonthlyStoreCardPayments = userMonthlyStoreCardPayments_find[1];
          }

          let userOtherMonthlyPayments_find = slide80find.find((arr) => arr[0] === '235');
          if (userOtherMonthlyPayments_find) {
            userOtherMonthlyPayments = userOtherMonthlyPayments_find[1];
          }
        }


        const creditCardOpenYr = res.questionData.creditCardOpenYr
          ? res.questionData.creditCardOpenYr
          : "";

        setHardshipQuest(hardshipQuest_val);
        setBankruptcy(bankruptcy_value);
        setOutstandingBalance(OutStandBlncVal);
        setApproximateOutstandingBalance(ApproxOutStandBlncVal);
        setCredcardOpenYr(creditCardOpenYr);
        setRecentCredit(creditlimit_inc_year);
        setWhatYearThisHappen(what_year_happen);
        setSoldOnDebt(sold_debt_on);
        setQuestion76(userSituation76);
        setMinPaymentMonDue(minPaymentMonDue);
        setExceedCredLimit(exceedCredLimit);
        setCreditCardlimitInc(creditCardlimitInc);
        setHightBalCred(hightestCredBal);
        setAccClosedyear(accClosedyear);
        setProductClaim(product_claim);
        setEmploymentVal(emp_statu);
        setUserName(res.client_name);
        SetT2AStatus(res.t2a_status);
        setUserSign(res.signature);
        let timeNow = new Date().toLocaleString();
        timeNow = timeNow.split(" ")[0].replace(/,/g, "");
        setCurrentDate(timeNow);
        setOtherChoose(userOtherChoose);
        setFinanceAgreement(still_active);
        SetDependent(dependent_status);
        SetRepayment(repayment_status);
        setCardMonthlyIncome(mnthlyIncomeval);
        setQuestion79(quest79List);
        setDidReceiveIncome(incomeYorN);
        setUserMortage(userMortage);
        setUserRent(userRent);
        setUserTotalUtilityBills(userTotalUtilityBills);
        setUserCreditCardRepayments(userCreditCardRepayments);
        setUserMonthlyLoanRepayments(userMonthlyLoanRepayments);
        setUserMonthlyCarFinance(userMonthlyCarFinance);
        setUserMonthlyStoreCardPayments(userMonthlyStoreCardPayments);
        setUserOtherMonthlyPayments(userOtherMonthlyPayments);
        setSlide80(slide80find);
        const email_provided = res.questionData.email_provided
          ? res.questionData.email_provided
          : "";
        if (email_provided && email_provided == '162') {
          SetRegisEmail(res.email);
        } else if (email_provided && email_provided == '163') {
          SetRegisEmail(regis_email);
        }

      });

    },
    []
  );
  return (

    <>
      <GetVisitorsParams />
      <div className="preview_flp_S4" >
        <div id="root">
          <div className="PreviewPageS4">
            <div className="container-top textwrap mb-5">
              <h2 className="preview_headline">
                Here is the UPDATED preview of the Questionnaire document with your
                answers.
              </h2>
              <p className="green_subtext">
              Please click the "SUBMIT" button on the bottom when you have reviewed the Document.
              </p>
            </div>
            <section className="container-1 mb-5 mt-1 declare-sec">
              <table>
                <tbody><tr>
                  <td>
                    <table className="logo-tab">
                      <tbody>
                        <tr>
                          <td style={{ textAlign: 'center' }}>
                            <img
                              src="assets/VAQ_UB_5/prviewPage/img/logo.png"
                              style={{ width: 125, opacity: "0.8" }}
                            />
                          </td>
                        </tr>
                      </tbody>
                    </table>
                    {/* Header */}
                    <div style={{ clear: 'both' }} />
                    <h4 style={{ fontSize: 24, margin: '10px 0', textAlign: 'center', color: '#2680d5', fontWeight: 400 }}>Vanquis Questionnaire</h4>


                    {productClaimTypeId && productClaimTypeId != null && productClaimTypeId != '' && (
                      <>
                        <p>
                          <b>
                            Which product would you like to register a claim
                            against?
                          </b>
                        </p>
                        {productClaimTypeId && productClaimTypeId == "168" ? (
                          <p>Vanquis</p>
                        ) : (
                          <p>{productClaim}</p>
                        )}
                        <br />
                      </>
                    )}

                    {/* <p>
                      <b>What year did you take out the product with Vanquis?</b>
                    </p>
                    <p>{financeYear}</p>
                    <br /> */}

                    {confirmation && confirmation != '' && (
                      <>
                        <p>
                          <b>
                            I only recently understood I had cause to complain
                            when TMS Legal represented my interests regarding
                            irresponsible lending. I considered my financial
                            issues to be my problem and didn't link it to any
                            irresponsible lending by the lender.
                          </b>
                        </p>
                        <p>{confirmation}</p>
                        <br />
                      </>
                    )}

                    {credcardOpenYr && credcardOpenYr != '' && (
                      <>
                        <p>
                          <b>{productType == "loan" ? 'What year did you open your Vanquis loan?' : 'What year did you open your Vanquis credit card?'}</b>
                        </p>
                        <p>{credcardOpenYr}</p>
                        <br />
                      </>
                    )}


                    {financeAgreement && financeAgreement != '' && (
                      <>
                        <p>
                          <b>Is your account with Vanquis still active?</b>
                        </p>
                        <p>{financeAgreement == "95" ? "YES" : "NO"}</p>
                        <br />
                      </>
                    )}

                    {/* {financeAgreement == "95" &&
                      <>
                        <p>
                          <b>Can you remember the last time Vanquis increased your credit limit?</b>
                        </p>
                        <p>{creditLimit == "197" ? 'YES' : 'NO'}</p>
                        <br />
                      </>
                    } */}

                    {financeAgreement == "95" ? (
                      <>
                        <p>
                          <b>What year was the last credit limit increase on your account, if ever?</b>
                        </p>
                        <p>{recentCredit}</p>
                        <br />
                      </>
                    ) : financeAgreement == "96" ? (
                      <>
                        <p>
                          <b>What year did you close the account?</b>
                        </p>
                        <p>{accClosed_year}</p>
                        <br />
                      </>
                    ) : ""}

                    {soldOnDebt && soldOnDebt != '' && (
                      <>
                        <p>
                          <b>
                            Has Vanquis sold on your debt, if any, to a company such
                            as Lowell Portfolio / Arrow Global?
                          </b>
                        </p>
                        <p>{soldOnDebt == "206" ? "YES" : "NO"}</p>
                        <br />
                      </>
                    )}

                    {soldOnDebt == "206" && (
                      <>
                        <p>
                          <b>What year did this happen?</b>
                        </p>
                        <p>{whatYearThisHappen}</p>
                        <br />
                      </>
                    )}

                    {employmentVal && employmentVal != '' && (
                      <>
                        <p>
                          <b>
                            At the time you took out the account with Vanquis what
                            was your employment status?
                          </b>
                        </p>
                        <p>{employmentVal}</p>
                        <br />
                      </>
                    )}

                   {bankruptcy && bankruptcy != '' && (
                      <>
                        <p>
                          <b>
                          Are you currently subject to Bankruptcy or Trust deed?
                          </b>
                        </p>
                        <p>{bankruptcy}</p>
                        <br />
                      </>
                    )}

                    {hardshipQuest && hardshipQuest != '' && (
                      <>
                        <p>
                          <b>
                          Have you experienced any hardships or struggle in making the repayments to Vanquis on time while still meeting all your other commitments?
                          </b>
                        </p>
                        <p>{hardshipQuest}</p>
                        <br />
                      </>
                    )}

                    {repayment && repayment != '' && (
                      <>
                        <p>
                          <b>
                            Have you, (or a company on your behalf), ever filed a
                            claim with Vanquis?
                          </b>
                        </p>
                        <p>{repayment}</p>
                        <br />
                      </>
                    )}
                    {/* <p>
                  <b>Are you currently subject to Bankruptcy or Trust deed?</b>
                </p>
                <p>{bankruptcy == "21" ? 'YES' : 'NO'}</p>
                <br /> */}

                    {hightBalCred && hightBalCred != '' && (
                      <>
                        <p>
                          <b>
                            Approximately what was the highest limit you ever had
                            with Vanquis credit account?
                          </b>
                        </p>
                        <p>{hightBalCred}</p>
                        <br />
                      </>
                    )}
                    {creditCardlimitInc && creditCardlimitInc != '' && (
                      <>
                        <p>
                          <b>
                            Approximately how many times was your credit limit
                            increased on this account?
                          </b>
                        </p>
                        <p>{creditCardlimitInc}</p>
                        <br />
                      </>
                    )}

                    {exceedCredLimit && exceedCredLimit != '' && (
                      <>
                        <p>
                          <b>
                            How often would you have a balance which was close to,
                            or exceeding your credit limit?
                          </b>
                        </p>
                        <p>{exceedCredLimit}</p>
                        <br />
                      </>
                    )}

                    {minPaymentMonDue && minPaymentMonDue != '' && (
                      <>
                        <p>
                          <b>
                            How often did you only make the minimum payment due on
                            the monthly account statement (rather than paying off a
                            larger amount)?
                          </b>
                        </p>
                        <p>{minPaymentMonDue}</p>
                        <br />
                      </>
                    )}

                    {outstandingBalance && outstandingBalance != "" && outstandingBalance == "YES" && (
                      <>
                        <p>
                          <b>
                            Do you have any debt/outstanding balance with Vanquis?
                          </b>
                        </p>
                        <p>{outstandingBalance}</p>
                        <br />
                      </>
                    )}

                    {approximateOutstandingBalance && approximateOutstandingBalance != "" && outstandingBalance == "YES" && (
                      <>
                        <p>
                          <b>
                            Please select from the list below approximately how much is your outstanding balance with Vanquis.
                          </b>
                        </p>
                        <p>{approximateOutstandingBalance}</p>
                        <br />
                      </>
                    )}


                    {didReceiveIncome && didReceiveIncome != null && (

                      <>
                        <p>
                          <b>{`In the year when you took the ${productType} – did you receive any income?`}</b>
                        </p>
                        <p>{didReceiveIncome}</p>
                        <br />
                      </>
                    )}

                    {(didReceiveIncome == "YES" && cardMonthlyIncome != "") && (
                      <>
                        <p>
                          <b>{`When you took out your ${productType} what was your monthly income?`}</b>
                        </p>
                        <p>{cardMonthlyIncome}</p>
                        <br />
                      </>
                    )}

                    {(dependent && dependent != "") && (
                      <>
                        <p>
                          <b>How many dependents did you have at that time?</b>
                        </p>
                        <p>{dependent}</p>
                        <br />
                      </>
                    )}


                    {question79 && question79.length > 0 && (
                      <>
                        <p>
                          <b>{`When you took out the ${productType},`} <b>{`were you already paying monthly`}</b> {`for one or more of the following:`}</b>
                        </p>
                        <MonthlyIncomeList />
                      </>
                    )}

                    {slide80 && (
                      <>
                        <p>
                          <b> Please confirm the following MONTHLY / ANNUAL income and expenditure details</b>
                        </p>

                        {(userMortages || userRentss) && (
                          <p>
                            My monthly Mortgage / Rent was:
                            {userMortages || userRentss}
                          </p>
                        )}
                        {userTotalUtilityBillss && userTotalUtilityBillss != "" && (
                          <>
                            <p> My monthly TOTAL utility bills were: {userTotalUtilityBillss}</p>
                          </>
                        )}
                        {userMonthlyLoanRepaymentss && userMonthlyLoanRepaymentss != "" && (
                          <>
                            <p> My monthly loan repayments were:{userMonthlyLoanRepaymentss}</p>
                          </>
                        )}
                        {userCreditCardRepaymentss && userCreditCardRepaymentss != "" && (
                          <>
                            <p> My monthly credit card repayments were: {userCreditCardRepaymentss}</p>
                          </>
                        )}
                        {userMonthlyCarFinancess && userMonthlyCarFinancess != "" && (
                          <>
                            <p> My monthly car finance payments were: {userMonthlyCarFinancess}</p>
                          </>
                        )}
                        {userMonthlyStoreCardPaymentss && userMonthlyStoreCardPaymentss != "" && (
                          <>
                            <p> My monthly store card payments were: {userMonthlyStoreCardPaymentss}</p>
                          </>
                        )}
                        {userOtherMonthlyPaymentss && userOtherMonthlyPaymentss != "" && (
                          <>
                            <p> My other monthly payment commitments were: {userOtherMonthlyPaymentss}</p>
                          </>
                        )}
                      </>
                    )}

                    {/* )} */}

                    {RegisEmail && RegisEmail != '' && RegisEmail != null && (

                      <>
                        <p>
                          <b>
                            Please provide the e-mail address you registered with
                            Vanquis
                          </b>
                        </p>
                        <p>{RegisEmail}</p>
                        <br />
                      </>
                    )}


                    {(otherChoose.length > 0 || question76.length > 0) && (
                      <div>
                        <p>

                          <b>{questinnaire36Vanquis.question}</b>
                        </p>
                        <OtherChooseStrCombined />
                      </div>
                    )}

                    <br />
                  </td>
                </tr>
                </tbody></table>
            </section>
            {/* page-6 */}
            <section className="container-1 my-5 table-sec">
              <table style={{ width: '100%' }}>
                <tbody>
                  <tr>
                    <td>
                      <div className="d-flex flex-column flex-lg-row justify-content-evenly">
                        <div style={{ borderRadius: 35, border: '1px solid #065197', padding: '18px 15px 2px 15px', width: 312, marginBottom: 12 }}>
                          <h3 style={{ textAlign: 'center', fontSize: 18, fontWeight: 900 }}>Customer 1</h3>
                          <p style={{ fontSize: 13, paddingTop: 3 }}>
                            Full Name : {userName}
                          </p>
                          <div style={{ border: '1px solid black', padding: '15px 5px 10px 5px' }}>
                            <table style={{ fontSize: 14, height: 50 }}>
                              <tbody>
                                <tr>
                                  <td>
                                    Signature:
                                  </td>
                                  <td>
                                    <img style={{ width: '85%' }} src={userSign} alt="" />
                                  </td>
                                </tr>
                              </tbody>
                            </table>
                          </div>

                          <p style={{ fontSize: 12 }}>
                            Dated: {currentDate}
                          </p>
                        </div>
                        <div style={{ borderRadius: 35, border: '1px solid #065197', padding: '18px 15px 2px 15px', width: 312, marginBottom: 12 }}>
                          <h3 style={{ textAlign: 'center', fontSize: 18, fontWeight: 900 }}>Customer 2</h3>
                          <p style={{ fontSize: 13, paddingTop: 3 }}>
                            Full Name: ____________________
                          </p>
                          <div style={{ border: '1px solid black', padding: '15px 5px 10px 5px' }}>
                            <table style={{ fontSize: 14, height: 50 }}>
                              <tbody>
                                <tr>
                                  <td>
                                    Signature:
                                  </td>
                                  <td>
                                  </td>
                                </tr>
                              </tbody>
                            </table>
                          </div>
                          <p style={{ fontSize: 12 }}>
                            Dated:
                          </p>
                        </div>
                      </div>
                    </td>
                  </tr>
                </tbody>
              </table>
            </section>


            {/* <footer className="next-btn  bg-dark p-2">
        <a className="anim_ylw_preview btn btn-success px-3 " id="next-btn2">
          Next
        </a>
      </footer> */}
            <footer className="next-btn  bg-dark p-2">
              <button
                className="anim_ylw_preview btn btn-success px-3"
                type="button"
                id="nextscroll"
                disabled={btndisabled}
                onClick={pushPageChange}
                data-click-id='secondPreview_next'
              >
                Submit</button>

              {/* <span className="text-white ms-3 ms-md-4 ms-lg-5">Signature Reviewed : {signReview}/1</span> */}
            </footer>
          </div>
        </div>
      </div>
    </>

  );
};

export default PreviewTwo_FLP_S4;