import React, { useState } from "react";
import { RecentCreditIncreaseYear } from "../../../../Constants/Constants";
import SelectBox from "../../../UI/SelectBox";
import { Modal } from "react-bootstrap";
import InputButton from "../../../UI/InputButton";
import RadioButtonQuest from "../../../UI/RadioButtonQuest";

const RecentCreditIncrease = ({
  className,
  saveQuestAnswer,
  validationMsg,
  clearErrors,
  validation,
  trigger,
  getValues,
  backClick,
  cardOpenYr,
  addPending,
  removeFromPending,
  qnNo
}) => {
  const [claimModal, setshowClaimModal] = useState(false);
  const handleCloseclaimModal = () => setshowClaimModal(false);
  const [exceededYear, setExceededYear] = useState(false);

  const selectedYear = async (e) => {
    if (e.target.name == "RecentCreditIncreaseOptions") {
      saveQuestAnswer(70,null,e.target.value);
    } else if (e.target.name == "dontRecall") {
      saveQuestAnswer(70,null,"I Don't Recall");
    } else {
      let errorFlag = 0;
      let yearresult = await trigger("RecentCreditIncrease");

      if (!yearresult) {
        errorFlag = 1;
        return false;
      }
      // const data = getValues();
      // let yearSelected = data.RecentCreditIncrease;
      // if (yearSelected < 2016) {
      //   setshowClaimModal(true);
      // }
      else {
        const valuesreg = getValues();
        saveQuestAnswer(70, null, valuesreg['RecentCreditIncrease']);
      }
    }
    removeFromPending("credLimitIncrYear");
  };

  const RecentCreditIncreaseYearV2 = [{ label: "Select Year", value: "" }];
  const startRecentCreditIncreaseV2 = 2024;
  const endRecentCreditIncreaseV2 = cardOpenYr;

  for (
    var k = startRecentCreditIncreaseV2;
    k >= endRecentCreditIncreaseV2;
    k--
  ) {
    var objYearOfPossession = {};
    objYearOfPossession["label"] = k;
    objYearOfPossession["value"] = k;
    RecentCreditIncreaseYearV2.push(objYearOfPossession);
  }

  // RecentCreditIncreaseYearV2.push({
  //   label: "Can't Remember",
  //   value: "Can't Remember",
  // });

  // Filter out the "Select Year" option

  let filteredYears = RecentCreditIncreaseYearV2.filter(
    (year) => year.label !== "Select Year"
  );

  // Split the filteredYears array into two parts
  const radioButtonYears = filteredYears.slice(0, 7); // First 7 for radio buttons
  const selectBoxYears = filteredYears.slice(7); // Remaining for select box

  selectBoxYears.unshift({ label: "Select Year", value: "" });

  radioButtonYears.push({
    label: "Can't Remember",
    value: "Can't Remember",
  });

  return (
    <>
      <div className={`container ${className}`}>
        <div className="questitem mt-5  mt-0 scale-down-ver-center  px-3 py-lg-3 py-md-3 py-sm-2 py-2  ">
          <h1 className="que-title p-3 ">
            What year was the <b>last credit limit increase</b> on your account,
            if ever?
          </h1>
          <div className="row mb-3">
            <div className="mb-2">
              <ul className="p-0 m-0 option-grid">
                {radioButtonYears.map((year, index) => (
                  <li>
                    <div className="optionbtn qb7 nextBtn">
                      <div key={year.value || index}>
                        <RadioButtonQuest
                          type="button"
                          id={`optionsRecentCreditIncrease${index + 1}`}
                          name="RecentCreditIncreaseOptions"
                          value={year.value}
                          labelName={year.label}
                          onClick={selectedYear}
                          dataQuestId={70}
                          dataClickId='credLimitIncrYear_option_next'
                        >
                          {year.label}
                        </RadioButtonQuest>
                      </div>
                    </div>
                  </li>
                ))}
              </ul>
            </div>
          </div>

          {selectBoxYears.length > 1 && (
            <>
              <div className="row">
                <div className="col-lg-8 mb-2">
                  <SelectBox
                    className="form-select onSelect"
                    OptionValue={selectBoxYears}
                    name="RecentCreditIncrease"
                    id="RecentCreditIncrease"
                    onChange={() => clearErrors("RecentCreditIncrease")}
                    clearErrors={clearErrors}
                    myRef={validation({ required: "Please select year" })}
                    validationMsg={
                      validationMsg.RecentCreditIncrease &&
                      validationMsg.RecentCreditIncrease.message
                    }
                  ></SelectBox>
                </div>
                <div className="col-lg-4 mb-2">
                  <button
                    type="button"
                    className=" qb7 btn-comm2 py-lg-3 py-md-3 py-sm-2 py-2 w-100 nextBtn"
                    id="nextscroll"
                    name="recentcreditIncrease"
                    data-quest-id={70}
                    onClick={selectedYear}
                    data-click-id='credLimitIncrYear_next'
                  >
                    Next &#8594;
                  </button>
                </div>
              </div>
            </>
          )}
          <div className="row">
            <div className="col-lg-4 m-auto mb-2 mt-2">
              <button
                type="button"
                className=" qb7 btn-comm2 py-lg-3 py-md-3 py-sm-2 py-2 w-100 nextBtn"
                id="nextscroll"
                name="dontRecall"
                data-quest-id={70}
                onClick={selectedYear}
                data-click-id='credLimitIncrYear_next'
              >
                I Don't Recall
              </button>
            </div>
          </div>
          <p className="text-center bck-marwid">
            <button
              name="stillactive"
              className="btn btn-secondary backbtn1a"
              id=""
              value=""
              type="button"
              onClick={()=>addPending('stillActive')}
              data-click-id='credLimitIncrYear_back'
            >
              Go Back
            </button>
          </p>
        </div>
        <div class="tipbox tip2 show">
        If you are unsure, please select ‘I don’t recall<div class="arrow  arrow-top"></div>
        </div>
        <div
          id="rangequestions"
          class="qrange d-flex align-items-sm-center text-center"
        >
          Question <br class="d-none d-md-block" />{qnNo}
        </div>
      </div>
      <Modal
        size="md"
        show={claimModal}
        onHide={handleCloseclaimModal}
        centered
        backdrop="static"
        className="modal fade "
        id="cannotClaim"
      >
        <div className="modal-content">
          <Modal.Body>
            <div className="text-center my-3">
              <svg
                width="50px"
                height="50px"
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M8.5 9C8.22386 9 8 8.77614 8 8.5C8 8.22386 8.22386 8 8.5 8C8.77614 8 9 8.22386 9 8.5C9 8.77614 8.77614 9 8.5 9Z"
                  fill="#EF8019"
                  stroke="#EF8019"
                  strokeWidth="1.5"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <path
                  d="M15.5 9C15.2239 9 15 8.77614 15 8.5C15 8.22386 15.2239 8 15.5 8C15.7761 8 16 8.22386 16 8.5C16 8.77614 15.7761 9 15.5 9Z"
                  fill="#EF8019"
                  stroke="#EF8019"
                  strokeWidth="1.5"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <path
                  d="M12 22C17.5228 22 22 17.5228 22 12C22 6.47715 17.5228 2 12 2C6.47715 2 2 6.47715 2 12C2 17.5228 6.47715 22 12 22Z"
                  stroke="#EF8019"
                  strokeWidth="1.5"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <path
                  d="M7.5 15.5C7.5 15.5 9 13.5 12 13.5C15 13.5 16.5 15.5 16.5 15.5"
                  stroke="#EF8019"
                  strokeWidth="1.5"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>
            </div>

            <h4 className="text-center my-4">
              Sorry, we cannot accept your claim
            </h4>
            <div className="input-group  prevname-Btnpop justify-content-center">
              <InputButton
                className="btn-comm2modalreject py-lg-3 py-md-3 py-sm-2 py-2 w-100"
                name="modalBack"
                value="Close"
                btnType="button"
                onClick={handleCloseclaimModal}
              />
            </div>
          </Modal.Body>
        </div>
      </Modal>
    </>
  );
};
export default RecentCreditIncrease;
